import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  getMerchantDetialExternal,
  getAdminUserByMerchantId,
} from "store/actions"
import errorCatching from "../../helpers/commonhelpers/errorCatching"
import { isEmpty } from "lodash"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Link,
  Font,
} from "@react-pdf/renderer"
import PayAllLinkLogo from "../../assets/images/images/payalllink-logo@2x.png"
import AmadeusLogo from "../../assets/images/images/amadeuslogo.png"
import { Row, Col, Label, Container } from "reactstrap"
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
})
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    fontFamily: "Open Sans",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  colorBlue: {
    color: "#005eb8",
    fontWeight: "bold",
  },
  colorLightBlue: {
    backgroundColor: "#E1EFFB",
  },
  row: {
    flexDirection: "row",
  },
  col: {
    flexDirection: "column",
    padding: 5,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },

  marginTitle: {
    marginLeft: 10,
    fontSize: "10px",
  },
  registrationForm: {
    fontSize: "15px",
    marginTop: "-10px",
    marginLeft: "-5px",
  },

  payallLinkLogo: {
    width: "150px",
    height: "50px",
    marginLeft: "-10px",
  },
  fontSmall: {
    fontSize: "8px",
  },

  table: {
    width: "100%",
    marginLeft: 10,
    marginRight: 10,
  },
  titleName: {
    fontSize: "8px",
    fontWeight: "bold",
  },
  rowTable: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    borderTop: 0.8,
    borderBottom: 0.8,
    borderLeft: 0.8,
    borderRight: 0.8,
    borderColor: "#adb5bd",
    BorderStyle: "solid",
    fontSize: "10px",
  },
  header: {
    borderTop: 1,
    borderBottom: 1,
    borderLeft: 1,
    borderRight: 1,
    fontSize: "10px",
    width: "100%",
    padding: 5,
  },
  bold: {
    fontWeight: "bold",
    fontSize: "10px",
  },
  // So Declarative and unDRY 👌
  row1: {
    width: "25%",
    borderRight: 0.8,
  },
  row2: {
    width: "25%",
    borderRight: 0.8,
    padding: 5,
  },
  row3: {
    width: "25%",
    borderRight: 0.8,
    padding: 5,
  },
  row4: {
    width: "8%",
    borderRight: 0.8,
    padding: 5,
    fontSize: "6px",
  },
  // row for merchant details
  rowMerchantCol: {
    width: "50%",
  },
  rowMerchantColAdd: {
    width: "100%",
  },
})
function MerhantPDF(props) {
  const {
    onGetMerchantDetail,
    onGetAdminUserByMerchantID,
    merchantDetail,
    userDetail,
    loading,
    loadingUser,
    error,
    match: { params },
  } = props
  const [merchantDetails, setMerhcantDetails] = useState({})
  const [userDetails, setUserDetails] = useState({})
  const [errorResponse, setErrorResponse] = useState(undefined)
  useEffect(() => {
    if (params && params.id) {
      onGetMerchantDetail(params.id)
      onGetAdminUserByMerchantID(params.id)
    }
  }, [])
  useEffect(() => {
    if (merchantDetail && merchantDetail.merchantId !== undefined) {
      setMerhcantDetails(merchantDetail)
    }
  }, [merchantDetail])
  useEffect(() => {}, [merchantDetails])
  useEffect(() => {
    setUserDetails(userDetail)
  }, [userDetail])
  useEffect(() => {
    if (userDetails?.firstName === undefined) {
      //console.log("nothign")
      // window.location.reload()
    }
  }, [userDetails])
  useEffect(() => {
    if (error !== undefined && !isEmpty(error) && error !== {}) {
      setErrorResponse(error)
    }
  }, [error])

  const OpenLink = link => {
    // console.log(link)
    window.open(link, "_blank")
  }
  return (
    <>
      {loading &&
      loadingUser &&
      merchantDetails !== undefined &&
      userDetails !== undefined ? (
        <Row className="spinner-custom mx-auto my-auto pb-3">
          <LoadingSpinnerThreeDots />
        </Row>
      ) : (
        <PDFViewer style={styles.viewer} showToolbar={false}>
          {errorCatching(errorResponse, setErrorResponse)}
          {/* Start of the document*/}
          <Document>
            {/*render a single page*/}
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Image style={styles.payallLinkLogo} src={PayAllLinkLogo} />
                <Text style={[styles.registrationForm]}>
                  {" "}
                  Registration Form
                </Text>
              </View>
              <Row style={styles.row}>
                <View style={styles.table}>
                  <View
                    style={[
                      styles.rowTable,
                      styles.colorBlue,
                      styles.header,
                      styles.colorLightBlue,
                    ]}
                  >
                    <Text>Merchant Details</Text>
                  </View>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Merchant Name</Text>
                    </Col>
                    <Col style={[styles.col]}>
                      <Text>{merchantDetails.merchantName}</Text>
                    </Col>
                  </Row>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Merchant ID</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2]}>
                      <Text>{merchantDetails.merchantId}</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Merchant Type</Text>
                    </Col>
                    <Col style={[styles.col]}>
                      <Text>{merchantDetails.type}</Text>
                    </Col>
                  </Row>
                  {/* <Row style={styles.rowTable} wrap={false}>

              </Row> */}
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Business Reg No.</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2]}>
                      <Text>{merchantDetails.businessRegNumber}</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Tax Reg No.</Text>
                    </Col>
                    <Col style={[styles.col]}>
                      <Text>{merchantDetails.taxRegNumber}</Text>
                    </Col>
                  </Row>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Phone Number</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2]}>
                      <Text>{merchantDetails.phoneNumber}</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>PO Box</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2]}>
                      <Text>{merchantDetails.poBox}</Text>
                    </Col>
                  </Row>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>City</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2]}>
                      <Text>{merchantDetails.city}</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Country</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2]}>
                      <Text>{merchantDetails.country}</Text>
                    </Col>
                  </Row>
                </View>
              </Row>
              <Row style={styles.row}>
                <View style={styles.table}>
                  <View
                    style={[
                      styles.rowTable,
                      styles.colorBlue,
                      styles.header,
                      styles.colorLightBlue,
                    ]}
                  >
                    <Text>Contacts</Text>
                  </View>
                  <View style={[styles.rowTable]}>
                    <Col
                      style={[
                        styles.col,
                        styles.row3,
                        { fontWeight: "bold", textAlign: "center" },
                      ]}
                    >
                      <Text>Position</Text>
                    </Col>

                    <Col
                      style={[
                        styles.col,
                        styles.row3,
                        { fontWeight: "bold", textAlign: "center" },
                      ]}
                    >
                      <Text>Name</Text>
                    </Col>

                    <Col
                      style={[
                        styles.col,
                        styles.row3,
                        { fontWeight: "bold", textAlign: "center" },
                      ]}
                    >
                      <Text>Email</Text>
                    </Col>

                    <Col
                      style={[
                        styles.col,
                        styles.row3,
                        { fontWeight: "bold", textAlign: "center" },
                      ]}
                    >
                      <Text>Mobile</Text>
                    </Col>
                  </View>
                  {merchantDetails.contacts !== undefined
                    ? merchantDetails?.contacts.map((contact, i) => {
                        return (
                          <>
                            <Row style={styles.rowTable} wrap={false}>
                              <Col style={[styles.col, styles.row3]}>
                                <Text>{contact.position}</Text>
                              </Col>

                              <Col style={[styles.col, styles.row3]}>
                                <Text>{contact.name}</Text>
                              </Col>

                              <Col
                                style={[
                                  styles.col,
                                  styles.row3,
                                  styles.fontSmall,
                                ]}
                              >
                                <Text>{contact.email}</Text>
                              </Col>

                              <Col style={[styles.col, styles.row3]}>
                                <Text>{contact.mobile}</Text>
                              </Col>
                            </Row>
                          </>
                        )
                      })
                    : null}
                </View>
              </Row>
              <Row style={styles.row}>
                <View style={styles.table}>
                  <View
                    style={[
                      styles.rowTable,
                      styles.colorBlue,
                      styles.header,
                      styles.colorLightBlue,
                    ]}
                  >
                    <Text>Admin User</Text>
                  </View>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Title</Text>
                    </Col>
                    <Col style={[styles.col]}>
                      <Text>{userDetails?.title}</Text>
                    </Col>
                  </Row>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>First Name</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2]}>
                      <Text>{userDetails?.firstName}</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Last Name</Text>
                    </Col>
                    <Col style={[styles.col]}>
                      <Text>{userDetails?.lastName}</Text>
                    </Col>
                  </Row>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Email</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2, styles.fontSmall]}>
                      <Text wrap={true}>{userDetails?.email}</Text>
                    </Col>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Mobile</Text>
                    </Col>
                    <Col style={[styles.col]}>
                      <Text>{userDetails?.mobile}</Text>
                    </Col>
                  </Row>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Department</Text>
                    </Col>
                    <Col style={[styles.col]}>
                      <Text>{userDetails?.department}</Text>
                    </Col>
                  </Row>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Role</Text>
                    </Col>
                    <Col style={[styles.col]}>
                      <Text>{userDetails?.role}</Text>
                    </Col>
                  </Row>
                </View>
              </Row>
              <Row style={styles.row}>
                <View style={styles.table}>
                  <View
                    style={[
                      styles.rowTable,
                      styles.colorBlue,
                      styles.header,
                      styles.colorLightBlue,
                    ]}
                  >
                    <Text>Other Details</Text>
                  </View>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Notification Email</Text>
                    </Col>

                    {merchantDetails.notificationEmails !== undefined
                      ? merchantDetails?.notificationEmails.map((email, i) => {
                          return (
                            <Col style={[styles.col]}>
                              {/* <Row style={[styles.row, { marginTop: 2 }]}> */}
                              <Text>{email.email}</Text>
                              {/* </Row> */}
                            </Col>
                          )
                        })
                      : null}
                  </Row>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2, styles.titleName]}>
                      <Text>Services</Text>
                    </Col>

                    {merchantDetails.services !== undefined
                      ? merchantDetails?.services.map((service, i) => {
                          return (
                            <Col style={[styles.col]}>
                              {/* <Row style={[styles.row, { marginTop: 2 }]}> */}
                              <Text>
                                {service.service}{" "}
                                {merchantDetails?.services.length > 1 ? (
                                  <>,</>
                                ) : null}
                              </Text>
                              {/* </Row> */}
                            </Col>
                          )
                        })
                      : null}
                  </Row>
                  {merchantDetails.type === "Travel Agency" ? (
                    <Row style={styles.rowTable} wrap={false}>
                      <Col style={[styles.col, styles.row2, styles.titleName]}>
                        <Text>Office Ids</Text>
                      </Col>
                      <Col style={[styles.col]}>
                        {merchantDetails.officeIds !== undefined
                          ? merchantDetails?.officeIds.map((oid, i) => {
                              // if (i % 3 == 0) {

                              // }
                              // if (i % 3 !== 0) {
                              //   return (
                              //
                              //       <Text>{oid.officeId},</Text>
                              //
                              //   )
                              // }
                              return (
                                <Row
                                  key={i}
                                  style={[styles.row, { marginTop: 2 }]}
                                >
                                  <Text>{oid.officeId}</Text>
                                </Row>
                              )
                            })
                          : null}
                      </Col>
                    </Row>
                  ) : null}
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2]}>
                      <Text>Logo</Text>
                    </Col>
                    <Col style={[styles.col]}>
                      {/* <Image style={styles.Logo} src={merchantDetails.logo} /> */}

                      <Link style={[styles.fontSmall]}>
                        {merchantDetails.logo}
                      </Link>
                    </Col>
                  </Row>
                  <Row style={styles.rowTable} wrap={false}>
                    <Col style={[styles.col, styles.row2]}>
                      <Text>Banner</Text>
                    </Col>
                    <Col style={[styles.col]}>
                      {/* <div onClick={() => OpenLink(merchantDetails.banner)}> */}
                      <Link
                        style={[styles.fontSmall]}
                        src={merchantDetails.banner}
                        wrap={true}
                      >
                        {merchantDetails.banner}
                      </Link>
                      {/* <Text>Banner.png</Text> */}
                      {/* </div> */}
                    </Col>
                  </Row>
                </View>
              </Row>

              <Row style={[styles.row, { borderTop: 1, margin: 10 }]}>
                <View style={styles.section}>
                  <Text style={[styles.colorBlue, styles.bold]}>
                    Powered by Amadeus Qatar
                  </Text>
                </View>
              </Row>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  merchantDetail: state.Merchants.merchantDetail,
  userDetail: state.Users.userDetail,
  loading: state.Merchants.loading,
  loadingUser: state.Users.loading,
  error: state.Merchants.error,
})

const mapDispatchToProps = dispatch => ({
  onGetMerchantDetail: id => dispatch(getMerchantDetialExternal(id)),
  onGetAdminUserByMerchantID: id => dispatch(getAdminUserByMerchantId(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MerhantPDF)
