import React from "react"
import { Link } from "react-router-dom"
import { Button,  UncontrolledTooltip } from "reactstrap"
import {statusPillsBool} from '../../../helpers/commonhelpers/statusPills'



const PaymentGatewayColumns = (toggleModal,user,handleCommissionOverview) => {


  const isHidden = () => {
    if(user.role === "Admin") return true
    else return false
  }

  return(
    [
  
      {
        dataField: "paymentGateway",
        text: "Payment Gateway",
        sort: true,
        formatter: (cellContent, row) => {
            return <span>
     
              <b>{cellContent}</b>
            </span>
          }
      },
      // {
      //   dataField: "terminalId",
      //   text: "Terminal Id",
      //   sort: true,
      // },
      // {
      //   dataField: "terminalPassword",
      //   text: "Terminal Password",
      //   sort: true,
      //   hidden: isHidden()
      // },

      {
        dataField: "commissionBasedOn",
        text: "Basis",
        sort: true,
        hidden: isHidden()
      },

      
      {
        dataField: "paymentGatewayId",
        text: "Id",
        sort: true,
      },
    
      {
        dataField: "isActive",
        text: "isActive",
        sort: true,
        formatter: (cellContent, row) => {
            return <span>
     
              { statusPillsBool(cellContent,cellContent.toString(),true)}
    
            </span>
          }
      },
    
      {
        dataField: "commissionSettings",
        text: "More ",
        sort: true,
        hidden: isHidden(),
        formatter: (cellContent,row,rowIndex) => (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => handleCommissionOverview(rowIndex)}
          >
            View Details
          </Button>
        ),
      },
      
      // {
      //   dataField: "_id",
      //   hidden: isHidden(),
      //   text: "Action",
      //   formatter: () => (
      //     <>
      //       <Link to="#" className="mr-3 text-primary">
      //         <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip" />
      //         <UncontrolledTooltip placement="top" target="edittooltip">
      //           Edit
      //         </UncontrolledTooltip>
      //       </Link>
      //       <Link to="#" className="text-danger">
      //         <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip" />
      //         <UncontrolledTooltip placement="top" target="deletetooltip">
      //           Delete
      //         </UncontrolledTooltip>
      //       </Link>
      //     </>
      //   ),
      // },
    ]
  )

}

export default PaymentGatewayColumns
