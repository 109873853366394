//Search column
const CustomerColumns = () => [
 
  {
    dataField: "name",
    text: "Name",
    sort:true,
    formatter: (cellContent, row) => {
     return(
     <div className="customer-table">{cellContent}</div>
     )
    }
  },
  {
    text: " Email",
    dataField: "email",
    sort: true,
    formatter: (cellContent, row) => {
      return(
        <div className="customer-table">{cellContent}</div>
      )
     }
  },
  {
    dataField: "mobile",
    text: "Mobile",
    sort: true,
    formatter: (cellContent, row) => {
      return(
        <div className="customer-table">{cellContent}</div>
      )
     }
  },
  // {
  //   dataField: "menu",
  //   isDummyField: true,
  //   text: "Action",
  //   formatter: () => (
  //     <UncontrolledDropdown>
  //       <DropdownToggle href="#" className="card-drop" tag="i">
  //         <i className="mdi mdi-dots-horizontal font-size-18" />
  //       </DropdownToggle>
  //       <DropdownMenu right>
  //         <DropdownItem href="#">
  //           <i className="fas fa-pencil-alt text-success mr-1" />
  //           Edit
  //         </DropdownItem>
  //         <DropdownItem href="#">
  //           <i className="fas fa-trash-alt text-danger mr-1" />
  //           Delete
  //         </DropdownItem>
  //       </DropdownMenu>
  //     </UncontrolledDropdown>
  //   ),
 // },
]

//Column with Actions
// const CustomerColumns = () => [
 
//   {
//     dataField: "name",
//     text: "Name",
//   },
//   {
//     text: " Email",
//     dataField: "email",
//     sort: true,
//   },
//   {
//     dataField: "mobile",
//     text: "Mobile",
//     sort: true,
//   },
  // {
  //   dataField: "menu",
  //   isDummyField: true,
  //   text: "Action",
  //   formatter: () => (
  //     <UncontrolledDropdown>
  //       <DropdownToggle href="#" className="card-drop" tag="i">
  //         <i className="mdi mdi-dots-horizontal font-size-18" />
  //       </DropdownToggle>
  //       <DropdownMenu right>
  //         <DropdownItem href="#">
  //           <i className="fas fa-pencil-alt text-success mr-1" />
  //           Edit
  //         </DropdownItem>
  //         <DropdownItem href="#">
  //           <i className="fas fa-trash-alt text-danger mr-1" />
  //           Delete
  //         </DropdownItem>
  //       </DropdownMenu>
  //     </UncontrolledDropdown>
  //   ),
 // },
//]

export default CustomerColumns
