
/* MERCHANT DETAIL*/
export const GET_SUMMARY = "GET_SUMMARY"
export const GET_SUMMARY_SUCCESS = "GET_SUMMARY_SUCCESS"
export const GET_SUMMARY_FAIL = "GET_SUMMARY_FAIL"

export const SET_SURVEY_ANSWERS = "SET_SURVEY_ANSWERS"
export const SET_SURVEY_ANSWERS_SUCCESS = "SET_SURVEY_ANSWERS_SUCCESS"
export const SET_SURVEY_ANSWERS_FAIL = "SET_SURVEY_ANSWERS_FAIL"

export const GET_TOP_MERCHANTS = "GET_TOP_MERCHANTS"
export const GET_TOP_MERCHANTS_SUCCESS = "GET_TOP_MERCHANTS_SUCCESS"
export const GET_TOP_MERCHANTS_FAIL = "GET_TOP_MERCHANTS_FAIL"

export const GET_TOP_USERS = "GET_TOP_USERS"
export const GET_TOP_USERS_SUCCESS = "GET_TOP_USERS_SUCCESS"
export const GET_TOP_USERS_FAIL = "GET_TOP_USERS_FAIL"

export const RETURN_STATE = "RETURN_STATE"