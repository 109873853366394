import { UPDATE_CUSTOMER_RESULT_FAIL } from "store/customer/actionTypes"
import {
  SET_PAYMNET_GATEWAY,
  SET_PAYMNET_GATEWAY_SUCCESS,
  SET_PAYMNET_GATEWAY_FAIL,
  GET_PAYMENT_GATEWAY_LIST,
  GET_PAYMENT_GATEWAY_LIST_SUCCESS,
  GET_PAYMENT_GATEWAY_LIST_FAIL,
  UPDATE_PAYMENT_GATEWAY,
  UPDATE_PAYMENT_GATEWAY_SUCCESS,
  UPDATE_PAYMENT_GATEWAY_FAIL,
  UPDATE_STATUS_CHANGE,
  UPDATE_STATUS_CHANGE_SUCCESS,
  UPDATE_STATUS_CHANGE_FAIL,
  GET_PAYMENT_GATEWAY_DETAIL,
  GET_PAYMENT_GATEWAY_DETAIL_SUCCESS,
  GET_PAYMENT_GATEWAY_DETAIL_FAIL,
  RESTART_STATE
} from "./actionTypes"
export const setPaymentGateway = paygate => ({
  type: SET_PAYMNET_GATEWAY,
  payload: paygate
})

export const setPaymentGatewaySuccess = paygate => ({
  type: SET_PAYMNET_GATEWAY_SUCCESS,
  payload: paygate
})

export const setPaymentGatewayFail = paygate => ({
  type: SET_PAYMNET_GATEWAY_FAIL,
  payload: paygate
})

export const getPaymentGatewayList = paygate => ({
  type: GET_PAYMENT_GATEWAY_LIST,
  payload: paygate
})

export const getPaymentGatewayListSuccess = paygate => ({
  type: GET_PAYMENT_GATEWAY_LIST_SUCCESS,
  payload: paygate
})

export const getPaymentGatewayListFail = paygate => ({
  type: GET_PAYMENT_GATEWAY_LIST_FAIL,
  payload: paygate
})

export const getPaymentGatewayDetail = paygate => ({
  type: GET_PAYMENT_GATEWAY_DETAIL,
  payload: paygate
})

export const getPaymentGatewayDetailSuccess = paygate => ({
  type: GET_PAYMENT_GATEWAY_DETAIL_SUCCESS,
  payload: paygate
})

export const getPaymentGatewayDetailFail = paygate => ({
  type: GET_PAYMENT_GATEWAY_DETAIL_FAIL,
  payload: paygate
})

export const updatePaymentGateway = paygate => ({
  type: UPDATE_PAYMENT_GATEWAY,
  payload: paygate
})
export const updatePaymentGatewaySuccess = paygate => ({
  type: UPDATE_PAYMENT_GATEWAY_SUCCESS,
  payload: paygate
})
export const updatePaymentGatewayFail = paygate => ({
  type: UPDATE_PAYMENT_GATEWAY_FAIL,
  payload: paygate
})

export const updateStatusChange = paygate => ({
  type: UPDATE_STATUS_CHANGE,
  payload: paygate
})
export const updateStatusChangeSuccess = paygate => ({
  type: UPDATE_STATUS_CHANGE_SUCCESS,
  payload: paygate
})


export const updateStatusChangeFail = paygate => ({
  type: UPDATE_STATUS_CHANGE_FAIL,
  payload: paygate
})

export const restartState = () => ({
  type: RESTART_STATE
})
