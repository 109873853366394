import React, { useState, useEffect } from "react"
import { Col, Row, Button, Spinner, Modal, ModalBody, Label } from "reactstrap"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
import Layout from "./Layout"
function index(props) {
  const [loading, setLoading] = useState()
  const [modal_large, setModal] = useState(false)

  function tog_large() {
    setModal(!modal_large)
    removeBodyCss()
  }

  //Function for closing the modal
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <>
      <Modal className="my-modal" isOpen={modal_large} centered={true}>
        <div className="modal-header">
          <Label className="my-auto">SEARCH ALL</Label>
          <h5
            className="modal-title mt-0 color-danger"
            id="myLargeModalLabel"
          ></h5>
          <button
            onClick={() => {
              setModal(false)
            }}
            type="button"
            color="danger"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span caria-hidden="true" className="color-danger my-auto">
              {" "}
              x
            </span>
          </button>
        </div>
        <ModalBody>
          {loading ? (
            <Row className="spinner-custom mx-auto my-auto pb-3">
              <LoadingSpinnerThreeDots />
            </Row>
          ) : (
            <Layout />
          )}
        </ModalBody>
      </Modal>
      <Row>
        <Col sm="12" className="float-left">
          <Button
            color="success"
            data-toggle="modal"
            outline
            data-target=".bs-example-modal-lg"
            className="main-search-bar mr-1 my-3"
            //className="mr-1 waves-effect waves-light my-3"
            onClick={() => {
              tog_large()
            }}
          >
            <i className="fas fa-search mr-1" />
            Main Search
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default index
