import { actionTypes } from "redux-form"
import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAIL,
  GET_USER_LIST_BY_MERCHANTID,
  GET_USER_LIST_BY_MERCHANTID_SUCCESS,
  GET_USER_LIST_BY_MERCHANTID_FAIL,
  SET_USER,
  SET_USER_SUCCESS,
  SET_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DEACTIVATE_USER,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_FAIL,
  SET_NEW_USER_PASSWORD,
  SET_NEW_USER_PASSWORD_SUCCESS,
  SET_NEW_USER_PASSWORD_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  RETURN_STATE,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_ADMIN_USER_BY_MERCHANTID,
  GET_ADMIN_USER_BY_MERCHANTID_SUCCESS,
  GET_ADMIN_USER_BY_MERCHANTID_FAIL,
  IS_USER_EXISTING,
  IS_USER_EXISTING_SUCCESS, 
  IS_USER_EXISTING_FAIL
} from "./actionTypes"

const initialState = {
  user: {},
  usersList: {},
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_USER_LIST_SUCCESS:
      state = {
        ...state,
        usersList: action.payload,
        loading: false,
      }
      break
    case GET_USER_LIST_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case SET_USER:
      state = {
        ...state,
        user: action.payload,
        userCreated: undefined,
        loading: true,
      }
      break
    case SET_USER_SUCCESS:
      state = {
        ...state,
        userCreated: action.payload,
        loading: false,
      }
      break
    case SET_USER_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case DELETE_USER:
      state = {
        ...state,
        userDelete: undefined,
        loading: true,
      }
      break
    case DELETE_USER_SUCCESS:
      state = {
        ...state,
        userDelete: true,
        loading: false,
      }
      break
    case DELETE_USER_FAIL:
      state = {
        ...state,
        error: action.payload,
        userDelete: false,
        loading: false,
      }
      break
    case DEACTIVATE_USER:
      state = {
        ...state,
        userDeactivate: undefined,
        loading: true,
      }
      break
    case DEACTIVATE_USER_SUCCESS:
      state = {
        ...state,
        userDeactivate: true,
        loading: true,
      }
      break
    case DEACTIVATE_USER_FAIL:
      state = {
        ...state,
        error: action.payload,
        userDeactivate: false,
        loading: true,
      }
      break
    case SET_NEW_USER_PASSWORD:
      state = {
        ...state,
        newUserPassord: action.payload,
        loading: true,
      }
      break
    case SET_NEW_USER_PASSWORD_SUCCESS:
      state = {
        ...state,
        newUserPassord: action.payload,
        loading: false,
      }
      break
    case SET_NEW_USER_PASSWORD_FAIL:
      state = {
        ...state,
        newUserPassord: action.payload,
        loading: false,
      }
      break
    case UPDATE_USER:
      state = {
        ...state,
        user: action.payload,
        update: undefined,
        loading: true,
      }
      break
    case UPDATE_USER_SUCCESS:
      state = {
        ...state,
        update: action.payload,
        loading: false,
      }
      break
    case UPDATE_USER_FAIL:
      state = {
        ...state,
        update: action.payload,
        loading: false,
      }
      break
    case UPDATE_PASSWORD:
      state = {
        ...state,
        email: action.payload,
        loading: true,
      }
      break
    case UPDATE_PASSWORD_SUCCESS:
      state = {
        ...state,
        email: action.payload,
        loading: false,
      }
      break
    case UPDATE_PASSWORD_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

 
    case GET_USER:
      state = {
        ...state,
        user: action.payload,
        loading: true,
      }
      break
    case GET_USER_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        loading: false,
      }
      break
    case GET_USER_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case GET_USER_LIST_BY_MERCHANTID:
      state = {
        ...state,
        usersList: action.payload,
        loading: true,
      }

    case GET_USER_LIST_BY_MERCHANTID_SUCCESS:
      state = {
        ...state,
        usersList: action.payload,
        loading: false,
      }

    case GET_USER_LIST_BY_MERCHANTID_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    case RETURN_STATE:
      state = {
        ...state,
        email: undefined,
        update: undefined,
        userCreated: undefined,
        userDelete: undefined,
        error: undefined,
        user: undefined,
      }
      break
    case GET_ADMIN_USER_BY_MERCHANTID:
      state = {
        ...state,
        userDetail: action.payload,
        loading: true,
      }
      break
    case GET_ADMIN_USER_BY_MERCHANTID_SUCCESS:
      state = {
        ...state,
        userDetail: action.payload,
        loading: false,
      }
      break
    case GET_ADMIN_USER_BY_MERCHANTID_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

      case IS_USER_EXISTING:
      state = {
        ...state,
        user: action.payload,
        isUserExistingLoad: true,
      }
      break
    case IS_USER_EXISTING_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        isUserExistingLoad: false,
      }
      break
    case IS_USER_EXISTING_FAIL:
      state = {
        ...state,
        error: action.payload,
        isUserExistingLoad: false,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default user
