import React, { useEffect, useContext, useState } from 'react'
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Col,
    Input,
    Card,
    FormGroup,
    Label,
    CardBody,
    Button,
    Collapse,

} from "reactstrap"
import { isEmpty } from 'lodash'
import { TransactionUpdateContext } from './index'

const UpdateTransactionModal = (props) => {

    const {
        isOpen,
        toggle,
        selectedRow,
        paymentHistory,
        errorPaymentHistory,
        isLoadedPaymentHistory,
        isLoadedEmail,
        isLoadedApprove,
        isErrorEmail,
        

    } = props

 

    const { handleUpdatePaymentHistory,
        handleEmail,
        handleApproved,
        handleOpenOverviewPrId
    } = useContext(TransactionUpdateContext)
    const [col2, setcol2] = useState(false)
    const [isLoadedUpdate, setIsLoadedUpdate] = useState(isLoadedPaymentHistory)
    const [isLoadedEmail_, setIsLoadedEmail_] = useState(isLoadedEmail)
    const [isLoadedApprove_, setIsLoadedApprove_] = useState(isLoadedApprove)
    const [isNotifyCustomer, setIsNotifyCustomer] = useState(false)
    const [isSelectedRow, seIsSelectedRow] = useState(selectedRow)


    useEffect(() => {
        seIsSelectedRow(selectedRow)
    }, [selectedRow])

    useEffect(() => {
        setIsLoadedUpdate(isLoadedPaymentHistory)
    }, [isLoadedPaymentHistory])

    useEffect(() => {
        setIsLoadedEmail_(isLoadedEmail)
    }, [isLoadedEmail])

    useEffect(() => {
        setIsLoadedApprove_(isLoadedApprove)
    }, [isLoadedApprove])

    function showUpdateButton() {

        if (isLoadedUpdate) {
            return <Button color="primary">
                <Spinner className="mt-4 mb-2 font-size-16" />
                <p className=" mb-4 font-size-16" style={{ color: "#2948df" }}>Click to Update Payment History{" "} </p>
            </Button>
        }

        return <Button color="primary" onClick={() => handleUpdatePaymentHistory(isSelectedRow)}>
            <i className="fas fa-arrow-alt-circle-up fa-2x mt-4 mb-2" />
            <p className=" mb-4 font-size-16">Click to Update Payment History{" "} </p>
        </Button>
    }


    function showEmailButton() {

        if (isLoadedEmail_) {
            return <><br></br> <Button color="primary">
                <i className="fas fa-envelope me-1" /> Please wait ... {" "}
            </Button> <Spinner className="ms-2" color="success" size="sm"/>
            </>
        }

        return <Button color="primary"
            onClick={() => handleEmail(isSelectedRow?.trackId, isNotifyCustomer)}>
            <i className="fas fa-envelope me-1" /> Send Email Notification{" "}
        </Button>
    }


    function showApproveButton() {

        if (isLoadedApprove_) {
            return <><br></br> <Button color="success" style={{width:"150px"}}>
                 <Spinner className="ms-2" size="sm" />
            </Button> 
            </>
        }

        return <Button color="success" onClick={() => handleApproved(isSelectedRow)}>
            Checked & Approved
            </Button>


    }





    function parseBool(value) {
        var bool = value === "true" ? true : false
        return bool
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                role="dialog"
                backdrop="static"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                tabindex="-1"
                toggle={toggle}
                size="lg"
            >
                <div className="modal-content">
                    <ModalHeader >Payment History of {isSelectedRow?.trackId} / {isSelectedRow?.paymentHistoryId} ({isSelectedRow?.response}) <b></b>
                    <Button  color="primary" outline className="waves-effect"  
                onClick={() => handleOpenOverviewPrId(isSelectedRow?.paymentRequestId)}>{isSelectedRow?.paymentRequestId}</Button>
               </ModalHeader>
                    <ModalBody>



                        <div className="checkout-tabs">

                            <Row>

                                <Col xl="3" sm="3">
                                    <h1>Step 1</h1>
                                </Col>
                                <Col xl="9" sm="9">
                                    {isSelectedRow.isRecordUpdated === false &&

                                        showUpdateButton()

                                    }

                                    {isSelectedRow.isRecordUpdated === true &&
                                        <>
                                            <Button color="secondary" style={{ cursor: "pointer", width: "50%" }}>
                                                <i className="fas fa-check fa-2x mt-4 mb-2" />
                                                <p className=" mb-4 font-size-16">Successfully Updated {" "} </p>
                                            </Button>
                                        </>

                                    }
                                    {!isEmpty(errorPaymentHistory) &&
                                        <span>Error: errorPaymentHistory</span>
                                    }
                                </Col>


                                <br></br>
                            </Row>

                            <br></br>

                            <Row>

                                <Col xl="3" sm="3">
                                    <h1>Step 2</h1>
                                </Col>
                                <Col xl="9" sm="9">

                                    <Button color="warning"
                                        onClick={() => { setcol2(!col2) }}
                                        style={{ cursor: "pointer", width: "50%" }}
                                    >
                                        <i className="fas fa-envelope fa-2x mt-4 mb-2" />
                                        <p className=" mb-4 font-size-16">Send Notification{" "} </p>
                                    </Button>
                                </Col>

                            </Row>

                            <Row>
                                <Col xl="3" sm="3"></Col>
                                <Col xl="9" sm="9">
                                    <Card>
                                        <CardBody>
                                            <Collapse isOpen={col2}>
                                                <hr></hr>
                                                <div>
                                                    <p>You may skip this step if necessary.</p><br></br>
                                                    <div>
                                                        <div className="form-check form-check-inline font-size-16">

                                                            <FormGroup>
                                                                <Input
                                                                    checked
                                                                    type="radio"
                                                                    value="true"
                                                                    id="customRadioInline1"
                                                                    name="customRadioInline1"
                                                                    className="form-check-input"
                                                                    onChange={(e) => setIsNotifyCustomer(parseBool(e.target.value))}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline1"
                                                                >

                                                                    Customer and Registered Notification Emails
                                                                 </Label>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="form-check form-check-inline font-size-16">
                                                            <FormGroup>
                                                                <Input
                                                                    type="radio"
                                                                    value="false"
                                                                    id="customRadioInline2"
                                                                    name="customRadioInline1"
                                                                    className="form-check-input"
                                                                    onChange={(e) => setIsNotifyCustomer(parseBool(e.target.value))}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13"
                                                                    htmlFor="customRadioInline2"
                                                                >

                                                                    Registered Notification Emails Only (excluding the customer)
                                                 </Label>
                                                            </FormGroup>
                                                        </div>

                                                        {isSelectedRow.isNotificationSent === false &&

                                                            showEmailButton()

                                                        }

                                                        {isSelectedRow.isNotificationSent === true &&
                                                            <>
                                                                <Button
                                                                    color="secondary"
                                                                >
                                                                    <i className="fas fa-check me-1" /> Successfully Sent Notification{" "}
                                                                </Button>
                                                            </>

                                                        }



                                                    </div>
                                                </div>

                                            </Collapse>{" "}


                                        </CardBody>
                                    </Card>
                                    <Row className="mt-4">

                                    </Row>
                                </Col>
                            </Row>


                        </div >



                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="danger" onClick={toggle}>
                            Cancel
                         </Button>
                        {isSelectedRow.isApproved === false &&

                            showApproveButton()

                        }

                        {isSelectedRow.isApproved === true &&
                            <>
                                <Button type="secondary" >
                                    <i className="fas fa-check me-1" /> Already Approved
                                </Button>
                            </>

                        }

                    </ModalFooter>
                </div>
            </Modal>
        </>
    )


}

export default UpdateTransactionModal
