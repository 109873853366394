import React, { useState, useEffect } from "react"
import { Col, FormGroup, Input, Row, Button, Form } from "reactstrap"

export const OfficeIds = props => {
  const {
    officeId,
    handleOfficeIdChange,
    handleOfficeIdDelete,
    validOfficeIdList,
    setValidOfficeIdList,
    index,
  } = props

  let inputOfficeId = "OfficeId" + index
  useEffect(() => {
    
  }, [validOfficeIdList])

  function handleChange(changes) {
    handleOfficeIdChange(officeId.id, { ...officeId, ...changes })
  }

  const [isOfficeIdValid, setIsOfficeIdValid] = useState()

  function handleOfficeId(value) {
    var pattern = /^[\w+]{9}$/

    if (pattern.test(value)) {
      setIsOfficeIdValid(true)
      if (!validOfficeIdList.includes(inputOfficeId)) {
        validOfficeIdList.push(inputOfficeId)
      }
    } else {
      setIsOfficeIdValid(false)
      if (validOfficeIdList.includes(inputOfficeId)) {
        let tempArray = validOfficeIdList.filter(
          item => !item.includes(inputOfficeId)
        )
        setValidOfficeIdList(tempArray)
      }
    }

    handleChange({ OfficeId: value })
  }

  return (
    <Row>
      <Col lg="7">
        <FormGroup>
          <input
            name={inputOfficeId}
            value={officeId.OfficeId}
            onChange={e => handleOfficeId(e.target.value)}
            onBlur={e => handleOfficeId(e.target.value)}
            type="text"
            className="form-control"
            placeholder="Enter OfficeId"
          />

          {isOfficeIdValid === false && (
            <small className="color-danger" style={{ float: "left" }}>
              Invalid OfficeId.
            </small>
          )}
        </FormGroup>
        <Input
          hidden
          disabled
          value={officeId.Date}
          name="Date"
          type="text"
          className="form-control"
          placeholder={" "}
        />
      </Col>
      <Col lg="4">
        <FormGroup>
          {index > 0 && (
            <Button
              onClick={e => handleOfficeIdDelete(officeId.id, index)}
              color="danger"
              className="btn-block inner "
              style={{ width: "40%" }}
            >
              <i className="mdi mdi-close-thick " />
            </Button>
          )}
        </FormGroup>
      </Col>
    </Row>
  )
}

export default OfficeIds
