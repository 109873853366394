import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Col, Row, Card, CardBody, Label } from "reactstrap"
import { getPlacerErrorLogList } from "store/actions"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import PlacerErrorLogsCol from "./PlacerLogsColumns.js"
import AuthUser from "components/AuthUser/AuthUser"
import { useDispatch } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { IsEmpty } from "react-lodash"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import { CSVLink } from "react-csv"

const headers = [
  { label: "Id", key: "_id" },
  { label: "User Id", key: "userId" },
  { label: "Date", key: "date" },
  { label: "File", key: "file" },
  { label: "Message", key: "message" },
]

function ErrorLogsTable(props) {
  const dispatch = useDispatch()
  const [user, setUser] = useState(AuthUser())
  const [logs, setLogs] = useState({})
  const pageOptionsEmailLogs = {
    sizePerPage: 15,
    totalSize: logs.length,
    custom: true,
    onPageChange: (page, sizePerPage) => {
      handleTableChangeLogs()
    },
  }
  let searchText = ""
  const [loading, setUserLoading] = useState(props.loading)

  const { SearchBar } = Search
  const { ExportCSVButton } = CSVExport
  useEffect(() => {
    if (user.role === "SuperAdmin") {
      dispatch(getPlacerErrorLogList())
    } else {
      window.location = "/page-404"
    }
  }, [])

  useEffect(() => {
    setUserLoading(true)
    if (Array.isArray(props.placerErrorLogs)) {
      setLogs(props.placerErrorLogs)
    }
  }, [props.placerErrorLogs])

  useEffect(() => {
    setUserLoading(false)
  }, [logs])

  useEffect(() => {
    if (props.loading !== undefined) {
      setUserLoading(props.loading)
    }
  }, [props.loading])

  useEffect(() => {}, [loading])

  const handleTableChangeLogs = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setLogs(
        logs.filter(logList =>
          Object.keys(logList).some(key =>
            logList[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }
  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Label> Error Logs</Label>
            {loading ? (
              <Row className="spinner-custom mx-auto my-auto pb-3">
                <LoadingSpinnerThreeDots />
              </Row>
            ) : (
              <IsEmpty
                value={logs}
                yes={() => (
                  <Row>
                    <Col>No list</Col>
                  </Row>
                )}
                no={() => (
                  <PaginationProvider
                    pagination={paginationFactory(pageOptionsEmailLogs)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={logs || []}
                        columns={PlacerErrorLogsCol()}
                        bootstrap4
                        search
                        exportCSV={{
                          fileName: "PlacerErrorList.csv",
                          separator: ",",
                          noAutoBOM: false,
                          exportAll: false,
                          onlyExportFiltered: true,
                        }}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2  mx-2">
                              <Col sm="4">
                                <div className="search-box mr-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="width-search"
                                      placeholder="Search"
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-right">
                                  {/* <CSVLink
                                                                        color="warning"
                                                                        filename={"LogsErrorPlacerList.csv"}
                                                                        className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                                                                        data={logs}
                                                                        headers={headers}
                                                                        key="_id"
                                                                        target='_blank'
                                                                       >
                                                                            <i className="bx bx-download label-icon mr-1" />
                                                                            Download Placer Error Logs
                                                                    </CSVLink> */}
                                  <ExportCSVButton
                                    color="warning"
                                    className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                                    {...toolkitProps.csvProps}
                                  >
                                    <i className="bx bx-download label-icon mr-1" />
                                    Download Placer Error Logs
                                  </ExportCSVButton>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    hover={true}
                                    striped={false}
                                    classes={
                                      "table table-centered pointer table-nowrap table-hover mr-5 mb-5"
                                    }
                                    //rowEvents={rowEvents}
                                    keyField="_id"
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChangeLogs(
                                      searchText
                                    )}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                )}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
const mapStateToProps = state => {
  return {
    loading: state.Logs.loading,
    placerErrorLogs: state.Logs.placerErrorLogs,
  }
}
export default connect(mapStateToProps)(ErrorLogsTable)
