import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

const CommissionModal = props => {
  const { isOpen, toggle, paymentGatewaySelected } = props
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabindex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Configuration Details of <b>{paymentGatewaySelected?.paymentGateway}</b></ModalHeader>
        <ModalBody>

<b>Commission</b>
          <Table>
            <thead>
              <tr>
                <th>Payment Method</th>
                <th>Commission Type</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>


              {paymentGatewaySelected?.commissionSettings?.map((c, index) =>

                <tr key={index}>
                  <td>{c.basis}</td>
                  <td>{c.type}</td>
                  <td>{c.value}</td>

                </tr>

              )}
              {/* {map(commissionSettings, (c, index) =>
                                    <tr key={index}>
                                        <td>{c.paymentMethod}</td>
                                        <td>{c.type}</td>
                                        <td>{c.value}</td>

                                    </tr>
                                )} */}
            </tbody>
          </Table>

      <hr></hr>
      <b>Custom Config</b>
      <Table>
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>


              {paymentGatewaySelected?.customConfig?.map((c, index) =>

                <tr key={index}>
                  <td>{c.key}</td>
                  <td>{c.value}</td>
                </tr>

              )}
              {/* {map(commissionSettings, (c, index) =>
                                    <tr key={index}>
                                        <td>{c.paymentMethod}</td>
                                        <td>{c.type}</td>
                                        <td>{c.value}</td>

                                    </tr>
                                )} */}
            </tbody>
          </Table>

        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

CommissionModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default CommissionModal
