import {
  SET_CUSTOMER,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_LIST_FAIL,
  SEARCH_CUSTOMER,
  GET_CUSTOMER_DETAIL,
  GET_CUSTOMER_DETAIL_SUCCESS,
  GET_CUSTOMER_DETAIL_FAIL,
  SET_CUSTOMER_SUCCESS,
  SET_CUSTOMER_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_RESULT_SUCCESS,
  UPDATE_CUSTOMER_RESULT_FAIL,
  GET_CUSTOMERS_BY_MERCHANTID,
  //TODO:change here
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  RESTART_STATE,
  SELECT_CUSTOMER,
  SELECT_CUSTOMER_SUCCESS,
  SELECT_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_EXPRESS,
  UPDATE_CUSTOMER_EXPRESS_SUCCESS,
  UPDATE_CUSTOMER_EXPRESS_FAIL,
  SET_CUSTOMER_EXPRESS,
  SET_CUSTOMER_EXPRESS_SUCCESS,
  SET_CUSTOMER_EXPRESS_FAIL,
} from "./actionTypes"

const initialState = {
  customer: {},
  expressCustomer: {},
  customerList: {},
  error: "",
}

const customer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER:
      state = {
        ...state,
        customer: action.payload,
        customerCreated: undefined,
      }
      break
    case SET_CUSTOMER_SUCCESS:
      state = {
        ...state,
        customerCreated: action.payload,
      }
      break
    case SET_CUSTOMER_FAIL:
      state = {
        ...state,
        customerCreated: action.payload,
        //error: action.payload,
      }
      break
    case GET_CUSTOMER_LIST:
      state = {
        ...state,
        customer: action.payload,
        loading: true,
      }
      break
    case GET_CUSTOMER_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        customerList: action.payload,
      }
      break
    case GET_CUSTOMER_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break
    case SEARCH_CUSTOMER:
      state = {
        customer: action.payload,
        loading: true,
      }

      break
    case GET_CUSTOMER_DETAIL:
      state = {
        ...state,
        customerList: action.payload,
        loading: true,
      }
      break
    case GET_CUSTOMER_DETAIL_SUCCESS:
      state = {
        ...state,
        customerList: action.payload,
        loading: false,
      }
      break
    case GET_CUSTOMER_DETAIL_FAIL:
      state = {
        ...state,
        customerList: action.payload,
        loading: false,
      }
      break
    case UPDATE_CUSTOMER:
      state = {
        ...state,
        customer: action.payload,
        update: undefined,
        loading: true,
      }
      break
    case UPDATE_CUSTOMER_RESULT_SUCCESS:
      state = {
        ...state,
        customer: action.payload,
        update: true,
        loading: false,
      }
      break
    case UPDATE_CUSTOMER_RESULT_FAIL:
      state = {
        ...state,
        error: action.payload,
        //update: false,
        loading: true,
      }
      break
    case UPDATE_CUSTOMER_EXPRESS:
      state = {
        ...state,
        expressCustomer: action.payload,
        update: undefined,
        loading: true,
      }
      break
    case UPDATE_CUSTOMER_EXPRESS_SUCCESS:
      state = {
        ...state,
        //expressCustomer: action.payload,
        update: true,
        loading: false,
      }
      break
    case UPDATE_CUSTOMER_EXPRESS_FAIL:
      state = {
        ...state,
        error: action.payload,
        //update: false,
        loading: true,
      }
      break
    case GET_CUSTOMERS_BY_MERCHANTID:
      state = {
        ...state,
        loading: true,
        customer: action.payload,
      }
    case DELETE_CUSTOMER:
      state = {
        ...state,
        customer: action.payload,
        customerDeleted: undefined,
        loading: true,
      }
      break
    case DELETE_CUSTOMER_SUCCESS:
      state = {
        ...state,
        customerDeleted: true,
        loading: false,
      }
      break
    case DELETE_CUSTOMER_FAIL:
      state = {
        ...state,
        customerDeleted: false,
        loading: false,
      }
      break
    case RESTART_STATE:
      state = {
        ...state,
        customerDeleted: undefined,
        customerCreated: undefined,
        expressCustomer: undefined,
        update: undefined,
      }
      break
    case SELECT_CUSTOMER:
      state = {
        ...state,
        selectedCustomer: action.payload,
        loading: true,
      }
      break
    case SELECT_CUSTOMER_SUCCESS:
      state = {
        ...state,
        selectedCustomer: action.payload,
        loading: false,
      }
      break
    case SELECT_CUSTOMER_FAIL:
      state = {
        ...state,
        selectedCustomer: action.payload,
        loading: false,
      }
      break
    case SET_CUSTOMER_EXPRESS:
      state = {
        ...state,
        expressCustomer: action.payload,
        loading: true,
      }
      break
    case SET_CUSTOMER_EXPRESS_SUCCESS:
      state = {
        ...state,
        expressCustomer: action.payload,
        loading: false,
      }
      break
    case SET_CUSTOMER_EXPRESS_FAIL:
      state = {
        ...state,
        expressCustomer: action.payload,
        loading: false,
      }
      break
    default:
      state = state
      break
  }
  return state
}

export default customer
