import React, { useState } from 'react'
import { Row, Card, CardTitle, CardBody, Table } from "reactstrap"
import AuthUser from "components/AuthUser/AuthUser"
import SimpleBar from "simplebar-react"

export default function Configuration(props) {


    const { linkValidity, marketingBanner, logo } = props

    const [user, setUser] = useState(AuthUser())




    return (
        <>
            <Card style={{ minHeight: "350px", maxHeight: "450px", overflow: "auto" }}>
                <CardTitle style={{ "backgroundColor": "#CCDFF1", "padding": "5px" }}><i className="fas fa-wrench text-success mr-1" />Configuration</CardTitle>
                <CardBody>
                    <Row>
                        
                            <Table>
                            <SimpleBar style={{ maxHeight: "450px" }}>
                                <tbody>
                                    <tr>
                                        <th scope="row"><i className="fas fa-file-alt text-success mr-1" />Invoice Link Validity: </th>
                                        <td>{linkValidity} HRS</td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><i className="fas fa-file-alt text-success mr-1" />Marketing Banner: </th>
                                        <td><a href={marketingBanner}>Show Banner</a></td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><i className="fas fa-file-alt text-success mr-1" />Logo: </th>
                                        <td ><a href={logo}>Show Logo</a></td>
                                    </tr>
                                </tbody>
                                </SimpleBar>
                            </Table>
                   
                    </Row>
                </CardBody>

            </Card>

        </>
    )
}
