import React from "react"
import {
  Badge,
  Row,
  Col
} from "reactstrap"
import dateFormat from '../../helpers/commonhelpers/dateFormat'

const EmailLogsColumns = (merchantList) => [
  {
    dataField: "email._id",
    text: "Id",
    searchable: true,
    sort: true,
  }, {
    dataField: "email.isSuccessful",
    text: "Sending Status",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      if (cellContent === true) {
        return <Badge
          className={"font-size-12 badge-soft-success"}
          color="success"
          pill
        >
          {cellContent.toString()}
        </Badge>
      }
      if (cellContent === false) {
        return <Badge
          className={"font-size-12 badge-soft-danger"}
          color="danger"
          pill
        >
          {cellContent.toString()}
        </Badge>
      }
    }
  },
  {
    dataField: "merchId",
    text: "Merchant Id",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      if (cellContent === null) {
        return <>N/A</>
      }
      else {
        return <>{cellContent}</>
      }
    }
  },
  {
    dataField: "",
    text: "Merchant Name",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <>
          {merchantList.map((merchant, i) => {
            if (row.merchId === merchant.merchantId) {
              return <div key={i}>{merchant.merchantName}</div>
            }
          })}
        </>
      )

    }
  },
  {
    dataField: "userId",
    text: "User Id",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      if (cellContent === null) {
        return <>N/A</>
      }
      else {
        return <>{cellContent}</>
      }
    }
  },
  {
    dataField: "userEmail",
    text: "Sent From",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      if (cellContent === null) {
        return <>N/A</>
      }
      else {
        return <>{cellContent}</>
      }
    }
  },
  {
    dataField: "email.to",
    text: "Sent To",
    searchable: true,
    sort: true,
  },
  {
    dataField: "email.cc",
    text: "CC",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <>
          {cellContent.map((x, i) => {
            return (<small key={i}>{x} <br /></small>)
          })}
        </>
      )
    }
  },
  {
    dataField: "date",
    text: "Date",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <span>
          {dateFormat(cellContent)}
        </span>
      )
    }
  },
  {
    dataField: "refPage",
    text: "Reference Page",
    searchable: true,
    sort: true,
  },
  {
    dataField: "email.title",
    text: "Subject Title",
    searchable: true,
    sort: true,
  },

  {
    dataField: "email.errorMsg",
    text: "Error Message",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      if (cellContent === null) {
        return (
          <Row>
            <Col>
              N/A
            </Col>
          </Row>
        )
      }
      else {
        return cellContent
      }
    }
  },
]
export default EmailLogsColumns