export const MAIN_SEARCH_TEXT ="MAIN_SEARCH_TEXT"
export const MAIN_SEARCH_TEXT_SUCCESS ="MAIN_SEARCH_TEXT_SUCCESS"
export const MAIN_SEARCH_TEXT_FAIL ="MAIN_SEARCH_TEXT_FAIL"

export const MAIN_SEARCH_FILTER_DATE_RANGE="MAIN_SEARCH_FILTER_DATE_RANGE"
export const MAIN_SEARCH_FILTER_DATE_RANGE_SUCCESS="MAIN_SEARCH_FILTER_DATE_RANGE_SUCCESS"
export const MAIN_SEARCH_FILTER_DATE_RANGE_FAIL ="MAIN_SEARCH_FILTER_DATE_RANGE_FAIL"

export const MAIN_SEARCH_FILTER_DROPDOWN="MAIN_SEARCH_FILTER_DROPDOWN"
export const MAIN_SEARCH_FILTER_DROPDOWN_SUCCESS="MAIN_SEARCH_FILTER_DROPDOWN_SUCCESS"
export const MAIN_SEARCH_FILTER_DROPDOWN_FAIL =" MAIN_SEARCH_FILTER_DROPDOWN_FAIL"

export const MAIN_SEARCH_LIST_RESET= "MAIN_SEARCH_LIST_RESET"
export const MAIN_SEARCH_LIST_RESET_SUCCESS="MAIN_SEARCH_LIST_RESET_SUCCESS"
export const MAIN_SEARCH_LIST_RESET_FAIL="MAIN_SEARCH_LIST_RESET_FAIL"

export const RESET_FILTER_VALUES ="RESET_FILTER_VALUES"
export const RESET_FILTER_VALUES_SUCCESS="RESET_FILTER_VALUES"
export const RESET_FILTER_VALUES_FAIL ="RESET_FILTER_VALUES_FAIL"

export const RESET_VALUES="RESET_VALUES"