import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_PAYMENTRESPONSE_DETAIL,
  GET_PAYMENTRESPONSE_DETAIL_FAIL,
  GET_PAYMENTRESPONSE_DETAIL_SUCCESS,
} from "./actionTypes"

import {
  getPaymentResponseDetailSuccess,
  getPaymentResponseDetailFail,
} from "./actions"
import { getPaymentResponseDetail } from "helpers/fakebackend_helper"
import { SliderPicker } from "react-color"

function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

function* _getPaymentResponseDetail({ trackId }) {
  try {
    let response = yield call(getPaymentResponseDetail, trackId)

    if(response){

      if (response.isSuccess){
       
        let count = 10

       if(response.data.paymentStatus){
        
        //DIBSY: delay on webhook
          while (response.data.paymentStatus.toLowerCase().includes("pending") && count > 1) 
            {
              //await sleep(1000)
                response = yield call(getPaymentResponseDetail, trackId)
                count--
              
            }   
        }
         yield put(getPaymentResponseDetailSuccess(response.data))

      }

      if (!response.isSuccess) 
      {
          let count = 10

          //MF AND APS: delay on webhook
          while (response?.message.includes("Payment Not Found") && count > 1) 
            {
              //await sleep(1000)
                response = yield call(getPaymentResponseDetail, trackId)
                   
                   if (response.isSuccess)
                   {
                      yield put(getPaymentResponseDetailSuccess(response.data))
                      break;
                   }

                   count--
            }   

          if(!response.isSuccess && response.hasOwnProperty('_id'))
              {
                  if(response._id)
                  yield put(getPaymentResponseDetailFail({message: "Error id: " +  response._id + " " + response.message} ))
                  else
                  yield put(getPaymentResponseDetailFail({message: response.message + ". " + "Click OK to reload. If error persists, please contact support."} ))
              }
         
      } 
     
    }else
      yield put(getPaymentResponseDetailFail({message:trackId + " Invalid Response."}))

  } catch (error) {
    yield put(getPaymentResponseDetailFail(error?.message))
  }
}

function* paymentResponsesSaga() {
  yield takeEvery(GET_PAYMENTRESPONSE_DETAIL, _getPaymentResponseDetail)
}

export default paymentResponsesSaga
