import { actionTypes } from "redux-form"
import {

  GET_TRANSACTION_UPDATE_LOGS_LIST,
  GET_TRANSACTION_UPDATE_LOGS_LIST_SUCCESS,
  GET_TRANSACTION_UPDATE_LOGS_LIST_FAIL,
  GET_USER_LOGS,
  GET_USER_LOGS_SUCCESS,
  GET_USER_LOGS_FAIL,
  GET_USER_LOGS_LIST,
  GET_USER_LOGS_LIST_SUCCESS,
  GET_USER_LOGS_LIST_FAIL,
  GET_EMAIL_LOGS_LIST,
  GET_EMAIL_LOGS_LIST_SUCCESS,
  GET_EMAIL_LOGS_LIST_FAIL,
  GET_ERROR_LOGS_LIST,
  GET_ERROR_LOGS_LIST_SUCCESS,
  GET_ERROR_LOGS_LIST_FAIL,
  UPDATE_TRANSACTION_LOG,
  UPDATE_TRANSACTION_LOG_SUCCESS,
  UPDATE_TRANSACTION_LOG_FAIL,
  GET_PARSER_ERROR_LOG_LIST,
  GET_PARSER_ERROR_LOG_LIST_SUCCESS,
  GET_PARSER_ERROR_LOG_LIST_FAIL,
  GET_PARSER_EXECUTION_LOG_LIST,
  GET_PARSER_EXECUTION_LOG_LIST_SUCCESS,
  GET_PARSER_EXECUTION_LOG_LIST_FAIL,
  GET_PLACER_ERROR_LOG_LIST,
  GET_PLACER_ERROR_LOG_LIST_SUCCESS,
  GET_PLACER_ERROR_LOG_LIST_FAIL,
  GET_PLACER_EXECUTION_LOG_LIST,
  GET_PLACER_EXECUTION_LOG_LIST_SUCCESS,
  GET_PLACER_EXECUTION_LOG_LIST_FAIL,
  SET_WHATSAPP_LOG,
  SET_WHATSAPP_LOG_SUCCESS,
  SET_WHATSAPP_LOG_FAIL,
  GET_SYNC_CHECKER_EXECUTION_LOG_LIST,
  GET_SYNC_CHECKER_EXECUTION_LOG_LIST_SUCCESS,
  GET_SYNC_CHECKER_EXECUTION_LOG_LIST_FAIL,
  GET_SYNC_CHECKER_ERROR_LOG_LIST,
  GET_SYNC_CHECKER_ERROR_LOG_LIST_SUCCESS,
  GET_SYNC_CHECKER_ERROR_LOG_LIST_FAIL,
  RETURN_STATE

} from "./actionTypes"

const INIT_STATE = {
  logsTransactionUpdateList: [],
  logsTransactionUpdateDetail: {},
  error: {},
  loading: false,
  loadingUpdate: false,
  email: false,
}

const logsTransactionUpdate = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_TRANSACTION_UPDATE_LOGS_LIST:

      return {
        ...state,
        logsTransactionUpdateList: action.payload,
        loading: true
      }

    case GET_TRANSACTION_UPDATE_LOGS_LIST_SUCCESS:

      return {
        ...state,
        logsTransactionUpdateList: action.payload,
        loading: false
      }

    case GET_TRANSACTION_UPDATE_LOGS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case GET_USER_LOGS:
      return state = {
        ...state,
        logs: action.payload,
        loading: true,
      }
      break
    case GET_USER_LOGS_SUCCESS:
      return state = {
        ...state,
        logs: action.payload,
        loading: false
      }
      break
    case GET_USER_LOGS_FAIL:
      return state = {
        ...state,
        logs: action.payload,
        loading: false
      }
      break
    case GET_USER_LOGS_LIST:
      return state = {
        ...state,
        allUserLogs: action.payload,
        loading: true
      }
      break
    case GET_USER_LOGS_LIST_SUCCESS:
      return state = {
        ...state,
        allUserLogs: action.payload,
        loading: false
      }
      break
    case GET_USER_LOGS_LIST_FAIL:
      return state = {
        ...state,
        allUserLogs: action.payload,
        loading: false
      }
      break;
    case GET_EMAIL_LOGS_LIST:
      return state = {
        ...state,
        allEmailLogs: action.payload,
        loading: true
      }
      break
    case GET_EMAIL_LOGS_LIST_SUCCESS:
      return state = {
        ...state,
        allEmailLogs: action.payload,
        loading: false
      }
      break
    case GET_EMAIL_LOGS_LIST_FAIL:
      return state = {
        ...state,
        allEmailLogs: action.payload,
        loading: false
      }
      break
    case GET_ERROR_LOGS_LIST:
      return state = {
        ...state,
        allErrorLogs: action.payload,
        loading: true
      }
      break
    case GET_ERROR_LOGS_LIST_SUCCESS:
      return state = {
        ...state,
        allErrorLogs: action.payload,
        loading: false
      }
      break
    case GET_ERROR_LOGS_LIST_FAIL:
      return state = {
        ...state,
        allErrorLogs: action.payload,
        loading: false
      }
      break

    case UPDATE_TRANSACTION_LOG:

      return {
        ...state,
        logsTransactionUpdateDetail: action.payload,
        loadingUpdate: true
      }

    case UPDATE_TRANSACTION_LOG_SUCCESS:

      return {
        ...state,
        logsTransactionUpdateDetail: action.payload,
        loadingUpdate: false
      }

    case UPDATE_TRANSACTION_LOG_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingUpdate: false
      }
    case GET_PARSER_ERROR_LOG_LIST:
      return {
        ...state,
        parserErrorLogs: action.payload,
        loading: true
      }
    case GET_PARSER_ERROR_LOG_LIST_SUCCESS:
      return {
        ...state,
        parserErrorLogs: action.payload,
        loading: false
      }
    case GET_PARSER_ERROR_LOG_LIST_FAIL:
      return {
        ...state,
        parserErrorLogs: action.payload,
        loading: false
      }
    case GET_PARSER_EXECUTION_LOG_LIST:
      return {
        ...state,
        parserExecutionLogs: action.payload,
        loading: true
      }
    case GET_PARSER_EXECUTION_LOG_LIST_SUCCESS:
      return {
        ...state,
        parserExecutionLogs: action.payload,
        loading: false
      }
    case GET_PARSER_EXECUTION_LOG_LIST_FAIL:
      return {
        ...state,
        parserExecutionLogs: action.payload,
        loading: false
      }
    case GET_PLACER_ERROR_LOG_LIST:
      return {
        ...state,
        placerErrorLogs: action.payload,
        loading: true
      }
    case GET_PLACER_ERROR_LOG_LIST_SUCCESS:
      return {
        ...state,
        placerErrorLogs: action.payload,
        loading: false
      }
    case GET_PLACER_ERROR_LOG_LIST_FAIL:
      return {
        ...state,
        placerErrorLogs: action.payload,
        loading: false
      }
    case GET_PLACER_EXECUTION_LOG_LIST:
      return {
        ...state,
        placerExecutionLogs: action.payload,
        loading: true
      }
    case GET_PLACER_EXECUTION_LOG_LIST_SUCCESS:
      return {
        ...state,
        placerExecutionLogs: action.payload,
        loading: false
      }
    case GET_PLACER_EXECUTION_LOG_LIST_FAIL:
      return {
        ...state,
        placerExecutionLogs: action.payload,
        loading: false
      }
    case SET_WHATSAPP_LOG:
      return {
        ...state,
        whatsApp: action.payload,
        loading: true
      }
    case SET_WHATSAPP_LOG_SUCCESS:
      return {
        ...state,
        whatsApp: action.payload,
        loading: false
      }
    case SET_WHATSAPP_LOG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_SYNC_CHECKER_EXECUTION_LOG_LIST:
      return {
        ...state,
        syncCheckerExecutionLogs: action.payload,
        loading: true
      }
    case GET_SYNC_CHECKER_EXECUTION_LOG_LIST_SUCCESS:
      return {
        ...state,
        syncCheckerExecutionLogs: action.payload,
        loading: false
      }
    case GET_SYNC_CHECKER_EXECUTION_LOG_LIST_FAIL:
      return {
        ...state,
        syncCheckerExecutionLogs: action.payload,
        loading: false
      }
      


      case GET_SYNC_CHECKER_ERROR_LOG_LIST:
        return {
          ...state,
          syncCheckerErrorLogs: action.payload,
          loading: true
        }
      case GET_SYNC_CHECKER_ERROR_LOG_LIST_SUCCESS:
        return {
          ...state,
          syncCheckerErrorLogs: action.payload,
          loading: false
        }
      case GET_SYNC_CHECKER_ERROR_LOG_LIST_FAIL:
        return {
          ...state,
          syncCheckerErrorLogs: action.payload,
          loading: false
        }


    case RETURN_STATE:
      return {
        logsTransactionUpdateList: [],
        logsTransactionUpdateDetail: {},
        error: {},
        loading: false,
        email: false,
        update: undefined,
        userCreated: undefined,
        userDelete: undefined,
        error: undefined,
        user: undefined,
      }


    default:
      return state
  }
}

export default logsTransactionUpdate
