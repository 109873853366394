import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  Collapse,
  Button,
} from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import CreditEmbedded from './CreditEmbedded'
import ApplePayEmbedded from "./ApplePayEmbedded"
import ApplePayRedirection from "./ApplePayRedirect"
import DebitRedirect from "./DebitRedirect"
import Dibsy from "../Dibsy/Dibsy"

export const MyFatoorah = (props) => {

  const { 
    mfCreditEmbedded, 
    mfDebitRedirect, 
    mfApplePayEmbedded, 
    paymentRequestDetails } = props
    

  const [creditCard, setCreditCard] = useState(false)
  const [debitCard, setDebitCard] = useState(false)
  const [applePayCard, setApplePayCard] = useState(false)

  
  const creditCollapse = () => {
    setCreditCard(!creditCard)
    setDebitCard(false)
    setApplePayCard(false)
  }

  function isSafariBrowser() {

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return isSafari

  }

  const debitCollapse = () => {
    setDebitCard(!debitCard)
    setCreditCard(false)
    setApplePayCard(false)
  }

  const applePayCollapse = () => {
    setApplePayCard(!applePayCard)
    setCreditCard(false)
    setDebitCard(false)
  }

  return (
    <React.Fragment>
        <Col lg="12">
        
        {/* CREDIT CARD ________________________________________________________  */}
      { mfCreditEmbedded &&
        <>
        {paymentRequestDetails?.paymentRequestSettings.paymentType.toLowerCase().includes("credit") && (
                <>
                  <Button
                    width="100%"
                    onClick={creditCollapse}
                    className={classnames(
                      "btn-block inner mb-2",
                      "fw-medium",
                      { collapsed: !creditCard }
                    )}
                    color="success"
                    >
                     {/* <i className="far fa-credit-card" />  */}
                     <span style={{ border:"1px solid", borderRadius: "0.15rem", padding:"2px", fontSize:"7px", fontStyle:"italic", verticalAlign: "middle"}}><b>___</b></span>
                     <span  style={{   fontSize: "1rem"}}>{props.t(" CREDIT CARD")}</span>
                  </Button>

                  <Collapse isOpen={creditCard} className="p-3">
                      <CreditEmbedded
                        paymentRequestDetails={paymentRequestDetails}
                        mfCreditEmbedded={mfCreditEmbedded}
                      />
                    <hr></hr>
                  </Collapse>

                </>
              )}
        </>      
        }


    {/* DEBIT CARD__________________________________________________________________ */}
        {mfDebitRedirect &&
        <>
        {props.paymentRequestDetails?.paymentRequestSettings.paymentType
          .toLowerCase()
          .includes("debit") && (
          <>

         <DebitRedirect mfDebitRedirect={mfDebitRedirect} paymentRequestDetails={paymentRequestDetails}/>
          </>
        )}

        </>
    }

        {/* APPLE PAY CARD___________________________________________________________ */}
        {(mfApplePayEmbedded && isSafariBrowser()) && 
        <>
        {props.paymentRequestDetails?.paymentRequestSettings.paymentType
          .toLowerCase()
          .includes("applepay") && (
          <>
           
             <ApplePayRedirection mfApplePayEmbedded={mfApplePayEmbedded} paymentRequestDetails={paymentRequestDetails}/> 
            {/* <ApplePayEmbedded mfApplePayEmbedded={mfApplePayEmbedded} paymentRequestDetails={paymentRequestDetails} /> */}
          
          </>
        )}

        </>
        }
        
        </Col>
    </React.Fragment>
  )
}


MyFatoorah.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(MyFatoorah))