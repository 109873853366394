import React from "react"
import { Row, Col, Label } from "reactstrap"

const Header = props => {
  const { paymentStatus, isSuccessful } = props
  return (
    <div>
      {paymentStatus ? (
        <>
          {paymentStatus.toLowerCase().includes("succ") && (
            <Row className="paymentNotificationTitle paymentSuccess ">
              <Col>
                <Label className="mx-auto">
                  <i className="fas fa-check fa-2x" /> SUCCESSFUL{" "}
                </Label>
              </Col>
            </Row>
          )}

          {paymentStatus.toLowerCase().includes("fail") && (
            <Row className="paymentNotificationTitle paymentFailed ">
              <Col>
                <Label className="mx-auto">
                  <i className="fas fa-times fa-2x" /> FAILED{" "}
                </Label>
              </Col>
            </Row>
          )}

          {paymentStatus.toLowerCase().includes("pending") && (
            <Row className="paymentNotificationTitle paymentPending ">
              <Col>
                <Label className="mx-auto">
                  <i className="fas fa-spinner fa-xl" /> PROCESSING{" "}
                </Label>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <>
          {isSuccessful ? (
            <Row className="paymentNotificationTitle paymentSuccess ">
              <Col>
                <Label className="mx-auto">
                  <i className="fas fa-check fa-2x" /> SUCCESSFUL{" "}
                </Label>
              </Col>
            </Row>
          ) : (
            <Row className="paymentNotificationTitle paymentFailed ">
              <Col>
                <Label className="mx-auto">
                  <i className="fas fa-times fa-2x" /> FAILED{" "}
                </Label>
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  )
}

export default Header
