import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { isEmpty } from "lodash"

export default function errorCatching(error, setError) {
  if (error === undefined || isEmpty(error) || error === "") {
    return null
  }

  if (error === undefined || isEmpty(error) || error === "") {
    return null
  }

  if (setError) {
    switch (error) {
      case `Unexpected token 'S', "Status cod"... is not valid JSON`:
        return (
          <SweetAlert
            error
            title="Session Timeout"
            onConfirm={() => {
              setError(undefined)
              localStorage.removeItem("authUser")
              window.location = "/login"
            }}
          >
            Session has expired. Please log in again.
          </SweetAlert>
        )
      case `JSON.parse: unexpected character at line 1 column 1 of the JSON data`:
        return (
          <SweetAlert
            error
            title="Session Timeout"
            onConfirm={() => {
              setError(undefined)
              localStorage.removeItem("authUser")
              window.location = "/login"
            }}
          >
            Session has expired. Please log in again.
          </SweetAlert>
        )
      case `JSON Parse error: Unexpected identifier "Status"`:
        return (
          <SweetAlert
            error
            title="Session Timeout"
            onConfirm={() => {
              setError(undefined)
              localStorage.removeItem("authUser")
              window.location = "/login"
            }}
          >
            Session has expired. Please log in again.
          </SweetAlert>
        )
      default:
        return (
          <SweetAlert
            error
            title=""
            onConfirm={() => {
              setError(undefined)
              window.location.reload()
            }}
          >
            {error.hasOwnProperty("message") ? error?.message : error}
          </SweetAlert>
        )
    }
  }
  // if (
  //   setError &&
  //   (error !== `Unexpected token 'S', "Status cod"... is not valid JSON` ||
  //     error !==
  //       `JSON.parse: unexpected character at line 1 column 1 of the JSON data`)
  // ) {
  //   //console.log(error.message)
  //   return (
  //     <SweetAlert
  //       error
  //       title=""
  //       onConfirm={() => {
  //         setError(undefined)
  //         window.location.reload()
  //       }}
  //     >
  //       {error.hasOwnProperty("message") ? error?.message : error}
  //     </SweetAlert>
  //   )
  // } else if (
  //   setError &&
  //   error === `Unexpected token 'S', "Status cod"... is not valid JSON`
  // ) {
  //   return (
  //     <SweetAlert
  //       error
  //       title="Session Timeout"
  //       onConfirm={() => {
  //         setError(undefined)
  //         localStorage.removeItem("authUser")
  //         window.location = "/login"
  //       }}
  //     >
  //       Session has expired. Please log in again.
  //     </SweetAlert>
  //   )
  // } else if (
  //   setError &&
  //   error ===
  //     `JSON.parse: unexpected character at line 1 column 1 of the JSON data`
  // ) {
  //   return (
  //     <SweetAlert
  //       error
  //       title="Session Timeout"
  //       onConfirm={() => {
  //         setError(undefined)
  //         localStorage.removeItem("authUser")
  //         window.location = "/login"
  //       }}
  //     >
  //       Session has expired. Please log in again.
  //     </SweetAlert>
  //   )
  // } else {
  //   return (
  //     <SweetAlert error title="" onConfirm={false} showConfirm={false}>
  //       {error.hasOwnProperty("message") ? error?.message : error}
  //     </SweetAlert>
  //   )
  // }
}
