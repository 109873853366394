import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {
  UPDATE_PAYMENT_HISTORY,
  SEND_NOTIFICATION_EMAIL,
  DOWNLOAD_PAYMENT_HISTORY,
} from "./actionTypes"

import {
  updatePaymentHistorySuccess,
  updatePaymentHistoryFail,
  sendNotificationEmailSuccess,
  sendNotificationEmailFail,
  downloadPaymentHistorySuccess,
  downloadPaymentHistoryFail,
  restartState,
} from "./actions"

//Include Both Helper File with needed methods
import {
  updatePaymentHistory,
  sendNotificationEmail,
  downloadPaymentHistory,
} from "helpers/fakebackend_helper"

function* _updatePaymentHistory({ payload: data }) {
  // try{

  //   const response = yield call(updatePaymentHistory,data)

  //   if(response){
  //     yield put(updatePaymentHistorySuccess(response))
  //   }
  //   else{
  //     yield put(updatePaymentHistoryFail(response))
  //   }
  //   yield put(restartState())
  // }
  // catch(error){
  //   yield put(updatePaymentHistoryFail(error))
  // }
  try {
    const response = yield call(updatePaymentHistory, data)

    if (response) {
      if (response.isSuccess)
        yield put(updatePaymentHistorySuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              updatePaymentHistoryFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(updatePaymentHistoryFail(response))
        }
      }
    } else
      yield put(
        updatePaymentHistoryFail({
          message: "Invalid Response.",
        })
      )
  } catch (error) {
    yield put(updatePaymentHistoryFail(error?.message))
  }
}

function* _sendNotificationEmail({ payload: data }) {
  try {
    const response = yield call(sendNotificationEmail, data)

    if (response) {
      if (response.isSuccess)
        yield put(sendNotificationEmailSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              sendNotificationEmailFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(sendNotificationEmailFail(response))
        }
      }
    } else
      yield put(
        sendNotificationEmailFail({
          message: "Invalid Response.",
        })
      )
  } catch (error) {
    yield put(sendNotificationEmailFail(error?.message))
  }
}

function* _downloadPaymentHistory({ payload: data }) {
  try {
    const response = yield call(downloadPaymentHistory, data)

    if (response) {
      if (response.isSuccess)
        yield put(downloadPaymentHistorySuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              downloadPaymentHistoryFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(downloadPaymentHistoryFail(response))
        }
      }
    } else
      yield put(
        downloadPaymentHistoryFail({
          message: "Invalid Response.",
        })
      )
  } catch (error) {
    yield put(downloadPaymentHistoryFail(error?.message))
  }
}

function* paymentHistorySaga() {
  yield takeEvery(UPDATE_PAYMENT_HISTORY, _updatePaymentHistory)
  yield takeEvery(SEND_NOTIFICATION_EMAIL, _sendNotificationEmail)
  yield takeEvery(DOWNLOAD_PAYMENT_HISTORY, _downloadPaymentHistory)
}

export default paymentHistorySaga
