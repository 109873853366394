import React, { useContext, useState, useEffect } from "react"
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col
} from "reactstrap"

import { PaymentActionsContext } from './index'

const ResendEmailModal = (props) => {

  const { isOpen, toggle, paymentrequestDetails } = props

  const { register, handleSubmit, errors } = useForm();

  const [toggleModal, setToggleModal] = useState({ toggle })

  const [drp_secondary, setDrp_secondary] = useState(false)

  const [showInput, setShowInput] = useState(false)

  const [dropDownValue, setDropDownValue] = useState(paymentrequestDetails?.customerDetails?.email)

  const [selectedEmail, setSelectedEmail] = useState(paymentrequestDetails?.customerDetails?.email)

  const { handleSendPaymentLinkEmail } = useContext(PaymentActionsContext)

  useEffect(() => {

  }, [selectedEmail, showInput, dropDownValue])

  function handleChangeEmail(e) {
    setSelectedEmail(e.target.value)

  }

  const onSubmit = (data) => {
    handleSendPaymentLinkEmail(selectedEmail)
    setSelectedEmail("")
    toggle()
  }

  const onSubmitFailed = (data) => {
    // console.warn(errors);
  }

  function handleNewEmail(value) {
    setSelectedEmail("")
    setDropDownValue(value)
    setShowInput(true)

  }

  function handleCurrentEmail(value) {
    setDropDownValue(value)
    setSelectedEmail(paymentrequestDetails?.customerDetails?.email)
    setShowInput(false)
  }




  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">

        <form onSubmit={handleSubmit(onSubmit, onSubmitFailed)}>
          <ModalHeader toggle={toggle}>Resend Email Options</ModalHeader>
          <ModalBody style={{ minHeight: "170px" }}>

            <div className="btn-group mb-2">
              <div className="from-group ">

                <Row>
                  <Col lg={3}> <label >Send to : </label></Col>
                  <Col lg={9}>


                    <ButtonDropdown
                      isOpen={drp_secondary}
                      toggle={() => setDrp_secondary(!drp_secondary)}
                      name="emailBtn"
                      id="emailBtn"

                    >
                      <Button id="caret" className="btn btn-info" style={{ minWidth: "300px" }} disabled >
                        {selectedEmail} &nbsp; {" "}
                      </Button>

                      <DropdownToggle className="btn btn-info" name="emailDp" id="emailDp"   >
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>

                      <DropdownMenu style={{ minWidth: "300px", textAlign: "left" }} >
                        <DropdownItem value={paymentrequestDetails?.customerDetails?.email} name="option1" id="option1"
                         >
                          <div onClick={(e) => handleCurrentEmail(selectedEmail)} id="current">
                            <b>{paymentrequestDetails?.customerDetails?.email}</b>
                          </div>
                        </DropdownItem>
                        <DropdownItem name="option2" id="option2">
                          <div onClick={(e) => handleNewEmail("")} id="newEmail">
                            Send to New Email ...
                              </div>
                        </DropdownItem>
                      </DropdownMenu>



                      
                    </ButtonDropdown>

                    {showInput &&
                      <>
                        <Row>
                          <Col lg={8} style={{ "marginTop": "1rem" }}>
                            <input
                              name="email"
                              id="email"
                              type="text"
                              className="form-control"
                              style={{ minWidth: "300px", textAlign: "left" }}
                              ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                              placeholder="Enter New Email..."
                              onChange={handleChangeEmail}

                            />
                            {errors.email && errors.email.type === "required" &&
                              <><small className="color-danger">This is required</small></>}

                            {errors.email && errors.email.type === "pattern" &&
                              <><small className="color-danger">Enter valid email</small></>}
                          </Col>
                        </Row>

                      </>


                    }



                  </Col></Row>
              </div></div>{" "}

          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
          </Button>

            <Button type="submit" color="primary"

            >
              Send Email Now
            </Button>
          </ModalFooter>
        </form>
      </div>
    </Modal>
  )
}

ResendEmailModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ResendEmailModal
