import {
  GET_CUSTOMER_LIST_ARCHIVED,
  GET_CUSTOMER_LIST_ARCHIVED_SUCCESS,
  GET_CUSTOMER_LIST_ARCHIVED_FAIL,
  PATCH_CUSTOMER_ARCHIVE,
  PATCH_CUSTOMER_ARCHIVE_SUCCESS,
  PATCH_CUSTOMER_ARCHIVE_FAIL,
  RETURN_STATE,
} from "./actionTypes"

export const getCustomerListArchived = () => ({
  type: GET_CUSTOMER_LIST_ARCHIVED,
})
export const getCustomerListArchivedSuccess = data => ({
  type: GET_CUSTOMER_LIST_ARCHIVED_SUCCESS,
  payload: data,
})
export const getCustomerListArchivedFail = data => ({
  type: GET_CUSTOMER_LIST_ARCHIVED_FAIL,
  payload: data,
})

export const patchCustomerArchive = data => ({
  type: PATCH_CUSTOMER_ARCHIVE,
  payload: data,
})
export const patchCustomerArchiveSuccess = data => ({
  type: PATCH_CUSTOMER_ARCHIVE_SUCCESS,
  payload: data,
})
export const patchCustomerArchiveFail = data => ({
  type: PATCH_CUSTOMER_ARCHIVE_FAIL,
  payload: data,
})

export const returnState = () => ({
  type: RETURN_STATE,
})
