import React, { useContext, useState, useEffect } from "react"
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { PaymentMethodContext } from "../../index"
import { useForm } from "react-hook-form"
import Luhn from "luhn-js"
import localCards from "../../../../helpers/commonhelpers/localCards.json"
import CardNotificationModal from "./CardNotificationModal"

var prefixes = localCards.NAPS.map(x => x.Prefix)
var currentMonth = new Date().getMonth()
var currentYear = parseInt(new Date().getFullYear().toString().substr(-2))

const CreditCustom = props => {
  const {
    apsCreditCustomTokenization,
    paymentRequestDetails,
    apsCreditCustom,
    apsDebitRedirect,
  } = props


  const {
    handleTokenizationDetailsChange,
  } = useContext(PaymentMethodContext)

  const { register, handleSubmit, errors, reset, watch } = useForm()
  
  var image = "/cards/cards_accepted.png"

  const [creditCustom, setCreditCustom] = useState(apsCreditCustom)
  const [cardNumberValidity, setCardNumberValidity] = useState(false)
  const [modal, setModal] = useState(false)
  const [isCardNumberValid, setCardNumberValid] = useState()
  const [isNameValid, setIsNameValid] = useState()
  const [isDateExpiryValid, setIsDateExpiryValid] = useState()
  const [isDateExpiryMMValid, setIsDateExpiryMMValid] = useState()
  const [isDateExpiryYYValid, setIsDateExpiryYYValid] = useState()
  const [isCvvValid, setIsCvvValid] = useState()
  const [dateExpiry, setDateExpiry] = useState()
  const [dateExpiryMM, setDateExpiryMM] = useState()
  const [dateExpiryYY, setDateExpiryYY] = useState()
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (apsCreditCustom)
      setCreditCustom(apsCreditCustom)
  }, [apsCreditCustom])

  useEffect(() => {}, [
    isCardNumberValid,
    isNameValid,
    isCvvValid,
    isDateExpiryMMValid,
    isDateExpiryYYValid,
  ])

  useEffect(() => {
    var dateExpiry = dateExpiryYY + dateExpiryMM
    handleChange({ expiry_date: dateExpiry })
  }, [dateExpiryMM, dateExpiryYY])

  const toggleModal = () => {
    setModal(!modal)
  }

  function handleChange(changes) {
    handleTokenizationDetailsChange({ ...apsCreditCustomTokenization, ...changes })
  }

  function handleCardNumber(value) {
    if (!Number(value)) {
      setCardNumberValid(false)
    } else {
      try {
        if (prefixes.filter(x => value.startsWith(x)).length > 0) {
          toggleModal()
        } else {
          if (Luhn.isValid(value)) {
            setCardNumberValid(true)
          } else {
            setCardNumberValid(false)
          }
        }
      } catch {
        setCardNumberValid(false)
      }
    }

    handleChange({ card_number: value })
  }

  function handleName(value) {
    var pattern = /^[A-Za-z\s]+$/

    if (pattern.test(value)) {
      setIsNameValid(true)
    } else {
      setIsNameValid(false)
    }

    handleChange({ card_holder_name: value })
  }

  function handleDateExpiry(value) {
    if (!Number(value) || value.length !== 4) {
      setIsDateExpiryValid(false)
    } else {
      setIsDateExpiryValid(true)
      handleChange({ expiry_date: value })
    }
  }

  function handleDateExpiryMM(value) {
    if (Number(value) && value.length == 2 && value <= 12) {
      setIsDateExpiryMMValid(true)
      setDateExpiryMM(value)
    } else {
      setIsDateExpiryMMValid(false)
    }
  }

  function handleDateExpiryYY(value) {
    if (Number(value) && value.length == 2 && value >= currentYear) {
      setIsDateExpiryYYValid(true)
      setDateExpiryYY(value)
    } else {
      setIsDateExpiryYYValid(false)
    }
  }

  function handleCvv(value) {
    if (Number(value) && (value.length == 3 || value.length == 4)) {
      setIsCvvValid(true)
    } else {
      setIsCvvValid(false)
    }

    handleChange({ card_security_code: value })
  }

  const onSubmit = (form, e) => {
    e.preventDefault()

    if (Luhn.isValid(apsCreditCustomTokenization?.card_number)) {
      
      let formData = new FormData()

      formData.append("access_code", "c4GudTxOCWYBmMcmJDrI")
      formData.append("card_holder_name", "test")
      formData.append("card_number", "4005550000000001")
      formData.append("card_security_code", "123")
      formData.append("expiry_date", "2505")
      formData.append("access_code", "c4GudTxOCWYBmMcmJDrI")
      formData.append("language", "en")
      formData.append("merchant_identifier", "d2f48ac2")
      formData.append("merchant_reference", "PR034493-9C4B6544")
      formData.append("remember_me", "NO")
      formData.append("merchant_reference", "PR034493-9C4B6544")
      formData.append(
        "return_url",
        "https://paldev.developerqa.info/api/paymentgateway/apsprocesspayment"
      )
      formData.append("service_command", "TOKENIZATION")
      formData.append(
        "signature",
        "229f4edb8cfb967c004228898d3622497c5c303c3e10dc460fc852b078cd4c6b"
      )

      fetch("https://sbcheckout.PayFort.com/FortAPI/paymentPage", {
        method: "post",
        body: formData,
        headers: {
          Accept: "multipart/form-data",
          "Content-Type": "multipart/form-data",
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Credentials': 'true'
        },
      })
        .then()
        .catch(function (error) {
          // console.log("error---", error)
        })
    } else {
      setCardNumberValidity("Invalid Card Number")
    }

    //AXIOS
    // if (Luhn.isValid(tokenizationDetails?.card_number)) {
    //   axios( {

    //      method: 'post',
    //      url:'https://sbcheckout.PayFort.com/FortAPI/paymentPage',
    //      data: 'access_code=c4GudTxOCWYBmMcmJDrI&card_holder_name=test&card_number=4005550000000001&card_security_code=123&expiry_date=2505&language=en&merchant_identifier=d2f48ac2&merchant_reference=PR3E7E71-0E9146A9&remember_me=NO&return_url=https://paldev.developerqa.info/api/paymentgateway/apsprocesspayment&service_command=TOKENIZATION&signature=9bf141145690b94eaf9341c5243866af1fb0cbebb5d10d527d0e7acaf7bca362&',
    //      headers: {

    //        'Content-Type': 'application/x-www-form-urlencoded',
    //         //'Access-Control-Allow-Origin': '*',
    //        // 'Access-Control-Allow-Credentials': 'true'
    //      }
    //    })
    //      .then()
    //      .catch(function (error) {
    //        console.log("error---", error)
    //      })
    //  } else {
    //    setCardNumberValidity("Invalid Card Number")
    //  }
  }

  return (
    <div className="p-1">
      <CardNotificationModal
        isOpen={modal}
        toggle={toggleModal}
        apsDebitRedirect={apsDebitRedirect}
      />

      <form
        name="form"
        onSubmit={(e) => setClicked(true)}
        action={creditCustom.formApi}
        method="POST"
      >
        <input
          type="text"
          className="form-control"
          hidden
          readOnly
          name="service_command"
          value={creditCustom.service_command}
        />
        <input
          type="text"
          className="form-control"
          hidden
          readOnly
          name="access_code"
          value={creditCustom.access_code}
        />
        <input
          type="text"
          className="form-control"
          hidden
          readOnly
          name="merchant_identifier"
          value={creditCustom.merchant_identifier}
        />
        <input
          type="text"
          className="form-control"
          hidden
          readOnly
          name="merchant_reference"
          value={creditCustom.merchant_reference}
        />
        <input
          type="text"
          className="form-control"
          hidden
          readOnly
          name="language"
          value={creditCustom.language}
        />
        <input
          type="text"
          className="form-control"
          hidden
          readOnly
          name="expiry_date"
          value={apsCreditCustomTokenization.expiry_date}
        />
        <input
          type="text"
          className="form-control"
          hidden
          readOnly
          name="signature"
          value={creditCustom.signature}
        />
       
        <Row className="mb-2">
          <Col lg="12">
            <input
              type="text"
              value={apsCreditCustomTokenization.card_number}
              className="form-control"
              id="cardnumberInput"
              placeholder={props.t("Card Number")}
              name="card_number"
              onBlur={e => handleCardNumber(e.target.value)}
              onChange={e => handleCardNumber(e.target.value)}
            />

            {isCardNumberValid === false && (
              <small className="color-danger" style={{ float: "left" }}>
                {props.t("Invalid Card Number")}
              </small>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg="12">
            <input
              type="text"
              value={apsCreditCustomTokenization.card_holder_name}
              className="form-control"
              id="nameInput"
              placeholder={props.t("Name on Card")}
              name="card_holder_name"
              onChange={e => handleName(e.target.value)}
              onBlur={e => handleName(e.target.value)}
            />
            {isNameValid === false && (
              <small className="color-danger" style={{ float: "left" }}>
                {props.t("Invalid Name")}
              </small>
            )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg="4" className="mb-2">
            <input
              type="text"
              className="form-control"
              id="expirydateMMInput"
              placeholder={props.t("Month (MM) Expiry")}
              name="expiry_date"
              onChange={e => handleDateExpiryMM(e.target.value)}
              onBlur={e => handleDateExpiryMM(e.target.value)}
            />
            {isDateExpiryMMValid === false && (
              <small className="color-danger" style={{ float: "left" }}>
                {props.t("Invalid Month Expiry (MM)")}{" "}
              </small>
            )}
          </Col>
          <Col lg="4" className="mb-2">
            <input
              type="text"
              className="form-control"
              id="expirydateYYInput"
              placeholder={props.t("Year (YY) Expiry")}
              name="expiry_date"
              onChange={e => handleDateExpiryYY(e.target.value)}
              onBlur={e => handleDateExpiryYY(e.target.value)}
            />
            {isDateExpiryYYValid === false && (
              <small className="color-danger" style={{ float: "left" }}>
                {props.t("Invalid Year Expiry (YY)")}
              </small>
            )}
          </Col>

          <Col lg="4">
            <input
              type="text"
              value={apsCreditCustomTokenization.card_security_code}
              className="form-control"
              id="cvvcodeInput"
              placeholder="CVV "
              name="card_security_code"
              onChange={e => handleCvv(e.target.value)}
              onBlur={e => handleCvv(e.target.value)}
            />

            {isCvvValid === false && (
              <small className="color-danger" style={{ float: "left" }}>
                {props.t("Invalid CVV")}
              </small>
            )}
          </Col>
        </Row>

        <Row>
          <Col style={{ marginTop: "0.5rem" }}></Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="d-flex justify-content-center">
              {isCardNumberValid === true &&
              isNameValid === true &&
              isDateExpiryMMValid === true &&
              isDateExpiryYYValid === true &&
              isCvvValid === true ? (
                <Button
                  type="submit"
                  style={{
                    width: "auto",
                    height: "40px",
                    fontSize: "1rem",
                    background: "#34C38F",
                    border: "none",
                  }}
                 
                  disabled={clicked}
                  
                >
                  <i className="fas fa-lock mr-1" /> {props.t("Pay Now")}
                </Button>
              ) : (
                <div
                  style={{
                    width: "auto",
                    height: "40px",
                    fontSize: "1rem",
                    background: "#d3d3d3",
                    border: "none",
                    padding: "7px",
                    color: "white",
                  }}
                >
                  <i className="fas fa-lock mr-1" /> {props.t("Pay Now")}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginTop: "1rem" }}></Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="d-flex justify-content-center">
              <img src={image} className="img-fluid" style={{ width: "40%" }} />
            </div>
          </Col>
        </Row>
      </form>
    </div>
  )
}

CreditCustom.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(CreditCustom))
