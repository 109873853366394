import React, { useContext, useState, useEffect } from "react"
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { PaymentMethodContext } from "../../index"

const CreditEmbedded = props => {
  const {mfCreditEmbedded } = props

  const [clicked, setClicked] = useState(false)

  const { onSetMyfatoorahInitiateSessionData } = useContext(
    PaymentMethodContext
  )

  useEffect(() => {
    const script = document.createElement("script")
    script.src = mfCreditEmbedded?.sessionUrl
   // script.async = true
    script.onload = function handleScriptLoaded() {
     // console.log("script has loaded")
      var config = {
        countryCode: mfCreditEmbedded?.countryCode,
        sessionId: mfCreditEmbedded?.sessionId,
        cardViewId: "card-element",
        style: {
          direction: "ltr",
          cardHeight: 200,
          input: {
            color: "black",
            fontSize: "13px",
            fontFamily: "sans-serif",
            inputHeight: "32px",
            inputMargin: "0px",
            borderColor: "c7c7c7",
            borderWidth: "1px",
            borderRadius: "8px",
            boxShadow: "",
            placeHolder: {
              holderName: "Name On Card",
              cardNumber: "Card Number",
              expiryDate: "MM / YY",
              securityCode: "CVV",
            },
          },
          label: {
            display: false,
            color: "black",
            fontSize: "13px",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            text: {
              holderName: "Card Holder Name",
              cardNumber: "Card Number",
              expiryDate: "Expiry Date",
              securityCode: "Security Code",
            },
          },
          error: {
            borderColor: "red",
            borderRadius: "8px",
            boxShadow: "0px",
          },
        },
      }

      myFatoorah.init(config)
    }

    script.onerror = function handleScriptError() {
      console.log("error loading script")
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])


  const pay = () => {

    setClicked(true)
    
    const script = document.createElement("script")
    script.src = mfCreditEmbedded?.sessionUrl
    script.async = true
    script.onload = function handleScriptLoaded() {
      myFatoorah.submit()
      window.addEventListener("message", event => {
        const { data } = event
        if (isJsonString(data) === true) {
          var jsonData = JSON.parse(data)
          var model = {
            SessionId: jsonData.data.Data.SessionId,
            TrackId: mfCreditEmbedded.trackId,
            PaymentMethod: "credit"
          }
          onSetMyfatoorahInitiateSessionData(model)
        }
      }); 
    }
    document.body.appendChild(script)
  }


  function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

  return (
    <>
      <div className="p-1 ">
        <div style={{ height: "120px" }} className="mb-2">
          <div id="card-element"></div>
        </div>

        <Button
          onClick={() => pay()}
          className="mt-2"
          style={{
            width: "auto",
            height: "40px",
            fontSize: "1rem",
            background: "#34C38F",
            border: "none",
          }}
          disabled= {clicked}
        >
          <i className="fas fa-lock mr-1" /> {props.t("Pay Now")}
        </Button>
      </div>
    </>
  )
}

CreditEmbedded.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(CreditEmbedded))
