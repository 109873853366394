import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import LoadingSpinnerThreeDots from '../../helpers/commonhelpers/loadingSpinner'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getLogsTransactionUpdateList,
    updatePaymentHistory, 
    sendNotificationEmail, 
    updateTransactionLogs,
    getPaymentRequestDetail } from 'store/actions'
import { IsEmpty } from "react-lodash"
import { isEmpty } from 'lodash'
import AuthUser from "components/AuthUser/AuthUser"
import { Col, Row, Container} from "reactstrap"
import LogsTransactionUpdateTable from './LogsTransactionUpdateTable'
export const TransactionUpdateContext = React.createContext()


function LogsTransactionUpdate(props) {

    const {
        onGetLogsTransactionUpdate,
        onGetPaymentRequestDetail,
        onUpdatePaymentHistory,
        onNotificationEmail,
        onApproveTransUpdate,
        logsTransactionUpdateList,
        paymentHistory,
        errorPaymentHistory,
        errorEmail,
        paymentRequestDetail,
        loading,
        loadingPaymentHistory,
        loadingEmail,
        loadingApprove } = props

       
    const [user, setUser] = useState(AuthUser())
    const [isLoaded, setIsLoaded] = useState(loading)
    const [isLoadedEmail, setIsLoadedEmail] = useState(loadingEmail)
    const [isLoadedApprove, setIsLoadedApprove] = useState(loadingApprove)
    const [isLoadedPaymentHistory, setIsLoadedPaymentHistory] = useState(loadingPaymentHistory)
    const [isErrorEmail, setIsErrorEmail] = useState()
    const [prDetail, setPrDetail] = useState()
    const [openPr, setOpenPr] = useState("")

    useEffect(() => {

        if(user.role === "SuperAdmin"){
                onGetLogsTransactionUpdate()
        }else{
            window.location="/page-404"
        }
    }, [])


    useEffect(() => {

        if(!isEmpty(paymentRequestDetail) || paymentRequestDetail !== undefined){
            setPrDetail(paymentRequestDetail)
        }

       
    }, [paymentRequestDetail])

    useEffect(() => {

        if( openPr === prDetail?.paymentRequestId){
            const link = "/paymentrequest/overview/" + prDetail._id
            window.open(link, "_blank")
        }
        
    }, [openPr,prDetail])

    useEffect(() => {

    }, [logsTransactionUpdateList])

    useEffect(() => {
        setIsLoadedEmail(loadingEmail)
        onGetLogsTransactionUpdate()
    }, [loadingEmail])

    useEffect(() => {
        onGetLogsTransactionUpdate()
        setIsLoadedPaymentHistory(loadingPaymentHistory)
    }, [ loadingPaymentHistory])

    useEffect(() => {
        setIsLoaded(loading)
    }, [loading])


    useEffect(() => {
        
        setIsLoadedApprove(loadingApprove)
        onGetLogsTransactionUpdate()
    }, [loadingApprove])


    useEffect(() => {
        setIsErrorEmail(errorEmail)
    }, [errorEmail])

    const handleUpdatePaymentHistory = (data) =>{
   
    if(data){onUpdatePaymentHistory(data)}
        
    }

    const handleEmail = (trackId,isNotifyCustomer) =>{
        
        const data = {
            trackId: trackId,
            isNotifyCustomer: isNotifyCustomer
         }

         if(data.trackId !== ""){
            onNotificationEmail(data)
         }

    }

    const handleApproved = (data) =>{
        if(data){
            onApproveTransUpdate(data)
        }
    }




  const handleOpenOverviewPrId = (paymentRequestId) => {

    onGetPaymentRequestDetail(paymentRequestId)
    setOpenPr(paymentRequestId)

  }

       ///context
       const TransactionUpdateContextValue = {
        handleUpdatePaymentHistory,
        handleEmail,
        handleApproved,
        handleOpenOverviewPrId,
      }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Logs " breadcrumbItem=" TRANSACTIONS TO UPDATE" />
                    {isLoaded ?
                        <Row className="spinner-custom mx-auto my-auto pb-3">
                            <LoadingSpinnerThreeDots />
                        </Row>
                        : <IsEmpty
                            value={logsTransactionUpdateList}
                            yes={() => 
                            <Row>
                                <Col>
                                    No list
                                </Col>
                            </Row>}
                            no={() => (

                                <TransactionUpdateContext.Provider value={TransactionUpdateContextValue}>
                                <LogsTransactionUpdateTable 
                                                logsTransactionUpdateList={logsTransactionUpdateList} 
                                                paymentHistory={paymentHistory} 
                                                errorPaymentHistory={errorPaymentHistory}
                                                isLoadedPaymentHistory={isLoadedPaymentHistory}
                                                isLoadedEmail={isLoadedEmail}
                                                isLoadedApprove={isLoadedApprove}
                                                isErrorEmail={isErrorEmail}
                                                handleOpenOverviewPrId={handleOpenOverviewPrId}
                                />
                                </TransactionUpdateContext.Provider>
                            )} />}

                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.Logs.loading,
        logsTransactionUpdateList: state.Logs.logsTransactionUpdateList,
        paymentHistory: state.PaymentHistory.paymentHistory,
        errorPaymentHistory:  state.PaymentHistory.error,
        errorEmail:state.PaymentHistory.errorEmail,
        loadingPaymentHistory:  state.PaymentHistory.loading,
        loadingEmail:state.PaymentHistory.loadingEmail,
        loadingApprove: state.Logs.loadingUpdate,
        paymentRequestDetail: state.paymentRequests.paymentRequestDetail?.paymentRequestDetails,
    }
}

const mapDispatchToProps = dispatch => (
    {
        onGetLogsTransactionUpdate: () => dispatch(getLogsTransactionUpdateList()),
        onUpdatePaymentHistory:(data) => dispatch(updatePaymentHistory(data)),
        onNotificationEmail:(data) => dispatch(sendNotificationEmail(data)),
        onApproveTransUpdate:(data) => dispatch(updateTransactionLogs(data)),
        onGetPaymentRequestDetail:(id) => dispatch(getPaymentRequestDetail(id))
    })

export default connect(mapStateToProps,mapDispatchToProps)(LogsTransactionUpdate)
