export const SET_PAYMNET_GATEWAY ="SET_PAYMNET_GATEWAY "
export const SET_PAYMNET_GATEWAY_SUCCESS ="SET_PAYMNET_GATEWAY_SUCCESS"
export const SET_PAYMNET_GATEWAY_FAIL="SET_PAYMNET_GATEWAY_FAIL"

export const GET_PAYMENT_GATEWAY_DETAIL="GET_PAYMENT_GATEWAY_DETAIL"
export const GET_PAYMENT_GATEWAY_DETAIL_SUCCESS="GET_PAYMENT_GATEWAY_DETAIL_SUCCESS"
export const GET_PAYMENT_GATEWAY_DETAIL_FAIL="GET_PAYMENT_GATEWAY_DETAIL_FAIL"

export const GET_PAYMENT_GATEWAY_LIST="GET_PAYMENT_GATEWAY_LIST"
export const GET_PAYMENT_GATEWAY_LIST_SUCCESS="GET_PAYMENT_GATEWAY_LIST_SUCCESS"
export const GET_PAYMENT_GATEWAY_LIST_FAIL ="GET_PAYMENT_GATEWAY_LIST_FAIL"

export const UPDATE_PAYMENT_GATEWAY="UPDATE_PAYMENT_GATEWAY"
export const UPDATE_PAYMENT_GATEWAY_SUCCESS="UPDATE_PAYMENT_GATEWAY_SUCCESS"
export const UPDATE_PAYMENT_GATEWAY_FAIL="UPDATE_PAYMENT_GATEWAY_FAIL"

export const UPDATE_STATUS_CHANGE="UPDATE_STATUS_CHANGE"
export const UPDATE_STATUS_CHANGE_SUCCESS="UPDATE_STATUS_CHANGE_SUCCESS"
export const UPDATE_STATUS_CHANGE_FAIL ="UPDATE_STATUS_CHANGE_FAIL"

export const RESTART_STATE="RESTART_STATE"