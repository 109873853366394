import {
  GET_PAYMENTREQUEST_LIST_ARCHIVED,
  GET_PAYMENTREQUEST_LIST_ARCHIVED_SUCCESS,
  GET_PAYMENTREQUEST_LIST_ARCHIVED_FAIL,
  PATCH_PAYMENTREQUEST_ARCHIVE,
  PATCH_PAYMENTREQUEST_ARCHIVE_SUCCESS,
  PATCH_PAYMENTREQUEST_ARCHIVE_FAIL,
  RETURN_STATE,
} from "./actionTypes"

export const getPaymentRequestListArchived = () => ({
  type: GET_PAYMENTREQUEST_LIST_ARCHIVED,
})

export const getPaymentRequestListArchivedSuccess = data => ({
  type: GET_PAYMENTREQUEST_LIST_ARCHIVED_SUCCESS,
  payload: data,
})

export const getPaymentRequestListArchivedFail = data => ({
  type: GET_PAYMENTREQUEST_LIST_ARCHIVED_FAIL,
  payload: data,
})

export const patchPaymentRequestArchive = data => ({
  type: PATCH_PAYMENTREQUEST_ARCHIVE,
  payload: data,
})
export const patchPaymentRequestArchiveSuccess = data => ({
  type: PATCH_PAYMENTREQUEST_ARCHIVE_SUCCESS,
  payload: data,
})
export const patchPaymentRequestArchiveFail = data => ({
  type: PATCH_PAYMENTREQUEST_ARCHIVE_FAIL,
  payload: data,
})

export const returnState = () => ({
  type: RETURN_STATE,
})
