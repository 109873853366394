import React, { useState, useEffect } from 'react'
import { Col, Row, Form, FormGroup, InputGroup, InputGroupAddon, Label } from "reactstrap"
import { useForm } from "react-hook-form";

import Select from "react-select"
import AuthUser from "components/AuthUser/AuthUser";
import makeAnimated from "react-select/animated"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  toast } from 'react-toastify';

function PayDateUpdatePopUp(props) {
 
  const animatedComponents = makeAnimated()
 const [{ paymentGateway, contractStartDate, contractEndDate, paymentType, apiDefultLink}, setPaymentRequest] = useState({
      mobilePlus: props.paymentGatewayDetails.mobilePlus,
      paymentGateway: props.paymentGatewayDetails.paymentGateway,
      contractStartDate:props.paymentGatewayDetails.contractStartDate,
      contractEndDate:props.paymentGatewayDetails.contractEndDate,
      paymentType: props.paymentGatewayDetails.paymentType,
      apiDefultLink: props.paymentGatewayDetails.apiDefaultLink,
      position: props.paymentGatewayDetails.position,
    })
  const [user, setUser] = useState(AuthUser())
  const { register, errors } = useForm();
  const multi = {

    filtered: [],
    select2: undefined

  }
  const [selectedMultiSelect, setselectedMultiSelect] = useState(multi);
  const optionGroup = [
    { label: "credit", value: "credit" },
    { label: "debit", value: "debit" }
  ]

  useEffect(()=>{
    if(selectedMultiSelect !== undefined){
      
      props.onChange(formRequest =>({
        ...formRequest,
        paymentType:selectedMultiSelect
      }))
    }
  },[selectedMultiSelect])

  useEffect(()=>{
    let option=[]
    if(paymentType !== undefined){
      paymentType.map((p,i)=>{
        option.push({label:p,value:p})
      })
        setselectedMultiSelect({ select2: option })
        
    }
  },[paymentType])
  
  useEffect(()=>{
    if(contractStartDate !== undefined && contractEndDate!== undefined){
          if(contractStartDate >= contractEndDate){
            toast.warning("Contract Start Date should not be after Contract End Date!")
            setPaymentRequest(formRequest => ({
              ...formRequest,
              contractEndDate: props.paymentGatewayDetails.contractEndDate
            }))
          }
    }
  },[contractStartDate,contractEndDate])

  const storeDataOnForm = (event) => {
   if(event.target !== undefined){
    const { name, value } = event.target;
    setPaymentRequest(formRequest => (
      { ...formRequest, [name]: value }
    ));
    props.onChange(formRequest => (
      { ...formRequest, [name]: value }
    ));
   }
  };
  


  function handleContractDates(dateType,dateValue) {
    let dateConvert = new Date(dateValue)
    dateConvert.setDate(dateConvert.getDate() + 1)
   if(dateType === "start"){
    props.onChange(formRequest =>({
      ...formRequest,
      contractStartDate:dateConvert.toISOString()
    }))
   }
   else{
    props.onChange(formRequest =>({
      ...formRequest,
      contractEndDate:dateConvert.toISOString()
    }))
   }
  }

  function onFilteredChangeCustom(value, accessor) {


    let filtered = selectedMultiSelect.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    setselectedMultiSelect({ filtered: filtered });
   
  };


  return (
    <Form className=" mx-auto my-auto">
      <Row className="form-group mx-3 my-3">
        <Col>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                PAYMENT GATEWAY
                            </Label>
            </InputGroupAddon>
            <input
              id="paymentGateway"
              name="paymentGateway"
              value={paymentGateway}
              type="text"
              className="form-control form-control-lg input-text-size"
              ref={register({ required: true })}
              onChange={(event) => storeDataOnForm(event, props.setPaymentRequest)}
            />
          </InputGroup>
          {errors.paymentGateway && errors.paymentGateway.type === "required" && <p className="color-danger">This is required</p>}

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                API DEFAULT LINK
                            </Label>
            </InputGroupAddon>
            <input
              id="apiDefultLink"
              name="apiDefultLink"
              value={apiDefultLink}
              type="text"
              className="form-control form-control-lg input-text-size"
              ref={register({ required: true })}
              onChange={(event) => storeDataOnForm(event, props.setPaymentRequest)}
            />
          </InputGroup>
          {errors.apiDefultLink && errors.apiDefultLink.type === "required" && <p className="color-danger">This is required</p>}
          <Label className=" input-text-size item-center">
            CONTRACT
                            </Label>
          <Row>

            <Col>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text input-text-size">
                    START DATE
                            </Label>
                </InputGroupAddon>
                <Flatpickr
                  id="contractStartDate"
                  name="contractStartDate"
                  value={contractStartDate}
                  className="form-control d-block"
                  placeholder="dd M,yyyy"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d"
                  }}
                  onChange={(date) => handleContractDates("start",date)}
                />
              </InputGroup>
              {errors.contractStartDate && errors.contractStartDate.type === "required" && <p className="color-danger">This is required</p>}

            </Col>
            <Col>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text input-text-size">
                    END DATE
                            </Label>
                </InputGroupAddon>
                <Flatpickr
                  id="contractEndDate"
                  name="contractEndDate"
                  value={contractEndDate}
                  className="form-control d-block"
                  placeholder="dd M,yyyy"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d"
                  }}
                  onChange={(date) => handleContractDates("end",date)}
                />
              </InputGroup>
              {errors.contractEndDate && errors.contractEndDate.type === "required" && <p className="color-danger">This is required</p>}

            </Col>

          </Row>
          <FormGroup className="mb-0 templating-select select2-container">
            <Label className=" input-text-size  item-center">
              PAYMENT TYPE
                            </Label>
            <Select
              name="multiSelect"
              value={selectedMultiSelect.select2}
              isMulti={true}
              onChange={(entry) => {

                if (entry) {
                  setselectedMultiSelect({ select2: entry })
                 
                }

              }}
              options={optionGroup}
              classNamePrefix="select2-selection"
              closeMenuOnSelect={false}
              components={animatedComponents}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>

  )
}

export default PayDateUpdatePopUp
