import React, { useEffect, useState} from 'react'
import { Col, Card, CardBody, Row, CardTitle, Button } from "reactstrap"
import { isEmpty } from "lodash"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MerchantColumns from './MerchantColumns'



export const Merchants = (props) => {

    const { 
        merchantList,
        handleReportDownload
    } = props

  
    const [merchants, setMerchantList] = useState([])
    const { SearchBar } = Search

    const pageOptions = {
      sizePerPage: 15,
      totalSize: merchantList?.length, // replace later with size(orders),
      custom: true,
    }
 
    
      useEffect(() => {
        if (!isEmpty(merchantList)) 
        setMerchantList(merchantList)
      }, [merchantList])



    useEffect(() => {
      
    }, [merchants])

    const handleTableChange = (type, { page, searchText }) => {
        setMerchantList(
            merchantList.filter(contact =>
            Object.keys(contact).some(
              key =>
                typeof contact[key] === "string" &&
                contact[key].toLowerCase().includes(searchText.toLowerCase())
            )
          )
        )
      }


  //Actions
  const handleOpenOverview = (merchantId) => {
    const link = "/merchant/overview/" + merchantId
    window.open(link, "_blank")
  }

   //Actions
  //  const handleOpenOverview = (index) => {
  //   var merchant = merchants[index]
  //   const link = "/merchant/overview/" + merchant?.merchantId
  //   window.open(link, "_blank")
  // }

  const handleAddMerchant = () => {

    const link = "/merchant/create"
    window.open(link, "_blank")

  }

    return (
       <>
      <Card >
           <CardBody>
           <CardTitle style={{"padding":"5px"}}>Merchant List</CardTitle>
                <Row>
                    <Col lg="12">
               


                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField={"_id"}
                          data={merchants || []}
                          columns={MerchantColumns(handleOpenOverview)}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box mr-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                               
                                <Col sm="8">
                                  <div className="text-sm-right">
                                  <Button
                                      type="button"
                                      color="secondary"
                                      className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                      onClick ={() => handleReportDownload()}
                                    >
                                      <i className="mdi mdi-download mr-1" />
                                     Download All Merchants
                                    </Button>

                                    
                                    <Button
                                    onClick={handleAddMerchant}
                                      type="button"
                                      color="success"
                                      className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                    >
                                      <i className="mdi mdi-plus mr-1" />
                                      Add New Merchant
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      responsive
                                 
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table table-centered table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
           



                    </Col>
                </Row>
            </CardBody>
        </Card>
               </>
    )
}



export default (Merchants)
