import React, { useState } from 'react'
import { Row } from "reactstrap"

function FormFourPageAttachmentList(props) {
    const [Attachments, setAttachments] = useState(props.Attachments)
    return (
        <>
            {Attachments === undefined  ?
                "N/A" :
               Attachments.length === 0 ? "N/A":
               Attachments.map((x, i) => {
                return (
                        <Row key={i} className="mx-auto">
                            <label className="color-text "  key={i}>{x}</label>
                        </Row>
                )
            })
            }
        </>
    )
}

export default FormFourPageAttachmentList
