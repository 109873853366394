export const SET_ITEM ="SET_ITEM"
export const SET_ITEM_SUCCESS="SET_ITEM_SUCCESS"
export const SET_ITEM_FAIL ="SET_ITEM_FAIL"

export const UPDATE_ITEM="UPDATE_ITEM"
export const UPDATE_ITEM_SUCCESS="UPDATE_ITEM_SUCCESS"
export const UPDATE_ITEM_FAIL = "UPDATE_ITEM_FAIL"

export const GET_ITEM_DETAIL ="GET_ITEM"
export const GET_ITEM_DETAIL_SUCCESS="GET_ITEM_SUCCESS"
export const GET_ITEM_DETAIL_FAIL="GET_ITEM_FAIL"

export const RESET_STATE="RESET_STATE" 