import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_PAYMENTREQUEST_LIST_ARCHIVED,
  PATCH_PAYMENTREQUEST_ARCHIVE,
} from "./actionTypes"
import {
  getPaymentRequestListArchivedSuccess,
  getPaymentRequestListArchivedFail,
  patchPaymentRequestArchiveSuccess,
  patchPaymentRequestArchiveFail,
  returnState,
} from "./actions"

//Include Both Helper File with needed methods

import {
  getPaymentRequestListArchived,
  patchPaymentRequestArchive,
} from "helpers/fakebackend_helper"

function* _getPaymentRequestListArchived({ payload: data }) {
  // try {
  //   const response = yield call(getPaymentRequestListArchived, data)
  //   if (response) {
  //     yield put(getPaymentRequestListArchivedSuccess(response))
  //   } else {
  //     yield put(getPaymentRequestListArchivedFail(response))
  //   }
  // } catch (error) {
  //   yield put(getPaymentRequestListArchivedFail(error))
  // }
  try {
    const response = yield call(getPaymentRequestListArchived, data)

    if (response) {
      if (response.isSuccess)
        yield put(getPaymentRequestListArchivedSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPaymentRequestListArchivedFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(getPaymentRequestListArchivedFail(response))
        }
      }
    } else
      yield put(
        getPaymentRequestListArchivedFail({
          message: "Invalid Response.",
        })
      )
  } catch (error) {
    yield put(getPaymentRequestListArchivedFail(error?.message))
  }
}

function* _patchPaymentRequestArchive({ payload: data }) {
  // try {
  //   const response = yield call(patchPaymentRequestArchive, data)
  //   if (response) {
  //     yield put(patchPaymentRequestArchiveSuccess(response))
  //   } else {
  //     yield put(patchPaymentRequestArchiveFail(response))
  //   }
  // } catch (error) {
  //   yield put(patchPaymentRequestArchiveFail(error))
  // }
  // yield put(returnState())
  try {
    const response = yield call(patchPaymentRequestArchive, data)

    if (response) {
      if (response.isSuccess)
        yield put(patchPaymentRequestArchiveSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              patchPaymentRequestArchiveFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(patchPaymentRequestArchiveFail(response))
        }
      }
    } else
      yield put(
        patchPaymentRequestArchiveFail({
          message: "Invalid Response.",
        })
      )
  } catch (error) {
    yield put(patchPaymentRequestArchiveFail(error?.message))
  }
  yield put(returnState())
}

function* paymentrequestsArchiveSaga() {
  yield takeEvery(
    GET_PAYMENTREQUEST_LIST_ARCHIVED,
    _getPaymentRequestListArchived
  )
  yield takeEvery(PATCH_PAYMENTREQUEST_ARCHIVE, _patchPaymentRequestArchive)
}

export default paymentrequestsArchiveSaga
