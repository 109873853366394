import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_CUSTOMER_LIST_ARCHIVED,
  PATCH_CUSTOMER_ARCHIVE,
} from "./actionTypes"
import {
  getCustomerListArchivedSuccess,
  getCustomerListArchivedFail,
  patchCustomerArchiveSuccess,
  patchCustomerArchiveFail,
  returnState,
} from "./actions"

//Include Both Helper File with needed methods

import {
  getCustomerListArchived,
  patchCustomerArchive,
} from "helpers/fakebackend_helper"

function* _getCutomerListArchived({ payload: data }) {
  try {
    const response = yield call(getCustomerListArchived, data)

    if (response) {
      if (response?.isSuccess)
        yield put(getCustomerListArchivedSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getCustomerListArchivedFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getCustomerListArchivedFail(response.message))
      }
    } else
      yield put(getCustomerListArchivedFail({ message: "Invalid Response." }))
    yield put(returnState())
  } catch (error) {
    yield put(getCustomerListArchivedFail(error?.message))
  }
}

function* _patchCutomerArchive({ payload: data }) {
  // try {
  //   const response = yield call(patchCustomerArchive, data)
  //   if (response) {
  //     yield put(patchCustomerArchiveSuccess(response))
  //   } else {
  //     yield put(patchCustomerArchiveFail(response))
  //   }
  // } catch (error) {
  //   yield put(patchCustomerArchiveFail(error))
  // }
  //yield put(returnState())
  try {
    const response = yield call(patchCustomerArchive, data)

    if (response) {
      if (response?.isSuccess)
        yield put(patchCustomerArchiveSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              patchCustomerArchiveFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(patchCustomerArchiveFail(response.message))
      }
    } else yield put(patchCustomerArchiveFail({ message: "Invalid Response." }))
    yield put(returnState())
  } catch (error) {
    yield put(patchCustomerArchiveFail(error?.message))
  }
}

function* cutomerArchiveSaga() {
  yield takeEvery(GET_CUSTOMER_LIST_ARCHIVED, _getCutomerListArchived)
  yield takeEvery(PATCH_CUSTOMER_ARCHIVE, _patchCutomerArchive)
}

export default cutomerArchiveSaga
