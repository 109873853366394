export const GET_CUSTOMER_LIST_ARCHIVED = "GET_CUSTOMER_LIST_ARCHIVED"
export const GET_CUSTOMER_LIST_ARCHIVED_SUCCESS =
  "GET_CUSTOMER_LIST_ARCHIVED_SUCCESS"
export const GET_CUSTOMER_LIST_ARCHIVED_FAIL = "GET_CUSTOMER_LIST_ARCHIVED_FAIL"

export const PATCH_CUSTOMER_ARCHIVE = "PATCH_CUSTOMER_ARCHIVE"
export const PATCH_CUSTOMER_ARCHIVE_SUCCESS = "PATCH_CUSTOMER_ARCHIVE_SUCCESS"
export const PATCH_CUSTOMER_ARCHIVE_FAIL = "PATCH_CUSTOMER_ARCHIVE_FAIL"

export const RETURN_STATE = "RETURN_STATE"
