import React, { useState, useEffect } from 'react'
import { Page, Document, pdfjs  } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
const file = "https://res.cloudinary.com/developerqa/image/upload/v1660742549/UserGuide/PayAllLink_User_Guide_05JUL22_giic5f.pdf"


const TravelAgency_User = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div style={{width:"100%"}}>
      <Document
        file={file}
      //  options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >

        
        {Array.from(new Array(numPages), (el, index) => (
        <Page scale={2.5} key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
        

      </Document>


    </div>
  );
}

export default TravelAgency_User