import React from 'react'
import { Col, Row, Button, Spinner, Modal, ModalBody, Label } from "reactstrap"

import Filters from "./Filters"
import SearchBar from "./SearchBar"
import Table from "./Table"
function Layout() {
    return (
        <Row className="item-center">
            <Col>
                <SearchBar />
                <Filters />
                <Table />
            </Col>
        </Row>
    )
}

export default Layout
