import React, { useState, useEffect } from 'react'
import { Col, Row, Form, FormGroup, InputGroup, InputGroupAddon, Label } from "reactstrap"
import { useForm } from "react-hook-form";

import Select from "react-select"
import AuthUser from "components/AuthUser/AuthUser";
import { useDispatch } from "react-redux"
import makeAnimated from "react-select/animated"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  toast } from 'react-toastify';


function PayGateAddPopUp(props) {
  const dispatch = useDispatch();

  const animatedComponents = makeAnimated()

  let startDate = new Date()
  let endDate = new Date(startDate)
  endDate.setMonth(endDate.getMonth()+1) //Default is contract good for 1 month. Ask Ms.Bless if 1 month or 1 year
  const [{ paymentGateway, contractStartDate, contractEndDate, paymentType, apiDefultLink
    , position, name, email, mobilePlus, mobile, modal_large }, setPaymentRequest] = useState({
      mobilePlus: "+974",
      name: "",
      email: "",
      mobile: "",
      paymentGateway: "",
      contractStartDate: startDate.toISOString(),
      contractEndDate:endDate.toISOString(),
      paymentType: {},
      apiDefultLink: "",
      position: "",
      modal_large: false
    })
  const [user, setUser] = useState(AuthUser())
  const { register, handleSubmit, errors, reset } = useForm();
  const multi = {

    filtered: [],
    select2: undefined

  }
  const [selectedMultiSelect, setselectedMultiSelect] = useState(multi);
  const optionGroup = [
    { label: "credit", value: "credit" },
    { label: "debit", value: "debit" },
    { label: "debit and credit", value: "debit and credit" }
  ]

  useEffect(()=>{
    if(selectedMultiSelect !== undefined){
      setPaymentRequest(formRequest =>({
        ...formRequest,
        paymentType:selectedMultiSelect
      }))
      props.onChange(formRequest =>({
        ...formRequest,
        paymentType:selectedMultiSelect
      }))
    }
  },[selectedMultiSelect])
  
  useEffect(()=>{
    if(contractStartDate !== undefined && contractEndDate!== undefined){
          if(contractStartDate >= contractEndDate){
            toast.warning("Contract Start Date should not be after Contract End Date!")
            setPaymentRequest(formRequest => ({
              ...formRequest,
              contractEndDate: endDate.toISOString()
            }))
          }
    }
  },[contractStartDate,contractEndDate])

  const storeDataOnForm = (event) => {
   if(event.target !== undefined){
    const { name, value } = event.target;
    setPaymentRequest(formRequest => (
      { ...formRequest, [name]: value }
    ));
    props.onChange(formRequest => (
      { ...formRequest, [name]: value }
    ));
   }
  };
  


  function handleContractDates(dateType,dateValue) {
    let dateConvert = new Date(dateValue)
    dateConvert.setDate(dateConvert.getDate() + 1)
   if(dateType === "start"){
    props.onChange(formRequest =>({
      ...formRequest,
      contractStartDate:dateConvert.toISOString()
    }))
   }
   else{
    props.onChange(formRequest =>({
      ...formRequest,
      contractEndDate:dateConvert.toISOString()
    }))
   }
  }

  return (
    <Form className=" mx-auto my-auto">
      <Row className="form-group mx-3 my-3">
        <Col>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                PAYMENT GATEWAY
                            </Label>
            </InputGroupAddon>
            <input
              id="paymentGateway"
              name="paymentGateway"
              value={paymentGateway}
              type="text"
              className="form-control form-control-lg input-text-size"
              ref={register({ required: true })}
              onChange={(event) => storeDataOnForm(event, props.setPaymentRequest)}
            />
          </InputGroup>
          {errors.paymentGateway && errors.paymentGateway.type === "required" && <p className="color-danger">This is required</p>}

          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                API DEFAULT LINK
                            </Label>
            </InputGroupAddon>
            <input
              id="apiDefultLink"
              name="apiDefultLink"
              value={apiDefultLink}
              type="text"
              className="form-control form-control-lg input-text-size"
              ref={register({ required: true })}
              onChange={(event) => storeDataOnForm(event, props.setPaymentRequest)}
            />
          </InputGroup>
          {errors.apiDefultLink && errors.apiDefultLink.type === "required" && <p className="color-danger">This is required</p>}
          <Label className=" input-text-size item-center">
            CONTRACT
                            </Label>
          <Row>

            <Col>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text input-text-size">
                    START DATE
                            </Label>
                </InputGroupAddon>
                <Flatpickr
                  id="contractStartDate"
                  name="contractStartDate"
                  value={contractStartDate}
                  className="form-control d-block"
                  placeholder="dd M,yyyy"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d"
                  }}
                  onChange={(date) => handleContractDates("start",date)}
                />
              </InputGroup>
              {errors.contractStartDate && errors.contractStartDate.type === "required" && <p className="color-danger">This is required</p>}

            </Col>
            <Col>

              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text input-text-size">
                    END DATE
                            </Label>
                </InputGroupAddon>
                <Flatpickr
                  id="contractEndDate"
                  name="contractEndDate"
                  value={contractEndDate}
                  className="form-control d-block"
                  placeholder="dd M,yyyy"
                  options={{
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d"
                  }}
                  onChange={(date) => handleContractDates("end",date)}
                />
              </InputGroup>
              {errors.contractEndDate && errors.contractEndDate.type === "required" && <p className="color-danger">This is required</p>}

            </Col>

          </Row>
          <FormGroup className="mb-0 templating-select select2-container">
            <Label className=" input-text-size  item-center">
              PAYMENT TYPE
                            </Label>
            <Select
              name="multiSelect"
              value={selectedMultiSelect.select2}
              isMulti={true}
              // ref={register({ required: true })}
              onChange={(entry) => {

                if (entry) {
                  setselectedMultiSelect({ select2: entry })
                  //  entry.map(o => {return onFilteredChangeCustom(o.value,o.label)})
                 
                }

              }}
              options={optionGroup}
              // options={selectedMultiSelect.data.map((o, i) => {
              //   return { id: i, value: o.firstName, label: o.firstName };
              // })}
              classNamePrefix="select2-selection"
              closeMenuOnSelect={false}
              components={animatedComponents}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>

  )
}

export default PayGateAddPopUp
