import React, { useEffect, useContext, useState } from "react"

import { Button, Card, CardBody, Col, Row } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import PaymentRequestColumns from "./PaymentRequestColumns"
import copy from "copy-to-clipboard"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import ResendEmailModal from "./ResendEmailModal"
import { PaymentRequestContext } from "./index"
import AuthUser from "components/AuthUser/AuthUser"

const PaymentRequestList = props => {
  const { paymentRequestList, isLoaded } = props
  const [prList, setPrList] = useState([])
  const [selectedPrRow, setSelectedPrRow] = useState({})
  // const prsReversed = prList.reverse()
  const [modal, setModal] = useState(false)
  const { handleSendPaymentLinkEmail } = useContext(PaymentRequestContext)
  const pageOptions = {
    sizePerPage: 15,
    totalSize: prList.length,
    custom: true,
    withFirstAndLast: true,
  }
  const { SearchBar } = Search
  const [user, setUser] = useState(AuthUser())

  // const defaultSorted = [{
  //   dataField: 'paymentRequestDate',
  //   order: 'desc'
  // }];

  useEffect(() => {
    setPrList(paymentRequestList)
  }, [paymentRequestList, isLoaded])

  useEffect(() => {
    // console.log(prList.length)
  }, [prList, selectedPrRow])

  const handleNewRequestButton = () => {
    const link = "/paymentrequest/create"
    window.open(link, "_blank")
  }

  //search
  const handleTableChange = (type, { page, searchText }) => {
    try {
      setPaymentRequestList(
        paymentrequests.paymentrequests.filter(pr =>
          Object.keys(pr).some(
            key =>
              typeof pr[key] === "string" &&
              pr[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    } catch {}
  }

  //Actions
  const handleOpenOverviewId = index => {
    // var pr = prsReversed[index];
    var pr = prList[index]
    const link = "/paymentrequest/overview/" + pr._id
    window.open(link, "_blank")
  }

  const handleOpenOverviewPrId = paymentRequestId => {
    const pr = prList.filter(x => x.paymentRequestId == paymentRequestId)
    const id = pr.map(function (p) {
      return p._id
    })
    const link = "/paymentrequest/overview/" + id
    window.open(link, "_blank")
  }

  const handleCopyLink = id => {
    // const pr = prList[index]
    // const shortLink = pr.generatedLinkShort
    const pr = prList.filter(x => x?._id == id)
    // console.log(pr)
    const shortLink = pr.map(function (p) {
      return p.generatedLinkShort
    })
    copy(shortLink)
    showToast(shortLink)
  }

  const handleResendEmail = id => {
    //setSelectedPrRow({})
    // const pr = prList[index]
    //console.log(pr)
    const pr = prList.filter(x => x?._id == id)
    setSelectedPrRow(pr[0])
    toggleModal()
  }

  const handleArchivePR = id => {
    props.onChange(id)
  }

  const showToast = link => {
    toastr.options = {
      positionClass: "toast-top-right",
      // timeOut: timeOut,
      // extendedTimeOut: extendedTimeOut,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.success(link, "Link Copied!")
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  return (
    <Row>
      <Col xs="12">
        <Card>
          <CardBody>
            <ResendEmailModal
              isOpen={modal}
              toggle={toggleModal}
              paymentrequestDetails={selectedPrRow}
              handleSendPaymentLinkEmail={handleSendPaymentLinkEmail}
            />

            {isLoaded ? (
              <LoadingSpinnerThreeDots />
            ) : (
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={prList || []}
                    columns={PaymentRequestColumns(
                      handleOpenOverviewPrId,
                      handleCopyLink,
                      handleResendEmail,
                      user,
                      false,
                      handleArchivePR
                    )}
                    loading={true}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="8">
                            <div className="search-box mr-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  {...toolkitProps.searchProps}
                                 // style={{ maxWidth: "50rem" }}
                                  placeholder="Search Anything ..."
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="text-sm-right">
                              <Button
                                onClick={handleNewRequestButton}
                                type="button"
                                color="success"
                                className="btn-rounded waves-effect waves-light mb-2 mr-2"
                              >
                                <i className="mdi mdi-plus mr-1" />
                                New Request
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive
                                // remote={ {
                                //   filter: true, // not working
                                //   pagination: true, //not working
                                //   sort: true, // not working
                                //   cellEdit: false
                                // } }
                                loading={true}
                                bordered={false}
                                hover={true}
                                striped={false}
                                classes={"table table-centered table-nowrap"}
                                keyField="_id"
                                headerWrapperClasses={"thead-light"}
                                onTableChange={handleTableChange}
                                // rowEvents={ rowEvents }
                                // selectRow={selectRow}
                                filter={filterFactory()}
                                // defaultSorted={defaultSorted}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
                        <Col xs="12">
                          <div className="text-center my-3">
                            <Link to="#" className="text-success">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />
                                  Load more
                                </Link>
                          </div>
                        </Col>
                      </Row> */}

                        <Row className="align-items-md-center mt-30">
                          <Col xs="6">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </Col>

                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PaymentRequestList

// export default PaymentRequestList2
