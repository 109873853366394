import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Col,
} from "reactstrap"
import useTable from "./Table/useTable"
import TableFooter from "./Table/TableFooter"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
export const ExistingCustomerData = ({ data, rowsPerPage, onChange }) => {
  //const { customers } = props
  //const newData = data.filter(cus => cus.isActive === true)
  const [page, setPage] = useState(1)
  const { slice, range } = useTable(data, page, rowsPerPage)

  //////////////////////////////////////////////////////////////////////////
  // useEffect(() => {
  //   console.log
  //   if (search !== "") {
  //     data = {
  //       data: data.filter(item =>
  //         item.name.toLowerCase().includes(search.toLowerCase())
  //       ),
  //     }
  //   }
  // }, [search])
  // useEffect(() => {
  //   //console.log(data)
  // }, [data])
  /////////////////////////////////////////////////////////////////////////
  const handleRowClick = rowValue => {
    //console.log(rowValue)
    onChange(rowValue)
    showToastSuccess("Customer " + rowValue.name + " has been selected.")
  }
  // const handleSearch = event => {
  //   setSearch(event.target.value)
  // }

  const showToastSuccess = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.success(message)
  }
  //////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Table className="table table-sm " hover>
        <thead>
          {/* <tr>
            <th colspan="3">
            
            </th>
          </tr> */}
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile</th>
          </tr>
        </thead>
        <tbody>
          {/* {customers.map((cus, i) => {
            return (
              <tr>
                <td>{cus.name}</td>
                <td>{cus.email}</td>
                <td>{cus.mobile}</td>
              </tr>
            )
          })} */}
          {slice.map(el => (
            <tr
              className="tableRowItems pointer"
              onClick={() => handleRowClick(el)}
              key={el._id}
            >
              <td className="tableRowFontSize">{el.name}</td>
              <td className="tableRowFontSize">{el.email}</td>
              <td className="tableRowFontSize">{el.mobile}</td>
            </tr>
          ))}
          {/* <tr>
            <td>Mohammed</td>
            <td>mark@gmail.com</td>
            <td>+97423455432</td>
          </tr>
          <tr>
            <td>Mohammed Isla</td>
            <td>mark@gmail.com</td>
            <td>+97423455432</td>
          </tr>
          <tr>
            <td>Nebuchadnezzar II</td>
            <td>mark@gmail.com</td>
            <td>+97423455432</td>
          </tr>
          <tr>
            <td>Samantha Princess</td>
            <td>mark@gmail.com</td>
            <td>+97423455432</td>
          </tr> */}
        </tbody>
      </Table>

      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistingCustomerData)
