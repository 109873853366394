import React, { useState, useEffect } from 'react'
import { Col, Row, Button ,InputGroup, InputGroupAddon, Label } from "reactstrap"
import { useForm } from "react-hook-form";
export default function FormSecondPageItemsList(props) {
  //Constants-----------------------------------------------------------------------------------------------
  const [ItemsList, setItems] = useState([{ ItemName: "", Qty: "" , Amount:""}]);
  const [Currency, setCurreny] = useState("QAR")
  const { register} = useForm();
  const [merchantServices, setMerchantServices] = useState(props.MerchantServices)
  //UseEffects----------------------------------------------------------------------------------------------
  //Use effect for reseting values 
  useEffect(() => {
    if (props.customchk === false) {
      setItems([{ ItemName: "", Qty: "" , Amount:""}])
    }
  }, [props.customchk])

  useEffect(() => {
    if (props.MerchantServices !== undefined) {
      setMerchantServices(props.MerchantServices)
    }
  }, [props.MerchantServices])

  useEffect(() => {
  }, [merchantServices])

  //Functions-----------------------------------------------------------------------------------------------
  //Function for input changes
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...ItemsList];
    if (e.target.name === 'Qty') {
      list[index][name] = parseInt(value);
    }
      if (e.target.name === 'Amount') {
      list[index][name] = parseFloat(value);
    }
    else {
      list[index][name] = value;
    }
    setItems(list);
    props.onChange(list)
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...ItemsList];
    list.splice(index, 1);
    setItems(list);
    props.onChange(list)
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setItems([...ItemsList, { ItemName: "", Qty: "" , Amount:""}]);
  };
  const onSubmit = (data) => {
  }

  return (
    <div >
      {ItemsList.map((x, i) => {
        return (
          < div key={i}>
            <Row className="my-2 mx-2 form-group" key={i}>
              <Col className="">

                {/* <select className="form-control form-control-lg input-text-size"
                  key={i}
                  id="ItemName"
                  name="ItemName"
                  value={x.ItemName}
                  ref={register({ required: true })}
                  onChange={e => handleInputChange(e, i, props.onChange)}>
                    <option></option>
                  {merchantServices && Array.isArray(merchantServices)?
                  merchantServices.map((services,i)=>{
                    return(
                      <option key={i} value={services.service} >{services.service}</option>
                     )
                  })
                  :null}
                </select> */}
                <input
                  type="text"
                  value={x.ItemName}
                  name="ItemName"
                  placeholder="Item Name"
                  className="form-control form-control-lg input-text-size"
                  ref={register({ required: true })}
                  onChange={e => handleInputChange(e, i, props.onChange)}
                />
              </Col>
              
              <Col className="">

                <input
                  type="number"
                  value={x.Qty}
                  name="Qty"
                  min={1}
                  placeholder="Quantity"
                  className="form-control form-control-lg input-text-size"
                  ref={register({ required: true, min: 1 })}
                  onChange={e => handleInputChange(e, i, props.onChange)}
                />
              </Col>
              <Col className="">
                 <InputGroup className="">
              <select className="form-control  custom-select input-text-size"
                id="Currency"
                name="Currency"
                style={{ maxWidth: "90px" }}
                value={Currency}
                ref={register({ required: true })}
                onChange={(event) => storeDataOnForm(event, props.setFormRequest)}>
                <option>QAR</option>
              </select>
              <input
                type="number"
                value={x.Amount}
                name="Amount"
                min={1}
                placeholder="Amount"
                className="form-control form-control-lg input-text-size"
                ref={register({ required: true, min: 1 })}
                onChange={e => handleInputChange(e, i, props.onChange)} />
            </InputGroup>
              </Col>
              <Col className="">
                {ItemsList.length !== 1 &&
                  <Button onClick={() => handleRemoveClick(i)} className=" bnt" color="danger" outline>
                    <i className="mdi mdi-close font-size-15  color-danger mr-1" id="deletetooltip" />
                  REMOVE ITEM
                </Button>}

              </Col>
            </Row>
            <Row className="mx-3">
              {ItemsList.length - 1 === i &&
                <Button onClick={handleAddClick} className="ml-2 btn" color="primary" outline>
                  <i className="fas fa-plus font-size-15  color-primary mr-1" id="deletetooltip" />
                 ADD ITEM
                 </Button>}
            </Row>
          </div>
        )
      })}
     {/* <div className="custom-control custom-checkbox my-3 mx-3">
            <input
              type="checkbox"
              className="custom-control-input form-control-lg"
              id="CustomCheck1"
              onChange={() => false}
              name="customchk"
              ref={register({ required: false })}
              checked={customchk}
            />
            <label
              className="custom-control-label "
              onClick={() => {
                setCustomchk(!customchk)
                setOthersBoolean( !customchk)
              }}
            >
              Others:
          </label>
          </div>
      {othersBoolean ?
      <>
       {otherItemsList.map((x, i) => {
        return (
          < div key={i}>
            <Row className="my-2 mx-2 form-group" key={i}>
            <Col className="">
               <input
                 type="others"
                 placeholder="Others"
                 value={others}
                 name="others"
                 className="form-control form-control-lg input-text-size"
                 onChange={e => handleInputChange(e, i)}
               />
             </Col>
             <Col className="">
                <input
                  type="number"
                  min={0}
                  placeholder="0"
                  className="form-control form-control-lg input-text-size"
                  ref={register({ required: true, min: 0 })}
                  onChange={e => handleInputChange(e, i, props.onChange)}
                />
              </Col>
              <Col className="">
                {ItemsList.length !== 1 &&
                  <Button onClick={() => handleRemoveClick(i)} className=" bnt" color="danger" outline>
                    <i className="mdi mdi-close font-size-15  color-danger mr-1" id="deletetooltip" />
                  REMOVE ITEM
                </Button>}

              </Col>
            </Row>
            <Row className="mx-3">
              {ItemsList.length - 1 === i &&
                <Button onClick={handleAddClick} className="ml-2 btn" color="primary" outline>
                  <i className="fas fa-plus font-size-15  color-primary mr-1" id="deletetooltip" />
                 ADD ITEM
                 </Button>}
            </Row>
          </div>
        )
      })}
             </>
              :null} */}
    </div>
  )
}
