import usFlag from "../assets/images/flags/us.jpg"
import spain from "../assets/images/flags/spain.jpg"
import germany from "../assets/images/flags/germany.jpg"
import italy from "../assets/images/flags/italy.jpg"
import russia from "../assets/images/flags/russia.jpg"
import qatar from "../assets/images/flags/qatar.jpg"

const languages = {
 
  ar: {
    label: "عربى",
    flag: qatar,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}


// const languages = {
//   sp: {
//     label: "Spanish",
//     flag: spain,
//   },
//   gr: {
//     label: "German",
//     flag: germany,
//   },
//   it: {
//     label: "Italian",
//     flag: italy,
//   },
//   rs: {
//     label: "Russian",
//     flag: russia,
//   },
//   en: {
//     label: "English",
//     flag: usFlag,
//   },
// }

export default languages
