import {
  GET_COUNTRY_CODE,
  GET_COUNTRY_CODE_SUCCESS,
  GET_COUNTRY_CODE_FAIL,
} from "./actionTypes"

export const getCountryCode = () => ({
  type: GET_COUNTRY_CODE,
})

export const getCountryCodeSuccess = data => ({
  type: GET_COUNTRY_CODE_SUCCESS,
  payload: data,
})
export const getCountryCodeFail = data => ({
  type: GET_COUNTRY_CODE_FAIL,
  payload: data,
})
