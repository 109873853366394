import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Container, Row, Col, Table, Label, Badge } from 'reactstrap'
import { getPaymentGatewayDetail } from "store/actions"
import { useDispatch } from "react-redux"
import { connect } from 'react-redux'
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer } from 'react-toastify';
import dateFormat from '../../../helpers/commonhelpers/dateFormat'
import LoadingSpinnerThreeDots from '../../../helpers/commonhelpers/loadingSpinner'
import PayGateContacts from "./PayGateOverviewContacts"

export const ValuesContext = React.createContext()

function PayGateOverview(props) {
    const [paygateId, setPayGateId] = useState("")
    const [paymentGateway, setPaymentGateway] = useState()
    const dispatch = useDispatch();
    const [loading, setUserLoading] = useState(props.loading)

    useEffect(() => {
        if (props.match.params.id !== undefined) {
            setPayGateId(props.match.params.id)
        }
    }, [props.match.params.id])

    useEffect(() => {
        if (paygateId !== "") {
            dispatch(getPaymentGatewayDetail(paygateId))
        }
    }, [paygateId])

    useEffect(() => {
        setUserLoading(props.loading)
    }, [props.loading])


    useEffect(() => {
        if (typeof props.paymentGateway === "object" && props.paymentGateway !== undefined) {
            setPaymentGateway(props.paymentGateway)
        }
    }, [props.paymentGateway])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Overview" breadcrumbItem=" PAYMENT GATEWAYS" />
                    {loading ?
                        <Row className="spinner-custom mx-auto my-auto pb-3">
                            <LoadingSpinnerThreeDots />
                        </Row>
                        :
                        <Row>
                            <Col>
                                <Card className="mb-2">
                                    <CardBody>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td><Label>PAYMENT GATEWAY ID:</Label></td>
                                                    <td>{paymentGateway && paymentGateway.paymentGatewayId}</td>
                                                </tr>
                                                <tr>
                                                    <td><Label>PAYMENT GATEWAY :</Label></td>
                                                    <td>{paymentGateway && paymentGateway.paymentGateway}</td>
                                                </tr>
                                                <tr>
                                                    <td><Label>STATUS :</Label></td>
                                                    <td>{paymentGateway && paymentGateway.status === "Active" ?
                                                        <Badge
                                                            className={"font-size-12 badge-soft-success"}
                                                            color="success"
                                                            pill>
                                                            {paymentGateway && paymentGateway.status}
                                                        </Badge>
                                                        :
                                                        <Badge
                                                            className={"font-size-12 badge-soft-danger"}
                                                            color="danger"
                                                            pill>
                                                            {paymentGateway && paymentGateway.status}
                                                        </Badge>}</td>
                                                </tr>
                                                <tr>
                                                    <td><Label>REGISTRATION DATE :</Label></td>
                                                    <td>{paymentGateway && paymentGateway.registrationDate ?
                                                        dateFormat(paymentGateway.registrationDate)
                                                        :
                                                        null}</td>
                                                </tr>
                                                <tr>
                                                    <td><Label>CONTRACT START DATE :</Label></td>
                                                    <td>{paymentGateway && paymentGateway.contractStartDate ?
                                                        dateFormat(paymentGateway.contractStartDate)
                                                        :
                                                        null}</td>
                                                </tr>
                                                <tr>
                                                    <td><Label>CONTRACT END DATE :</Label></td>
                                                    <td>{paymentGateway && paymentGateway.contractEndDate ?
                                                        dateFormat(paymentGateway.contractEndDate)
                                                        :
                                                        null}</td>
                                                </tr>
                                                <tr>
                                                    <td><Label>API DEFAULT LINK :</Label></td>
                                                    <td>{paymentGateway && paymentGateway.apiDefaultLink}</td>
                                                </tr>
                                                <tr>
                                                    <td><Label>API DEFAULT LINK :</Label></td>
                                                    <td>{paymentGateway && paymentGateway.paymentType ?
                                                        paymentGateway.paymentType.map((pType, i) => {
                                                            return (
                                                                <Row key={i} className="mb-3"><Col>{pType}</Col></Row>
                                                            )
                                                        })
                                                        : null}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <ValuesContext.Provider value={{ paymentGateway }}>
                                    <Card>
                                        <CardBody>
                                            <PayGateContacts />
                                        </CardBody>
                                    </Card>
                                </ValuesContext.Provider>
                            </Col>
                        </Row>}
                </Container>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
        loading: state.PaymentGateway.loading,
        editPaygate: state.PaymentGateway.editPaygate,
        statusPaygate: state.PaymentGateway.statusPaygate,
        paymentGateway: state.PaymentGateway.paymentGateway
    }
}
export default connect(mapStateToProps)(PayGateOverview)
