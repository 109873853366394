import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {
  SET_PAYMNET_GATEWAY,
  GET_PAYMENT_GATEWAY_LIST,
  UPDATE_PAYMENT_GATEWAY,
  UPDATE_STATUS_CHANGE,
  GET_PAYMENT_GATEWAY_DETAIL,
} from "./actionTypes"

import {
  setPaymentGatewaySuccess,
  setPaymentGatewayFail,
  getPaymentGatewayListSuccess,
  getPaymentGatewayListFail,
  updatePaymentGatewaySuccess,
  updatePaymentGatewayFail,
  updateStatusChangeSuccess,
  updateStatusChangeFail,
  getPaymentGatewayDetailSuccess,
  getPaymentGatewayDetailFail,
  restartState,
} from "./actions"

//Include Both Helper File with needed methods
import {
  setPaymentGateway,
  getPaymentGatewayList,
  updatePaymentGateway,
  getPaymentGatewayDetail,
} from "helpers/fakebackend_helper"

function* _setPaymentGateway({ payload: paygate }) {
  // try {
  //   const response = yield call(setPaymentGateway, paygate)

  //   if (response) {
  //     yield put(setPaymentGatewaySuccess(response))
  //   } else {
  //     yield put(setPaymentGatewayFail(response))
  //   }
  //   yield put(restartState())
  // } catch (error) {
  //   yield put(setPaymentGatewayFail(response))
  // }
  try {
    const response = yield call(setPaymentGateway, paygate)

    if (response) {
      if (response?.isSuccess)
        yield put(setPaymentGatewaySuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setPaymentGatewayFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setPaymentGatewayFail(response.message))
      }
    } else yield put(setPaymentGatewayFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(setPaymentGatewayFail(error?.message))
  }
}
function* _getPaymentGatewayList({ payload: paygate }) {
  // try {
  //   const response = yield call(getPaymentGatewayList, paygate)
  //   if (response) {
  //     yield put(getPaymentGatewayListSuccess(response))
  //   }
  //   else {
  //     yield put(getPaymentGatewayListFail(response))
  //   }
  // }
  // catch (error) {
  //   yield put(getPaymentGatewayListFail(error))
  // }
  try {
    const response = yield call(getPaymentGatewayList, paygate)

    if (response) {
      if (response?.isSuccess)
        yield put(getPaymentGatewayListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPaymentGatewayListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getPaymentGatewayListFail(response.message))
      }
    } else
      yield put(getPaymentGatewayListFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getPaymentGatewayListFail(error?.message))
  }
}

function* _updatePaymentGateway({ payload: paygate }) {
  // try {
  //   const response = yield call(updatePaymentGateway, paygate)
  //   if (response) {
  //     yield put(updatePaymentGatewaySuccess(response))
  //   } else {
  //     yield put(updatePaymentGatewayFail(response))
  //   }
  //   yield put(restartState())
  // } catch (error) {
  //   yield put(updatePaymentGatewayFail(error))
  // }
  try {
    const response = yield call(updatePaymentGateway, paygate)

    if (response) {
      if (response?.isSuccess)
        yield put(updatePaymentGatewaySuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              updatePaymentGatewayFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(updatePaymentGatewayFail(response.message))
      }
    } else yield put(updatePaymentGatewayFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(updatePaymentGatewayFail(error?.message))
  }
}

function* _updateStatusChange({ payload: paygate }) {
  // try {
  //   const response = yield call(updatePaymentGateway, paygate)
  //   if (response) {
  //     yield put(updateStatusChangeSuccess(response))
  //   } else {
  //     yield put(updateStatusChangeFail(response))
  //   }
  //   yield put(restartState())
  // } catch (error) {
  //   yield put(updateStatusChangeFail(error))
  // }
  try {
    const response = yield call(updatePaymentGateway, paygate)

    if (response) {
      if (response?.isSuccess)
        yield put(updateStatusChangeSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              updateStatusChangeFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(updateStatusChangeFail(response.message))
      }
    } else yield put(updateStatusChangeFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(updateStatusChangeFail(error?.message))
  }
}

function* _getPaymentGatewayDetail({ payload: paygate }) {
  // try {
  //   const response = yield call(getPaymentGatewayDetail, paygate)
  //   if (response) {
  //     yield put(getPaymentGatewayDetailSuccess(response))
  //   } else {
  //     yield put(getPaymentGatewayDetailFail(response))
  //   }
  // } catch (error) {
  //   yield put(getPaymentGatewayDetailFail(error))
  // }
  try {
    const response = yield call(getPaymentGatewayDetail, paygate)

    if (response) {
      if (response?.isSuccess)
        yield put(getPaymentGatewayDetailSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPaymentGatewayDetailFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getPaymentGatewayDetailFail(response.message))
      }
    } else
      yield put(
        getPaymentGatewayDetailFail({ message: paygate + " Invalid Response." })
      )
  } catch (error) {
    yield put(getPaymentGatewayDetailFail(error?.message))
  }
}

function* paymentGatewaySaga() {
  yield takeEvery(SET_PAYMNET_GATEWAY, _setPaymentGateway)
  yield takeEvery(GET_PAYMENT_GATEWAY_LIST, _getPaymentGatewayList)
  yield takeEvery(UPDATE_PAYMENT_GATEWAY, _updatePaymentGateway)
  yield takeEvery(UPDATE_STATUS_CHANGE, _updateStatusChange)
  yield takeEvery(GET_PAYMENT_GATEWAY_DETAIL, _getPaymentGatewayDetail)
}

export default paymentGatewaySaga
