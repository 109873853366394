export const GET_PAYMENTREQUEST_LIST_ARCHIVED =
  "GET_PAYMENTREQUEST_LIST_ARCHIVED"
export const GET_PAYMENTREQUEST_LIST_ARCHIVED_SUCCESS =
  "GET_PAYMENTREQUEST_LIST_ARCHIVED_SUCCESS"
export const GET_PAYMENTREQUEST_LIST_ARCHIVED_FAIL =
  "GET_PAYMENTREQUEST_LIST_ARCHIVED_FAIL"

export const PATCH_PAYMENTREQUEST_ARCHIVE = "PATCH_PAYMENTREQUEST_ARCHIVE"
export const PATCH_PAYMENTREQUEST_ARCHIVE_SUCCESS =
  "PATCH_PAYMENTREQUEST_ARCHIVE_SUCCESS"
export const PATCH_PAYMENTREQUEST_ARCHIVE_FAIL =
  "PATCH_PAYMENTREQUEST_ARCHIVE_FAIL"

export const RETURN_STATE = "RETURN_STATE"
