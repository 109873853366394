import React, { useState } from "react"
import {
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { updateCustomer } from "../../store/actions.js"

function CustomerUpdatePopUp(props) {
  const dispatch = useDispatch()
  let mPLus = props.customerDetails.mobile.split("-")
  const [
    { id, Name, Email, Mobile, MobilePlus, Type, MerchantId },
    setPaymentRequest,
  ] = useState({
    MobilePlus: mPLus[0],
    Name: props.customerDetails.name,
    Email: props.customerDetails.email,
    Mobile: mPLus[1],
    SearchCustomer: "",
    Type: props.customerDetails.type,
    MerchantId: props.customerDetails.merchantId,
    modal_large: false,
  })
  const { register, handleSubmit, errors, reset } = useForm()

  const storeDataOnForm = event => {
    const re = /^[a-zA-Z\s]*$/
    const me = /^[0-9\s]*$/
    const { name, value } = event.target
    if (name === "Name" && name !== "Mobile" && re.test(value)) {
      setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
    }
    if (name === "Mobile" && name !== "Name" && me.test(value)) {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        [name]: value.replaceAll(" ", ""),
      }))
    }
    if (name !== "Name" && name !== "Mobile") {
      setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
    }
  }
  //Function for updating customer
  const handleUpdateCustomer = () => {
    if (props.customerDetails) {
      const newCustomerDetails = {
        _id: props.customerDetails._id,
        Name,
        Email,
        Mobile: MobilePlus + "-" + Mobile.toString(),
        RegistrationDate: props.customerDetails.registrationDate,
        Type,
        MerchantId: props.customerDetails.merchantID,
        IsActive: true,
        Archive: props.customerDetails.archive,
      }
      if (id !== "" && Name != "" && Email != "" && Mobile != "") {
        dispatch(updateCustomer(newCustomerDetails))
        setPaymentRequest(formRequest => ({
          ...formRequest,
          Name,
          Email,
          MobilePlus,
          Mobile,
        }))
        props.onChange(formRequest => ({
          ...formRequest,
          modal_large: false,
        }))
      }
    }
  }
  return (
    <Form
      onSubmit={handleSubmit(handleUpdateCustomer)}
      className=" mx-auto my-auto"
    >
      <Row className="form-group mx-3 my-3">
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <Label className="input-group-text input-text-size">
              CUSTOMER NAME
            </Label>
          </InputGroupAddon>
          <input
            id="Name"
            name="Name"
            value={Name}
            type="text"
            className="form-control form-control-lg input-text-size"
            ref={register({ required: true })}
            onChange={event => storeDataOnForm(event)}
          />
        </InputGroup>
        {errors.Name && errors.Name.type === "required" && (
          <p className="color-danger">This is required</p>
        )}

        <InputGroup className="">
          <InputGroupAddon addonType="prepend">
            <Label className="input-group-text input-text-size">
              CUSTOMER EMAIL
            </Label>
          </InputGroupAddon>
          <input
            id="Email"
            name="Email"
            value={Email}
            type="email"
            ref={register({ required: true })}
            className="form-control form-control-lg input-text-size"
            onChange={event => storeDataOnForm(event)}
          />
        </InputGroup>
        {errors.Name && errors.Name.type === "required" && (
          <p className="color-danger">This is required</p>
        )}
      </Row>
      <Row className="form-group mx-1 my-1">
        <Col>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">MOBILE</Label>
            </InputGroupAddon>
            <select
              className="form-control  custom-select input-text-size"
              id="MobilePlus"
              name="MobilePlus"
              value={MobilePlus}
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            >
              <option value=" +974" hidden="hidden">
                +974
              </option>
              {props.countryCodes?.map((cc, i) => {
                return (
                  <option value={"+" + cc.countryCode}>
                    +{cc.countryCode}
                  </option>
                )
              })}
            </select>
          </InputGroup>
          {errors.Name && errors.Name.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
        <Col>
          <InputGroup>
            <input
              id="Mobile"
              name="Mobile"
              value={Mobile}
              type="text"
              ref={register({ required: true })}
              className="form-control form-control-lg input-text-size"
              onChange={event => storeDataOnForm(event)}
            />
          </InputGroup>
          {errors.Name && errors.Name.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
      </Row>
      <Row className="">
        <Col className="item-center mb-3">
          <Button
            color="primary"
            type="submit"
            className=" waves-effect waves-light mt-3 btn-lg"
          >
            <i className="mdi mdi-account-multiple-plus mr-1" />
            Update Customer
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default CustomerUpdatePopUp
