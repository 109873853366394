import React, { useEffect, useState } from "react"
import {
  Col,
  Card,
  CardBody,
  Row,
  Container,
  Button,
  Modal,
  ModalBody,
  Label,
} from "reactstrap"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"

import { dateFormatString } from "../../helpers/commonhelpers/dateFormat"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import CustomerArchiveCol from "./CustomerArchiveCol"
function CustomerArchiveListTable(props) {
  const { merchantList, loading, list } = props
  const [usersList, setUsersList] = useState([])

  let searchText = ""
  const pageOptions = {
    sizePerPage: 10,
    totalSize: list.length, // replace later with size(customerList),
    custom: true,
    onPageChange: (page, sizePerPage) => {
      handleTableChange()
    },
  }

  const { SearchBar } = Search
  //Use Effect for getting customer list with Merchant ID

  useEffect(() => {
    //console.log(merchantList)
  }, [merchantList])
  //////////////////////////////////////////////////////////////////////////////

  const handleTableChange = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setUsersList(
        props.userList.filter(customer =>
          Object.keys(customer).some(key =>
            customer[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }

  const handleArchiveCustomer = value => {
    //console.log(value)
    if (value !== "" && value !== undefined) {
      props.onChange(value)
    }
  }

  /////////////////////////////////////////////
  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            {loading ? (
              <Row className="spinner-custom mx-auto my-auto pb-3">
                <LoadingSpinnerThreeDots />
              </Row>
            ) : (
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={list}
                    columns={CustomerArchiveCol(
                      merchantList,
                      handleArchiveCustomer
                    )}
                    bootstrap4
                    search
                    exportCSV={{
                      fileName: "UserList.csv",
                      separator: ",",
                      noAutoBOM: false,
                      exportAll: false,
                      onlyExportFiltered: true,
                    }}
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2  mx-2">
                          <Col sm="4">
                            <div className="search-box mr-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  {...toolkitProps.searchProps}
                                  className="width-search"
                                  placeholder="Search User/Email/Mobile"
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive
                                bordered={false}
                                hover={true}
                                striped={false}
                                classes={
                                  "table table-centered pointer table-nowrap table-hover mr-5 mb-5"
                                }
                                //rowEvents={rowEvents}
                                keyField="_id"
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange(searchText)}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CustomerArchiveListTable
