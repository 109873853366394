import React, {
  useEffect,
  useState,
} from "react"
import { Col, Form, FormGroup, Label, Row, Button } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import { useForm } from "react-hook-form"
import ImageModal from "./ImageModal"
import { Link } from "react-router-dom"

export default function PGSettingsForm(props) {

  const{merchantDetails, handleMerchantPGAccountSettings, handleSubmit} = props

  const [isCompleted, setIsCompleted] = useState(false)

  const [isApiKeyValid, setIsApiKeyValid] = useState()
  const [isCompletedValid, setIsCompletedValid] = useState()
  const [imageModal, setImageModal] = useState(false)
  const [image, setImage] = useState()

  useEffect(() => {
  
  }, [isApiKeyValid,isCompletedValid])
  

  function handleApiKey(value) {
    var pattern = /^[^\s]+$/

    if (pattern.test(value)) {
      setIsApiKeyValid(true)
    } else {
      setIsApiKeyValid(false)
    }

    handleChange({ apiKey: value })
  }

  function handleIsCompleted(value) {

    if (value) {
      setIsCompletedValid(true)
    } else {
      setIsCompletedValid(false)
    }

    handleChange({ isCompletedSteps: value })
  }


  
  function handleChange(changes) {
    handleMerchantPGAccountSettings({ ...merchantDetails, ...changes })
  }



  function renderSubmitButton(){

    if (isApiKeyValid && isCompletedValid) {
      return (
        <Button color="success" onClick={() => handleSubmit()}>
          <i className="fas fa-paper-plane" /> Submit Now
        </Button>
      )
    }else{
      return (
        <Button color="secondary" disabled>
          <i className="fas fa-paper-plane" /> Submit Now
        </Button>
      )

    }

  }

  const toggleImageModal = (value) => {
    setImageModal(!imageModal)
    setImage(value)
  }

  return (
    < >
      <ImageModal isOpen={imageModal} toggle={toggleImageModal} image={image} />

        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="basicpill-merchantname-input14">
                Merchant Name <span style={{ color: "red" }}>*</span>
              </Label>
              <input
                autoComplete="off"
                type="text"
                value={merchantDetails.merchantName}
                name="MerchantName"
                className="form-control"
                id="MerchantName"
                readOnly
                disabled
              />

            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <Label>
                PaymentGateway <span style={{ color: "red" }}>*</span>
              </Label>
              <select
                className="custom-select"
                name="Type"
                value={merchantDetails.paymentGateway}
                readOnly
              >
                <option value="MyFatoorah">MyFatoorah</option>

              </select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <hr></hr>
        </Row>

        <Row>
        <Col lg="12">
          <p style={{fontSize: "1.5rem", fontWeight: "300", color: "#006699"}} > 1. Provide your <b>{merchantDetails.paymentGateway}
                </b> API Key</p>
          <p>Log in to your portal then click <b>Integration Settings {'>'} API Key </b> copy the Api Key and then paste it in the box below. 
          <Link to="#" onClick={() => toggleImageModal("apikey")}> View sample </Link></p>

            <FormGroup>
              <Label for="basicpill-address-input2">
                Api Key <span style={{ color: "red" }}>*</span>
              </Label>
              <textarea
                autoComplete="off"
                value={merchantDetails.apiKey}
                name="ApiKey"
                onBlur={e => handleApiKey(e.target.value)}
                onChange={e => handleApiKey(e.target.value)}
                id="ApiKey"
                className="form-control"
                rows="5"
              
              />

              {isApiKeyValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Invalid Api Key.
                </small>
              )}
            </FormGroup>
          </Col>
        </Row>
       <Row>
        <hr></hr>
       </Row>

        <Row>
        <Col lg="12">
        <p style={{fontSize: "1.5rem", fontWeight: "300",  color: "#006699"}} > 2. Paste the webhook url to your {merchantDetails.paymentGateway} account settings</p>
        <p>Copy the url below and paste into your {merchantDetails.paymentGateway} Account, click <b>Integration Settings {'>'} Webhook url </b> and apply the other settings as<Link to="#" onClick={() => toggleImageModal("webhook")}> shown here. </Link></p>
        <div style={{backgroundColor:"#EBECF0", padding:"40px"}} height="150px" width="100%" className="d-flex justify-content-center">
        <p style={{fontSize: "2.5vw", fontSize: "12px",fontWeight: "300"}} ><b>https://pal.developerqa.info/api/mf/webhook</b></p>

       </div>

          </Col>
        </Row>
        <Row>
          <hr></hr>
        </Row>

        <Row>
        
          <Col lg="6">
                          <div className="form-check form-check-primary mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={merchantDetails.isCompletedSteps}
                              onChange={() => {
                                handleIsCompleted(!merchantDetails.isCompletedSteps)
                              }}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                            >
                              I have provided correct api key and updated my settings.
                            </label>
                          </div>
                          {isCompletedValid === false && (
                <small className="color-danger" style={{ float: "left" }}>
                  Required
                </small>
              )}
          </Col>
        </Row>

    
        <div width="100%" className="d-flex justify-content-center">

        {renderSubmitButton()}


        

</div>
       
       
      

</>
  )
}
