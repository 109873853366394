import React, { useState, useEffect } from "react"
import {
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap"
import { useForm } from "react-hook-form"
import AuthUser from "components/AuthUser/AuthUser"
import { useDispatch } from "react-redux"
import { setUser } from "../../store/actions.js"
import { isEmail } from "components/Validation/EmailValidation.js"

function UserCreatePopUp(props) {
  const dispatch = useDispatch()
  const [
    {
      title,
      userName,
      firstName,
      lastName,
      email,
      Mobile,
      role,
      MobilePlus,
      department,
      merchantId,
      status,
    },
    setPaymentRequest,
  ] = useState({
    title: "MR",
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    Mobile: "",
    MobilePlus: "+974",
    role: "User",
    department: "",
    merchantId: "",
    status: false,
  })
  const [merchantIds, setMerchantIds] = useState(props.merchantIds)
  const [user, setAuthUser] = useState(AuthUser())
  const { register, handleSubmit, errors } = useForm()
  const storeDataOnForm = event => {
    const re = /^[a-zA-Z\s]*$/
    const me = /^[0-9\s]*$/
    const { name, value } = event.target

    if (name === "firstName" && re.test(value)) {
      setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
    }
    if (name === "lastName" && re.test(value)) {
      setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
    }
    if (name === "Mobile" && me.test(value)) {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        [name]: value.replaceAll(" ", ""),
      }))
    }
    if (name !== "firstName" && name !== "lastName" && name !== "Mobile") {
      setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
    }
  }

  useEffect(() => {
    if (props.merchantIds !== undefined) {
      setMerchantIds(props.merchantIds)
    }
  }, [props.merchantIds])

  useEffect(() => {}, [merchantIds])

  const handleCreateUser = data => {
    if (user.role !== "SuperAdmin") {
      const userModel = {
        UserId: "",
        MerchantId: user.merchantId,
        RegistrationDate: "2020-01-01T01:01:01",
        Title: data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        Mobile: MobilePlus + "-" + Mobile.toString(),
        UserName: data.userName,
        Password: "",
        Email: data.email,
        Role: data.role,
        Department: data.department,
        IsActive: data.status === "true" ? true : false,
      }
      dispatch(setUser(userModel))
    } else {
      const userModel = {
        UserId: "",
        MerchantId: data.merchantId,
        RegistrationDate: "2020-01-01T01:01:01",
        Title: data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        Mobile: MobilePlus + "-" + Mobile.toString(),
        UserName: data.userName,
        Password: "",
        Email: data.email,
        Role: data.role,
        Department: data.department,
        IsActive: data.status === "true" ? true : false,
      }
      dispatch(setUser(userModel))
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(handleCreateUser)}
      className=" mx-auto my-auto"
    >
      <Row className="form-group mx-3 my-3">
        <Col lg={6}>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">TITLE</Label>
            </InputGroupAddon>
            <select
              className="form-control form-control-lg input-text-size"
              id="title"
              name="title"
              value={title}
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            >
              <option>MR</option>
              <option>MS</option>
            </select>
          </InputGroup>
          {errors.title && errors.title.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
        {user.role === "Admin" ? (
          <Col lg={6}>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">ROLE</Label>
              </InputGroupAddon>
              <select
                className="form-control form-control-lg input-text-size"
                id="role"
                name="role"
                value={role}
                ref={register({ required: true })}
                onChange={event => storeDataOnForm(event)}
              >
                <option>Admin</option>
                <option>User</option>
              </select>
            </InputGroup>
            {errors.role && errors.role.type === "required" && (
              <p className="color-danger">This is required</p>
            )}
          </Col>
        ) : null}
        {user.role === "User" ? (
          <Col lg={6}>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">ROLE</Label>
              </InputGroupAddon>
              <select
                className="form-control form-control-lg input-text-size"
                id="role"
                name="role"
                value={role}
                ref={register({ required: true })}
                onChange={event => storeDataOnForm(event)}
              >
                <option>User</option>
              </select>
            </InputGroup>
            {errors.role && errors.role.type === "required" && (
              <p className="color-danger">This is required</p>
            )}
          </Col>
        ) : null}
        {user.role === "SuperAdmin" ? (
          <Col lg={6}>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">ROLE</Label>
              </InputGroupAddon>
              <select
                className="form-control form-control-lg input-text-size"
                id="role"
                name="role"
                value={role}
                ref={register({ required: true })}
                onChange={event => storeDataOnForm(event)}
              >
                <option>SuperAdmin</option>
                <option>Admin</option>
                <option>User</option>
              </select>
            </InputGroup>
            {errors.role && errors.role.type === "required" && (
              <p className="color-danger">This is required</p>
            )}
          </Col>
        ) : null}
      </Row>
      <Row className="form-group mx-3 my-3">
        {/* <Col>
                    <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                            <Label className="input-group-text input-text-size">
                                USERNAME
                              </Label>
                        </InputGroupAddon>
                        <input
                            id="userName"
                            name="userName"
                            value={userName}
                            type="text"
                            className="form-control  form-control-lg input-text-size"
                            ref={register({ required: true })}
                            onChange={(event) => storeDataOnForm(event)}
                        />
                    </InputGroup>
                    {errors.userName && errors.userName.type === "required" && <p className="color-danger">This is required</p>}
                </Col> */}
        <Col lg={12}>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">EMAIL</Label>
            </InputGroupAddon>
            <input
              id="email"
              name="email"
              value={email}
              type="email"
              className="form-control  form-control-lg input-text-size"
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            />
          </InputGroup>
          {errors.email && errors.email.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
      </Row>
      <Row className="form-group mx-3 my-3">
        <Col lg={6}>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                FIRST NAME
              </Label>
            </InputGroupAddon>
            <input
              id="firstName"
              name="firstName"
              value={firstName}
              type="text"
              className="form-control  form-control-lg input-text-size"
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            />
          </InputGroup>
          {errors.firstName && errors.firstName.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
        <Col lg={6}>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                LAST NAME
              </Label>
            </InputGroupAddon>
            <input
              id="lastName"
              name="lastName"
              value={lastName}
              type="text"
              className="form-control  form-control-lg input-text-size"
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            />
          </InputGroup>
          {errors.lastName && errors.lastName.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
      </Row>
      <Row className="form-group mx-3 my-3">
        <Col lg={6}>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">MOBILE</Label>
            </InputGroupAddon>
            <select
              className="form-control  custom-select input-text-size"
              id="MobilePlus"
              name="MobilePlus"
              value={MobilePlus}
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            >
              <option value=" +974" hidden="hidden">
                +974
              </option>
              {props.countryCodes?.map((cc, i) => {
                return (
                  <option key={i} value={"+" + cc.countryCode}>
                    +{cc.countryCode}
                  </option>
                )
              })}
            </select>
          </InputGroup>
        </Col>
        <Col lg={6}>
          <InputGroup>
            <input
              id="Mobile"
              name="Mobile"
              value={Mobile}
              type="text"
              ref={register({ required: true })}
              className="form-control form-control-lg input-text-size"
              onChange={event => storeDataOnForm(event)}
            />
          </InputGroup>
          {errors.Mobile && errors.Mobile.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
      </Row>
      <Row className="form-group mx-3 my-3">
        <Col lg={6}>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                DEPARTMENT
              </Label>
            </InputGroupAddon>
            <select
              className="form-control form-control-lg input-text-size"
              id="department"
              name="department"
              value={department}
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            >
              <option>Administration</option>
              <option>Accounting</option>
              <option>Operations</option>
              <option>Finance</option>
              <option>Sales and Marketing</option>
            </select>
            {/* <input
                            id="department"
                            name="department"
                            value={department}
                            type="text"
                            ref={register({ required: true })}
                            className="form-control form-control-lg input-text-size"
                            onChange={(event) => storeDataOnForm(event)}
                        /> */}
          </InputGroup>
          {errors.department && errors.department.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
        {user.role === "SuperAdmin" ? (
          <Col lg={6}>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">
                  MERCHANT ID
                </Label>
              </InputGroupAddon>
              <select
                className="form-control  custom-select input-text-size"
                id="merchantId"
                name="merchantId"
                placeholder="..."
                value={merchantId}
                ref={register({ required: true })}
                onChange={event => storeDataOnForm(event)}
              >
                {merchantIds
                  ? merchantIds.map((ids, i) => {
                      return (
                        <option key={i} value={ids}>
                          {ids}
                        </option>
                      )
                    })
                  : "No Merchant Ids"}
              </select>
            </InputGroup>
            {errors.merchantId && errors.merchantId.type === "required" && (
              <p className="color-danger">This is required</p>
            )}
          </Col>
        ) : null}
      </Row>
      <Row className="form-group mx-3 my-3">
        <Col lg={6}>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">ACTIVE</Label>
            </InputGroupAddon>
            <select
              className="form-control form-control-lg input-text-size"
              id=""
              name="status"
              value={status}
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            >
              <option value={false}>False</option>
              <option value={true}>True</option>
            </select>
          </InputGroup>
          {errors.status && errors.status.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
        <Col lg={6}></Col>
      </Row>
      <Row className="">
        <Col className="item-center mb-3">
          <Button
            color="success"
            type="submit"
            className=" waves-effect waves-light mt-3 btn-lg"
          >
            <i className="mdi mdi-account-multiple-plus mr-1" />
            Enter New User
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default UserCreatePopUp
