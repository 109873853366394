import React, { useContext, useState, useEffect } from "react"
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { PaymentMethodContext } from "../../index"
import classnames from "classnames"

const ApplyPayRedirect = props => {
  const {mfApplePayEmbedded, paymentRequestDetails} = props
  const { onSetMyfatoorahInitiateSessionData } = useContext(
    PaymentMethodContext
  )


  const pay = () => {

          var model = {
            SessionId: null,
            TrackId: mfApplePayEmbedded.trackId,
            PaymentMethod: "applepay"
          }
          onSetMyfatoorahInitiateSessionData(model)
  }


  function isJsonString(str) {

    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}

  return (
    <>
     

        <Button
              width="100%"
              className=
                "btn-outline-applepay btn-block inner mb-2 "
              onClick={() => pay()}
            
              outline
              style={{ fontSize: "1rem", paddingBottom: "1px", paddingTop: "1px"}}
            >
             <i className="fab fa-apple-pay fa-2x" />
            </Button>

    </>
  )
}

ApplyPayRedirect.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(ApplyPayRedirect))
