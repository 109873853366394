import React, { useRef } from "react"
import Select from "react-select"
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    backgroundColor: "white",
    padding: 10,
  }),
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    height: state.selectProps.height,
    padding: 10,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    minLength: 10,
    textOverflow: "none",
    width: state.selectProps.width,
  }),
}
function index(props) {
  //console.log(array)
  //props.value = array
  const valueRef = useRef(props.value)
  valueRef.current = props.value

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: "all",
  }
  const isSelectAllSelected = () =>
    valueRef.current.length === props.options.length + 1

  const isOptionSelected = option =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected()

  const getOptions = () => [selectAllOption, ...props.options]

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta

    if (action === "select-option" && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta)
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta)
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta
      )
    } else {
      props.onChange(newValue || [], actionMeta)
    }
  }

  return (
    <Select
      styles={customStyles}
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={onChange}
      defaultValue={selectAllOption}
      hideSelectedOptions={true}
      closeMenuOnSelect={false}
      isMulti
    />
  )
}

export default index
