import { UPDATE_CUSTOMER_RESULT_FAIL } from "store/customer/actionTypes"
import {
  UPDATE_PAYMENT_HISTORY,
  UPDATE_PAYMENT_HISTORY_SUCCESS,
  UPDATE_PAYMENT_HISTORY_FAIL,
  SEND_NOTIFICATION_EMAIL,
  SEND_NOTIFICATION_EMAIL_SUCCESS,
  SEND_NOTIFICATION_EMAIL_FAIL,
  DOWNLOAD_PAYMENT_HISTORY,
  DOWNLOAD_PAYMENT_HISTORY_SUCCESS,
  DOWNLOAD_PAYMENT_HISTORY_FAIL,
  RESTART_STATE,
} from "./actionTypes"

export const updatePaymentHistory = data => ({
  type: UPDATE_PAYMENT_HISTORY,
  payload: data,
})
export const updatePaymentHistorySuccess = data => ({
  type: UPDATE_PAYMENT_HISTORY_SUCCESS,
  payload: data,
})
export const updatePaymentHistoryFail = error => ({
  type: UPDATE_PAYMENT_HISTORY_FAIL,
  payload: error,
})

export const sendNotificationEmail = data => ({
  type: SEND_NOTIFICATION_EMAIL,
  payload: data,
})
export const sendNotificationEmailSuccess = data => ({
  type: SEND_NOTIFICATION_EMAIL_SUCCESS,
  payload: data,
})
export const sendNotificationEmailFail = error => ({
  type: SEND_NOTIFICATION_EMAIL_FAIL,
  payload: error,
})

export const downloadPaymentHistory = data => ({
  type: DOWNLOAD_PAYMENT_HISTORY,
  payload: data,
})
export const downloadPaymentHistorySuccess = data => ({
  type: DOWNLOAD_PAYMENT_HISTORY_SUCCESS,
  payload: data,
})
export const downloadPaymentHistoryFail = data => ({
  type: DOWNLOAD_PAYMENT_HISTORY_FAIL,
  payload: data,
})

export const restartState = () => ({
  type: RESTART_STATE,
})
