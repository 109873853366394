import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { sessionTimeout } from "store/actions"
import { useDispatch } from "react-redux"
import { connect } from "react-redux"
function index(props) {
  const [seconds, setTimers] = useState({
    seconds: 0,
  })
  const [confirm_alert, setconfirm_alert] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    // localStorage.removeItem("authUser")
    window.location = "/login"
    dispatch(sessionTimeout(true))
  }, [])

  // useEffect(()=>{
  //   console.log(props.sessionTimeout)
  //   if(props.sessionTimeout === true){
  //     localStorage.removeItem("authUser")
  //     window.location = "/login";
  //   }
  // },[props.sessionTimeout])

  const TimerCountDown = () => {
    setTimers(prevState => ({
      prevState,
      seconds: seconds + 1,
    }))
  }

  const timerCount = () => {
    if (seconds === 10) {
      localStorage.removeItem("authUser")
      window.location = "/login"
    } else {
      setTimeout(() => {
        TimerCountDown()
      })
    }
  }

  return (
    <React.Fragment>
      {confirm_alert ? (
        <SweetAlert
          warning
          confirmBtnText="Logout"
          confirmBtnBsStyle="danger"
          title="Sorry, your session has expired."
          onConfirm={() => {
            setconfirm_alert(false)
            localStorage.removeItem("authUser")
            window.location = "/login"
          }}
        >
          Please go back to login page.
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    sessionTimeout: state.Login.sessionTimeout,
  }
}

export default connect(mapStateToProps)(index)
