import React from "react"
import dateFormat from "../../helpers/commonhelpers/dateFormat"

const UserLogsColumns = (merchantList, userList) => [
  {
    dataField: "refId",
    text: "Reference Id",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      if (cellContent === null) {
        return <>N/A</>
      } else {
        return <>{cellContent}</>
      }
    },
  },
  {
    dataField: "merchId",
    text: "Merchant Id",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      if (cellContent === null || cellContent === "") {
        return <>N/A</>
      } else {
        return <>{cellContent}</>
      }
    },
  },
  {
    dataField: "",
    text: "Merchant Name",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      var merchantName = ""
      if (row.merchId === null) {
        return <>N/A</>
      } else {
        return (
          <>
            {merchantList.map((merchant, i) => {
              if (row.merchId === merchant.merchantId) {
                merchantName = merchant.merchantName
                return <div key={i}>{merchant.merchantName}</div>
              }
            })}
            {merchantName === "" ? <>N/A</> : null}
          </>
        )
      }
    },
  },
  {
    dataField: "userId",
    text: "User Id",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      if (cellContent === null || cellContent === "") {
        return <>N/A</>
      } else {
        return <>{cellContent}</>
      }
    },
  },
  {
    dataField: "",
    text: "Username",
    sort: true,
    formatter: (cellContent, row) => {
      var username = ""
      if (row.userId === null) {
        return <>N/A</>
      } else {
        return (
          <>
            {userList.map((user, i) => {
              if (row.userId === user.userId) {
                username = user.userName
                return <div key={i}>{user.userName}</div>
              }
            })}
            {username === "" ? <>N/A</> : null}
          </>
        )
      }
    },
  },
  {
    dataField: "",
    text: "Email",
    sort: true,
    formatter: (cellContent, row) => {
      var email = ""
      if (row.userId === null) {
        return <>N/A</>
      } else {
        return (
          <>
            {userList.map((user, i) => {
              if (row.userId === user.userId) {
                email = user.email
                return <div key={i}>{user.email}</div>
              }
            })}
            {email === "" ? <>N/A</> : null}
          </>
        )
      }
    },
  },
  {
    dataField: "date",
    text: "Date",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      return <span>{dateFormat(cellContent)}</span>
    },
  },
  {
    dataField: "refPage",
    text: "Reference Page",
    searchable: true,
    sort: true,
  },
  {
    dataField: "action",
    text: "Action",
    searchable: true,
    sort: true,
  },
  {
    dataField: "ip",
    text: "IP Address",
    searchable: true,
    sort: true,
  },
  {
    dataField: "deviceName",
    text: "Device Name",
    searchable: true,
    sort: true,
  },
]
export default UserLogsColumns
