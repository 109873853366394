import React from "react"
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import dateFormat from "../../helpers/commonhelpers/dateFormat"

const PayGateColumns = handleActionTableUser => [
  {
    dataField: "paymentGatewayId",
    text: "Id",
    searchable: true,
    sort: true,
  },
  {
    dataField: "paymentGateway",
    text: "Payment Gateway",
    searchable: true,
    sort: true,
  },
  {
    dataField: "status",
    text: "Status",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      if (cellContent === "Active") {
        return (
          <Badge
            className={"font-size-12 badge-soft-success"}
            color="success"
            pill
          >
            {cellContent}
          </Badge>
        )
      }
      if (cellContent === "InActive") {
        return (
          <Badge
            className={"font-size-12 badge-soft-danger"}
            color="danger"
            pill
          >
            {cellContent}
          </Badge>
        )
      }
    },
  },
  {
    dataField: "registrationDate",
    text: "Registration Date",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      return <span>{dateFormat(cellContent)}</span>
    },
  },
  {
    dataField: "contractStartDate",
    text: "Contract Start Date",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      return <span>{dateFormat(cellContent)}</span>
    },
  },
  {
    dataField: "contractEndDate",
    text: "Contract End Date",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      return <span>{dateFormat(cellContent)}</span>
    },
  },
  {
    dataField: "_id",
    text: "Action",
    csvExport: false,
    formatter: (cellContent, row) => {
      if (row.status === "Active") {
        return (
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="card-drop" tag="i">
              <i className="mdi mdi-dots-horizontal font-size-18" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => handleActionTableUser("update")}>
                <i
                  className="mdi mdi-pencil font-size-18 mr-1 color-primary"
                  id="edittooltip"
                />
                Update Payment Gateway
              </DropdownItem>

              <DropdownItem
                href={"/paymentgateway/overview/" + row.paymentGatewayId}
              >
                <i className="mdi mdi-eye font-size-18 text-success mr-1" />
                View Payment Gateway
              </DropdownItem>

              <DropdownItem onClick={() => handleActionTableUser("deactivate")}>
                <i
                  className="mdi mdi-close font-size-18 mr-1 color-danger"
                  id="deletetooltip"
                />
                Deactivate Payment Gateway
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
      if (row.status === "InActive") {
        return (
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="card-drop" tag="i">
              <i className="mdi mdi-dots-horizontal font-size-18" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => handleActionTableUser("update")}>
                <i
                  className="mdi mdi-pencil font-size-18 mr-1 color-primary"
                  id="edittooltip"
                />
                Update Payment Gateway
              </DropdownItem>

              <DropdownItem
                href={"/paymentgateway/overview/" + row.paymentGatewayId}
              >
                <i className="mdi mdi-eye font-size-18 text-success mr-1" />
                View Payment Gateway
              </DropdownItem>

              <DropdownItem onClick={() => handleActionTableUser("activate")}>
                <i
                  className="fas fa-plus font-size-15 mr-1 color-active"
                  id="deletetooltip"
                />
                Activate Payment Gateway
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )
      }
    },
  },
]
export default PayGateColumns
