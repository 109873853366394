import React, { useEffect } from "react"
import { Button } from "reactstrap"
import styles from "assets/scss/tableFooter.scss"
const TableFooter = ({ range, setPage, page, slice }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1)
    }
  }, [slice, page, setPage])
  return (
    <div className="tableFooter">
      <Button
        color="primary"
        //   className={`${styles.button} ${
        //     page === el ? styles.activeButton : styles.inactiveButton
        //   }`}
        className="button"
        onClick={() => setPage(1)}
      >
        First
      </Button>
      {page !== 1 ? (
        <Button
          color="primary"
          //   className={`${styles.button} ${
          //     page === el ? styles.activeButton : styles.inactiveButton
          //   }`}
          className="button"
          onClick={() => setPage(page - 1)}
        >
          {"<"}
        </Button>
      ) : (
        <Button
          disabled
          color="disabled"
          //   className={`${styles.button} ${
          //     page === el ? styles.activeButton : styles.inactiveButton
          //   }`}
          className="button"
          onClick={() => setPage(page - 1)}
        >
          {"<"}
        </Button>
      )}
      {range.map((el, index) => {
        if (el !== 1 && el !== range.length && el === page) {
          return (
            <Button
              color="primary"
              key={index}
              //   className={`${styles.button} ${
              //     page === el ? styles.activeButton : styles.inactiveButton
              //   }`}
              className="button"
              onClick={() => setPage(el)}
            >
              {el}
            </Button>
          )
        }
      })}
      {page !== range.length ? (
        <Button
          color="primary"
          //   className={`${styles.button} ${
          //     page === el ? styles.activeButton : styles.inactiveButton
          //   }`}
          className="button"
          onClick={() => setPage(page + 1)}
        >
          {">"}
        </Button>
      ) : (
        <Button
          disabled
          color="disabled"
          //   className={`${styles.button} ${
          //     page === el ? styles.activeButton : styles.inactiveButton
          //   }`}
          className="button"
          onClick={() => setPage(page + 1)}
        >
          {">"}
        </Button>
      )}
      <Button
        color="primary"
        //   className={`${styles.button} ${
        //     page === el ? styles.activeButton : styles.inactiveButton
        //   }`}
        className="button"
        onClick={() => setPage(range.length)}
      >
        {/* {range.length} */}Last
      </Button>
    </div>
  )
}

export default TableFooter
