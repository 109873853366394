import React, { useState, useEffect } from 'react';
import AuthUser from "components/AuthUser/AuthUser"
import TravelAgency_Admin from './TravelAgency_Admin'
import TravelAgency_User from './TravelAgency_User';
import { CardBody, Card, Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux'
import { isEmpty } from "lodash"
import { getMerchantDetail } from 'store/actions'
import PropTypes from "prop-types"
import NonTA_Admin from './NonTA_Admin';
import NonTA_User from './NonTA_User';


const UserGuide = (props) => {

    const { merchantDetail, onGetMerchantDetail } = props

    const [user, setUser] = useState(AuthUser())


    useEffect(() => {

        if (user) {

            if (user.merchantId) {
                onGetMerchantDetail(user.merchantId)
            }
        }

    }, [])

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Card>
                            <CardBody>
                                <Row><Col>
                                    <div className="d-flex justify-content-center" >
                                        {merchantDetail?.type === "Travel Agency" &&
                                            <>
                                                {user.role === "Admin" &&
                                                    <TravelAgency_Admin />}
                                                {user.role === "User" &&
                                                    <TravelAgency_User />}
                                            </>
                                        }

                                        {merchantDetail?.type !== "Travel Agency" &&
                                            <>
                                                {user.role === "Admin" &&
                                                    <NonTA_Admin />}
                                                {user.role === "User" &&
                                                    <NonTA_User />}
                                            </>
                                        }
                                    </div>
                                </Col></Row>

                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>


        </>
    )
}

UserGuide.propTypes = {
    merchantDetail: PropTypes.object
}

const mapStateToProps = state => (

    {
        merchantDetail: state.Merchants.merchantDetail,

    })

const mapDispatchToProps = dispatch => (
    {
        onGetMerchantDetail: id => dispatch(getMerchantDetail(id)),

    })


export default connect(mapStateToProps, mapDispatchToProps)(UserGuide)
