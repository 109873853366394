import {
  GET_USERS_LIST_ARCHIVED,
  GET_USERS_LIST_ARCHIVED_SUCCESS,
  GET_USERS_LIST_ARCHIVED_FAIL,
  PATCH_USER_ARCHIVE,
  PATCH_USER_ARCHIVE_SUCCESS,
  PATCH_USER_ARCHIVE_FAIL,
  RETURN_STATE,
} from "./actionTypes"
export const getUsersListArchived = () => ({
  type: GET_USERS_LIST_ARCHIVED,
})
export const getUsersListArchivedSuccess = data => ({
  type: GET_USERS_LIST_ARCHIVED_SUCCESS,
  payload: data,
})
export const getUsersListArchivedFail = data => ({
  type: GET_USERS_LIST_ARCHIVED_FAIL,
  payload: data,
})

export const patchUserArchive = data => ({
  type: PATCH_USER_ARCHIVE,
  payload: data,
})
export const patchUserArchiveSuccess = data => ({
  type: PATCH_USER_ARCHIVE_SUCCESS,
  payload: data,
})
export const patchUserArchiveFail = data => ({
  type: PATCH_USER_ARCHIVE_FAIL,
  payload: data,
})

export const returnState = () => ({
  type: RETURN_STATE,
})
