import React from 'react'
import { connect } from 'react-redux'
import {
    Col,
    FormGroup,
    Row,
    Button,
    Form
} from "reactstrap"


export const CommissionSettings = (props) => {
    const {
        commission,
        handleCommissionChange,
        handleCommissionDelete,
        selectedPg,
        commissionBasis,
        handleSubmit,
        onSubmit,
        register,
        errors,
        index,
        indexPG
    } = props

    const inputBasis = "Basis" + indexPG + index
    const inputType = "Type" + indexPG + index
    const inputValue = "Value" + indexPG + index

   

    function handleChange(changes) {
        handleCommissionChange(commission.id, { ...commission, ...changes })
    }

    return (
        <div style={{ backgroundColor: "#b7c6e7", marginLeft: "20px", padding: "10px" }}>

            <Form onSubmit={handleSubmit(onSubmit)} >
                <Row >

                    <Col lg="3">
                        {/* TODO: pg get from db*/}
                        <FormGroup>
                            <label htmlFor="subject">Commission Basis </label>
                            <select className="custom-select"
                                value={commission.Basis}
                                name={inputBasis}
                                onChange={(e) => handleChange({ Basis: e.target.value })}
                                ref={register({
                                    required: {
                                        value: true,
                                        message: "This is required"
                                    }
                                })}
                            >
                                <option value="" selected="selected" hidden="hidden">Choose here</option>
                                {commissionBasis?.map((type, index) =>
                                    <option key={index} value={type}>{type}</option>

                                )}
                            </select>
                            <small className="color-danger">{errors[inputBasis] && errors[inputBasis].message}</small>
                        </FormGroup>
                    </Col>
                    <Col lg="3">
                        <FormGroup>
                            <label htmlFor="subject">Commission Type </label>
                            <select className="custom-select"
                                value={commission.Type}
                                placeholder="Select Commission Type"
                                name={inputType}
                                onChange={(e) => handleChange({ Type: e.target.value })}
                                ref={register({
                                    required: {
                                        value: true,
                                        message: "This is required"
                                    }
                                })}
                            >
                                <option value="" selected="selected" hidden="hidden">Choose here</option>
                                <option value="Fixed">Fixed</option>
                                <option value="Percentage">Percentage</option>
                            </select>
                            <small className="color-danger">{errors[inputType] && errors[inputType].message}</small>
                        </FormGroup>
                    </Col>
                    <Col lg="3">
                        <FormGroup>
                            <label htmlFor="subject">QAR or in % </label>
                            <input
                                value={commission.Value}
                                name={inputValue}
                                step="0.01"
                                onChange={(e) => handleChange({ Value: parseFloat(e.target.value) || '' })}
                                className="form-control"
                                type="number"
                                placeholder={
                                    " "
                                }
                                ref={register({
                                    required: {
                                        value: true,
                                        message: "This is required"
                                    }
                                })}
                            />

                            <small className="color-danger">{errors[inputValue] && errors[inputValue].message}</small>
                        </FormGroup>
                    </Col>


                    <Col lg="3">
                        <FormGroup>
                            <label style={{ color: "#b7c6e7" }} htmlFor="subject">Delete </label>

                            <Button
                                onClick={(e) => handleCommissionDelete(commission.id)}
                                color="secondary"
                                className="btn-block inner"
                                style={{ width: "100%" }}
                            >
                                {" "}
                                {" "}
                                          Delete Commission Setting{" "}
                            </Button>

                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionSettings)
