import React from 'react'
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import {
  Col,
  Table,
} from "reactstrap"

import {
  paymentRequestStatus,
  paymentRequestLinkStatus,
  payNowStatus
} from '../../helpers/paymentrequest/paymentrequest_helper'
import dateFormat from '../../helpers/commonhelpers/dateFormat'

const Header = (props) => {

  const { paymentRequestDetail, merchantDetail } = props
  const path = "/merchant/"

  return (
    <>


      <Col lg="3">


        <img
          // src={path + merchantDetail?.logo}
          src={merchantDetail?.logo}
          alt=""
          className="img-fluid"
          height="auto"
          maxwidth="50%"
          width="80%"
        />

      </Col>

      <Col lg="4" style={{paddingBottom: "0.5rem" }} className="headerDetails">


        <span>
          {/* {props.t("BILL TO")} : {" "} */}
          <b>{paymentRequestDetail?.customerDetails.name}</b> <br></br>
         {/* <small>{paymentRequestDetail?.customerDetails.email} /
          {paymentRequestDetail?.customerDetails.mobile}</small>  */}
        </span>


      </Col>

      <Col lg="2" className="headerDetails">


        <span>
          {paymentRequestDetail?.paymentRequestId} {" "}
          {paymentRequestLinkStatus(paymentRequestDetail?.generatedLinkStatus,
            paymentRequestDetail?.generatedLinkStatus,
            true)}
        </span>

      </Col>


      <Col lg="3" className="headerDetails">


        <span>
        {dateFormat(paymentRequestDetail?.paymentRequestDate)}
        </span>

      </Col>

      

    </>
  )
}

Header.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Header))
