import React from "react"
import {
    Badge,
    Row,
    Col
} from "reactstrap"
import dateFormat from '../../helpers/commonhelpers/dateFormat'

const ParserExecutionLogsColumns = () =>[
   
    {
        dataField: "fileName",
        text: "File Name",
        searchable: true,
        sort: true,
    },
    {
        dataField: "date",
        text: "Date",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
            return (
              <span>
               {dateFormat(cellContent)}
              </span>
            )
          }
    },
    {
        dataField: "hasErrors",
        text: "Has Error",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
            if (cellContent === true) {
              return <Badge
                className={"font-size-12 badge-soft-success"}
                color="success"
                pill
              >
                {cellContent.toString()}
              </Badge>
            }
            if (cellContent === false) {
              return <Badge
                className={"font-size-12 badge-soft-danger"}
                color="danger"
                pill
              >
                {cellContent.toString()}
              </Badge>
            }
          }
    },
    {
        dataField: "unparsed",
        text: "Unparesed",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
            if(cellContent === null || cellContent === "\r\n"){
                return(
                    <Row>
                        <Col>
                         N/A
                        </Col>
                    </Row>
                )
            }
            else{
                return cellContent
            }
        }
    },
    {
        dataField: "unparsedNotification",
        text: "Unparsed Notification",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
            if (cellContent === true) {
              return <Badge
                className={"font-size-12 badge-soft-success"}
                color="success"
                pill
              >
                {cellContent.toString()}
              </Badge>
            }
            if (cellContent === false) {
              return <Badge
                className={"font-size-12 badge-soft-danger"}
                color="danger"
                pill
              >
                {cellContent.toString()}
              </Badge>
            }
          }
    },
    {
        dataField: "projectName",
        text: "Project Name",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
            if(cellContent === null || cellContent === ""){
                return(
                    <Row>
                        <Col>
                         N/A
                        </Col>
                    </Row>
                )
            }
            else{
                return cellContent
            }
        }
    },
    {
        dataField: "tagsCaptured",
        text: "Tags Captured",
        searchable: true,
        sort: true,
    },
   
   
    {
        dataField: "content",
        text: "File Content",
        searchable: true,
        sort: true,
    },
   
   
]
export default ParserExecutionLogsColumns