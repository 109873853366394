import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_TRANSACTION_UPDATE_LOGS_LIST,
  GET_USER_LOGS,
  GET_USER_LOGS_LIST,
  GET_EMAIL_LOGS_LIST,
  GET_ERROR_LOGS_LIST,
  UPDATE_TRANSACTION_LOG,
  GET_PARSER_ERROR_LOG_LIST,
  GET_PARSER_EXECUTION_LOG_LIST,
  GET_PLACER_ERROR_LOG_LIST,
  GET_PLACER_EXECUTION_LOG_LIST,
  GET_SYNC_CHECKER_EXECUTION_LOG_LIST,
  GET_SYNC_CHECKER_ERROR_LOG_LIST,
  SET_WHATSAPP_LOG,
} from "./actionTypes"

import {
  getLogsTransactionUpdateListSuccess,
  getLogsTransactionUpdateListFail,
  getUserLogsByIdSuccess,
  getUserLogsByIdFail,
  getUserLogListSuccess,
  getUserLogListFail,
  getEmailLogListSuccess,
  getEmailLogListFail,
  getErrorLogListSuccess,
  getErrorLogListFail,
  updateTransactionLogsSuccess,
  updateTransactionLogsFail,
  getParserErrorLogListSuccess,
  getParserErrorLogListFail,
  getParserExecutionLogListSuccess,
  getParserExecutionLogListFail,
  getPlacerErrorLogListSuccess,
  getPlacerErrorLogListFail,
  getPlacerExecutionLogListSuccess,
  getPlacerExecutionLogListFail,
  setWhatAppLogSuccess,
  getSyncCheckerExecutionLogListSuccess,
  getSyncCheckerExecutionLogListFail,
  getSyncCheckerErrorLogListSuccess,
  getSyncCheckerErrorLogListFail,
  setWhatAppLogFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getLogsTransactionUpdateList,
  getUserLogsById,
  getUserLogList,
  getEmailLogList,
  getErrorLogList,
  updateTransactionLogs,
  getParserErrorLogList,
  getParserExecutionLogList,
  getPlacerErrorLogList,
  getPlacerExecutionLogList,
  getSyncCheckerExecutionLogList,
  getSyncCheckerErrorLogList,
  setWhatsAppLog,
} from "helpers/fakebackend_helper"

function* _getTransactionUpdateLogsList() {
  // try {

  //   const response = yield call(getLogsTransactionUpdateList)

  //   if (response) {
  //     yield put(getLogsTransactionUpdateListSuccess(response))
  //   }
  // }
  // catch (error) {
  //   yield put(getLogsTransactionUpdateListFail(error))
  // }
  try {
    const response = yield call(getLogsTransactionUpdateList)

    if (response) {
      if (response?.isSuccess)
        yield put(getLogsTransactionUpdateListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getLogsTransactionUpdateListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getLogsTransactionUpdateListFail(response.message))
      }
    } else
      yield put(
        getLogsTransactionUpdateListFail({ message: "Invalid Response." })
      )
  } catch (error) {
    yield put(getLogsTransactionUpdateListFail(error?.message))
  }
}

function* _updateTransactionLog({ payload: data }) {
  // try {
  //   const response = yield call(updateTransactionLogs, data)

  //   if (response) {
  //     yield put(updateTransactionLogsSuccess(response))
  //   }
  // } catch (error) {
  //   yield put(updateTransactionLogsFail(error))
  // }
  try {
    const response = yield call(updateTransactionLogs, data)

    if (response) {
      if (response?.isSuccess)
        yield put(updateTransactionLogsSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              updateTransactionLogsFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(updateTransactionLogsFail(response.message))
      }
    } else
      yield put(updateTransactionLogsFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(updateTransactionLogsFail(error?.message))
  }
}

function* _getUserLogsById({ payload: user }) {
  // try {
  //   const response = yield call(getUserLogsById, user)
  //   if (response) {
  //     yield put(getUserLogsByIdSuccess(response))
  //   }
  //   else {
  //     yield put(getUserLogsByIdFail(response))
  //   }
  // }
  // catch (error) {
  //   yield put(getUserLogsByIdFail(error))
  // }
  try {
    const response = yield call(getUserLogsById, user)

    if (response) {
      if (response?.isSuccess) yield put(getUserLogsByIdSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getUserLogsByIdFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getUserLogsByIdFail(response.message))
      }
    } else
      yield put(getUserLogsByIdFail({ message: user + " Invalid Response." }))
  } catch (error) {
    yield put(getUserLogsByIdFail(error?.message))
  }
}

function* _getUserLogList({ payload: user }) {
  // try {
  //   const response = yield call(getUserLogList, user)
  //   if (response) {
  //     yield put(getUserLogListSuccess(response))
  //   } else {
  //     yield put(getUserLogListFail(response))
  //   }
  // } catch (error) {
  //   yield put(getUserLogListFail(error))
  // }
  try {
    const response = yield call(getUserLogList, user)

    if (response) {
      if (response?.isSuccess) yield put(getUserLogListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getUserLogListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getUserLogListFail(response.message))
      }
    } else
      yield put(getUserLogListFail({ message: user + " Invalid Response." }))
  } catch (error) {
    yield put(getUserLogListFail(error?.message))
  }
}

function* _getEmailLogList({ payload: user }) {
  // try {
  //   const response = yield call(getEmailLogList, user)
  //   if (response) {
  //     yield put(getEmailLogListSuccess(response))
  //   } else {
  //     yield put(getEmailLogListFail(response))
  //   }
  // } catch (error) {
  //   yield put(getEmailLogListFail(error))
  // }
  try {
    const response = yield call(getEmailLogList, user)

    if (response) {
      if (response?.isSuccess) yield put(getEmailLogListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getEmailLogListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getEmailLogListFail(response.message))
      }
    } else
      yield put(getUserLogsByIdFail({ message: user + "Invalid Response." }))
  } catch (error) {
    yield put(getEmailLogListFail(error?.message))
  }
}

function* _getErrorLogList({ payload: user }) {
  // try {
  //   const response = yield call(getErrorLogList, user)
  //   if (response) {
  //     yield put(getErrorLogListSuccess(response))
  //   } else {
  //     yield put(getErrorLogListFail(response))
  //   }
  // } catch (error) {
  //   yield put(getErrorLogListFail(error))
  // }
  try {
    const response = yield call(getErrorLogList, user)

    if (response) {
      if (response?.isSuccess) yield put(getErrorLogListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getErrorLogListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getErrorLogListFail(response.message))
      }
    } else yield put(getErrorLogListFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getErrorLogListFail(error?.message))
  }
}

function* _getParserErrorLogList({ payload: logs }) {
  // try {
  //   const response = yield call(getParserErrorLogList, logs)
  //   if (response) {
  //     yield put(getParserErrorLogListSuccess(response))
  //   } else {
  //     yield put(getParserErrorLogListFail(response))
  //   }
  // } catch (error) {
  //   yield put(getParserErrorLogListFail(error))
  // }
  try {
    const response = yield call(getParserErrorLogList, logs)

    if (response) {
      if (response?.isSuccess)
        yield put(getParserErrorLogListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getParserErrorLogListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getParserErrorLogListFail(response.message))
      }
    } else
      yield put(getParserErrorLogListFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getParserErrorLogListFail(error?.message))
  }
}

function* _getParserExecutionLogList({ payload: logs }) {
  // try {
  //   const response = yield call(getParserExecutionLogList, logs)
  //   if (response) {
  //     yield put(getParserExecutionLogListSuccess(response))
  //   } else {
  //     yield put(getParserExecutionLogListFail(response))
  //   }
  // } catch (error) {
  //   yield put(getParserExecutionLogListFail(error))
  // }
  try {
    const response = yield call(getParserExecutionLogList, logs)

    if (response) {
      if (response?.isSuccess)
        yield put(getParserExecutionLogListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getParserExecutionLogListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getParserExecutionLogListFail(response.message))
      }
    } else
      yield put(getParserExecutionLogListFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getParserExecutionLogListFail(error?.message))
  }
}

function* _getPlacerErrorLogList({ payload: logs }) {
  // try {
  //   const response = yield call(getPlacerErrorLogList, logs)
  //   if (response) {
  //     yield put(getPlacerErrorLogListSuccess(response))
  //   } else {
  //     yield put(getPlacerErrorLogListFail(response))
  //   }
  // } catch (error) {
  //   yield put(getPlacerErrorLogListFail(error))
  // }
  try {
    const response = yield call(getPlacerErrorLogList, logs)

    if (response) {
      if (response?.isSuccess)
        yield put(getPlacerErrorLogListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPlacerErrorLogListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getPlacerErrorLogListFail(response.message))
      }
    } else
      yield put(getPlacerErrorLogListFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getPlacerErrorLogListFail(error?.message))
  }
}

function* _getPlacerExecutionLogList({ payload: logs }) {
  // try {
  //   const response = yield call(getPlacerExecutionLogList, logs)
  //   if (response) {
  //     yield put(getPlacerExecutionLogListSuccess(response))
  //   } else {
  //     yield put(getPlacerExecutionLogListFail(response))
  //   }
  // } catch (error) {
  //   yield put(getPlacerExecutionLogListFail(error))
  // }
  try {
    const response = yield call(getPlacerExecutionLogList, logs)

    if (response) {
      if (response?.isSuccess)
        yield put(getPlacerExecutionLogListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getPlacerExecutionLogListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getPlacerExecutionLogListFail(response.message))
      }
    } else
      yield put(getPlacerExecutionLogListFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getPlacerExecutionLogListFail(error?.message))
  }
}

function* _setWhatsAppLog({ payload: logs }) {
  // try {
  //   const response = yield call(setWhatsAppLog, logs)
  //   if (response) {
  //     yield put(setWhatAppLogSuccess(response))
  //   } else {
  //     yield put(setWhatAppLogFail(response))
  //   }
  // } catch (error) {
  //   yield put(setWhatAppLogFail(error))
  // }
  try {
    const response = yield call(setWhatsAppLog, logs)

    if (response) {
      if (response?.isSuccess) yield put(setWhatAppLogSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setWhatAppLogFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setWhatAppLogFail(response.message))
      }
    } else yield put(setWhatAppLogFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(setWhatAppLogFail(error?.message))
  }
}

function* _getSyncCheckerExecutionLogList({ payload: logs }) {
  // try {
  //   const response = yield call(getSyncCheckerExecutionLogList, logs)
  //   if (response) {
  //     yield put(getSyncCheckerExecutionLogListSuccess(response))
  //   } else {
  //     yield put(getSyncCheckerExecutionLogListFail(response))
  //   }
  // } catch (error) {
  //   yield put(getSyncCheckerExecutionLogListFail(error))
  // }
  try {
    const response = yield call(getSyncCheckerExecutionLogList, logs)

    if (response) {
      if (response?.isSuccess)
        yield put(getSyncCheckerExecutionLogListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getSyncCheckerExecutionLogListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getSyncCheckerExecutionLogListFail(response.message))
      }
    } else
      yield put(
        getSyncCheckerExecutionLogListFail({ message: "Invalid Response." })
      )
  } catch (error) {
    yield put(getSyncCheckerExecutionLogListFail(error?.message))
  }
}

function* _getSyncCheckerErrorLogList({ payload: logs }) {
  // try {
  //   const response = yield call(getSyncCheckerErrorLogList, logs)
  //   if (response) {
  //     yield put(getSyncCheckerErrorLogListSuccess(response))
  //   } else {
  //     yield put(getSyncCheckerErrorLogListFail(response))
  //   }
  // } catch (error) {
  //   yield put(getSyncCheckerErrorLogListFail(error))
  // }
  try {
    const response = yield call(getSyncCheckerErrorLogList, logs)

    if (response) {
      if (response?.isSuccess)
        yield put(getSyncCheckerErrorLogListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getSyncCheckerErrorLogListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getSyncCheckerErrorLogListFail(response.message))
      }
    } else
      yield put(
        getSyncCheckerErrorLogListFail({ message: "Invalid Response." })
      )
  } catch (error) {
    yield put(getSyncCheckerErrorLogListFail(error?.message))
  }
}

function* logsSaga() {
  yield takeEvery(
    GET_TRANSACTION_UPDATE_LOGS_LIST,
    _getTransactionUpdateLogsList
  )
  yield takeEvery(GET_USER_LOGS, _getUserLogsById)
  yield takeEvery(GET_USER_LOGS_LIST, _getUserLogList)
  yield takeEvery(GET_EMAIL_LOGS_LIST, _getEmailLogList)
  yield takeEvery(GET_ERROR_LOGS_LIST, _getErrorLogList)
  yield takeEvery(UPDATE_TRANSACTION_LOG, _updateTransactionLog)
  yield takeEvery(GET_PARSER_ERROR_LOG_LIST, _getParserErrorLogList)
  yield takeEvery(GET_PARSER_EXECUTION_LOG_LIST, _getParserExecutionLogList)
  yield takeEvery(GET_PLACER_ERROR_LOG_LIST, _getPlacerErrorLogList)
  yield takeEvery(GET_PLACER_EXECUTION_LOG_LIST, _getPlacerExecutionLogList)
  yield takeEvery(
    GET_SYNC_CHECKER_EXECUTION_LOG_LIST,
    _getSyncCheckerExecutionLogList
  )
  yield takeEvery(GET_SYNC_CHECKER_ERROR_LOG_LIST, _getSyncCheckerErrorLogList)
  yield takeEvery(SET_WHATSAPP_LOG, _setWhatsAppLog)
}

export default logsSaga
