import React,{useState,useEffect} from "react"
import {
    Col,
    FormGroup,
    Input,
    Row,
    Button,
    Form
} from "reactstrap"

const Services = (props) => {

    const {
        index,
        service,
        handleServiceChange,
        handleServiceDelete,
        validServiceList,
        setValidServiceList,
    } = props

    let inputService = "Service" + index

    function handleChange(changes) {
        handleServiceChange(service.id, { ...service, ...changes })
    }


    const [isServiceValid, setIsServiceValid] = useState()

  function handleService(value) {
    var pattern = /^[A-Za-z\s]+$/

    if (pattern.test(value)) {
        setIsServiceValid(true)
      if(!validServiceList.includes(inputService)){
        validServiceList.push(inputService)
      }
    } else {
        setIsServiceValid(false)
      if(validServiceList.includes(inputService)){
        let tempArray =  validServiceList.filter(item => !item.includes(inputService))
        setValidServiceList(tempArray)
       }
    }

    handleChange({ Service: value })
  }



    return (
        <>
          
                <Row >
                    <Col lg="8">
                        <FormGroup>

                            <input
                                value={service.Service}
                                name={inputService}
                                onChange={e => handleService(e.target.value)}
                                onBlur={e => handleService(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder="Enter a product or service "
                               
                            />

                            {isServiceValid === false && (
                                <small className="color-danger" style={{ float: "left" }}>
                                Invalid Service
                              </small>
                             )}
                      </FormGroup>

                       
                    </Col>


                    <Col lg="2">
                        <FormGroup>

                        {index > 0 &&
                            <Button
                                onClick={(e) => handleServiceDelete(service.id)}
                                color="danger"
                                className="btn-block inner"
                                style={{ width: "100%" }}
                            >
                                 <i className="mdi mdi-close-thick"/>
                            </Button>
}
                        </FormGroup>
                    </Col>
                </Row>
   
        </>
    )
}


export default Services
