import {
    SET_PAYMNET_GATEWAY,
    SET_PAYMNET_GATEWAY_SUCCESS,
    SET_PAYMNET_GATEWAY_FAIL,
    GET_PAYMENT_GATEWAY_LIST,
    GET_PAYMENT_GATEWAY_LIST_SUCCESS,
    GET_PAYMENT_GATEWAY_LIST_FAIL,
    UPDATE_PAYMENT_GATEWAY,
    UPDATE_PAYMENT_GATEWAY_SUCCESS,
    UPDATE_PAYMENT_GATEWAY_FAIL,
    UPDATE_STATUS_CHANGE,
    UPDATE_STATUS_CHANGE_SUCCESS,
    UPDATE_STATUS_CHANGE_FAIL,
    GET_PAYMENT_GATEWAY_DETAIL,
    GET_PAYMENT_GATEWAY_DETAIL_SUCCESS,
    GET_PAYMENT_GATEWAY_DETAIL_FAIL,
    RESTART_STATE
} from "./actionTypes"

const INIT_STATE = {
    loading: false,

}



const paymentGateway = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_PAYMNET_GATEWAY:
            state = {
                ...state,
                paymentGateway: action.payload,
                newPaymentGateway: undefined,
                loading: true
            }
            break
        case SET_PAYMNET_GATEWAY_SUCCESS:
            state = {
                ...state,
                paymentGateway: action.payload,
                newPaymentGateway: true,
                loading: false
            }
            break
        case SET_PAYMNET_GATEWAY_FAIL:
            state = {
                ...state,
                paymentGateway: action.payload,
                newPaymentGateway: false,
                loading: false
            }
            break
        case GET_PAYMENT_GATEWAY_LIST:
            state = {
                ...state,
                paymentGateways: action.payload,
                loading: true
            }
        case GET_PAYMENT_GATEWAY_LIST_SUCCESS:
            state = {
                ...state,
                paymentGateways: action.payload,
                loading: false
            }
        case GET_PAYMENT_GATEWAY_LIST_FAIL:
            state = {
                ...state,
                paymentGateways: action.payload,
                loading: false
            }
        case UPDATE_PAYMENT_GATEWAY:
            state = {
                ...state,
                paymentGateway: action.payload,
                editPaygate: undefined,
                loading: true
            }
            break
        case UPDATE_PAYMENT_GATEWAY_SUCCESS:
            state = {
                ...state,
                paymentGateway: action.payload,
                editPaygate: true,
                loading: false
            }
            break
        case UPDATE_PAYMENT_GATEWAY_FAIL:
            state = {
                ...state,
                paymentGateway: action.payload,
                editPaygate: false,
                loading: false
            }
            break

        case UPDATE_STATUS_CHANGE:
            state = {
                ...state,
                statusPaygate: undefined,
                loading: true
            }
            break;
        case UPDATE_STATUS_CHANGE_SUCCESS:
            state = {
                ...state,
                statusPaygate: true,
                loading: false
            }
            break;
        case UPDATE_STATUS_CHANGE_FAIL:
            state = {
                ...state,
                statusPaygate: false,
                loading: false
            }
            break;
        case GET_PAYMENT_GATEWAY_DETAIL:
            state = {
                ...state,
                paymentGateway: action.payload,
                loading: true
            }
            break;
        case GET_PAYMENT_GATEWAY_DETAIL_SUCCESS:
            state = {
                ...state,
                paymentGateway: action.payload,
                loading: false
            }
            break;
        case GET_PAYMENT_GATEWAY_DETAIL_FAIL:
            state = {
                ...state,
                paymentGateway: action.payload,
                loading: false
            }
            break;
        case RESTART_STATE:
            state = {
                ...state,
                newPaymentGateway: undefined,
                editPaygate: undefined,
                statusPaygate: undefined,
            }
            break
        default:
            return state
    }
    return state
}

export default paymentGateway
