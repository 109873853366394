import React, { useState, useEffect } from "react"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

import { Document, pdfjs, Page } from "react-pdf"
const userGuide ="https://res.cloudinary.com/developerqa/image/upload/v1660742549/UserGuide/PayAllLink_User_Guide_Admin_05JUL22_ppruec.pdf"

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
}

const TravelAgency_Admin = () => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)


  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

 
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }


  return (
    <>
    <div style={{width:"100%"}}>

   
      <Document
        file={userGuide}
        onLoadError={console.error}
        // options={options}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page scale={2.5} key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>

      <p>
        Page {pageNumber} of {numPages}
      </p>

      </div>
    </>
  )
}

export default TravelAgency_Admin
