import React, { useState, useEffect } from 'react'
import { Input, InputGroup, InputGroupAddon, Label, Button, Row, Col, FormGroup } from "reactstrap"
import Select from "react-select"
import { useDispatch } from "react-redux"
import { mainSearchText, mainSearchDropdown } from "store/actions"

function SearchBar(props) {
    const [searchValue, setSearchValue] = useState("");
    const [prefix, setPrefix] = useState("TRXN")
    const dispatch = useDispatch();
    const [placeHolder, setPlaceHolder] = useState("Search...")

    const [selectedGroup, setselectedGroup] = useState(null)

    const optionGroup = [

        { label: "TRXN", value: "TRXN" },
        { label: "USER", value: "USER" },
        { label: "CUST", value: "CUST" },

    ]

    useEffect(() => {
        if (searchValue !== undefined && searchValue !== NaN && searchValue !== "") {
            handleSearch()
        }
    }, [searchValue])

    useEffect(() => {
        if (prefix !== undefined && prefix !== NaN && prefix !== "") {
            handleFilter()
            setPlaceHolder(prefix + ":")
        }
    }, [prefix])


    const handleSearch = () => {
        if (searchValue !== undefined) {
            dispatch(mainSearchText(searchValue))
        }

    }

    const handleFilter = () => {

        if (prefix !== undefined) {
            setSearchValue("")
            dispatch(mainSearchDropdown(prefix))
        }
    }

    const storeDataOnForm = (event) => {
        setSearchValue(event.target.value)
    };

    const storeData = (event) => {
        setPrefix(event.target.value)
    };

    function handleSelectGroup(value) {
        setSearchValue(value)
        // storeData(event)
    }

    return (
        <Row className="main-search">
            <Col lg="12">
                <InputGroup className="mb-3">
                    <Button
                        color="success"
                        type="submit"
                        className="main-search-button-radius form-control-lg main-search"
                        onClick={() => handleSearch()}
                        onKeyDown={handleSearch()}>
                        <Label className=" color-active ">
                            <i className="fas fa-search color-white" />
                        </Label>
                    </Button>
                    {/* {prefix !== undefined ? <Label className="form-control-lg main-search border-opacity color-disable">{placeHolder}</Label> : null} */}
                    <select className="form-control-lg main-search bg-color-mainsearch main-search-prefix main-search-prefix-border-radius border-none"
                        id="prefix"
                        name="prefix"
                        value={prefix}
                        onChange={(event) => storeData(event)}
                    >
                        <option value="TRXN">Transactions</option>
                        <option value="USER">Users</option>
                        <option value="CUST">Customers</option>
                    </select>
                    <Input
                        className="form-control-lg main-search bg-color-mainsearch border-left-only"
                        placeholder="Search..."
                        type="search"
                        name="searchValue"
                        id="searchValue"
                        value={searchValue}
                        onChange={(event) => storeDataOnForm(event)}
                    />

                    {/* <FormGroup style={{width:"100%"}} className=" select2-container">
                          <Select
                            placeholder={placeHolder}
                            type="search"
                            name="searchValue"
                            id="searchValue"
                            value={searchValue}
                            // onChange={() => storeDataOnForm()}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            classNamePrefix="select2-selection "
                          />
                        </FormGroup> */}
                </InputGroup>

            </Col>
        </Row>
    )
}

export default SearchBar
