import {

    MAIN_SEARCH_TEXT,
    MAIN_SEARCH_TEXT_SUCCESS,
    MAIN_SEARCH_TEXT_FAIL,
    MAIN_SEARCH_FILTER_DATE_RANGE,
    MAIN_SEARCH_FILTER_DATE_RANGE_SUCCESS,
    MAIN_SEARCH_FILTER_DATE_RANGE_FAIL,
    MAIN_SEARCH_FILTER_DROPDOWN,
    MAIN_SEARCH_FILTER_DROPDOWN_SUCCESS,
    MAIN_SEARCH_FILTER_DROPDOWN_FAIL,
    RESET_FILTER_VALUES,
    RESET_FILTER_VALUES_SUCCESS,
    RESET_FILTER_VALUES_FAIL,
    MAIN_SEARCH_LIST_RESET,
    MAIN_SEARCH_LIST_RESET_SUCCESS,
    MAIN_SEARCH_LIST_RESET_FAIL,
    RESET_VALUES
} from "./actionTypes"

const INIT_STATE = {
    dates: undefined,
    filter: undefined,
}



const mainSearch = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAIN_SEARCH_TEXT:
            return state = {
                ...state,
                search: action.payload,
                loading: true
            }
        case MAIN_SEARCH_TEXT_SUCCESS:
            return state = {
                ...state,
                search: action.payload,
                loading: false
            }
        case MAIN_SEARCH_TEXT_FAIL:
            return state = {
                ...state,
                error: action.payload,
                loading: false
            }
        case MAIN_SEARCH_FILTER_DATE_RANGE:
            return state = {
                ...state,
                dates: action.payload,
                loading: true
            }
        case MAIN_SEARCH_FILTER_DATE_RANGE_SUCCESS:
            return state = {
                ...state,
                dates: action.payload,
                loading: false
            }
        case MAIN_SEARCH_FILTER_DATE_RANGE_FAIL:
            return state = {
                ...state,
                dates: action.payload,
                loading: false
            }
        case MAIN_SEARCH_FILTER_DROPDOWN:
            return state = {
                ...state,
                filter: action.payload,
                loading: true
            }
        case MAIN_SEARCH_FILTER_DROPDOWN_SUCCESS:
            return state = {
                ...state,
                filter: action.payload,
                loading: false
            }
        case MAIN_SEARCH_FILTER_DROPDOWN_FAIL:
            return state = {
                ...state,
                filter: action.payload,
                loading: false
            }
        case RESET_FILTER_VALUES:
            return state = {
                ...state,
                loading: true
            }
        case RESET_FILTER_VALUES_SUCCESS:
            return state = {
                ...state,
                dates: undefined,
                filter: undefined,
                error: action.payload,
                loading: false
            }
        case RESET_FILTER_VALUES_FAIL:
            return state = {
                ...state,
                error: action.payload,
                loading: false
            }
        case MAIN_SEARCH_LIST_RESET:
            return state = {
                ...state,
                list: undefined,
                loading: true
            }
        case MAIN_SEARCH_LIST_RESET_SUCCESS:
            return state = {
                ...state,
                list: action.payload,
                loading: false
            }
        case MAIN_SEARCH_LIST_RESET_FAIL:
            return state = {
                ...state,
                list: action.payload,
                loading: false
            }
        case RESET_VALUES:
            return state={
                ...state,
                list: undefined
            }
        default:
            return state
    }
}

export default mainSearch
