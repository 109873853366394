import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import {
  Table,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import PaymentResultDetails from "./PaymentResultDetails"
import Banner from "./Banner"
import Header from "./Header"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import errorCatching from "../../helpers/commonhelpers/errorCatching"
import { getPaymentResponseDetail, getMerchantDetail } from "store/actions"
import { isEmpty } from "lodash"

const PaymentResponse = props => {
  const {
    paymentResponseDetail,
    loading,
    match: { params },
    onGetPaymentResponseDetail,
    errorResponse,
  } = props


  const [isLoaded, setIsLoaded] = useState(loading)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [paymentResponse, setPaymentResponse] = useState()

  useEffect(() => {
    if (params && params.trackId) {
      onGetPaymentResponseDetail(params.trackId)
    }
  }, [params, onGetPaymentResponseDetail])

  useEffect(() => {
    if (paymentResponseDetail) {
      setPaymentResponse(paymentResponseDetail)
    }
  }, [paymentResponseDetail])

  useEffect(() => {
    setIsLoaded(loading)
  }, [loading])

  useEffect(() => {
    
    setErrorMessage(errorResponse)
  }, [errorResponse])

  return (
    <>
      <React.Fragment>
        <Container>
          {isLoaded ? (
            <LoadingSpinnerThreeDots />
          ) : (
           <>
              {!isEmpty(paymentResponse) && (
                <>
                  <Row className="justify-content-center container-alignment">
                    <Col md={12}>
                      <Header paymentStatus={paymentResponse?.paymentStatus} isSuccessful={paymentResponse?.isSuccessful}/>
                      <PaymentResultDetails response={paymentResponse} />
                      <Banner banner={paymentResponse?.banner} />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}

       {errorCatching(errorMessage,setErrorMessage)}

        </Container>
      </React.Fragment>
    </>
  )
}

PaymentResponse.propTypes = {
  paymentResponseDetail: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  onGetPaymentResponseDetail: PropTypes.func,
  errorResponse: PropTypes.object,
}

const mapStateToProps = pr => (
  {
    paymentResponseDetail: pr.paymentResponses?.paymentResponseDetail,
    loading: pr.paymentResponses?.loading,
    errorResponse: pr.paymentResponses?.error,
  }
)

const mapDispatchToProps = dispatch => ({
  onGetPaymentResponseDetail: trackId =>
    dispatch(getPaymentResponseDetail(trackId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentResponse))
