import React from 'react'
import {  Container  } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import UserLogTable from "./UserLogsTable"
function index() {
    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>

                <Breadcrumbs title="Logs " breadcrumbItem=" USER ACTIVITIES" />
                <UserLogTable />
            </Container>
        </div>
    </React.Fragment>
    )
}

export default index
