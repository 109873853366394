import React from "react"
import {
  Badge,
  Row,
  Col
} from "reactstrap"
import dateFormat from '../../helpers/commonhelpers/dateFormat'

const SyncCheckerErrorLogsColumns = () => [
  {
    dataField: "date",
    text: "Date",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <span>
          {dateFormat(cellContent)}
        </span>
      )
    }
  },
  {
    dataField: "message",
    text: "Failed",
    searchable: true,
    sort: true,
  },
  {
    dataField: "stackTrace",
    text: "Success",
    searchable: true,
    sort: true,
  },

  {
    dataField: "file",
    text: "File",
    searchable: true,
    sort: true,
    
    

  },



]
export default SyncCheckerErrorLogsColumns