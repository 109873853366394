import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_MERCHANT_DETAIL,
  GET_MERCHANT_LIST,
  GET_MERCHANT_LIST_REPORT,
  GET_MERCHANT_IDS,
  SET_MERCHANT,
  GET_MERCHANT_DETAIL_EXTERNAL,
  REGISTER_MERCHANT,
  GET_MERCHANT_BY_OID,
} from "./actionTypes"

import {
  getMerchantDetailSuccess,
  getMerchantDetailFail,
  getMerchantListSuccess,
  getMerchantListFail,
  getMerchantListReportSuccess,
  getMerchantListReportFail,
  getAllMerchantIdsSuccess,
  getAllMerchantIdsFail,
  setMerchantSuccess,
  setMerchantFail,
  registerMerchantSuccess,
  registerMerchantFail,
  returnStateMerchant,
  getMerchantDetialExternalSuccess,
  getMerchantDetialExternalFail,
  getMerchantByOidSuccess,
  getMerchantByOidFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMerchantDetail,
  getMerchantList,
  getMerchantListReport,
  getMerchantIds,
  setMerchant,
  registerMerchant,
  getMerchantDetialExternal,
  getMerchantByOid,
} from "helpers/fakebackend_helper"

function* fetchMerchantDetails({ merchantId }) {
  try {
    const response = yield call(getMerchantDetail, merchantId)

    if (response) {
      if (response?.isSuccess)
        yield put(getMerchantDetailSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getMerchantDetailFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getMerchantDetailFail(response.message))
      }
    } else yield put(getMerchantDetailFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getMerchantDetailFail(error?.message))
  }
}

function* _getMerchantList() {
  try {
    const response = yield call(getMerchantList)

    if (response) {
      if (response?.isSuccess) yield put(getMerchantListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getMerchantListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getMerchantListFail(response.message))
      }
    } else yield put(getMerchantListFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getMerchantListFail(error?.message))
  }
}

function* fetchMerchantIds() {
  try {
    const response = yield call(getMerchantIds)

    if (response) {
      if (response?.isSuccess)
        yield put(getAllMerchantIdsSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getAllMerchantIdsFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getAllMerchantIdsFail(response.message))
      }
    } else yield put(getAllMerchantIdsFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getAllMerchantIdsFail(error?.message))
  }
}

function* _setMerchant({ payload: merchant }) {
  try {
    const response = yield call(setMerchant, merchant)

    if (response) {
      if (response?.isSuccess) yield put(setMerchantSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setMerchantFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setMerchantFail(response.message))
      }
    } else yield put(setMerchantFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(setMerchantFail(error?.message))
  }
}

function* _registerMerchant({ payload: merchant }) {
  try {
    const response = yield call(registerMerchant, merchant)

    if (response) {
      if (response?.isSuccess) yield put(registerMerchantSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              registerMerchantFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(registerMerchantFail(response.message))
      }
    } else yield put(registerMerchantFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(registerMerchantFail(error?.message))
  }
}

function* _getMerchantListReport() {
  try {
    const response = yield call(getMerchantListReport)

    if (response) {
      if (response?.isSuccess)
        yield put(getMerchantListReportSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getMerchantListReportFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getMerchantListReportFail(response.message))
      }
    } else
      yield put(getMerchantListReportFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getMerchantListReportFail(error?.message))
  }
}

function* _getMerchantDetailExternal({ payload: merchantDetail }) {
  try {
    const response = yield call(getMerchantDetialExternal, merchantDetail)

    if (response) {
      if (response?.isSuccess)
        yield put(getMerchantDetialExternalSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getMerchantDetialExternalFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getMerchantDetialExternalFail(response.message))
      }
    } else
      yield put(getMerchantDetialExternalFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getMerchantDetialExternalFail(error?.message))
  }
}

function* _getMerchantByOid({ payload: oid }) {
  try {
    const response = yield call(getMerchantByOid, oid)

    if (response) {
      if (response?.isSuccess) yield put(getMerchantByOidSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getMerchantByOidFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getMerchantByOidFail(response.message))
      }
    } else yield put(getMerchantByOidFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getMerchantByOidFail(error?.message))
  }
}

function* paymentResponsesSaga() {
  yield takeEvery(GET_MERCHANT_DETAIL, fetchMerchantDetails)
  yield takeEvery(GET_MERCHANT_DETAIL_EXTERNAL, _getMerchantDetailExternal)
  yield takeEvery(GET_MERCHANT_LIST, _getMerchantList)
  yield takeEvery(GET_MERCHANT_IDS, fetchMerchantIds)
  yield takeEvery(SET_MERCHANT, _setMerchant)
  yield takeEvery(REGISTER_MERCHANT, _registerMerchant)
  yield takeEvery(GET_MERCHANT_LIST_REPORT, _getMerchantListReport)
  yield takeEvery(GET_MERCHANT_BY_OID, _getMerchantByOid)
}

export default paymentResponsesSaga
