import React, { useContext, useState, useEffect } from "react"
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { PaymentMethodContext } from "../../index"

const GooglePayEmbedded = props => {

    const{dbsyGooglePayEmbedded, paymentRequestDetails} = props

    const { onSetDibsyInitiatePayment } = useContext(
        PaymentMethodContext
      )

  let paymentsClient = null

  const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
  }

  const allowedCardNetworks = [
    "MASTERCARD",
    "VISA",
  ]

  const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"]

  const tokenizationSpecification = {
    type: "PAYMENT_GATEWAY",
    parameters: {
      gateway: dbsyGooglePayEmbedded.gateway,
      gatewayMerchantId: dbsyGooglePayEmbedded.gatewayMerchantId,
    },
  }

  const baseCardPaymentMethod = {
    type: "CARD",
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: allowedCardNetworks,
    },
  }

  useEffect(() => {
    const googlePayScript = document.createElement("script")
    googlePayScript.src = dbsyGooglePayEmbedded.payJsUrl
    googlePayScript.async = true

    googlePayScript.onload = function handleScriptLoaded() {
      onGooglePayLoaded()

      const cardPaymentMethod = Object.assign({}, baseCardPaymentMethod, {
        tokenizationSpecification: tokenizationSpecification,
      })

      function getGoogleIsReadyToPayRequest() {
        return Object.assign({}, baseRequest, {
          allowedPaymentMethods: [baseCardPaymentMethod],
        })
      }

      function getGooglePaymentDataRequest() {
        const paymentDataRequest = Object.assign({}, baseRequest)
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod]
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo()
        paymentDataRequest.merchantInfo = {
          // @todo a merchant ID is available for a production environment after approval by Google
          // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
          // merchantId: '01234567890123456789',
          merchantName: "Merchant Name",
        }
        return paymentDataRequest
      }

      function getGooglePaymentsClient() {
        if (paymentsClient === null) {
          paymentsClient = new google.payments.api.PaymentsClient({
            environment: "TEST",
          })
        }
        return paymentsClient
      }

      function onGooglePayLoaded() {
        const paymentsClient = getGooglePaymentsClient()
        paymentsClient
          .isReadyToPay(getGoogleIsReadyToPayRequest())
          .then(function (response) {
            if (response.result) {
              addGooglePayButton() // @todo prefetch payment data to improve performance after confirming site functionality // prefetchGooglePaymentData();
            }
          })
          .catch(function (err) {
            // show error in developer console for debugging
            console.error(err)
          })
      }

      function addGooglePayButton() {
        const paymentsClient = getGooglePaymentsClient()
        const button = paymentsClient.createButton({
          onClick: onGooglePaymentButtonClicked,
        })
        document.getElementById("container").appendChild(button)
      }

      function getGoogleTransactionInfo() {
        return {
          countryCode: "QA",
          currencyCode: paymentRequestDetails.currency,
          totalPriceStatus: "FINAL", // set to cart total
          totalPrice: paymentRequestDetails.amount.toString(),
        }
      }

      /**
       * Prefetch payment data to improve performance
       *
       
       */
      function prefetchGooglePaymentData() {
        const paymentDataRequest = getGooglePaymentDataRequest() // transactionInfo must be set but does not affect cache
        paymentDataRequest.transactionInfo = {
          totalPriceStatus: "NOT_CURRENTLY_KNOWN",
          currencyCode: paymentRequestDetails.currency,
        }
        const paymentsClient = getGooglePaymentsClient()
        paymentsClient.prefetchPaymentData(paymentDataRequest)
      }

      /**
       * Show Google Pay payment sheet when Google Pay payment button is clicked
       */

      function onGooglePaymentButtonClicked() {
        const paymentDataRequest = getGooglePaymentDataRequest()
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo()
        console.log(paymentDataRequest)
        const paymentsClient = getGooglePaymentsClient()
        paymentsClient
          .loadPaymentData(paymentDataRequest)
          .then(function (paymentData) {
            // handle the response
            processPayment(paymentData)
            console.log(paymentData)
          })
          .catch(function (err) {
            // show error in developer console for debugging
            console.error(err)
          })
      }

      function processPayment(paymentData) {

        var model = {
            GooglePayToken: paymentData.paymentMethodData,
            TrackId: dbsyGooglePayEmbedded.trackId,
            PaymentMethod: "googlepay"
          }

          onSetDibsyInitiatePayment(model)


        // show returned data in developer console for debugging
       // console.log(paymentData) // @todo pass payment token to your gateway to process payment
       // paymentToken = paymentData.paymentMethodData.tokenizationData.token
      }

    }
    googlePayScript.onerror = function handleScriptError() {
      console.log("error loading script")
    }
    document.body.appendChild(googlePayScript)
    return () => {
      document.body.removeChild(googlePayScript)
    }
  }, [])

  return (
  <>
  <div
            style={{
              height: "45px",
              backgroundColor: "black",
              borderRadius: "0.30rem",
              padding: "5px",
              fontSize: "7px",
              fontStyle: "italic",
              verticalAlign: "middle",
              overflow:"hidden"
            }}
            className="btn-block inner mb-2 fw-medium"
          >
           <div id="container" ></div>
          </div>
  
  </>
)}

GooglePayEmbedded.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(GooglePayEmbedded))
