import React, { useEffect, useState } from "react"
import CustomerArchiveListTable from "./CustomerArchiveList"
import SweetAlert from "react-bootstrap-sweetalert"
import { connect } from "react-redux"
import {
  getCustomerListArchived,
  getMerchantList,
  patchCustomerArchive,
} from "store/actions"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
function index(props) {
  const {
    onGetCustomerListArchived,
    loading,
    customerListArchived,
    onGetMerchantList,
    merchantList,
    onPatchArchiveCustomer,
    customerArchiveResult,
  } = props
  const [archiveCustomer, setArchiveCustomer] = useState("")
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  useEffect(() => {
    onGetCustomerListArchived()
    onGetMerchantList()
  }, [])

  useEffect(() => {
    if (archiveCustomer && archiveCustomer !== undefined) {
      let model = {
        Field: "Customer",
        Id: archiveCustomer,
      }
      onPatchArchiveCustomer(model)
    }
  }, [archiveCustomer])
  useEffect(() => {
    //console.log(customerListArchived)
  }, [customerListArchived])

  useEffect(() => {
    // console.log(merchantList)
  }, [merchantList])

  useEffect(() => {
    if (customerArchiveResult === true) {
      setsuccess_dlg(true)
      setdynamic_title("Success")
      setdynamic_description("Customer has been restored.")
    }
    if (customerArchiveResult === false) {
      seterror_dlg(true)
      setdynamic_title("Fail")
      setdynamic_description("Customer is not restored.")
    }
  }, [customerArchiveResult])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Customer Archived" />
          {success_dlg ? (
            <SweetAlert
              timeout={1000}
              success
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                setsuccess_dlg(false)
                window.location.reload()
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {error_dlg ? (
            <SweetAlert
              timeout={1000}
              error
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                seterror_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          <CustomerArchiveListTable
            list={customerListArchived}
            loading={loading}
            merchantList={merchantList}
            onChange={value => setArchiveCustomer(value)}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = state => ({
  customerListArchived: state.CustomerArchived.customerListArchived,
  loading: state.CustomerArchived.loading,
  merchantList: state.Merchants.merchantList,
  customerArchiveResult: state.CustomerArchived.customerArchiveResult,
})

const mapDispatchToProps = dispatch => ({
  onGetCustomerListArchived: () => dispatch(getCustomerListArchived()),
  onGetMerchantList: () => dispatch(getMerchantList()),
  onPatchArchiveCustomer: id => dispatch(patchCustomerArchive(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
