import React from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,

} from "reactstrap"
import Bar from "./stackedbarchart"

import "chartist/dist/scss/chartist.scss"
import "../../assets/scss/chartist.scss"
function TopUserChart() {
    return (
        <React.Fragment>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">Top 10 Users</CardTitle>
                        {/* <Row className="justify-content-center">
                            <Col sm={4}>
                                <div className="text-center">
                                    <h5 className="mb-0 font-size-20">5241</h5>
                                    <p className="text-muted">Activated</p>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="text-center">
                                    <h5 className="mb-0 font-size-20">65411</h5>
                                    <p className="text-muted">Pending</p>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="text-center">
                                    <h5 className="mb-0 font-size-20">51654</h5>
                                    <p className="text-muted">Deactivated</p>
                                </div>
                            </Col>
                        </Row> */}
                        <Bar />
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default TopUserChart
