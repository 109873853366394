import {
  
  GET_PAYMENTRESPONSE_DETAIL,
  GET_PAYMENTRESPONSE_DETAIL_FAIL,
  GET_PAYMENTRESPONSE_DETAIL_SUCCESS,

} from "./actionTypes"


export const getPaymentResponseDetail = trackId => ({
  type: GET_PAYMENTRESPONSE_DETAIL,
  trackId,
})

export const getPaymentResponseDetailSuccess = paymentResponseDetail => ({
  type: GET_PAYMENTRESPONSE_DETAIL_SUCCESS,
  payload: paymentResponseDetail,
})

export const getPaymentResponseDetailFail = error => ({
  type: GET_PAYMENTRESPONSE_DETAIL_FAIL,
  payload: error,
})

