import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from "reactstrap"
import { getItemDetail } from "../../../store/items/actions"
import { connect } from "react-redux"
import { useDispatch } from "react-redux"

function FormFourPageItemList(props) {
  //Constants--------------------------------------------------------------------------
  const dispatch = useDispatch();
  const [ItemsList, setItems] = useState();

  //UseEffects--------------------------------------------------------------------------
  //Use effect for gettting itemId from props 
  useEffect(() => {
    if (props.ItemId) {
      dispatch(getItemDetail(props.ItemId))
    }
  }, [props.ItemId])

  //Use effect for setting items from api
  useEffect(() => {
    if (props.items.items.items) {
      setItems(props.items.items.items)
    }
  }, [props.items.items.items])

  return (
    <>
      {ItemsList === undefined ?
        "N/A" :
        ItemsList.map((x, i) => {
          return (
                  <Row key={i} className="color-text  table borderless ">
                  <Col><small>{x.itemName}</small></Col>
                  <Col><small>{x.qty} qty</small></Col>
                  <Col><small>{parseFloat(x.amount).toFixed(2)} QAR</small></Col>
                </Row>
          )
        })}

    </>
  )
}
const mapStateToProps = state => {
  return {
    items: state.Items
  }
}

export default connect(mapStateToProps)(FormFourPageItemList)