import {
    GET_MERCHANT_PG_ACCOUNT_SETTING_OID,
    GET_MERCHANT_PG_ACCOUNT_SETTING_OID_SUCCESS,
    GET_MERCHANT_PG_ACCOUNT_SETTING_OID_FAIL,
    UPDATE_MERCHANT_PG_ACCOUNT_SETTING,
    UPDATE_MERCHANT_PG_ACCOUNT_SETTING_SUCCESS,
    UPDATE_MERCHANT_PG_ACCOUNT_SETTING_FAIL,
    RETURN_STATE,
  } from "./actionTypes"
  
  
  
  export const updateMerchantPGAccontSettings = data => ({
    type: UPDATE_MERCHANT_PG_ACCOUNT_SETTING,
    payload: data,
  })
  
  export const updateMerchantPGAccontSettingsSuccess = merchantDetail => ({
    type: UPDATE_MERCHANT_PG_ACCOUNT_SETTING_SUCCESS,
    payload: merchantDetail,
  })
  
  export const updateMerchantPGAccontSettingsFail = error => ({
    type: UPDATE_MERCHANT_PG_ACCOUNT_SETTING_FAIL,
    payload: error,
  })
  
  export const returnStateMerchant = merchant => ({
    type: RETURN_STATE,
    payload: merchant,
  })
  
  
  export const getMerchantPGAccountSettingByOid = oid => ({
    type: GET_MERCHANT_PG_ACCOUNT_SETTING_OID,
    payload: oid,
  })
  
  export const getMerchantPGAccountSettingByOidSuccess = oid => ({
    type: GET_MERCHANT_PG_ACCOUNT_SETTING_OID_SUCCESS,
    payload: oid,
  })
  
  export const getMerchantPGAccountSettingByOidFail = error => ({
    type: GET_MERCHANT_PG_ACCOUNT_SETTING_OID_FAIL,
    payload: error,
  })
  