import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  Col,
  Card,
  CardBody,
  Row,
  Container,
  Button,
  Modal,
  ModalBody,
  Label,
} from "reactstrap"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"
import errorCatching from "../../helpers/commonhelpers/errorCatching"
import { isEmpty } from "lodash"
import { dateFormatString } from "../../helpers/commonhelpers/dateFormat"
import {
  getUserList,
  deactivateUser,
  deleteUser,
  getAllMerchantIds,
  //getMerchantList,
  getCountryCode,
  patchUserArchive,
} from "store/actions"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import UserColumns from "./UserColumns"
import { useDispatch } from "react-redux"
import AuthUser from "components/AuthUser/AuthUser"
import { IsEmpty } from "react-lodash"
import { ToastContainer } from "react-toastify"
import UserCreatePopUp from "./UserCreatePopUp"
import SweetAlert from "react-bootstrap-sweetalert"
import UserUpdatePopUp from "./UserUpdatePopUp"
import { CSVLink } from "react-csv"

export const index = props => {
  const _ = require("lodash")
  const dispatch = useDispatch()
  const [user, setUser] = useState(AuthUser())
  const [{ modal_large, step, userDetails }, setPaymentRequest] = useState({
    modal_large: false,
    step: 0,
  })
  const [usersList, setUsersList] = useState([])
  const [loading, setLoading] = useState(props.loading)
  const [fileName, setFileName] = useState("")
  // let searchText = ""
  // const pageOptions = {
  //   sizePerPage: 10,
  //   pageStartIndex: 1,
  //   //paginationSize: 1,
  //   totalSize: usersList.length, // replace later with size(customerList),
  //   custom: false,
  //   hidePageListOnlyOnePage: false,
  //   hideSizePerPage: true,
  //   disablePageTitle: true,
  //   hideSizePerPage: true,
  //   withFirstAndLast: false,
  //   alwaysShowAllBtns: false,
  //   onPageChange: (page, sizePerPage) => {
  //     handleTableChange()
  //   },
  // }
  const pageOptions = {
    hidePageListOnlyOnePage: true,
    sizePerPage: 10,
    //totalSize: usersList.length,
    custom: false,
    withFirstAndLast: true,
  }

  const [merchantIds, setMerchantIds] = useState([])
  const { SearchBar } = Search
  const { ExportCSVButton } = CSVExport
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert_activate, setconfirm_alert_activate] = useState(false)
  const [confirm_alert_delete, setconfirm_alert_delete] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [newUser, setNewUser] = useState("")
  const [updateUser, setUpdateUser] = useState("")
  const [merchantList, setMerchantList] = useState([])
  const [userPassowrd, setUserPassowrd] = useState("")
  const [countryCodes, setCountryCodes] = useState([])
  const [usersDownload, setUsersDownload] = useState([])
  const [searchResult, setSearchResult] = useState([])
  const [errorResponse, setErrorResponse] = useState(undefined)
  let form = ""
  let modalHeader = ""
  //Use Effect for getting customer list with Merchant ID
  useEffect(() => {
    dispatch(getUserList())
    if (user.role === "SuperAdmin") {
      dispatch(getAllMerchantIds())
      //dispatch(getMerchantList())
      dispatch(getCountryCode())
    }
  }, [])

  useEffect(() => {
    //console.log(fileName)
  }, [fileName])

  useEffect(() => {
    if (props.merchantIds !== undefined) {
      setMerchantIds(props.merchantIds)
    }
  }, [props.merchantIds])

  useEffect(() => {}, [merchantIds])

  useEffect(() => {
    if (newUser !== "") {
      if (newUser === true) {
        setPaymentRequest(formRequest => ({
          ...formRequest,
          modal_large: false,
        }))
        setsuccess_dlg(true)
        setdynamic_title("Success")
        setdynamic_description("User has been created.")
        dispatch(getUserList())
        setNewUser("")
      } else {
        setPaymentRequest(formRequest => ({
          ...formRequest,
          modal_large: false,
        }))
        seterror_dlg(true)
        setdynamic_title("Fail")
        setdynamic_description("User is not created.")
        setNewUser("")
      }
    }
  }, [newUser, props.loading])

  useEffect(() => {
    // console.log(props.userArchiveResult)
    if (props.userArchiveResult === true) {
      setsuccess_dlg(true)
      setdynamic_title("Success")
      setdynamic_description("User has been archived.")
    }
    if (props.userArchiveResult === false) {
      seterror_dlg(true)
      setdynamic_title("Fail")
      setdynamic_description("User is not archived.")
    }
  }, [props.userArchiveResult])

  useEffect(() => {
    if (props.userCreated !== undefined) {
      if (props.userCreated === true) {
        setNewUser(true)
      } else {
        setNewUser(false)
      }
    }
  }, [props.userCreated])

  //Use Effect for setting customerList with props
  useEffect(() => {
    setLoading(props.loading)
    if (Array.isArray(props.userList)) {
      if (user.role !== "SuperAdmin") {
        setUsersList(
          props.userList.filter(
            users =>
              users.firstName !== "SELLCO USER" &&
              users.merchantId === user.merchantId
          )
        )
      } else {
        setUsersList(props.userList)
      }
    }
  }, [props.userList, props.loading])

  useEffect(() => {
    //console.log(usersList)
  }, [usersList])

  useEffect(() => {}, [userDetails])

  useEffect(() => {
    if (props.userUpdated !== undefined) {
      if (props.userUpdated === true) {
        setsuccess_dlg(true)
        setdynamic_title("Success")
        setdynamic_description("User has been updated.")
        tog_large()
        dispatch(getUserList())
      }
    }
  }, [props.userUpdated])

  useEffect(() => {
    if (step === 1) {
      tog_large()
    }
  }, [step])

  useEffect(() => {
    if (modal_large === false) {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        step: 0,
      }))
    }
  }, [modal_large])

  useEffect(() => {
    if (props.userDeactivate !== undefined) {
      if (props.userDeactivate === true) {
        setsuccess_dlg(true)
        setdynamic_title("Success")
        dispatch(getUserList())
      }
      if (props.userDeactivate === false) {
        seterror_dlg(true)
        setdynamic_title("Fail")
      }
    }
  }, [props.userDeactivate])

  useEffect(() => {
    if (props.userDelete !== undefined) {
      if (props.userDelete === true) {
        setsuccess_dlg(true)
        setdynamic_title("Success")
        setdynamic_description("User has been deleted.")
        dispatch(getUserList())
      }
      if (props.userDelete === false) {
        seterror_dlg(true)
        setdynamic_title("Fail")
        setdynamic_description("User has not been deleted.")
      }
    }
  }, [props.userDelete])

  useEffect(() => {
    if (updateUser !== "") {
      if (updateUser === true) {
        setPaymentRequest(formRequest => ({
          ...formRequest,
          modal_large: false,
        }))
        setsuccess_dlg(true)
        setdynamic_title("Success")
        setdynamic_description("User has been updated.")
        dispatch(getUserList())
        setUpdateUser("")
      }

      if (updateUser === false) {
        setPaymentRequest(formRequest => ({
          ...formRequest,
          modal_large: false,
        }))
        seterror_dlg(true)
        setdynamic_title("Fail")
        setdynamic_description("User is not updated.")
        setUpdateUser("")
      }
    }
  }, [updateUser, props.loading])

  useEffect(() => {
    if (props.userUpdate !== undefined) {
      if (props.userUpdate === true) {
        setUpdateUser(true)
      } else {
        setUpdateUser(false)
      }
    }
  }, [props.userUpdate])

  useEffect(() => {}, [userPassowrd])
  useEffect(() => {
    //console.log(props.countryCodes)
    if (Array.isArray(props.countryCodes) && props.countryCodes?.length !== 0) {
      setCountryCodes(props.countryCodes)
    }
  }, [props.countryCodes])

  useEffect(() => {
    //console.log(countryCodes)
  }, [countryCodes])

  useEffect(() => {}, [usersDownload])
  //ERROR CATCHING
  useEffect(() => {
    if (
      props.errorM !== undefined &&
      !isEmpty(props.errorM) &&
      props.errorM !== {}
    )
      setErrorResponse(props.errorM)
  }, [props.errorM])

  useEffect(() => {
    if (
      props.errorU !== undefined &&
      !isEmpty(props.errorU) &&
      props.errorU !== {}
    )
      setErrorResponse(props.errorU)
    setPaymentRequest(formRequest => ({
      ...formRequest,
      modal_large: false,
    }))
  }, [props.errorU])

  useEffect(() => {}, [errorResponse])
  //////////////////////////////////////////////////////////////////////////////

  // const handleTableChange = searchText => {
  //   if (searchText !== "" && searchText !== undefined) {
  //     setUsersList(
  //       props.userList.filter(user =>
  //         Object.keys(user).some(key =>
  //           user[key].toLowerCase().includes(searchText.toLowerCase())
  //         )
  //       )
  //     )
  //   }
  // }

  const handleTableChange = (type, { page, searchText }) => {
    try {
      setUsersList(
        props.userList.filter(user =>
          Object.keys(user).some(key =>
            user[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    } catch {}
  }

  //Function for opening modal
  function tog_large() {
    setPaymentRequest(formRequest => ({
      ...formRequest,
      modal_large: !modal_large,
    }))
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        userDetails: row,
      }))
    },
  }

  const handleActionTableUser = value => {
    if (value === "update") {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        step: 1,
      }))
    }
    if (value === "deactivate") {
      setconfirm_alert(true)
    }
    if (value === "activate") {
      //console.log(value)
      setconfirm_alert_activate(true)
    }
    if (value === "delete") {
      setconfirm_alert_delete(true)
    }
  }

  const handleArchiveUser = value => {
    //console.log(value)
    if (value !== "" && value !== undefined) {
      let model = {
        Field: "User",
        Id: value,
      }
      dispatch(patchUserArchive(model))
    }
  }
  const handleDeactivateUser = () => {
    setdynamic_description("Deactivated")
    setconfirm_alert(false)
    if (userDetails !== undefined) {
      dispatch(deactivateUser(userDetails.userId))
    }
  }
  const handleActivateUser = () => {
    //console.log("activate")
    setdynamic_description("Activated")
    setconfirm_alert_activate(false)
    if (userDetails !== undefined) {
      dispatch(deactivateUser(userDetails.userId))
    }
  }

  const handleDeleteUser = () => {
    setconfirm_alert_delete(false)
    let userDeleteConfirm = {
      Password: userPassowrd,
      Email: userDetails._id,
    }
    if (
      userDeleteConfirm.Passowrd !== "" &&
      userDeleteConfirm.Email !== undefined
    ) {
      dispatch(deleteUser(userDeleteConfirm))
      setUserPassowrd("")
    }
  }
  const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
    <div
      className="btn-group btn-group-toggle btn-group-vertical"
      data-toggle="buttons"
    >
      {columns
        .map(column => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map(column => (
          <button
            type="button"
            key={column.dataField}
            className={`btn btn-warning ${column.toggle ? "active" : ""}`}
            data-toggle="button"
            aria-pressed={column.toggle ? "true" : "false"}
            onClick={() => onColumnToggle(column.dataField)}
          >
            {column.text}
          </button>
        ))}
    </div>
  )

  /////////////////////////////////////////////////////////////////
  switch (step) {
    case 0:
      form = (
        <UserCreatePopUp
          countryCodes={countryCodes}
          merchantIds={merchantIds}
        />
      )
      modalHeader = (
        <Row className="ml-1 my-3">
          <Label>ADD NEW USER</Label>
        </Row>
      )
      break
    case 1:
      form = (
        <UserUpdatePopUp
          countryCodes={countryCodes}
          userDetails={userDetails}
          merchantIds={merchantIds}
          onChange={value => setPaymentRequest(value)}
        />
      )
      modalHeader = (
        <Row className="ml-1 my-3">
          <Label>UPDATE USER</Label>
        </Row>
      )
      break
    default:
      break
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="MANAGE " breadcrumbItem=" USERS LISTS" />
          {errorCatching(errorResponse, setErrorResponse)}
          <Row>
            <Col lg="12">
              {confirm_alert ? (
                <SweetAlert
                  title="Deactivate User"
                  warning
                  showCancel
                  confirmButtonText="Confirm"
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    handleDeactivateUser()
                  }}
                  onCancel={() => setconfirm_alert(false)}
                >
                  Deactivate the selected user.
                </SweetAlert>
              ) : null}

              {confirm_alert_activate ? (
                <SweetAlert
                  title="Activate User"
                  warning
                  showCancel
                  confirmButtonText="Confirm"
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    handleActivateUser()
                  }}
                  onCancel={() => setconfirm_alert_activate(false)}
                >
                  Activate the selected user.
                </SweetAlert>
              ) : null}

              {confirm_alert_delete ? (
                <SweetAlert
                  title="Delete User"
                  warning
                  showCancel
                  confirmButtonText="Confirm"
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    handleDeleteUser()
                  }}
                  onCancel={() => setconfirm_alert_delete(false)}
                >
                  Please enter the password to delete the selected user.
                  <input
                    type="password"
                    className="form-control"
                    value={userPassowrd}
                    onChange={e => {
                      setUserPassowrd(e.target.value)
                    }}
                  />
                </SweetAlert>
              ) : null}

              {success_dlg ? (
                <SweetAlert
                  timeout={1000}
                  success
                  showConfirm={false}
                  title={dynamic_title}
                  onConfirm={() => {
                    setsuccess_dlg(false)
                    window.location.reload()
                  }}
                >
                  {dynamic_description}
                </SweetAlert>
              ) : null}

              {error_dlg ? (
                <SweetAlert
                  timeout={1000}
                  error
                  showConfirm={false}
                  title={dynamic_title}
                  onConfirm={() => {
                    seterror_dlg(false)
                  }}
                >
                  {dynamic_description}
                </SweetAlert>
              ) : null}

              <Modal
                size="lg"
                isOpen={modal_large}
                toggle={() => {
                  tog_large()
                }}
              >
                <div className="modal-header">
                  {modalHeader}
                  <h5 className="modal-title mt-0" id="myLargeModalLabel"></h5>
                  <button
                    onClick={() => {
                      setPaymentRequest(formRequest => ({
                        ...formRequest,
                        modal_large: false,
                      }))
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span caria-hidden="true" className="color-danger my-auto">
                      {" "}
                      x
                    </span>
                  </button>
                </div>
                <ModalBody>
                  {loading ? (
                    <Row className="spinner-custom mx-auto my-auto pb-3">
                      <LoadingSpinnerThreeDots />
                    </Row>
                  ) : (
                    form
                  )}
                </ModalBody>
              </Modal>
              <Card>
                <CardBody>
                  {loading ? (
                    <Row className="spinner-custom mx-auto my-auto pb-3">
                      <LoadingSpinnerThreeDots />
                    </Row>
                  ) : (
                    // <IsEmpty
                    //   value={usersList}
                    //   yes={() => <>No Users List</>}
                    //   no={() => (
                    // <PaginationProvider
                    //   pagination={paginationFactory(pageOptions)}
                    // >
                    //   {({ paginationProps, paginationTableProps }) => (
                    //     <ToolkitProvider
                    //       keyField="_id"
                    //       data={usersList || []}
                    //       columns={UserColumns(
                    //         handleActionTableUser,

                    //         user,
                    //         handleArchiveUser
                    //       )}
                    //       bootstrap4
                    //       search={{
                    //         afterSearch: newResult => {
                    //           setSearchResult(newResult)
                    //         },
                    //       }}
                    //       exportCSV={{
                    //         fileName:
                    //           "PayAllLink-Users-" +
                    //           dateFormatString(new Date()) +
                    //           ".csv",
                    //         separator: ",",
                    //         noAutoBOM: false,
                    //         exportAll: false,
                    //         onlyExportFiltered: true,
                    //       }}
                    //     >
                    //       {toolkitProps => (
                    //         <React.Fragment>
                    //           <Row className="mb-2  mx-2">
                    //             <Col sm="4">
                    //               <div className="search-box mr-2 mb-2 d-inline-block">
                    //                 <div className="position-relative">
                    //                   <SearchBar
                    //                     {...toolkitProps.searchProps}
                    //                     className="width-search"
                    //                     placeholder="Search User/Email/Mobile"
                    //                   />
                    //                   <i className="bx bx-search-alt search-icon" />
                    //                 </div>
                    //               </div>
                    //             </Col>
                    //             <Col sm="8">
                    //               <div className="text-sm-right">
                    //                 {/* {user.role === "Admin" ? (

                    //                 ) : (
                    //                   <ExportCSVButton
                    //                   color="warning"
                    //                   className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                    //                   {...toolkitProps.csvProps}
                    //                 >
                    //                   <i className="bx bx-download label-icon mr-1" />
                    //                   Download Billing
                    //                 </ExportCSVButton>
                    //                 )} */}
                    //                 {/* <CustomToggleList
                    //                   {...toolkitProps.columnToggleProps}
                    //                 /> */}
                    //                 <ExportCSVButton
                    //                   color="warning"
                    //                   className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                    //                   {...toolkitProps.csvProps}
                    //                   // onClick={() => {
                    //                   //   let file =
                    //                   //     "Payalllink-Users-" +
                    //                   //     new Date().toISOString()
                    //                   //   setFileName(file)
                    //                   // }}
                    //                 >
                    //                   <i className="bx bx-download label-icon mr-1" />
                    //                   Download Users
                    //                 </ExportCSVButton>
                    //                 {/* {usersDownload &&
                    //                 usersDownload.length !== 0 ? (
                    //                   <CSVLink
                    //                     color="warning"
                    //                     filename={"UserList.csv"}
                    //                     className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                    //                     data={usersDownload}
                    //                     separator=","
                    //                     key="_id"
                    //                     target="_blank"
                    //                   >
                    //                     <i className="bx bx-download label-icon mr-1" />
                    //                     Download Users
                    //                   </CSVLink>
                    //                 ) : null} */}
                    //                 <Button
                    //                   type="button"
                    //                   color="success"
                    //                   className="btn-rounded waves-effect waves-light mb-2 mr-2"
                    //                   onClick={() => {
                    //                     tog_large()
                    //                   }}
                    //                 >
                    //                   <i className="mdi mdi-account-multiple-plus mr-1" />
                    //                   New User
                    //                 </Button>
                    //               </div>
                    //             </Col>
                    //           </Row>
                    //           <Row>
                    //             <Col xl="12">
                    //               <div className="table-responsive">
                    //                 <BootstrapTable
                    //                   responsive
                    //                   bordered={false}
                    //                   loading={true}
                    //                   hover={true}
                    //                   striped={false}
                    //                   classes={
                    //                     "table table-centered pointer table-nowrap table-hover table-bordered mr-5 mb-5 "
                    //                   }
                    //                   rowEvents={rowEvents}
                    //                   keyField="_id"
                    //                   headerWrapperClasses={"thead-light"}
                    //                   {...toolkitProps.baseProps}
                    //                   filter={filterFactory()}
                    //                   //onTableChange={handleTableChange}
                    //                   {...paginationTableProps}
                    //                 />
                    //               </div>
                    //             </Col>
                    //           </Row>
                    //           <Row className="align-items-md-center mt-30">
                    //             <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                    //               <PaginationListStandalone
                    //                 {...paginationProps}
                    //               />
                    //             </Col>
                    //           </Row>
                    //         </React.Fragment>
                    //       )}
                    //     </ToolkitProvider>
                    //   )}
                    // </PaginationProvider>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          data={usersList || []}
                          columns={UserColumns(
                            handleActionTableUser,

                            user,
                            handleArchiveUser
                          )}
                          loading={true}
                          bootstrap4
                          search
                          exportCSV={{
                            fileName:
                              "PayAllLink-Users-" +
                              dateFormatString(new Date()) +
                              ".csv",
                            separator: ",",
                            noAutoBOM: false,
                            exportAll: false,
                            onlyExportFiltered: true,
                          }}
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="8">
                                  <div className="search-box mr-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                        className="width-search"
                                        placeholder="Search User/Email/Mobile"
                                        //placeholder="Search By Payment Request Id, Name, Amount, Currency , Platform ..."
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="text-sm-right">
                                    <ExportCSVButton
                                      color="warning"
                                      className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                                      {...toolkitProps.csvProps}
                                      // onClick={() => {
                                      //   let file =
                                      //     "Payalllink-Users-" +
                                      //     new Date().toISOString()
                                      //   setFileName(file)
                                      // }}
                                    >
                                      <i className="bx bx-download label-icon mr-1" />
                                      Download Users
                                    </ExportCSVButton>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                      onClick={() => {
                                        tog_large()
                                      }}
                                    >
                                      <i className="mdi mdi-account-multiple-plus mr-1" />
                                      New User
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      responsive
                                      remote={{
                                        filter: true, // not working
                                        pagination: false, //not working
                                        sort: true, // not working
                                        cellEdit: false,
                                      }}
                                      loading={true}
                                      bordered={false}
                                      hover={true}
                                      striped={false}
                                      classes={
                                        "table table-centered table-nowrap"
                                      }
                                      keyField="_id"
                                      headerWrapperClasses={"thead-light"}
                                      onTableChange={handleTableChange}
                                      rowEvents={rowEvents}
                                      // selectRow={selectRow}
                                      filter={filterFactory()}
                                      // defaultSorted={defaultSorted}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* <Row>
                        <Col xs="12">
                          <div className="text-center my-3">
                            <Link to="#" className="text-success">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />
                                  Load more
                                </Link>
                          </div>
                        </Col>
                      </Row> */}

                              <Row className="align-items-md-center mt-30">
                                {/* <Col xs="6">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </Col> */}

                                {/* <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col> */}
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                  {/* />
                  )} */}
                  <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.Users.loading,
    userList: state.Users.usersList,
    userCreated: state.Users.userCreated,
    userDeactivate: state.Users.userDeactivate,
    userDelete: state.Users.userDelete,
    userUpdate: state.Users.update,
    merchantIds: state.Merchants.merchantIds,
    // merchantList: state.Merchants.merchantList,
    countryCodes: state.Options.countryCodes,
    userArchiveResult: state.UserArchived.userArchiveResult,
    errorU: state.Users.error,
    errorM: state.Merchants.error,
  }
}

export default connect(mapStateToProps)(index)
