import React, { useEffect } from "react"
import { Modal } from "reactstrap"

const ImageModal = props => {
  const { isOpen, toggle, image } = props

  useEffect(() => {}, [image])

  
  return (
    <>
      <Modal
        className="show"
        backdrop="static"
        isOpen={isOpen}
        autoFocus={true}
        centered={true}
        toggle={toggle}
        tabindex="-1"
        size="xl"
      >
        {image === "apikey" && (
          <>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Api Key Sample Image
              </h5>
              <button
                type="button"
                onClick={() => toggle()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div style={{ padding: "10px" }}>
              <img
                src="https://res.cloudinary.com/developerqa/image/upload/v1673789301/common/payallink/registration/apikey_al9zea.png"
                style={{ width: "100%",height: "auto"}}
              />
            </div>
          </>
        )}

        {image === "webhook" && (
          <>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Webhook Settings
              </h5>
              <button
                type="button"
                onClick={() => toggle()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div style={{ padding: "10px" }}>
              <img
                src="https://res.cloudinary.com/developerqa/image/upload/v1673858604/common/payallink/registration/webhook_afba7a.png"
                style={{ width: "100%",height: "auto"}}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

export default ImageModal
