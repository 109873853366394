import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { Col, Row, Button, Modal, Label, ModalBody } from "reactstrap"
import CustomerPopup from "../../../components/Customer/CustomerList"
import { connect } from "react-redux"
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
const initialValues = {
  MobilePlus: "+974",
  Name: "",
  Email: "",
  Mobile: "",
  Type: "New",
  modal_xlarge: false,
  ItemCheck: false
}
const FormSecondPage = props => {
  //Constants-----------------------------------------------------------------------------------
  const [{ Name, Email, Mobile,
    MobilePlus, Type, ItemCheck, modal_xlarge, customerSearchorCreated, customerCreatedBool }, setPaymentRequest] = useState({
      MobilePlus: "+974",
      Name: props.Name,
      Email: "",
      Mobile: "",
      Type: "New",
      modal_xlarge: false,
      ItemCheck: false,
      customerCreatedBool: false
    })
  const [customchk, setCustomchk] = useState(false);
  const [ItemsList, setItems] = useState([]);
  const { register, handleSubmit, errors, reset } = useForm();
  //Use Effects---------------------------------------------------------------------------------
  useEffect(() => {
    setPaymentRequest(formRequest => ({
      ...formRequest,
      customerCreatedBool: props.customerCreated
    }))
  }, [props.customerCreated])

  useEffect(() => {
    if (!props.Name && modal_xlarge === false) {
      reset(initialValues)
      setPaymentRequest(formRequest => ({
        ...formRequest,
        MobilePlus: "+974",
        Email: "",
        Name: "",
        Mobile: "",
        Type: "New",
        modal_xlarge: false,
        ItemCheck: false
      }))
      props.onChange(formRequest => ({
        ...formRequest,
        ItemCheck: false
      }))
    }
    setCustomchk(false)
  }, [props.Name])
  //Use effect for props change in customer details
  useEffect(() => {
    setPaymentRequest(formRequest => ({
      ...formRequest,
      Name,
      Email,
      MobilePlus,
      Mobile,
      Type,
      customerSearchorCreated
    }))
    props.onChange(formRequest => ({
      ...formRequest,
      Name,
      Email,
      MobilePlus,
      Mobile,
      Type,
      customerSearchorCreated
    }))
  }, [Name, Email, Mobile,])

  //Use Effect for checking if customer is searched or created
  useEffect(() => {
  }, [customerSearchorCreated])

  //Use effect for adding and checking items list for source portal
  useEffect(() => {
    if (ItemsList && customchk) {
      props.onChange(formRequest => ({
        ...formRequest,
        ListItems: ItemsList
      }))
    } else {
      props.onChange(formRequest => ({
        ...formRequest,
        ListItems: []
      }))
    }
  }, [ItemsList, customchk])

  //Functions------------------------------------------------------------------------------------
  //Function to open modal
  function tog_xlarge() {
    setPaymentRequest(formRequest => ({
      ...formRequest,
      modal_xlarge: !modal_xlarge
    }))
    removeBodyCss()
  }

  //Function for closing modal
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  //Function for setting data state changes for this component and props
  const storeDataOnForm = (event) => {
    const { name, value } = event.target;
    setPaymentRequest(formRequest => (
      { ...formRequest, [name]: value }
    ));
    props.onChange(formRequest => (
      { ...formRequest, [name]: value }
    ));
  };

  const handleClearCustomer = () => {
    setPaymentRequest(formRequest => ({
      ...formRequest,
      SearchCustomer: "",
      Name: "",
      Email: "",
      Mobile: "",
      MobilePlus: "+974",
    }))
    props.onChange(formRequest => ({
      ...formRequest,
      Name: "",
      Email: "",
      Mobile: "",
      MobilePlus: "+974",
    }))
    toast.warn("Customer cleared.")
  }



  return (
    <form>
      <fieldset>
        <Modal size="xl"
          isOpen={modal_xlarge}
          centered={true}
          toggle={() => {
            tog_xlarge()
          }}>
          <div className="modal-header">
            <h5
              className="modal-title mt-0"
              id="myLargeModalLabel"
            >

            </h5>
            <button
              onClick={() => {
                setPaymentRequest(formRequest => ({
                  ...formRequest,
                  modal_xlarge: false
                }))
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span caria-hidden="true" className="color-danger my-auto"> x</span>
            </button>
          </div>
          <ModalBody>
            < CustomerPopup
              onChange={value => setPaymentRequest(value)} />
          </ModalBody>
        </Modal>

        <Row className="form-group mx-1">
          <Col >
            <Label className=" input-text-size">
              CUSTOMER NAME
                 </Label>

            <input
              id="Name"
              name="Name"
              value={Name}
              type="text"
              className="form-control bg-color-disable form-control-lg input-text-size"
              readOnly
              ref={register({ required: false })}
              onChange={(event) => storeDataOnForm(event, props.setFormRequest)}
            />


          </Col>
          <Col>

            <Label className=" input-text-size">
              CUSTOMER EMAIL
                                  </Label>

            <input
              id="Email"
              name="Email"
              value={Email}
              type="email"
              readOnly
              ref={register({ required: false })}
              className="form-control bg-color-disable form-control-lg input-text-size"
              onChange={(event) => storeDataOnForm(event, props.setFormRequest)}
            />



          </Col>
          <Col sm={3}>

            <Label className=" input-text-size">
              MOBILE
                                  </Label>

            <input
              id="MobilePlus"
              name="MobilePlus"
              value={MobilePlus}
              readOnly
              type="text"
              ref={register({ required: false })}
              className="form-control bg-color-disable form-control-lg input-text-size"
              onChange={(event) => storeDataOnForm(event, props.setFormRequest)}
            />


          </Col>
          <Col >
            <Label className=" input-text-size">
              <br />
            </Label>
            <input
              id="Mobile"
              name="Mobile"
              value={Mobile}
              readOnly
              type="number"
              ref={register({ required: false })}
              className="form-control bg-color-disable form-control-lg input-text-size"
              onChange={(event) => storeDataOnForm(event, props.setFormRequest)}
            />

          </Col>

        </Row>
        <Row className="form-group mx-1">
          <Col className="item-center">
            <br />

            {Name === "" ? <Button
              color="success"
              data-toggle="modal"
              data-target=".bs-example-modal-lg"
              className="mr-1 waves-effect waves-light my-3 form-control-lg btn-lg"
              onClick={() => {
                tog_xlarge()
              }}
            >
              Add/Search Customer
        </Button> : <Button
              color="warning"
              data-toggle="modal"
              data-target=".bs-example-modal-lg"
              className="mr-1 waves-effect waves-light my-3 form-control-lg btn-lg"
              onClick={handleClearCustomer}
            >
              Clear Customer
        </Button>}
          </Col>
        </Row>
       
      </fieldset>

    </form>
  )
}

const mapStateToProps = state => {
  return {
    customer: state.Customer.customer,
    customerCreated: state.Customer.customerCreated
  }
}


FormSecondPage.propTypes = {
  handleSubmit: PropTypes.func,
  previousPage: PropTypes.any
}

export default connect(mapStateToProps)(FormSecondPage)

