import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  getUsersListArchived,
  getMerchantList,
  patchUserArchive,
} from "store/actions"
import { Container } from "reactstrap"
import UserListTable from "./UserListTable"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import SweetAlert from "react-bootstrap-sweetalert"
function index(props) {
  const {
    onGetUserListArchived,
    loading,
    usersListArchived,
    merchantList,
    onGetMerchantList,
    onPatchUserArchive,
    userArchiveResult,
  } = props
  const [archiveUser, setArchiveUser] = useState("")
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  useEffect(() => {
    onGetUserListArchived()
    onGetMerchantList()
  }, [])

  useEffect(() => {
    //console.log(usersListArchived)
  }, [usersListArchived])
  useEffect(() => {
    //console.log(merchantList)
  }, [merchantList])
  useEffect(() => {
    if (archiveUser && archiveUser !== undefined) {
      let model = {
        Field: "User",
        Id: archiveUser,
      }
      onPatchUserArchive(model)
    }
  }, [archiveUser])
  useEffect(() => {
    // console.log(userArchiveResult)
    if (userArchiveResult === true) {
      setsuccess_dlg(true)
      setdynamic_title("Success")
      setdynamic_description("User has been restored.")
    }
    if (userArchiveResult === false) {
      seterror_dlg(true)
      setdynamic_title("Fail")
      setdynamic_description("User is not restored.")
    }
  }, [userArchiveResult])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="User Archived" />
          {success_dlg ? (
            <SweetAlert
              timeout={1000}
              success
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                setsuccess_dlg(false)
                window.location.reload()
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {error_dlg ? (
            <SweetAlert
              timeout={1000}
              error
              showConfirm={false}
              title={dynamic_title}
              onConfirm={() => {
                seterror_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          <UserListTable
            list={usersListArchived}
            loading={loading}
            merchantList={merchantList}
            onChange={value => setArchiveUser(value)}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = state => ({
  usersListArchived: state.UserArchived.usersListArchived,
  loading: state.UserArchived.loading,
  merchantList: state.Merchants.merchantList,
  userArchiveResult: state.UserArchived.userArchiveResult,
})

const mapDispatchToProps = dispatch => ({
  onGetUserListArchived: () => dispatch(getUsersListArchived()),
  onGetMerchantList: () => dispatch(getMerchantList()),
  onPatchUserArchive: id => dispatch(patchUserArchive(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
