import React from "react"
import ManAvatar from "../../assets/images/images/man.jpg"
import WomanAvatar from "../../assets/images/images/woman.jpg"
import { Col, Row, Card, CardBody, Button } from "reactstrap"
import ProfileImage from "../../assets/images/profile-img.png"
import AgencyLogo from "../../assets/images/merchant/abctravels/logo.png"
function UserProfileCard(props) {
  let userAvatar = ""
  switch (props.user && props.user.title) {
    case "MS":
      userAvatar = WomanAvatar
      break
    case "Ms":
      userAvatar = WomanAvatar
      break
    case "MR":
      userAvatar = ManAvatar
      break
    case "Mr":
      userAvatar = ManAvatar
      break
    default:
      userAvatar = ManAvatar
      break
  }
  return (
    <Card
      className="overflow-hidden"
      style={{ backgroundColor: "red !important" }}
    >
      <div
        // className="bg-soft-primary"
        style={{ backgroundColor: "white !important" }}
      >
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h5
                className="text-primary"
                // style={{color:'#2a3042'}}
              >
                Welcome to PayAllLink{" "}
                <b>{props.user && props.user.firstName}</b>!
              </h5>
              <p style={{ color: "gray" }}>
                {" "}
                {props.merchantDetail?.merchantName}{" "}
              </p>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img
              src={props.merchantDetail?.logo}
              alt=""
              className="img-fluid pb-3"
              style={{ width: "150px", height: "auto" }}
            />
          </Col>
        </Row>
      </div>
      {/* <CardBody className="pt-0" style={{ backgroundColor: "#D9E8F3" }}>
        <div style={{ backgroundColor: "red !important" }}>
          <Row>
            <Col>
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={userAvatar}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">
                Hi{" "}
                {props.user && props.user.firstName + " " + props.user.lastName}
              </h5>
              <p className="text-muted mb-0 text-truncate">
                {props.user && props.user.role}
              </p>
            </Col>

            <Col sm={8}>
              <div className="pt-4">
                <Row>
                  <Col>
                    {" "}
                    <p className="text-muted mb-0 text-truncate">
                      User Productivity
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <small className="text-muted mb-0"># of Paid PR:</small>
                    <h5 className="font-size-15">{props.prCount}</h5>
                  </Col>
                  <Col>
                    <small className="text-muted mb-0">
                      with total revenue:
                    </small>
                    <h5 className="font-size-15">QAR {props.revenue}</h5>
                  </Col>
                </Row>
               

                <div className="mt-4"></div>
              </div>
            </Col>
          </Row>
        </div>
      </CardBody> */}
      <CardBody className="pt-0" style={{ backgroundColor: "#D9E8F3" }}>
        <div style={{ backgroundColor: "red !important" }}>
          <Row>
            <Col>
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={userAvatar}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="user-profile text-truncate">
                <p className="text-muted mb-0 text-truncate">
                  Hi {props.user && props.user.firstName}, your user
                  productivity is as follows:
                </p>
              </h5>
            </Col>

            <Col sm={8}>
              <div className="pt-4">
                <Row>
                  <Col> </Col>
                </Row>
                <Row>
                  <Col>
                    <small className="text-muted mb-0"># of Paid PR:</small>
                    <h5 className="font-size-15">{props.prCount}</h5>
                  </Col>
                  <Col>
                    <small className="text-muted mb-0">
                      with total revenue:
                    </small>
                    <h5 className="font-size-15">QAR {props.revenue}</h5>
                  </Col>
                </Row>

                <div className="mt-4"></div>
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  )
}

export default UserProfileCard
