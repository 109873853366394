import React from 'react'
import ManAvatar from "../../assets/images/images/man.jpg"
import WomanAvatar from "../../assets/images/images/woman.jpg"
import { Col, Row, Card, CardBody, Button } from 'reactstrap'
import ProfileImage from "../../assets/images/profile-img.png"
import AgencyLogo from "../../assets/images/merchant/abctravels/logo.png"
import Banners from "./Banners"
function PromotionsAnnouncements(props) {
   
    return (
        <Card>
            <CardBody>
                <Banners />
            </CardBody>
        </Card>
    )
}

export default PromotionsAnnouncements
