import React from "react"
import {
    Badge,
} from "reactstrap"
import dateFormat from '../../helpers/commonhelpers/dateFormat'

const PlacerExecutionLogsColumns = () =>[
   
    {
        dataField: "fileName",
        text: "File Name",
        searchable: true,
        sort: true,
    },
    {
        dataField: "date",
        text: "Date",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
            return (
              <span>
               {dateFormat(cellContent)}
              </span>
            )
          }
    },
    {
        dataField: "hasErrors",
        text: "Has Error",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
            if (cellContent === true) {
              return <Badge
                className={"font-size-12 badge-soft-success"}
                color="success"
                pill
              >
                {cellContent.toString()}
              </Badge>
            }
            if (cellContent === false) {
              return <Badge
                className={"font-size-12 badge-soft-danger"}
                color="danger"
                pill
              >
                {cellContent.toString()}
              </Badge>
            }
          }
    },
    {
        dataField: "content",
        text: "File Content",
        searchable: true,
        sort: true,
    },
   
]
export default PlacerExecutionLogsColumns