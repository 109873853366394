import React, { useState } from "react"
import { Row, Col, Collapse, Table, Card } from "reactstrap"

import PortalItems from "./PurchaseDetailsSources/Portal"
import SellingPlatform from "./PurchaseDetailsSources/SellingPlatform"

export default function PurchaseDetails(props) {
  const { source, purchasedItems } = props
  const [col2, setcol2] = useState(false)

  return (
    <>
      <Col lg="12">
        <small
          onClick={() => {
            setcol2(!col2)
          }}
          style={{ cursor: "pointer", color: "rgb(52, 195, 143)" }}
        >
          <b>Show Purchase Details</b> {" >> "}
        </small>
        <Collapse isOpen={col2}>
          {source === "SELLCO" && (
            <>
              <div className="d-flex justify-content-center mt-4"></div>
              <SellingPlatform purchasedItems={purchasedItems} />
            </>
          )}

          {source === "PORTAL" && (
            <>
              <div className="d-flex justify-content-center mt-4"></div>

              <PortalItems purchasedItems={purchasedItems} />
            </>
          )}
        </Collapse>{" "}
      </Col>
    </>
  )
}
