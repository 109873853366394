import {
  GET_CUSTOMER_LIST,
  SET_CUSTOMER,
  SET_CUSTOMER_SUCCESS,
  SET_CUSTOMER_FAIL,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_LIST_FAIL,
  GET_CUSTOMER_DETAIL,
  GET_CUSTOMER_DETAIL_SUCCESS,
  GET_CUSTOMER_DETAIL_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_RESULT_SUCCESS,
  UPDATE_CUSTOMER_RESULT_FAIL,
  GET_CUSTOMERS_BY_MERCHANTID,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  RESTART_STATE,
  SELECT_CUSTOMER,
  SELECT_CUSTOMER_SUCCESS,
  SELECT_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_EXPRESS,
  UPDATE_CUSTOMER_EXPRESS_SUCCESS,
  UPDATE_CUSTOMER_EXPRESS_FAIL,
  SET_CUSTOMER_EXPRESS,
  SET_CUSTOMER_EXPRESS_SUCCESS,
  SET_CUSTOMER_EXPRESS_FAIL,
} from "./actionTypes"

export const getCustomerList = () => ({
  type: GET_CUSTOMER_LIST,
})

export const getCustomerListSuccess = customers => ({
  type: GET_CUSTOMER_LIST_SUCCESS,
  payload: customers,
})

export const getCustomerListFail = customers => ({
  type: GET_CUSTOMER_LIST_FAIL,
  payload: customers,
})

export const getCustomersByMerchantID = customers => ({
  type: GET_CUSTOMERS_BY_MERCHANTID,
  payload: customers,
})

export const getCustomerDetail = customer => ({
  type: GET_CUSTOMER_DETAIL,
  payload: customer,
})
export const getCustomerDetailSuccess = customer => ({
  type: GET_CUSTOMER_DETAIL_SUCCESS,
  payload: customer,
})
export const getCustomerDetailFail = customer => ({
  type: GET_CUSTOMER_DETAIL_FAIL,
  payload: customer,
})

export const setCustomer = customer => ({
  type: SET_CUSTOMER,
  payload: customer,
})

export const setCustomerSuccess = customer => ({
  type: SET_CUSTOMER_SUCCESS,
  payload: customer,
})
export const setCustomerFail = customer => ({
  type: SET_CUSTOMER_FAIL,
  payload: customer,
})

export const updateCustomer = customer => ({
  type: UPDATE_CUSTOMER,
  payload: customer,
})

export const updateCustomerSuccess = customer => ({
  type: UPDATE_CUSTOMER_RESULT_SUCCESS,
  payload: customer,
})

export const updateCustomerFail = customer => ({
  type: UPDATE_CUSTOMER_RESULT_FAIL,
  payload: customer,
})

export const updateCustomerExpress = customer => ({
  type: UPDATE_CUSTOMER_EXPRESS,
  payload: customer,
})

export const updateCustomerExpressSuccess = customer => ({
  type: UPDATE_CUSTOMER_EXPRESS_SUCCESS,
  payload: customer,
})

export const updateCustomerExpressFail = customer => ({
  type: UPDATE_CUSTOMER_EXPRESS_FAIL,
  payload: customer,
})
export const deleteCustomer = customer => ({
  type: DELETE_CUSTOMER,
  payload: customer,
})

export const deleteCustomerSuccess = customer => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload: customer,
})

export const deleteCustomerFail = customer => ({
  type: DELETE_CUSTOMER_FAIL,
  payload: customer,
})

export const restartState = customer => ({
  type: RESTART_STATE,
  payload: customer,
})

export const selectCustomer = customer => ({
  type: SELECT_CUSTOMER,
  payload: customer,
})

export const selectCustomerSuccess = customer => ({
  type: SELECT_CUSTOMER_SUCCESS,
  payload: customer,
})

export const selectCustomerFail = customer => ({
  type: SELECT_CUSTOMER_FAIL,
  payload: customer,
})

export const setCustomerExpress = customer => ({
  type: SET_CUSTOMER_EXPRESS,
  payload: customer,
})

export const setCustomerExpressSuccess = customer => ({
  type: SET_CUSTOMER_EXPRESS_SUCCESS,
  payload: customer,
})
export const setCustomerExpressFail = err => ({
  type: SET_CUSTOMER_EXPRESS_FAIL,
  payload: err,
})
