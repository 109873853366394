import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, } from 'reactstrap'
import { ValuesContext } from "./PayGateOverview"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { IsEmpty } from "react-lodash"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import PayGateOverviewColumns from "./PayGateOverviewColumns"
function PayGateOverviewContacts() {

    const data = useContext(ValuesContext);
    const [contacts, setContacts] = useState([])

    const pageOptionsEmailLogs = {
        sizePerPage: 15,
        totalSize: contacts.length,
        custom: true,
        onPageChange: (page, sizePerPage) => { handleTableChangeLogs() }
    }

    useEffect(() => {
        if (data.paymentGateway && data.paymentGateway.contacts) {
            setContacts(data.paymentGateway.contacts)
        }
    }, [data.paymentGateway])

    let searchText = "";
    const { SearchBar } = Search

    const handleTableChangeLogs = (searchText) => {
        if (searchText !== "" && searchText !== undefined) {
            setContacts(
                contacts.filter(contactList =>
                    Object.keys(contactList).some(key =>
                        contactList[key].toLowerCase().includes(searchText.toLowerCase())
                    )
                )
            )
        }
    }
    return (
        <>
            <IsEmpty
                value={contacts}
                yes={() => <Row>
                    <Col>
                        No list
                 </Col>
                </Row>}
                no={() => (
                    <PaginationProvider
                        pagination={paginationFactory(pageOptionsEmailLogs)}
                    >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                                keyField="name"
                                data={contacts || []}
                                columns={PayGateOverviewColumns()}
                                bootstrap4
                                search
                            >
                                {toolkitProps => (
                                    <React.Fragment>
                                        <Row className="mb-2  mx-2">
                                            <Col sm="4">
                                                <div className="search-box mr-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <SearchBar {...toolkitProps.searchProps} className="width-search" placeholder="Search " />
                                                        <i className="bx bx-search-alt search-icon" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    <BootstrapTable
                                                        responsive
                                                        bordered={false}
                                                        hover={true}
                                                        striped={false}
                                                        classes={
                                                            "table table-centered pointer table-nowrap table-hover mr-5 mb-5"
                                                        }
                                                        //rowEvents={rowEvents}
                                                        keyField="name"
                                                        headerWrapperClasses={"thead-light"}
                                                        {...toolkitProps.baseProps}
                                                        onTableChange={handleTableChangeLogs(searchText)}
                                                        {...paginationTableProps}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-md-center mt-30">
                                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        )}
                    </PaginationProvider>
                )} />
        </>
    )
}

export default PayGateOverviewContacts
