import React, { useState, useEffect } from "react"
import { Col, FormGroup, InputGroup, Label, Row, Button } from "reactstrap"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { connect } from "react-redux"
import Select from "react-select"
import makeAnimated from "react-select/animated"

import { useDispatch } from "react-redux"
import "react-toastify/dist/ReactToastify.css";
// import {PaymentRequestContext} from './index' 

import { mainSearchDateRange, mainSearchListReset } from "store/actions"
//UseForm
import { useForm } from "react-hook-form";

function Filters(props) {
  const dispatch = useDispatch();
  const [resetList, setResetList] = useState("")
  const [filter, setFilter] =useState("")
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  useEffect(() => {
    if (selectedDateRange !== undefined) {
      dispatch(mainSearchDateRange(selectedDateRange))
    }
  }, [selectedDateRange])

  useEffect(()=>{
    if(resetList === "Reset"){
     dispatch( mainSearchListReset(resetList))
     setResetList("")
     setSelectedDateRange(null)
    }
  },[resetList])

  useEffect(()=>{
    if(props.searchPrefix !== undefined){
      setFilter(props.searchPrefix)
    }
  },[props.searchPrefix])

  useEffect(()=>{},[filter])

  return (
    <Row className=" my-2">

      <Col lg="4">

        <FormGroup className="mb-4">
         {filter === "TRXN" ?  <Label>Select Payment Request Date Range</Label> :null}
         {filter === "USER" ?  <Label>Select User Registration Date Range</Label> :null}
         {filter === "CUST" ?  <Label>Select Customer Registration Date Range</Label> :null}
          <InputGroup>
            <Flatpickr
              name="selectedDates"
              className="form-control d-block"
              placeholder="Select Date Range"
              value={selectedDateRange}
              options={{
                mode: "range",
                altFormat: "Y-m-d",
                dateFormat: "F j, Y"
              }}
              onChange={(selectedDates) => setSelectedDateRange(selectedDates)}
            // ref={register({ required: true })}
            />
          </InputGroup>
        </FormGroup>
      </Col>
      <Col lg="1">
      <FormGroup className="mb-4">
      <Label>Reset List</Label>
        <Button
        style={{width:"100%",padding:"0%"}}
          color="danger"
          type="submit"
          className="form-control "
          onClick={() => setResetList("Reset")}>
          <Label className="mx-auto my-auto">
          <i className="bx bx-reset label-icon mr-1"/>
            Reset
          </Label>
        </Button>
        </FormGroup>
      </Col>
      {/* <Col lg="4">
        <FormGroup className="mb-0 templating-select select2-container">
          <label className="control-label">Select Filters</label>
          <Select
            name="multiSelect"
            value={selectedMultiSelect.select2}
            isMulti={true}
            // ref={register({ required: true })}
            onChange={(entry) => {

              if (entry) {
                setselectedMultiSelect({ select2: entry })
                //  entry.map(o => {return onFilteredChangeCustom(o.value,o.label)})
                onFilteredChangeCustom(
                  entry.map(o => { return o.value }), "entry"
                );
              }

            }}
            options={optionGroup2}
            // options={selectedMultiSelect.data.map((o, i) => {
            //   return { id: i, value: o.firstName, label: o.firstName };
            // })}
            classNamePrefix="select2-selection"
            closeMenuOnSelect={false}
            components={animatedComponents}
          />
        </FormGroup>
      </Col> */}
      {/* <Col lg="1">

            <FormGroup className="mb-0 templating-select select2-container">
            <Row>    <label className="control-label" style={whiteText}>Button</label> </Row>
              <Row>
              <button
                            type="button"
                            className="btn btn-primary waves-effect btn-label waves-light w-md"
                            onClick={() => handleFilterActions(selectedMultiSelect, selectedDateRange)}
                          >
                            <i className="bx bx-search-alt label-icon"></i> Search
                          </button>
              </Row>
          
            </FormGroup>

          </Col> */}
      {/* <Col lg="2">
            <FormGroup className="mb-0 templating-select select2-container">
              <Row><label className="control-label" style={whiteText}> Button</label> </Row>
              <Row>
                
              <button
                            type="button"
                            style={{minWidth:"100px"}}
                            className="btn btn-success waves-effect btn-label waves-light w-md"
                            onClick={handleNewRequestButton}
                          >
                            <i className="bx bx-download label-icon"></i>{" "}
                            Download CSV
                          </button>
              </Row>
              <label className="control-label" style={whiteText}> Download CSV</label>

            </FormGroup>
         
          </Col> */}
      {/* <Col lg="1">

            <FormGroup className="mb-0 templating-select select2-container">

            <Row><label className="control-label" style={whiteText}>Reset</label> </Row>
              <Row>
                
              <button
                            type="button"
                            className="btn btn-danger waves-effect btn-label waves-light w-md"
                            onClick={() => dispatch(resetFilterValues())}
                          >
                            <i className="bx bx-reset label-icon "></i> Reset 
                          </button>
              </Row>
            </FormGroup>

          </Col> */}
    </Row>
  )
}

const mapStateToProps = state => {
  return {
     
      searchPrefix: state.MainSearch.filter,
  }
}
export default connect(mapStateToProps)(Filters)

