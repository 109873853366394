import {
  
  GET_TRANSACTION_UPDATE_LOGS_LIST,
  GET_TRANSACTION_UPDATE_LOGS_LIST_SUCCESS,
  GET_TRANSACTION_UPDATE_LOGS_LIST_FAIL,
  GET_USER_LOGS,
  GET_USER_LOGS_SUCCESS,
  GET_USER_LOGS_FAIL,
  GET_USER_LOGS_LIST,
  GET_USER_LOGS_LIST_SUCCESS,
  GET_USER_LOGS_LIST_FAIL,
  GET_EMAIL_LOGS_LIST,
  GET_EMAIL_LOGS_LIST_SUCCESS,
  GET_EMAIL_LOGS_LIST_FAIL,
  GET_ERROR_LOGS_LIST,
  GET_ERROR_LOGS_LIST_SUCCESS,
  GET_ERROR_LOGS_LIST_FAIL,
  UPDATE_TRANSACTION_LOG,
  UPDATE_TRANSACTION_LOG_SUCCESS,
  UPDATE_TRANSACTION_LOG_FAIL,
  GET_PARSER_ERROR_LOG_LIST,
  GET_PARSER_ERROR_LOG_LIST_SUCCESS,
  GET_PARSER_ERROR_LOG_LIST_FAIL,
  GET_PARSER_EXECUTION_LOG_LIST,
  GET_PARSER_EXECUTION_LOG_LIST_SUCCESS,
  GET_PARSER_EXECUTION_LOG_LIST_FAIL,
  GET_PLACER_ERROR_LOG_LIST,
  GET_PLACER_ERROR_LOG_LIST_SUCCESS,
  GET_PLACER_ERROR_LOG_LIST_FAIL,
  GET_PLACER_EXECUTION_LOG_LIST,
  GET_PLACER_EXECUTION_LOG_LIST_SUCCESS,
  GET_PLACER_EXECUTION_LOG_LIST_FAIL,
  SET_WHATSAPP_LOG,
  SET_WHATSAPP_LOG_SUCCESS,
  SET_WHATSAPP_LOG_FAIL,
  GET_SYNC_CHECKER_EXECUTION_LOG_LIST,
  GET_SYNC_CHECKER_EXECUTION_LOG_LIST_SUCCESS,
  GET_SYNC_CHECKER_EXECUTION_LOG_LIST_FAIL,
  GET_SYNC_CHECKER_ERROR_LOG_LIST,
  GET_SYNC_CHECKER_ERROR_LOG_LIST_SUCCESS,
  GET_SYNC_CHECKER_ERROR_LOG_LIST_FAIL,
  RETURN_STATE

} from "./actionTypes"



export const getLogsTransactionUpdateList = () => ({
  type: GET_TRANSACTION_UPDATE_LOGS_LIST
})

export const getLogsTransactionUpdateListSuccess = logsTransactionUpdateList => ({
  type: GET_TRANSACTION_UPDATE_LOGS_LIST_SUCCESS,
  payload: logsTransactionUpdateList,
})

export const getLogsTransactionUpdateListFail = error => ({
  type: GET_TRANSACTION_UPDATE_LOGS_LIST_FAIL,
  payload: error,
})


export const updateTransactionLogs = (data) => ({
  type: UPDATE_TRANSACTION_LOG,
  payload: data,
})

export const updateTransactionLogsSuccess = data => ({
  type: UPDATE_TRANSACTION_LOG_SUCCESS,
  payload: data,
})

export const updateTransactionLogsFail = error => ({
  type: UPDATE_TRANSACTION_LOG_FAIL,
  payload: error,
})


export const returnStateLogsTransactionUpdate =logsTransactionUpdateList=>({
  type:RETURN_STATE,
  payload:logsTransactionUpdateList
})

export const getUserLogsById = user =>({
  type:GET_USER_LOGS,
  payload:user
})

export const getUserLogsByIdSuccess = user =>({
  type:GET_USER_LOGS_SUCCESS,
  payload:user
})

export const getUserLogsByIdFail = user =>({
  type:GET_USER_LOGS_FAIL,
  payload:user
})

export const getUserLogList = user =>({
  type:GET_USER_LOGS_LIST,
  payload:user
})
export const getUserLogListSuccess = user =>({
  type:GET_USER_LOGS_LIST_SUCCESS,
  payload:user
})
export const getUserLogListFail= user =>({
  type:GET_USER_LOGS_LIST_FAIL,
  payload:user
})

export const getEmailLogList = user =>({
  type:GET_EMAIL_LOGS_LIST,
  payload:user
})

export const getEmailLogListSuccess = user =>({
  type:GET_EMAIL_LOGS_LIST_SUCCESS,
  payload:user
})

export const getEmailLogListFail = user =>({
  type:GET_EMAIL_LOGS_LIST_FAIL,
  payload:user
})

export const getErrorLogList = user =>({
  type:GET_ERROR_LOGS_LIST,
  payload:user
})

export const getErrorLogListSuccess = user =>({
  type:GET_ERROR_LOGS_LIST_SUCCESS,
  payload:user
})

export const getErrorLogListFail = user =>({
  type:GET_ERROR_LOGS_LIST_FAIL,
  payload:user
})

export const getParserErrorLogList = logs =>({
  type:GET_PARSER_ERROR_LOG_LIST,
  payload:logs
})

export const getParserErrorLogListSuccess = logs =>({
  type:GET_PARSER_ERROR_LOG_LIST_SUCCESS,
  payload:logs
})

export const getParserErrorLogListFail = logs =>({
  type:GET_PARSER_ERROR_LOG_LIST_FAIL,
  payload:logs
})

export const getParserExecutionLogList = logs =>({
  type:GET_PARSER_EXECUTION_LOG_LIST,
  payload:logs
})

export const getParserExecutionLogListSuccess = logs =>({
  type:GET_PARSER_EXECUTION_LOG_LIST_SUCCESS,
  payload:logs
})

export const getParserExecutionLogListFail = logs =>({
  type:GET_PARSER_EXECUTION_LOG_LIST_FAIL,
  payload:logs
})

export const getPlacerErrorLogList = logs =>({
  type:GET_PLACER_ERROR_LOG_LIST,
  payload:logs
})

export const getPlacerErrorLogListSuccess = logs =>({
  type:GET_PLACER_ERROR_LOG_LIST_SUCCESS,
  payload:logs
})


export const getPlacerErrorLogListFail = logs =>({
  type:GET_PLACER_ERROR_LOG_LIST_FAIL,
  payload:logs
})


export const getPlacerExecutionLogList = logs =>({
  type:GET_PLACER_EXECUTION_LOG_LIST,
  payload:logs
})

export const getPlacerExecutionLogListSuccess = logs =>({
  type:GET_PLACER_EXECUTION_LOG_LIST_SUCCESS,
  payload:logs
})


export const getPlacerExecutionLogListFail = logs =>({
  type:GET_PLACER_EXECUTION_LOG_LIST_FAIL,
  payload:logs
})

export const setWhatAppLog = logs =>({
  type:SET_WHATSAPP_LOG,
  payload:logs
})

export const setWhatAppLogSuccess = logs =>({
  type:SET_WHATSAPP_LOG_SUCCESS,
  payload:logs
})

export const setWhatAppLogFail = logs =>({
  type:SET_WHATSAPP_LOG_FAIL,
  payload:logs
})


export const getSyncCheckerExecutionLogList = logs =>({
  type:GET_SYNC_CHECKER_EXECUTION_LOG_LIST,
  payload:logs
})

export const getSyncCheckerExecutionLogListSuccess = logs =>({
  type:GET_SYNC_CHECKER_EXECUTION_LOG_LIST_SUCCESS,
  payload:logs
})

export const getSyncCheckerExecutionLogListFail = logs =>({
  type:GET_SYNC_CHECKER_EXECUTION_LOG_LIST_FAIL,
  payload:logs
})

export const getSyncCheckerErrorLogList = logs =>({
  type:GET_SYNC_CHECKER_ERROR_LOG_LIST,
  payload:logs
})

export const getSyncCheckerErrorLogListSuccess = logs =>({
  type:GET_SYNC_CHECKER_ERROR_LOG_LIST_SUCCESS,
  payload:logs
})

export const getSyncCheckerErrorLogListFail = logs =>({
  type:GET_SYNC_CHECKER_ERROR_LOG_LIST_FAIL,
  payload:logs
})