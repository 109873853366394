import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import {
    CardTitle,
    Col,
    Table,
} from "reactstrap"
import { map } from 'lodash'

const SellingPlatform = (props) => {




    const [parsedItem, setParsedItem] = useState({})

    useEffect(() => {
        var parsedItem = JSON.parse(props.purchasedItems ? props.purchasedItems : "null")
        setParsedItem(parsedItem)
    }, [])




    return (
        <>

            {parsedItem.U_UnticketedAirSegment &&

                <Col lg={12}>

                    <CardTitle style={{ background: "#EAEFF5", padding: "1px", }}>{props.t("FLIGHT DETAILS")}
                    
                    </CardTitle>

                    <Table responsive size="sm" style={{ "textAlign": 'left' }}>
<tbody>
                        <tr>
                            <th scope="col"><small><b>{props.t("FLIGHT NO")}</b></small></th>
                            <th scope="col"><small><b>{props.t("DEPARTURE")}</b></small></th>
                            <th scope="col"><small><b>{props.t("DEPARTURE DATE-TIME")}</b></small></th>
                            <th scope="col"><small><b>{props.t("ARRIVAL")}</b></small></th>
                            <th scope="col"><small><b>{props.t("ARRIVAL DATE-TIME")}</b></small></th>
                            <th scope="col"><small><b>{props.t("FLIGHT DURATION")}</b></small></th>
                            <th scope="col"><small><b>{props.t("NO OF STOPS")}</b></small></th>
                        </tr>


                        {map(parsedItem.U_UnticketedAirSegment, (item, index) =>
                            <tr key={index}>
                                <td><small> {item.AIRLINE_CODE} - {item.FLIGHT_NUMBER}</small></td>
                                <td><small> {item.ORIGIN_CITY_AIRPORT_NAME} - {item.ORIGIN_COUNTRY_CODE}</small></td>
                                <td><small>{item.DEPARTURE_DATE} - {item.DEPARTURE_TIME}</small></td>
                                <td><small>{item.DESTINATION_CITY_AIRPORT_NAME}-  {item.DESTINATION_COUNTRY_CODE}</small></td>
                                <td><small>{item.ARRIVAL_DATE} - {item.ARRIVAL_TIME}</small></td>
                                <td><small>{item.FLIGHT_DURATION_TIME}</small></td>
                                <td><small> {item.NUMBER_OF_STOPS}</small></td>
                            </tr>
                        )}
</tbody>
                    </Table>

                </Col>
            }

            {parsedItem.I_Name &&

                <Col lg={12}>

                    <CardTitle style={{ background: "#EAEFF5", padding: "1px", }}> {props.t("PASSENGER DETAILS")} <small> (REFERENCE : <b>{parsedItem.AMD_AirRecordHeader.SYSTEM_PNR_LOCATOR}</b>, EMAIL :  </small>
                    
                    {map(parsedItem.RM_PnrRemarks,(remarks, index) =>
                               {
                                try{
                                    if(remarks.REMARK_TEXT.includes("*CUSEML:"))
                                    return <small><b>{remarks.REMARK_TEXT.replace("*CUSEML:","")} </b> )</small>
                                }catch{}
                               }  
                        )}
                    
                    </CardTitle>


                    <Table responsive size="sm" style={{ "textAlign": 'left' }}>
                        <tbody>
                        <tr>
                            <th scope="col"><small><b> {props.t("TITLE")}</b></small></th>
                            <th scope="col"><small><b> {props.t("NAME")}</b></small></th>
                            <th scope="col"><small><b> {props.t("MOBILE")}</b></small></th>
                          

                        </tr>

                        {map(parsedItem.I_Name, (passenger, index) =>
                            <tr key={index}>
                                <td><small> {passenger.PASSENGER_NAME_TITLE}</small></td>
                                <td><small> {passenger.PASSENGER_FIRST_NAME} {passenger.PASSENGER_LAST_NAME}</small></td>
                                <td><small>{passenger.PHONE_FIELD_DATA}</small></td>
                             
                            </tr>
                        )}
</tbody>
                    </Table>


                </Col>
            }

        </>
    )
}

SellingPlatform.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(SellingPlatform))
