import React, { useContext, useState, useEffect } from "react"
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { PaymentMethodContext } from "../../index"
import Iframe from 'react-iframe'

const ApplePayEmbedded = props => {
  const {apsApplePayRedirect, paymentRequestDetails} = props



  return (
    <>
      <div className="">
      <Iframe url={apsApplePayRedirect.applepayWebsite+"/v2/"+paymentRequestDetails.paymentRequestId}
        width="100%"
        height="10%"
        id=""
        className=""
        display="block"
        position="relative"
        scrolling="no"
        styles={{border:0}}/>
      </div>
    </>
  )
}

ApplePayEmbedded.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(ApplePayEmbedded))
