import React, { useState, useEffect } from "react"
import { Col, Row, Button, Spinner, Form, Label } from "reactstrap"
import {
  getPaymentRequestList,
  getCustomerList,
  getCustomersByMerchantID,
  mainSearchListReset,
  getUserList,
} from "../../store/actions.js"
import AuthUser from "components/AuthUser/AuthUser"
import { useDispatch } from "react-redux"
import { IsEmpty } from "react-lodash"
import PaymentRequestColumns from "./Table-Columns/PaymentRequestColumns"
import CustomerColumns from "./Table-Columns/CustomerColumns"
import UserColumns from "./Table-Columns/UserColumns"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { connect } from "react-redux"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import { isEmpty } from "lodash"

function Table(props) {
  const _ = require("lodash")
  const dispatch = useDispatch()
  const [user, setUser] = useState(AuthUser())
  const [prLoading, setPRLoading] = useState(props.prLoading)
  const [customerLoading, setCustomerLoading] = useState(props.customerLoading)
  const [payreqList, setPayReqLists] = useState([])
  const [customerList, setCustomerList] = useState([])
  const [usersList, setUsersList] = useState([])
  const [usersListUnFiltered, setUsersListUnFiltered] = useState([])
  const [userLoading, setUserLoading] = useState(props.userLoading)
  const [search, setSearch] = useState()
  const [filter, setFilter] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [prList, setPRList] = useState([])
  let searchText = ""
  const pageOptionsPR = {
    sizePerPage: 4,
    totalSize: payreqList.length, // replace later with size(customerList),
    custom: true,
    onPageChange: (page, sizePerPage) => {
      handleTableChangePR()
    },
  }
  const pageOptionsCustomer = {
    sizePerPage: 5,
    totalSize: customerList.length, // replace later with size(customerList),
    custom: true,
    onPageChange: (page, sizePerPage) => {
      handleTableChangeCustomer()
    },
  }
  const pageOptionsUser = {
    sizePerPage: 5,
    totalSize: usersList.length, // replace later with size(customerList),
    custom: true,
    onPageChange: (page, sizePerPage) => {
      handleTableChangeUser()
    },
  }
  const { SearchBar } = Search

  useEffect(() => {
    dispatch(getUserList())
    UserRolePR()
    UserRoleCustomer()
  }, [])

  useEffect(() => {
    if (props.searchPrefix !== undefined) {
      setFilter(props.searchPrefix)
    }
  }, [props.searchPrefix])

  useEffect(() => {}, [filter])

  useEffect(() => {
    //console.log(props.payreqList)
    if (Array.isArray(props.payreqList) && props.payreqList?.length !== 0) {
      setPayReqLists(props.payreqList)
      setPRList(props.payreqList)
    }
  }, [props.payreqList])

  useEffect(() => {
    //console.log(prList)
  }, [prList])

  useEffect(() => {
    if (Array.isArray(props.customersList)) {
      setCustomerList(props.customersList)
    }
  }, [props.customersList])

  useEffect(() => {
    if (Array.isArray(props.userList)) {
      if (user.role !== "SuperAdmin") {
        setUsersList(
          props.userList.filter(
            users =>
              users.firstName !== "SELLCO USER" &&
              users.merchantId === user.merchantId
          )
        )
        setUsersListUnFiltered(
          props.userList.filter(
            users =>
              users.firstName !== "SELLCO USER" &&
              users.merchantId === user.merchantId
          )
        )
      } else {
        setUsersList(props.userList)
        setUsersListUnFiltered(props.userList)
      }
    }
  }, [props.userList])

  useEffect(() => {
    if (props.userLoading !== undefined) {
      setUserLoading(props.userLoading)
    }
  }, [props.userLoading])

  useEffect(() => {}, [userLoading])

  useEffect(() => {
    if (props.prLoading !== undefined) {
      setPRLoading(props.prLoading)
    }
  }, [props.prLoading])

  useEffect(() => {}, [prLoading])

  useEffect(() => {
    if (props.customerLoading !== undefined) {
      setCustomerLoading(props.customerLoading)
    }
  }, [props.customerLoading])

  useEffect(() => {}, [customerLoading])

  useEffect(() => {
    if (props.searchValue !== undefined) {
      setSearch(props.searchValue)
    }
  }, [props.searchValue])

  useEffect(() => {
    handleSearchFilter(search)
  }, [search])

  useEffect(() => {
    // console.log(props.dateRange)
    if (props.dateRange !== undefined) {
      handleFilterActions(props.dateRange)
    } else {
      handleFilterActions([])
    }
  }, [props.dateRange])

  useEffect(() => {
    if (props.resetList === "Reset") {
      handleResetList(filter)
      dispatch(mainSearchListReset(""))
    }
  }, [props.resetList])

  useEffect(() => {
    // console.log(payreqList)
  }, [payreqList])

  useEffect(() => {}, [customerList])

  useEffect(() => {}, [usersList])

  useEffect(() => {}, [usersListUnFiltered])
  //Filter: Multi Selection & Date for Table
  function handleFilterActions(selectedDateRange) {
    let prs = []
    switch (filter) {
      case "TRXN":
        prs = payreqList
        if (isEmpty(selectedDateRange)) {
          prs = payreqList
        }
        if (!isEmpty(selectedDateRange) && selectedDateRange != null) {
          const dateFrom = Date.parse(selectedDateRange[0])
          const dateNext = dateFrom + 86400000
          //if two dates
          if (selectedDateRange[1]) {
            prs = prList
            const dateTo = Date.parse(selectedDateRange[1])
            const dateToAdded = dateTo + 86400000

            prs = prs.filter(pr => {
              return (
                Date.parse(pr.paymentRequestDate) >= dateFrom &&
                Date.parse(pr.paymentRequestDate) <= dateToAdded
              )
            })
          } else {
            prs = prs.filter(pr => {
              return (
                Date.parse(pr.paymentRequestDate) >= dateFrom &&
                Date.parse(pr.paymentRequestDate) <= dateNext
              )
            })
          }
        }

        setPayReqLists(prs)
        break
      case "CUST":
        let cus = customerList
        if (isEmpty(selectedDateRange)) {
          cus = customerList
        }
        if (!isEmpty(selectedDateRange) && selectedDateRange != null) {
          const dateFrom = Date.parse(selectedDateRange[0])
          const dateNext = dateFrom + 86400000
          //if two dates
          if (selectedDateRange[1]) {
            cus = props.customersList
            const dateTo = Date.parse(selectedDateRange[1])
            const dateToAdded = dateTo + 86400000

            cus = cus.filter(cus => {
              return (
                Date.parse(cus.registrationDate) >= dateFrom &&
                Date.parse(cus.registrationDate) <= dateToAdded
              )
            })
          } else {
            cus = cus.filter(cus => {
              return (
                Date.parse(cus.registrationDate) >= dateFrom &&
                Date.parse(cus.registrationDate) <= dateNext
              )
            })
          }
        }

        setCustomerList(cus)
        break
      case "USER":
        let user = usersList
        if (isEmpty(selectedDateRange)) {
          user = usersList
        }
        if (!isEmpty(selectedDateRange) && selectedDateRange != null) {
          const dateFrom = Date.parse(selectedDateRange[0])
          const dateNext = dateFrom + 86400000
          //if two dates
          if (selectedDateRange[1]) {
            user = usersListUnFiltered
            const dateTo = Date.parse(selectedDateRange[1])
            const dateToAdded = dateTo + 86400000

            user = user.filter(user => {
              return (
                Date.parse(user.registrationDate) >= dateFrom &&
                Date.parse(user.registrationDate) <= dateToAdded
              )
            })
          } else {
            user = user.filter(user => {
              return (
                Date.parse(user.registrationDate) >= dateFrom &&
                Date.parse(user.registrationDate) <= dateNext
              )
            })
          }
        }

        setUsersList(user)
        break
    }
  }

  const UserRoleCustomer = () => {
    if (user.role !== "SuperAdmin") {
      const data = {
        type: "all",
        merchantId: user.merchantId,
      }
      setCustomerLoading(props.loading)
      dispatch(getCustomersByMerchantID(data))
    }
    if (user.role === "SuperAdmin") {
      dispatch(getCustomerList())
    }
  }

  const UserRolePR = () => {
    if (user && user.role === "SuperAdmin") {
      dispatch(getPaymentRequestList("SuperAdmin"))
    } else {
      if (user.merchantId) {
        dispatch(getPaymentRequestList(user.merchantId))
      }
    }
  }

  const handleTableChangePR = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setPayReqLists(
        props.payreqList.filter(payreq =>
          Object.keys(payreq).some(key =>
            payreq[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }

  const handleTableChangeCustomer = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setCustomerList(
        props.customersList.filter(customer =>
          Object.keys(customer).some(key =>
            customer[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }
  const handleTableChangeUser = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setUsersList(
        props.userList.filter(user =>
          Object.keys(customer).some(key =>
            user[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }

  //Actions
  const handleOpenOverview = index => {
    // var pr = prsReversed[index];
    var pr = payreqList[index]
    const link = "/paymentrequest/overview/" + pr._id
    window.open(link, "_blank")
  }

  const handleCopyLink = index => {
    const pr = payreqList[index]
    const shortLink = pr.generatedLinkShort
    copy(shortLink)
    showToast(shortLink)
  }

  const handleResendEmail = index => {
    const pr = payreqList[index]
    setSelectedPrRow(pr)
    toggleModal()
  }

  const showToast = link => {
    toastr.options = {
      positionClass: "toast-top-right",
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }
    toastr.success(link, "Link Copied!")
  }

  const handleSearchFilter = search => {
    if (search !== undefined && filter === "TRXN") {
      setPayReqLists(
        payreqList.filter(
          pr =>
            pr.merchantDetails.merchantName
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            parseFloat(pr.amount) >= parseFloat(search) ||
            parseFloat(pr.amount) === parseFloat(search) ||
            pr.createdBy.toLowerCase().includes(search.toLowerCase()) ||
            pr.currency.toLowerCase().includes(search.toLowerCase()) ||
            pr.customerDetails.email
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            pr.customerDetails.mobile
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            pr.customerDetails.name
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            pr.generatedLinkStatus
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            pr.itemSource.toLowerCase().includes(search.toLowerCase()) ||
            pr.paymentRequestId.toLowerCase().includes(search.toLowerCase()) ||
            pr.paymentRequestSettings.language
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            pr.paymentRequestSettings.paymentType
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            // pr.paymentRequestSettings.terminalId
            //   .toLowerCase()
            //   .includes(search.toLowerCase()) ||
            pr.paymentStatus.toLowerCase().includes(search.toLowerCase()) ||
            pr.userDetails.email.toLowerCase().includes(search.toLowerCase()) ||
            pr.userDetails.userName.toLowerCase().includes(search.toLowerCase())
        )
      )
    }
    if (search !== undefined && filter === "CUST") {
      setCustomerList(
        customerList.filter(
          cus =>
            // console.log(cus.name.toLowerCase())
            cus.merchantID.toLowerCase().includes(search.toLowerCase()) ||
            cus.name.toLowerCase().includes(search.toLowerCase()) ||
            cus.email.toLowerCase().includes(search.toLowerCase()) ||
            cus.mobile.toLowerCase().includes(search.toLowerCase()) ||
            cus.type.toLowerCase().includes(search.toLowerCase()) ||
            cus.isActive.toString().toLowerCase().includes(search.toLowerCase())
        )
      )
    }

    if (search !== undefined && filter === "USER") {
      setUsersList(
        usersList.filter(
          user =>
            user.department?.toLowerCase().includes(search.toLowerCase()) ||
            user.email?.toLowerCase().includes(search.toLowerCase()) ||
            user.firstName.toLowerCase().includes(search.toLowerCase()) ||
            user.isActive
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            user.lastName?.toLowerCase().includes(search.toLowerCase()) ||
            user.merchantId?.toLowerCase().includes(search.toLowerCase()) ||
            user.mobile?.toLowerCase().includes(search.toLowerCase()) ||
            user.role.toLowerCase().includes(search.toLowerCase()) ||
            user.title?.toLowerCase().includes(search.toLowerCase()) ||
            user.userId.toLowerCase().includes(search.toLowerCase()) ||
            user.userName.toLowerCase().includes(search.toLowerCase())
        )
      )
    }
    if (search === "") {
      if (prList !== undefined) {
        setPayReqLists(prList)
      }
      if (props.customersList !== undefined) {
        setCustomerList(props.customersList)
      }
      if (props.userList !== undefined) {
        setUsersList(usersListUnFiltered)
      }
    }
  }

  const handleResetList = prefixFilter => {
    switch (prefixFilter) {
      case "TRXN":
        setPayReqLists(prList)
        break
      case "USER":
        setUsersList(usersListUnFiltered)
        break
      case "CUST":
        setCustomerList(props.customersList)
        break
      default:
        setPayReqLists(prList)
    }
  }

  return (
    <div className="mx-auto my-auto">
      {filter === "TRXN" ? (
        prLoading ? (
          <Row className="spinner-custom mx-auto my-auto pb-3">
            <LoadingSpinnerThreeDots />
          </Row>
        ) : (
          <IsEmpty
            value={payreqList}
            yes={() => <>No List</>}
            no={() => (
              <PaginationProvider pagination={paginationFactory(pageOptionsPR)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={payreqList || []}
                    columns={PaymentRequestColumns(
                      handleOpenOverview,
                      handleCopyLink,
                      handleResendEmail,
                      user
                    )}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          {prLoading ? (
                            <Row className="spinner-custom mx-auto my-auto pb-3">
                              <LoadingSpinnerThreeDots />
                            </Row>
                          ) : (
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  responsive
                                  bordered={false}
                                  hover={true}
                                  striped={false}
                                  classes={
                                    "table table-centered pointer table-nowrap table-hover input-text-size"
                                  }
                                  //rowEvents={rowEvents}
                                  keyField="_id"
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChangePR(
                                    searchText
                                  )}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          />
        )
      ) : null}

      {filter === "CUST" ? (
        customerLoading ? (
          <Row className="spinner-custom mx-auto my-auto pb-3">
            <LoadingSpinnerThreeDots />
          </Row>
        ) : (
          <IsEmpty
            value={customerList}
            yes={() => <>No List</>}
            no={() => (
              <PaginationProvider
                pagination={paginationFactory(pageOptionsCustomer)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={customerList || []}
                    columns={CustomerColumns(user)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          {customerLoading ? (
                            <Row className="spinner-custom mx-auto my-auto pb-3">
                              <LoadingSpinnerThreeDots />
                            </Row>
                          ) : (
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  responsive
                                  bordered={false}
                                  hover={true}
                                  striped={false}
                                  classes={
                                    "table table-centered pointer table-nowrap table-hover input-text-size"
                                  }
                                  //rowEvents={rowEvents}
                                  keyField="_id"
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChangeCustomer(
                                    searchText
                                  )}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          />
        )
      ) : null}

      {filter === "USER" ? (
        userLoading ? (
          <Row className="spinner-custom mx-auto my-auto pb-3">
            <LoadingSpinnerThreeDots />
          </Row>
        ) : (
          <IsEmpty
            value={usersList}
            yes={() => <>No List</>}
            no={() => (
              <PaginationProvider
                pagination={paginationFactory(pageOptionsUser)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={usersList || []}
                    columns={UserColumns(user)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive
                                bordered={false}
                                hover={true}
                                striped={false}
                                classes={
                                  "table table-centered pointer table-nowrap table-hover mr-5 mb-5"
                                }
                                // rowEvents={rowEvents}
                                keyField="_id"
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChangeUser(
                                  searchText
                                )}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          />
        )
      ) : null}
    </div>
  )
}
const mapStateToProps = state => {
  return {
    prLoading: state.paymentRequests.loading,
    payreqList: state.paymentRequests.paymentRequestList,
    searchValue: state.MainSearch.search,
    searchPrefix: state.MainSearch.filter,
    dateRange: state.MainSearch.dates,
    resetList: state.MainSearch.list,
    customersList: state.Customer.customerList,
    customerLoading: state.Customer.loading,
    userLoading: state.Users.loading,
    userList: state.Users.usersList,
  }
}
export default connect(mapStateToProps)(Table)
