
/* LOGS_TRANSACTION_UPDATE*/
export const GET_TRANSACTION_UPDATE_LOGS_LIST = "GET_TRANSACTION_UPDATE_LOGS_LIST"
export const GET_TRANSACTION_UPDATE_LOGS_LIST_SUCCESS = "GET_TRANSACTION_UPDATE_LOGS_LIST_SUCCESS"
export const GET_TRANSACTION_UPDATE_LOGS_LIST_FAIL = "GET_TRANSACTION_UPDATE_LOGS_LIST_FAIL"

/* LOGS_TRANSACTION_UPDATE*/
export const UPDATE_TRANSACTION_LOG = "UPDATE_TRANSACTION_LOG"
export const UPDATE_TRANSACTION_LOG_SUCCESS = "UPDATE_TRANSACTION_LOG_SUCCESS"
export const UPDATE_TRANSACTION_LOG_FAIL = "UPDATE_TRANSACTION_LOG_FAIL"


export const GET_USER_LOGS="GET_USER_LOGS"
export const GET_USER_LOGS_SUCCESS="GET_USER_LOGS_SUCCESS"
export const GET_USER_LOGS_FAIL="GET_USER_LOGS_FAIL"

export const GET_USER_LOGS_LIST="GET_USER_LOGS_LIST"
export const GET_USER_LOGS_LIST_SUCCESS="GET_USER_LOGS_LIST_SUCCESS"
export const GET_USER_LOGS_LIST_FAIL ="GET_USER_LOGS_LIST_FAIL"

export const GET_EMAIL_LOGS_LIST="GET_EMAIL_LOGS_LIST"
export const GET_EMAIL_LOGS_LIST_SUCCESS="GET_EMAIL_LOGS_LIST_SUCCESS"
export const GET_EMAIL_LOGS_LIST_FAIL="GET_EMAIL_LOGS_LIST_FAIL"

export const GET_ERROR_LOGS_LIST="GET_ERROR_LOGS_LIST"
export const GET_ERROR_LOGS_LIST_SUCCESS ="GET_ERROR_LOGS_LIST_SUCCESS"
export const GET_ERROR_LOGS_LIST_FAIL ="GET_ERROR_LOGS_LIST_FAIL"

export const GET_PARSER_ERROR_LOG_LIST="GET_PARSER_ERROR_LOG_LIST"
export const GET_PARSER_ERROR_LOG_LIST_SUCCESS="GET_PARSER_ERROR_LOG_LIST_SUCCESS"
export const GET_PARSER_ERROR_LOG_LIST_FAIL ="GET_PARSER_ERROR_LOG_LIST_FAIL"

export const GET_PARSER_EXECUTION_LOG_LIST="GET_PARSER_EXECUTION_LOG_LIST"
export const GET_PARSER_EXECUTION_LOG_LIST_SUCCESS="GET_PARSER_EXECUTION_LOG_LIST_SUCCESS"
export const GET_PARSER_EXECUTION_LOG_LIST_FAIL ="GET_PARSER_EXECUTION_LOG_LIST_FAIL"

export const GET_PLACER_ERROR_LOG_LIST="GET_PLACER_ERROR_LOG_LIST"
export const GET_PLACER_ERROR_LOG_LIST_SUCCESS="GET_PLACER_ERROR_LOG_LIST_SUCCESS"
export const GET_PLACER_ERROR_LOG_LIST_FAIL ="GET_PLACER_ERROR_LOG_LIST_FAIL"

export const GET_PLACER_EXECUTION_LOG_LIST="GET_PLACER_EXECUTION_LOG_LIST"
export const GET_PLACER_EXECUTION_LOG_LIST_SUCCESS="GET_PLACER_EXECUTION_LOG_LIST_SUCCESS"
export const GET_PLACER_EXECUTION_LOG_LIST_FAIL ="GET_PLACER_EXECUTION_LOG_LIST_FAIL"

export const SET_WHATSAPP_LOG="SET_WHATSAPP_LOG"
export const SET_WHATSAPP_LOG_SUCCESS="SET_WHATSAPP_LOG_SUCCESS"
export const SET_WHATSAPP_LOG_FAIL="SET_WHATSAPP_LOG_FAIL"


export const GET_SYNC_CHECKER_EXECUTION_LOG_LIST="GET_SYNC_CHECKER_EXECUTION_LOG_LIST"
export const GET_SYNC_CHECKER_EXECUTION_LOG_LIST_SUCCESS="GET_SYNC_CHECKER_EXECUTION_LOG_LIST_SUCCESS"
export const GET_SYNC_CHECKER_EXECUTION_LOG_LIST_FAIL ="GET_SYNC_CHECKER_EXECUTION_LOG_LIST_FAIL"

export const GET_SYNC_CHECKER_ERROR_LOG_LIST="GET_SYNC_CHECKER_ERROR_LOG_LIST"
export const GET_SYNC_CHECKER_ERROR_LOG_LIST_SUCCESS="GET_SYNC_CHECKER_ERROR_LOG_LIST_SUCCESS"
export const GET_SYNC_CHECKER_ERROR_LOG_LIST_FAIL ="GET_SYNC_CHECKER_ERROR_LOG_LIST_FAIL"

// RETURN STATE

export const RETURN_STATE = "RETURN_STATE"