import React from 'react'
import {  Container  } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EmailLogsTable from "./EmailLogsTable"
function index() {
    return (
       
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Logs " breadcrumbItem=" EMAIL NOTIFICATIONS" />
                    <EmailLogsTable/>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default index
