import React, { useState, useEffect } from "react"
import { Col, Row, Card, CardBody, CardTitle, Form, } from "reactstrap"
import Dropzone from "react-dropzone"
import { useForm } from "react-hook-form";
import { connect } from "react-redux"

const initialValues = {
  SubjectLine: "Request for payment to ",
  Attachment: "",
  Name: "",
  customerSearchorCreated: ""
}

const FormThirdPage = props => {
  //Constants----------------------------------------------------------------------------------
  const [{ SubjectLine, Attachment, customerSearchorCreated, Name, createdCustomer }, setPaymentRequest] = useState({
    SubjectLine: "Request for payment to ",
    Attachment: "",
    createdCustomer: false,
    customerSearchorCreated: props.customerSearchorCreated
  })
  const [selectedFiles, setSelectedFiles] = useState([])
  const { register, handleSubmit, errors, reset } = useForm();
  //UseEffects--------------------------------------------------------------------------------
  //Use Effect for clearing data
  useEffect(() => {
    if (!props.Name) {
      reset(initialValues)
      setPaymentRequest(formRequest => ({
        ...formRequest,
        SubjectLine: "Request for payment to ",
        Attachment: "",
        Name: ""
      }))
      setSelectedFiles([])
    }
  }, [props.Name])

  //Use Effect for updating Name
  useEffect(() => {
    setPaymentRequest(formRequest => ({
      ...formRequest,
      Name: props.Name,
      createdCustomer: props.customerCreated
    }))
  }, [props.Name, props.customerCreated])

  //Use Effect for joining subjectLine with customer name
  useEffect(() => {
    if (createdCustomer === true && Name !== "") {
      setPaymentRequest(formRequest => (
        {
          ...formRequest,
          SubjectLine: "Request for payment to " + Name
        }
      ));
      props.onChange(formRequest => (
        {
          ...formRequest,
          SubjectLine: "Request for payment to " + Name
        }
      ));

    }

    if (props.customerSearchorCreated === "Searched") {
      setPaymentRequest(formRequest => (
        {
          ...formRequest,
          SubjectLine: "Request for payment to " + Name
        }
      ));
      props.onChange(formRequest => (
        {
          ...formRequest,
          SubjectLine: "Request for payment to " + Name
        }
      ));
    }
  }, [Name, createdCustomer, props.customerSearchorCreated])

  //Use effect setting file attachment to props
  useEffect(() => {
    if (selectedFiles != null) {
      props.onChange(formRequest => ({
        ...formRequest,
        Attachment: selectedFiles
      }))
    }
  }, [selectedFiles])
  //Functions----------------------------------------------------------------------------------
  //Function for accepted files to attachment
  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(files)
  }

  //Function for setting data state changes for this component and props
  const storeDataOnForm = (event) => {
    const { name, value } = event.target;
    setPaymentRequest(formRequest => (
      { ...formRequest, [name]: value }
    ));
    props.onChange(formRequest => (
      { ...formRequest, [name]: value }
    ));
  };

  //Function for setting bytes of file attachment
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  //For form validation, this is needed
  const onSubmit = (data) => {
  }

  return (
    <div onChange={handleSubmit(onSubmit)}>
      <fieldset>
        <Row className="form-group mx-1">
          <Col>
            <label className=" col-form-label">
              EMAIL SUBJECT LINE
               </label>
            <input
              id="SubjectLine"
              name="SubjectLine"
              value={SubjectLine}
              type="text"
              placeholder="Request for payment to "
              className="form-control form-control-lg"
              ref={register({ required: true })}
              onChange={(event) => storeDataOnForm(event, props.setFormRequest)}
            />
            {errors.SubjectLine && errors.SubjectLine.type === "required" && <p className="color-danger">Please enter a subject.</p>}
          </Col>
        </Row>
        <Row className="form-group ">
          <Col>
            <Card>
              <CardBody>
                <CardTitle>
                  <Row>
                    <Col>Attachment</Col>
                    <Col>
                      <button
                        onClick={() => {
                          setSelectedFiles([])
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </Col>
                  </Row> </CardTitle>
                <Form>
                  <Dropzone
                    maxFiles={3}
                    maxSize={1e+7}
                    onDrop={acceptedFiles =>
                      handleAcceptedFiles(acceptedFiles)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h4>Drop files here or click to upload.<br />(Only 3 files with 10 MB size are allowed)</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div
                    className="dropzone-previews mt-3"
                    id="file-previews"
                  >
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                {f.name}
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    })}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </fieldset>

    </div>
  )
}


const mapStateToProps = state => {
  return {
    customer: state.Customer.customer,
    customerCreated: state.Customer.customerCreated
  }
}


export default connect(mapStateToProps)(FormThirdPage)