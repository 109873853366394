import { call, put, takeEvery, takeLatest, take } from "redux-saga/effects"
import {
  setCustomer,
  getCustomerList,
  getCustomerDetail,
  updateCustomer,
  getCustomerListByMerchantID,
  delCustomer,
} from "helpers/fakebackend_helper"

import {
  SET_CUSTOMER,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_DETAIL,
  UPDATE_CUSTOMER,
  GET_CUSTOMERS_BY_MERCHANTID,
  UPDATE_CUSTOMER_EXPRESS,
  SET_CUSTOMER_EXPRESS,
  //TODO:change here
  DELETE_CUSTOMER,
  SELECT_CUSTOMER,
} from "./actionTypes"

import {
  getCustomerDetailSuccess,
  getCustomerDetailFail,
  setCustomerSuccess,
  setCustomerFail,
  getCustomerListSuccess,
  getCustomerListFail,
  updateCustomerSuccess,
  updateCustomerFail,
  updateCustomerExpressSuccess,
  updateCustomerExpressFail,
  //TODO:change here
  deleteCustomerSuccess,
  deleteCustomerFail,
  restartState,
  selectCustomerSuccess,
  selectCustomerFail,
  setCustomerExpressSuccess,
  setCustomerExpressFail,
} from "./action"
import { connect } from "react-redux"

function* _getCustomerList() {
  try {
    const response = yield call(getCustomerList)

    if (response) {
      if (response?.isSuccess) yield put(getCustomerListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getCustomerListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getCustomerListFail(response.message))
      }
    } else yield put(getCustomerListFail({ message: "Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(getCustomerListFail(error?.message))
  }
}

function* _getCustomerListByMerchantID({ payload: customer }) {
  try {
    const response = yield call(getCustomerListByMerchantID, customer)

    if (response) {
      if (response?.isSuccess) yield put(getCustomerListSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getCustomerListFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getCustomerListFail(response.message))
      }
    } else
      yield put(
        getCustomerListFail({ message: customer + " Invalid Response." })
      )

    yield put(restartState())
  } catch (error) {
    yield put(getCustomerListFail(error?.message))
  }
}

function* _getCustomerDetail({ payload: customer }) {
  try {
    const response = yield call(getCustomerDetail, customer)

    if (response) {
      if (response?.isSuccess)
        yield put(getCustomerDetailSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getCustomerDetailFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getCustomerDetailFail(response.message))
      }
    } else
      yield put(
        getCustomerDetailFail({ message: customer._id + " Invalid Response." })
      )

    yield put(restartState())
  } catch (error) {
    yield put(getCustomerDetailFail(error?.message))
  }
}

function* _setCustomer({ payload: customer }) {
  try {
    const response = yield call(setCustomer, customer)

    if (response) {
      if (response?.isSuccess) yield put(setCustomerSuccess(true))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setCustomerFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setCustomerFail(false))
      }
    } else yield put(setCustomerFail({ message: "Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(setCustomerFail(error?.message))
  }
}
function* _setCustomerExpress({ payload: customer }) {
  try {
    const response = yield call(setCustomer, customer)

    if (response) {
      if (response?.isSuccess) yield put(setCustomerExpressSuccess(true))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setCustomerExpressFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(setCustomerExpressFail(false))
      }
    } else yield put(setCustomerExpressFail({ message: "Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(setCustomerExpressFail(error?.message))
  }
}
function* _updateCustomer({ payload: customer }) {
  try {
    const response = yield call(updateCustomer, customer)

    if (response) {
      if (response?.isSuccess) yield put(updateCustomerSuccess(true))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              updateCustomerFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(updateCustomerFail(response.message))
      }
    } else yield put(updateCustomerFail({ message: "Invalid Response." }))

    yield put(restartState())
  } catch (error) {
    yield put(updateCustomerFail(error?.message))
  }
}

function* _updateCustomerExpress({ payload: customer }) {
  try {
    const response = yield call(updateCustomer, customer)

    if (response) {
      if (response?.isSuccess) yield put(updateCustomerExpressSuccess(true))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              updateCustomerExpressFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(updateCustomerExpressFail(response.message))
      }
    } else
      yield put(
        updateCustomerExpressFail({
          message: customer._id + " Invalid Response.",
        })
      )

    yield put(restartState())
  } catch (error) {
    yield put(updateCustomerExpressFail(error?.message))
  }
}
function* _delCustomer({ payload: customer }) {
  try {
    const response = yield call(delCustomer, customer)

    if (response) {
      if (response?.isSuccess) yield put(deleteCustomerSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              deleteCustomerFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(deleteCustomerFail(response.message))
      }
    } else
      yield put(
        deleteCustomerFail({ message: customer._id + " Invalid Response." })
      )

    yield put(restartState())
  } catch (error) {
    yield put(deleteCustomerFail(error?.message))
  }
}

function* _selectCustomer({ payload: customer }) {
  try {
    if (customer !== undefined) {
      yield put(selectCustomerSuccess(customer))
    } else {
      yield put(selectCustomerFail(customer))
    }
  } catch (error) {
    yield put(selectCustomerFail(error))
  }
}

function* customerSaga() {
  yield takeEvery(SET_CUSTOMER, _setCustomer)
  yield takeEvery(GET_CUSTOMER_LIST, _getCustomerList)
  yield takeEvery(GET_CUSTOMER_DETAIL, _getCustomerDetail)
  yield takeEvery(UPDATE_CUSTOMER, _updateCustomer)
  yield takeEvery(UPDATE_CUSTOMER_EXPRESS, _updateCustomerExpress)
  yield takeEvery(GET_CUSTOMERS_BY_MERCHANTID, _getCustomerListByMerchantID)
  yield takeEvery(DELETE_CUSTOMER, _delCustomer)
  yield takeEvery(SELECT_CUSTOMER, _selectCustomer)
  yield takeEvery(SET_CUSTOMER_EXPRESS, _setCustomerExpress)
}

export default customerSaga
