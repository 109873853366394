import React from 'react'
import {  Container  } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PayGateTable from "./PayGateTable"
import { ToastContainer } from 'react-toastify';
function index() {
    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>

                <Breadcrumbs title="MANAGE" breadcrumbItem=" PAYMENT GATEWAYS" />
                <PayGateTable />
            </Container>
            <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
        </div>
    </React.Fragment>
    )
}

export default index
