import React from "react"
import {
  UncontrolledTooltip,
  Button
} from "reactstrap"
import { Link } from "react-router-dom"
import dateFormat from '../../helpers/commonhelpers/dateFormat'
import { statusPillsBool } from '../../helpers/commonhelpers/statusPills'

const TransactionUpdateLogsColumns = (handleOpenOverviewPrId) => {

  
  return(
    [
      {
        dataField: "_id",
        text: "Id",
        hidden: true,
      },
  
      {
        dataField: "date",
        text: "Date",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
          return <span>
    
            {dateFormat(cellContent)}
    
          </span>
        }
    
      },
      {
        dataField: "paymentRequestId",
        text: "Payment Request Id",
        searchable: true,
        sort: true,
        formatter: (cellContent, row, rowIndex) => {

          return  <Button  color="primary" outline className="waves-effect" 
          style={{borderColor:"#ffffff00"}}
           onClick={() => handleOpenOverviewPrId(cellContent)}>{cellContent}</Button>

        }
      },
      {
        dataField: "trackId",
        text: "TrackId",
        searchable: true,
        sort: true,
      },
      {
        dataField: "paymentHistoryId",
        text: "Payment History Id",
        searchable: true,
        sort: true,
      },
      {
        dataField: "transId",
        text: "TransId",
        searchable: true,
        sort: true,
      },
     
      {
        dataField: "response",
        text: "Response",
        searchable: true,
        sort: true,
      },
      {
        dataField: "additionalPaymentInfo",
        text: "More Info",
        searchable: true,
        sort: true,
      },
      {
        dataField: "responseCode",
        text: "Response Code",
        searchable: true,
        sort: true,
      },
      {
        dataField: "isRecordUpdated",
        text: "Record Updated",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
    
          if (cellContent === true) {
            return <span>
    
              {statusPillsBool(cellContent, "Done", true)}
    
            </span>
          }
          else {
    
            return <Link to="#" className="mr-3 text-primary">
              <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Update Records
                </UncontrolledTooltip>
            </Link>
    
          }
        }
    
      },
    
      {
        dataField: "isNotificationSent",
        text: "Notification Sent",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
    
          if (cellContent !== null && cellContent === true) {
            return <span>
              {statusPillsBool(cellContent, "Done", true)}
    
            </span>
          }
          else {
    
            return <Link to="#" className="text-danger">
              <i className="mdi mdi-email font-size-18 mr-3" id="emailtooltip" />
              <UncontrolledTooltip placement="top" target="emailtooltip">
                Send Email Notification
                </UncontrolledTooltip>
            </Link>
    
          }
        }
    
      },
    
      {
        dataField: "isApproved",
        text: "Good to Go",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
    
          if (cellContent === true) {
            return <span>
    
              {statusPillsBool(cellContent, "Checked & Approved", true)}
    
            </span>
          }
          else {
    
            return <Link to="#" className="text-danger">
              <i className="mdi mdi-check-bold font-size-18 mr-3" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Approve
                </UncontrolledTooltip>
            </Link>
    
          }
        }
    
      },
    
    
    
    ]


)}
export default TransactionUpdateLogsColumns