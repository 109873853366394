import React, { useState,useContext, useEffect } from "react"
import { connect } from "react-redux"
import { Col, Row, InputGroup, InputGroupAddon, Label } from "reactstrap"
import { useForm } from "react-hook-form";
import { ValuesContext } from "../index"
import FormFirstPageItemsList from "./FormFirstPage-ItemsList"

const initialValues = {
  Amount: parseFloat(1).toFixed(2),
  TransactionReference: "",
  Currency: "QAR",
  PaymentType: "Debit and Credit",
  Language: "EN",
  Remarks: ""
}

const FormFirstPage = props => {
  //Constants----------------------------------------------------------------------------------------------------------
  const values = useContext(ValuesContext);
  const [{ TransactionReference, Amount, Currency,
    PaymentType, Language, Remarks, }, setPaymentRequest] = useState({
      Amount: props.Amount,
      TransactionReference: "",
      Currency: "QAR",
      PaymentType: "Debit and Credit",
      Language: "EN",
      Remarks: ""
    })
  const { register, handleSubmit, errors, reset } = useForm();
  const [merchantServices , setMerchantServices] = useState(props.Merchant)
  const [customchk, setCustomchk] = useState(false);
  const [ItemsList, setItems] = useState([]);
  //UseEffects----------------------------------------------------------------------------------------------------------
  //Use effect for reset values in form. (NEEDS MORE WORK)
  // useEffect(() => {
  //   if (Amount !== 0.01) {
  //     reset(initialValues)
  //     setPaymentRequest(formRequest => ({
  //       ...formRequest,
  //       TransactionReference: "",
  //       Currency: "QAR",
  //       PaymentType: "Debit and Credit",
  //       Language: "EN",
  //       Remarks: ""
  //     }))
  //     setCustomchk(false)
  //     setItems([])
  //   }
  // }, [props.Amount])

  useEffect(()=>{
    if(props.Merchant !== undefined){
      setMerchantServices(props.Merchant.services)
    }
  },[props.Merchant])

  useEffect(()=>{
  },[merchantServices])

  useEffect(() => {
    if (ItemsList.length !==0 && customchk === true) {
      props.onChange(formRequest => ({
        ...formRequest,
        ListItems: ItemsList
      }))
    } else {
      props.onChange(formRequest => ({
        ...formRequest,
        ListItems: []
      }))
    }
  }, [ItemsList, customchk])

  //Functions------------------------------------------------------------------------------------------------------------
  //Function for setting data state changes for this component and props
  const storeDataOnForm = (event) => {
    const { name, value } = event.target;
    setPaymentRequest(formRequest => (
      { ...formRequest, [name]: value }
    ));
    props.onChange(formRequest => (
      { ...formRequest, [name]: value }
    ));
  };

  //For form validation, this is needed
  const onSubmit = (data) => {
  }


  return (
    <form onChange={handleSubmit(onSubmit)}>
      <fieldset>
        {customchk ? 
        <Row>
        <Col lg={4}>
          <Row className="form-group mx-1">
            <Col>
              <InputGroup className="">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text input-text-size">
                    AMOUNT
                                </Label>
                </InputGroupAddon>
                <select className="form-control  custom-select input-text-size"
                  id="Currency"
                  name="Currency"
                  style={{ maxWidth: "90px" }}
                  value={Currency}
                  ref={register({ required: true })}
                  onChange={(event) => storeDataOnForm(event, props.setFormRequest)}>
                  <option>QAR</option>
                  <option>USD</option>
                </select>
                <input
                  id="Amount"
                  name="Amount"
                  placeholder="0.01"
                  step="0.01"
                  min={0}
                  value={Amount}
                  type="number"
                  className="form-control  input-text-size"
                  ref={register({ required: true, min: 0 })}
                  onChange={(event) => storeDataOnForm(event, props.setFormRequest)} />
              </InputGroup>

            </Col>
          </Row>
          <Row className="form-group mx-1">
            <Col>
              <InputGroup className="">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text input-text-size">
                    LANGUAGE
                                </Label>
                </InputGroupAddon>
                <select className="form-control form-control-lg input-text-size"
                  id="Language"
                  name="Language"
                  value={Language}
                  ref={register({ required: true })}
                  onChange={(event) => storeDataOnForm(event, props.setFormRequest)}>
                  <option>EN</option>
                  <option>AR</option>
                </select>
              </InputGroup>
            </Col>
          </Row>
          <Row className="form-group mx-1">
            <Col>
              <InputGroup className="">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text input-text-size">
                    CARD TYPE
                                </Label>
                </InputGroupAddon>
                <select className="form-control form-control-lg input-text-size"
                  id="PaymentType"
                  name="PaymentType"
                  value={PaymentType}
                  ref={register({ required: true })}
                  onChange={(event) => storeDataOnForm(event, props.setFormRequest)}>
                  <option>Debit and Credit</option>
                  <option>Debit</option>
                  <option>Credit</option>
                </select>
              </InputGroup>
            </Col>
          </Row>
          <Row className="form-group mx-1">
            <Col>
              <InputGroup className="">
                <InputGroupAddon addonType="prepend">
                  <Label className="input-group-text input-text-size">
                    TRANSACTION REFERENCE
                                </Label>
                </InputGroupAddon>
                <input
                  id="TransactionReference"
                  name="TransactionReference"
                  placeholder="TR00000"
                  type="text"
                  value={TransactionReference}
                  className="form-control form-control-lg input-text-size"
                  ref={register({ required: false })}
                  onChange={(event) => storeDataOnForm(event, props.setFormRequest)}
                />
              </InputGroup>
            </Col>

          </Row>
          <Row className="form-group mx-1">
            <Col>
            <textarea
            id="Remarks"
            name="Remarks"
            placeholder="Remarks..."
            value={Remarks}
            className="form-control form-control-lg input-text-size"
            rows="5"
            style={{ resize: "none" }}
            ref={register({ required: false })}
            onChange={(event) => storeDataOnForm(event, props.setFormRequest)}
          /></Col>
          </Row>

        </Col>
        <Col lg={8}>
        <div className="custom-control custom-checkbox mb-3 ml-2">
            <input
              type="checkbox"
              className="custom-control-input form-control-lg"
              id="CustomCheck1"
              onChange={() => false}
              name="customchk"
              ref={register({ required: false })}
              checked={customchk}
            />
            <label
              className="custom-control-label "
              onClick={() => {
                setCustomchk(!customchk)
                setPaymentRequest(formRequest => ({
                  ...formRequest,
                  ItemCheck: !customchk
                }))
                props.onChange(formRequest => ({
                  ...formRequest,
                  ItemCheck: !customchk
                }))
              }}
            >
              Check to Add Item Breakdown
          </label>
          </div>
        <div className="form-group bg-color-disable my-auto py-2" hidden={!customchk}>
        <FormFirstPageItemsList
        MerchantServices ={merchantServices}
          customchk={customchk}
          onChange={value => setItems(value)} />

      </div>
         
        </Col>
      </Row>:
      <Row>
      <Col lg={8}>
        <Row className="form-group mx-1">
          <Col>
            <InputGroup className="">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">
                  AMOUNT
                              </Label>
              </InputGroupAddon>
              <select className="form-control  custom-select input-text-size"
                id="Currency"
                name="Currency"
                style={{ maxWidth: "90px" }}
                value={Currency}
                ref={register({ required: true })}
                onChange={(event) => storeDataOnForm(event, props.setFormRequest)}>
                <option>QAR</option>
                <option>USD</option>
              </select>
              <input
                id="Amount"
                name="Amount"
                placeholder="0.01"
                step="0.01"
                min={0}
                value={Amount}
                type="number"
                className="form-control  input-text-size"
                ref={register({ required: true, min: 0 })}
                onChange={(event) => storeDataOnForm(event, props.setFormRequest)} />
            </InputGroup>

          </Col>
        </Row>
        <Row className="form-group mx-1">
          <Col>
            <InputGroup className="">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">
                  LANGUAGE
                              </Label>
              </InputGroupAddon>
              <select className="form-control form-control-lg input-text-size"
                id="Language"
                name="Language"
                value={Language}
                ref={register({ required: true })}
                onChange={(event) => storeDataOnForm(event, props.setFormRequest)}>
                <option>EN</option>
                <option>AR</option>
              </select>
            </InputGroup>
          </Col>
        </Row>
        <Row className="form-group mx-1">
          <Col>
            <InputGroup className="">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">
                  CARD TYPE
                              </Label>
              </InputGroupAddon>
              <select className="form-control form-control-lg input-text-size"
                id="PaymentType"
                name="PaymentType"
                value={PaymentType}
                ref={register({ required: true })}
                onChange={(event) => storeDataOnForm(event, props.setFormRequest)}>
                <option>Debit and Credit</option>
                <option>Debit</option>
                <option>Credit</option>
              </select>
            </InputGroup>
          </Col>
        </Row>
        <Row className="form-group mx-1">
          <Col>
            <InputGroup className="">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">
                  TRANSACTION REFERENCE
                              </Label>
              </InputGroupAddon>
              <input
                id="TransactionReference"
                name="TransactionReference"
                placeholder="TR00000"
                type="text"
                value={TransactionReference}
                className="form-control form-control-lg input-text-size"
                ref={register({ required: false })}
                onChange={(event) => storeDataOnForm(event, props.setFormRequest)}
              />
            </InputGroup>
          </Col>

        </Row>
        <Row className="form-group mx-1">
          <Col>
          <textarea
          id="Remarks"
          name="Remarks"
          placeholder="Remarks..."
          value={Remarks}
          className="form-control form-control-lg input-text-size"
          rows="5"
          style={{ resize: "none" }}
          ref={register({ required: false })}
          onChange={(event) => storeDataOnForm(event, props.setFormRequest)}
        /></Col>
        </Row>

      </Col>
      <Col lg={4}>
      <div className="custom-control custom-checkbox mb-3 ml-2">
          <input
            type="checkbox"
            className="custom-control-input form-control-lg"
            id="CustomCheck1"
            onChange={() => false}
            name="customchk"
            ref={register({ required: false })}
            checked={customchk}
          />
          <label
            className="custom-control-label "
            onClick={() => {
              setCustomchk(!customchk)
              setPaymentRequest(formRequest => ({
                ...formRequest,
                ItemCheck: !customchk
              }))
              props.onChange(formRequest => ({
                ...formRequest,
                ItemCheck: !customchk
              }))
            }}
          >
            Check to Add Item Breakdown
        </label>
        </div>
      <div className="form-group my-auto py-2" hidden={!customchk}>
      <FormFirstPageItemsList
        MerchantServices ={merchantServices}
        customchk={customchk}
        onChange={value => setItems(value)} />

    </div>
       
      </Col>
    </Row>}
      </fieldset>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    paymentRequestDeatils: state.paymentRequests.paymentRequestDetail
  }
}

export default connect(mapStateToProps)(FormFirstPage)
