import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Col, FormGroup, Input, Row, Button, Form } from "reactstrap"

import { CommissionSettings } from "./CommissionSettings"
import { v4 as uuidv4 } from "uuid"
import { CustomConfig } from "./CustomConfig"

export const PaymentGateways = props => {
  const {
    paymentGateway,
    handlePaymentGatewayChange,
    handlePaymentGatewayDelete,
    paymentGatewayList,
    handleSubmit,
    onSubmit,
    register,
    errors,
    index,
  } = props

  const [selectedPg, setSelectedPg] = useState({})
  const [commissionBasis, setCommissionBasis] = useState([])

  const inputPaymentGateway = "PaymentGateway" + index
  // const inputTerminalId = "TerminalId" + index
  // const inputPassword = "Password" + index
  // const inputUserName = "UserName" + index
  // const inputTerminalPassword = "TerminalPassword" + index
  const inputMerchantId = "MerchantId" + index
  const inputIsActive = "IsActive" + index
  const inputCommissionBasedOn = "CommissionBasedOn" + index

  const [pgList, setPgList] = useState([])
  useEffect(() => {
    handleSelectedCommissionBasedOn("payment type")
 
  }, [selectedPg])

  useEffect(() => {}, [commissionBasis])

  useEffect(() => {
    if (paymentGatewayList) {
      setPgList(paymentGatewayList)
    }
  }, [paymentGatewayList])

  function handleChange(changes) {
    handlePaymentGatewayChange(paymentGateway.id, {
      ...paymentGateway,
      ...changes,
    })
  }

  function handleCommissionChange(id, commission) {
    const newCommissions = [...paymentGateway.CommissionSettings]
    const index = newCommissions.findIndex(i => i.id === id)
    newCommissions[index] = commission
    handleChange({ CommissionSettings: newCommissions })
  }

  function handleCustomConfigChange(id, config) {
    const newCustomConfigs = [...paymentGateway.CustomConfig]
    const index = newCustomConfigs.findIndex(i => i.id === id)
    newCustomConfigs[index] = config
    handleChange({ CustomConfig: newCustomConfigs })
  }

  function handleCommissionAdd() {
    const newCommission = {
      id: uuidv4(),
      PaymentMethod: "",
      Value: 1,
      Type: "",
    }
    handleChange({
      CommissionSettings: [...paymentGateway.CommissionSettings, newCommission],
    })
  }

  function handleCustomConfigAdd() {
    const newCustomConfig = {
      id: uuidv4(),
      Key: "",
      Value: 1,
    }
    handleChange({
      CustomConfig: [...paymentGateway.CustomConfig, newCustomConfig],
    })
  }

  function handleCommissionDelete(id) {
    handleChange({
      CommissionSettings: paymentGateway.CommissionSettings.filter(
        i => i.id !== id
      ),
    })
  }

  function handleCustomConfigDelete(id) {
    handleChange({
      CustomConfig: paymentGateway.CustomConfig.filter(i => i.id !== id),
    })
  }

  function handleSelectedPgId(e) {
    const index = e.target.selectedIndex
    const optionElement = e.target.childNodes[index]
    const optionElementId = optionElement.getAttribute("id")
    handleChange({
      PaymentGateway: e.target.value,
      PaymentGatewayId: optionElementId,
    })

    const selectedpaymentgateWay = paymentGatewayList.filter(
      i => i.paymentGatewayId == optionElementId
    )[0]
    setSelectedPg(selectedpaymentgateWay)
  }

  function handleSelectedCommissionBasedOn(value) {
    if (selectedPg) {
      if (value === "payment type") {
        setCommissionBasis(selectedPg?.paymentType)
      }

      if (value === "card type") {
        setCommissionBasis(selectedPg?.cardType)
      }
    }

    handleChange({ CommissionBasedOn: value })
  }

  function populateCommissionOptins(id) {}

  function parseBool(value) {
    var bool = value === "true" ? true : false
    return bool
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#e5edff",
          padding: "10px",
          marginBottom: "30px",
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg="3">
              {/* TODO: pg get from db*/}
              <FormGroup>
                <label htmlFor="subject">Payment Gateway </label>
                <select
                  className="custom-select"
                  value={paymentGateway.PaymentGateway}
                  name={inputPaymentGateway}
                  onChange={e => handleSelectedPgId(e)}
                  ref={register({
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  })}
                >
                  <option value="" selected="selected" hidden="hidden">
                    Choose here
                  </option>
                  {pgList?.map((pg, index) => (
                    <option
                      key={index}
                      id={pg.paymentGatewayId}
                      value={pg.paymentGateway}
                    >
                      {pg.paymentGateway}
                    </option>
                  ))}
                </select>

                <small className="color-danger">
                  {errors[inputPaymentGateway] &&
                    errors[inputPaymentGateway].message}
                </small>
              </FormGroup>
            </Col>

           
            {/*OLD IMPLEMENTATION UNDER TESS  */}
            {/* {selectedPg?.paymentGatewayId === "PG000001" &&(
              <>
                <Col lg="2">
                  <FormGroup>
                    <label htmlFor="subject">Terminal Id </label>
                    <input
                      value={paymentGateway.TerminalId}
                      name={inputTerminalId}
                      onChange={e =>
                        handleChange({ TerminalId: e.target.value })
                      }
                      type="text"
                      className="form-control"
                      placeholder=""
                      ref={register({
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                    />

                    <small className="color-danger">
                      {errors[inputTerminalId] &&
                        errors[inputTerminalId].message}
                    </small>
                  </FormGroup>
                </Col>
                <Col lg="2">
                  <FormGroup>
                    <label htmlFor="subject">Terminal Password </label>
                    <input
                      value={paymentGateway.TerminalPassword}
                      name={inputTerminalPassword}
                      onChange={e =>
                        handleChange({ TerminalPassword: e.target.value })
                      }
                      type="text"
                      className="form-control"
                      placeholder={" "}
                      ref={register({
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                    />

                    <small className="color-danger">
                      {errors[inputTerminalPassword] &&
                        errors[inputTerminalPassword].message}
                    </small>
                  </FormGroup>
                </Col>
                <Col lg="1">
                  <FormGroup>
                    <label htmlFor="subject">Merchant Id </label>
                    <input
                      value={paymentGateway.MerchantId}
                      name={inputMerchantId}
                      onChange={e =>
                        handleChange({ MerchantId: e.target.value })
                      }
                      type="text"
                      className="form-control"
                      placeholder={" "}
                      ref={register({
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                    />

                    <small className="color-danger">
                      {errors[inputMerchantId] &&
                        errors[inputMerchantId].message}
                    </small>
                  </FormGroup>
                </Col>
              </>
            )} */}



            <Col lg="1">
              <FormGroup>
                <label htmlFor="subject">Status</label>
                <select
                  ref={register({ required: true })}
                  className="custom-select"
                  value={paymentGateway.IsActive}
                  name={inputIsActive}
                  onChange={e =>
                    handleChange({ IsActive: parseBool(e.target.value) })
                  }
                >
                  <option value="" selected="selected" hidden="hidden">
                    Choose here
                  </option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
                <small className="color-danger">
                  {errors[inputIsActive] && errors[inputIsActive].message}
                </small>
              </FormGroup>
            </Col>

            <Col lg="2">
              <FormGroup>
                <label htmlFor="subject">Commission</label>
                <select
                  ref={register({
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  })}
                  className="custom-select"
                  value={paymentGateway.CommissionBasedOn}
                  name={inputCommissionBasedOn}
                  onChange={e =>
                    handleSelectedCommissionBasedOn(e.target.value)
                  }
                >
                  <option value="" selected="selected" hidden="hidden">
                    Choose here
                  </option>
                  <option value="payment type">
                    Payment Type (credit / debit)
                  </option>
                  <option value="card type">Card Type (visa / master)</option>
                </select>
                <small className="color-danger">
                  {errors[inputCommissionBasedOn] &&
                    errors[inputCommissionBasedOn].message}
                </small>
              </FormGroup>
            </Col>

            <Col lg="1">
              <FormGroup>
                <label style={{ color: "#e5edff" }} htmlFor="subject">
                  Delete{" "}
                </label>

                <Button
                  onClick={e => handlePaymentGatewayDelete(paymentGateway.id)}
                  color="danger"
                  className="btn-block inner"
                  style={{ width: "100%" }}
                >
                  {" "}
                  Delete PG{" "}
                </Button>
              </FormGroup>
            </Col>
          </Row>

          <Row>
     
            <Col lg="8">
              
                  
                  <b>Add Custom Configurations</b>
                  <p></p>
                  {paymentGateway.CustomConfig.map((config, index) => (
                    <>
                      <CustomConfig
                        index={index}
                        indexPG={props.index}
                        selectedPg={selectedPg}
                        key={config.id}
                        config={config}
                        handleCustomConfigChange={handleCustomConfigChange}
                        handleCustomConfigDelete={handleCustomConfigDelete}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        register={register}
                        errors={errors}
                      />
                    </>
                  ))}

                  <div style={{ padding: "10px" }}>
                    {" "}
                    <Button
                      onClick={() => handleCustomConfigAdd()}
                      color="warning"
                      className="mt-1"
                    >
                      + Add Config
                    </Button>
                  </div>
               
            </Col>
          </Row>

          <hr></hr>
          <b>Add Commission Settings</b>
          <p>For internal purposes only.</p>

          <Row>
            {paymentGateway.CommissionSettings.map((commission, index) => (
              <CommissionSettings
                index={index}
                indexPG={props.index}
                selectedPg={selectedPg}
                commissionBasis={commissionBasis}
                key={commission.id}
                commission={commission}
                handleCommissionChange={handleCommissionChange}
                handleCommissionDelete={handleCommissionDelete}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                register={register}
                errors={errors}
              />
            ))}
          </Row>

          <div style={{ padding: "10px" }}>
            {" "}
            <Button
              onClick={() => handleCommissionAdd()}
              color="warning"
              className="mt-1"
            >
              + Add Payment Method & Commission
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGateways)
