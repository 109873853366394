import React, { useEffect, useContext } from "react"
import { connect } from "react-redux"
import { PaymentMethodContext } from "../../index"

export const CreditEmbedded = props => {

  const {
  paymentRequestDetails,
  dbsyCreditEmbedded} = props

  const { onSetDibsyInitiatePayment } = useContext(
    PaymentMethodContext
  )


  useEffect(() => {
    const script = document.createElement("script")
    script.src = dbsyCreditEmbedded?.sessionUrl
    // script.async = true
    script.onload = function handleScriptLoaded() {
      // console.log("script has loaded")

      var options = {
        styles: {
          fontSize: "16px",
          color: "rgba(0, 0, 0, 0.8)",
          backgroundColor: "transparent",
          "&.is-invalid": {
            color: "#f42828"
          }
        }
      };
      
      const dibsy = Dibsy(dbsyCreditEmbedded?.publicKey, {
        locale: "en_US"
      });
      var cardNumber = dibsy.createComponent("cardNumber", options);
      cardNumber.mount("#card-number");
      
      var expiryDate = dibsy.createComponent("expiryDate", options);
      expiryDate.mount("#expiry-date");
      
      var verificationCode = dibsy.createComponent("verificationCode", options);
      verificationCode.mount("#verification-code");
      
      var cardNumberError = document.getElementById("card-number-error");
      cardNumber.addEventListener("change", function (event) {
        if (event.error && event.touched) {
          cardNumberError.textContent = event.error;
        } else {
          cardNumberError.textContent = "";
        }
      });
      
      var expiryDateError = document.getElementById("expiry-date-error");
      expiryDate.addEventListener("change", function (event) {
        if (event.error && event.touched) {
          expiryDateError.textContent = event.error;
        } else {
          expiryDateError.textContent = "";
        }
      });
      
      //
      
      var verificationCodeError = document.getElementById("verification-code-error");
      verificationCode.addEventListener("change", function (event) {
        if (event.error && event.touched) {
          verificationCodeError.textContent = event.error;
        } else {
          verificationCodeError.textContent = "";
        }
      });
      
      /**
       * Submit handler
       */
      
      var form = document.getElementById("payForm");
      var formError = document.getElementById("form-error");
      var submitButton = document.getElementById("submit-button");
      
      form.addEventListener("submit", function (event) {
        event.preventDefault();
        disableForm();
      
        // Reset possible form error
        formError.textContent = "";
      
        // Get a payment token
      
        dibsy.cardToken().then(function (result) {
          var token = result.token;
          var error = result.error;
      
          if (error) {
            enableForm();
            formError.textContent = error.message;
            return;
          }
      
          if(token){
         // console.log("Token:", token);

          var model = {
            CardToken: token,
            TrackId: dbsyCreditEmbedded.trackId,
            PaymentMethod: "credit"
          }
          onSetDibsyInitiatePayment(model)
        }
          // Add token to the form
          // var tokenInput = document.createElement("input");
          // tokenInput.setAttribute("name", "token");
          // tokenInput.setAttribute("type", "hidden");
          // tokenInput.setAttribute("value", token);
      
          // form.appendChild(tokenInput);
      
          // Re-submit form to the server
          //form.submit();
        });
      });
      
      function disableForm() {
        submitButton.disabled = true;
      }
      
      /**
       * Enables the form inputs and submit button
       */
      function enableForm() {
        submitButton.disabled = false;
      }
      
      
    }

    script.onerror = function handleScriptError() {
      console.log("error loading script")
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <div className="dbsy-outer-container">
        <div className="dbsy-inner-container">
          <form method="post" className="form" id="payForm">
            <p>Enter Credit Card Details</p>
            <div className="dbsy-form-fields">
              <div className="row">
                <div className="dbsy-form-group dbsy-form-group--card-number">
                  <div id="card-number"></div>
                  <div
                    id="card-number-error"
                    className="dbsy-field-error"
                    role="alert"
                  ></div>
                </div>
              </div>

              <div className="dbsy-row">
                <div className="dbsy-form-group dbsy-form-group--expiry-date">
                  <div id="expiry-date"></div>
                  <div
                    id="expiry-date-error"
                    className="dbsy-field-error"
                    role="alert"
                  ></div>
                </div>

                <div className="dbsy-form-group dbsy-form-group--verification-code">
                  <div id="verification-code"></div>
                  <div
                    id="verification-code-error"
                    className="dbsy-field-error"
                    role="alert"
                  ></div>
                </div>
              </div>
            </div>

            <button id="submit-button" className="dbsy-submit-button" type="submit">
              Confirm Payment
            </button>

            <div id="form-error" className="dbsyfield-error" role="alert"></div>
          </form>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CreditEmbedded)
