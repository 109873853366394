import React, { useEffect, useState } from 'react'
import { Col, Card, CardBody, Row, CardTitle, Button } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { isEmpty } from "lodash"
import ContactsColumns from './DataFields/ContactsColumns'
import AuthUser from "components/AuthUser/AuthUser"


export default function Contacts(props) {

  const { contacts } = props
  const [contactList, setContactList] = useState([])
  const [user, setUser] = useState(AuthUser())

  const pageOptions = {
    sizePerPage: 5,
    totalSize: contacts.length, 
    custom: true,
  }
  const { SearchBar } = Search


  // useEffect(() => {

  //   setContactList(contacts)
  // }, [contacts])

  useEffect(() => {
    if (!isEmpty(contacts)) setContactList(contacts)
  }, [contacts])

  const handleTableChange = (type, { page, searchText }) => {
    setContactList(
      contactList?.filter(contact =>
        Object.keys(contact).some(
          key =>
            typeof contact[key] === "string" &&
            contact[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const isHidden = () => {

    if (user !== null && user !== undefined) {
      if (user.role === "SuperAdmin") {
        return <>
          <Button
            type="button"
            color="success"
            className="btn-rounded waves-effect waves-light mb-2 mr-2"
          >
            <i className="mdi mdi-plus mr-1" />
                   Add New Contact
           </Button>
        </>
      }
      else {
        return <div></div>
      }
    }
  }

  return (
    <>
      <Card style={{ minHeight: "350px", maxHeight: "450px" }}>
        <CardTitle style={{ "backgroundColor": "#CCDFF1", "padding": "5px" }}><i className="fas fa-address-book text-success mr-1" />Contact List</CardTitle>
        <CardBody>
          <Row>
            <Col lg="12">

              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField={"_id"}
                    data={contactList}
                    columns={ContactsColumns(user)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box mr-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar  id="contactsSearch"
                                  {...toolkitProps.searchProps}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-right">
                              {isHidden()}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive

                                bordered={false}
                                striped={false}
                                classes={
                                  "table table-centered table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>




            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
