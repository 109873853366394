import {
  GET_PAYMENTREQUEST_LIST_ARCHIVED,
  GET_PAYMENTREQUEST_LIST_ARCHIVED_SUCCESS,
  GET_PAYMENTREQUEST_LIST_ARCHIVED_FAIL,
  PATCH_PAYMENTREQUEST_ARCHIVE,
  PATCH_PAYMENTREQUEST_ARCHIVE_SUCCESS,
  PATCH_PAYMENTREQUEST_ARCHIVE_FAIL,
  RETURN_STATE,
} from "./actionTypes"

const INIT_STATE = {
  paymentRequestListArchived: [],
  paymentRequestArchive: {},
  paymentRequestArchiveResult: undefined,
  loading: false,
  error: {},
}

const paymentRequestsArchived = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENTREQUEST_LIST_ARCHIVED:
      return (state = {
        ...state,
        loading: true,
      })

    case GET_PAYMENTREQUEST_LIST_ARCHIVED_SUCCESS:
      return {
        ...state,
        paymentRequestListArchived: action.payload,
        loading: false,
      }

    case GET_PAYMENTREQUEST_LIST_ARCHIVED_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case PATCH_PAYMENTREQUEST_ARCHIVE:
      return (state = {
        ...state,
        paymentRequestArchive: action.payload,
        loading: true,
      })

    case PATCH_PAYMENTREQUEST_ARCHIVE_SUCCESS:
      return {
        ...state,
        paymentRequestArchive: action.payload,
        paymentRequestArchiveResult: true,
        loading: false,
      }

    case PATCH_PAYMENTREQUEST_ARCHIVE_FAIL:
      return {
        ...state,
        error: action.payload,
        paymentRequestArchiveResult: false,
        loading: false,
      }
    case RETURN_STATE:
      return {
        ...state,
        paymentRequestArchiveResult: undefined,
      }
    default:
      return state
  }
}

export default paymentRequestsArchived
