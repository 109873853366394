
import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Spinner,
} from "reactstrap"

import { CopyToClipboard } from 'react-copy-to-clipboard'
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import ResendEmailModal from './ResendEmailModal'


const Actions = (props) => {

  const { paymentrequestDetails, isEmailLoaded } = props
  const [modal, setModal] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [copyLink, setCopyLink] = useState({
    value: '',
    copied: false,
  })
//  const { handleSendPaymentLinkEmail } = useContext(PaymentActionsContext)

  const toggleModal = () => {
    setModal(!modal)
  }

  return (
    <>
    

   
     <ResendEmailModal isOpen={modal} toggle={toggleModal} paymentrequestDetails={paymentrequestDetails}/>

      <Card>
        <CardBody>

          <Row>
            <Col lg="4"></Col>
            <Col lg="4">

              <div className="button-items">
                {isEmailLoaded ?

                  <button
                    type="button"
                    className="btn btn-primary waves-effect btn-label waves-light" style={{ "width": "180px" }}>
                    <i className="fas fa-paper-plane label-icon"></i>  <Spinner className="mr-2" color="info" style={{ "height": "20px", "width": "20px" }} />
                  </button>

                  :
                  <button
                    type="button"
                    className="btn btn-primary waves-effect btn-label waves-light"
                    onClick={toggleModal}
                    data-toggle="modal"
                    data-target="#myModal"
             
                  >
                    <i className="fas fa-paper-plane label-icon"></i> Send Payment Link
                          </button>


                }

                {/* copy link section */}

                <CopyToClipboard text={paymentrequestDetails.generatedLinkShort}
                  onCopy={() => setCopyLink({ copied: true })}>
                  <Button
                    color="primary"
                    className="btn btn-success waves-effect btn-label waves-light"
                    onClick={() => {
                      setsuccess_msg(true)
                    }}
                    id="sa-success"
                  >
                    <i className="far fa-copy label-icon"></i>{" "}
                                        Copy Payment Link
                               </Button>
                </CopyToClipboard>


                {/* <button
                  type="button"
                  className="btn btn-warning waves-effect btn-label waves-light"
                >
                  <i className="fas fa-cloud-download-alt label-icon "></i> Update Status
                          </button> */}

              </div>

            </Col>
            <Col lg="4"></Col>
          </Row>
          {success_msg ? (
            <SweetAlert
              title="Link Copied! Paste Now"
              success

              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            >
              {paymentrequestDetails.generatedLinkShort}
            </SweetAlert>
          ) : null}



        </CardBody>
      </Card>


     


    </>
  )
}

export default Actions
