import React, { useState, useEffect } from "react"
import { Col, Row, Container, Card, CardBody, CardTitle } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import Questions from "./Questions"
import { setSurveyAnswers } from "store/actions"

const initialState = {
  Rate: 0,
  FeatureSuggestion: "",
  Date: new Date().toISOString(),
  UserId: "",
}

const Survey = props => {
  const {
    userId,
    surveyAnswer,
    onGetSurveyAnswers,
    onSetSurveyAnswers,
    loading,
  } = props

  const [surveyDetails, setSurveyDetails] = useState(initialState)
  const [loadingSurvey, setloadingSurvey] = useState(undefined)

  useEffect(() => {
    setloadingSurvey(loading)
  }, [surveyDetails])

  useEffect(() => {
    setloadingSurvey(loading)
  }, [loading])

  function handleChangeSurveyAnswers(changes) {
    setSurveyDetails(changes)
  }

  const handleSetSurveyAnswers = () => {
    if (surveyDetails.Rate != 0) {
      onSetSurveyAnswers(surveyDetails)
      setloadingSurvey(undefined)
      setSurveyDetails(initialState)
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>We'd love to hear your feedback!</CardTitle>
          <Questions
            surveyAnswers={surveyDetails}
            handleChangeSurveyAnswers={handleChangeSurveyAnswers}
            handleSetSurveyAnswers={handleSetSurveyAnswers}
            loadingSurvey={loadingSurvey}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

Survey.propTypes = {
  onGetSurveyAnswers: PropTypes.func,
  onSetSurveyAnswers: PropTypes.func,
  loading: PropTypes.bool,
  surveyAnswer: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    surveyAnswer: state.Dashboard.answer,
    loading: state.Dashboard.loadingSurvey,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetSurveyAnswers: () => dispatch(),
  onSetSurveyAnswers: data => dispatch(setSurveyAnswers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Survey))
