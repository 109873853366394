import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Spinner, Form, Label } from "reactstrap"
import { selectCustomer,getCustomerList, updateCustomer, getCustomersByMerchantID } from "../../store/actions.js"
import AuthUser from "components/AuthUser/AuthUser"
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux"
import { IsEmpty } from "react-lodash"
import CustomerColumns from "./CustomerColumns"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import { connect } from "react-redux"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


function SearchCustomer(props) {
    const _ = require("lodash");
    const dispatch = useDispatch();
    const [user, setUser] = useState(AuthUser())
    const [{ id, Name, Email, Mobile, MobilePlus, Type,customerSearchorCreated,RegistrationDate,IsActive, MerchantId}, setPaymentRequest] = useState({
            MobilePlus: "+974",
            Name: "",
            Email: "",
            Mobile: "",
            SearchCustomer: "",
            Type: "New",
            customerSearchorCreated: "",
            step: 0
        })
    const [customerList, setCustomerList] = useState({})
    const [loading, setLoading] = useState(props.loading)
    const [addOrUpdateCustomer, setAddorUpdateCustomer] = useState(true)
    const { register, handleSubmit, errors, reset } = useForm();
    let searchText = "";
    const pageOptions = {
        sizePerPage: 5,
        totalSize: customerList.length, // replace later with size(customerList),
        custom: true,
        onPageChange: (page, sizePerPage) => { handleTableChange() }
    }
    const { SearchBar } = Search


    useEffect(() => {
        UserRole()
    }, [])

    //Use Effect for setting customerList with props
    useEffect(() => {
        setLoading(props.loading)
        if(Array.isArray(props.customersList)){
            setCustomerList(props.customersList.filter( cus => cus.isActive === true))
          }
    }, [props.customersList, props.loading])

    useEffect(()=>{
        if(Type === "Recurring"){
            const data = {
                _id:id,
                Name,
                Email,
                Mobile: MobilePlus + "-" + Mobile.toString(),
                RegistrationDate,
                Type,
                MerchantId,
                IsActive
            }
            dispatch(updateCustomer(data))
        }
    },[Type])

    useEffect(()=>{},[customerList])
    //Functions--------------------------------------------------------------------------------------------------------


    const UserRole = () => {
        if (user.role !== "SuperAdmin") {
            const data = {
                type: "all",
                merchantId: user.merchantId
            }
            setLoading(props.loading)
            dispatch(getCustomersByMerchantID(data))
        }
        if (user.role === "SuperAdmin") {
            dispatch(getCustomerList())
        }
    }



    const handleCancelButton = () => {
        props.onChange(formRequest => (
            {
                ...formRequest,
                step: 0
            }
        ));
    }

    //Function for click customer in row event
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            showToastSuccess("Customer " + row.name +" has been selected.")
            setAddorUpdateCustomer(false)
            if (row.mobile != undefined) {
                let MobileSplit = row.mobile.split("-")
                setPaymentRequest(formRequest => ({
                    ...formRequest,
                    id: row._id,
                    Name: row.name,
                    Email: row.email,
                    Mobile: MobileSplit[1],
                    MobilePlus: MobileSplit[0],
                    RegistrationDate: row.registrationDate,
                    IsActive: row.isActive,
                    MerchantId:row.merchantID,
                    Type: "Recurring",
                    customerSearchorCreated: "Searched"
                }))
            //    dispatch(selectCustomer(row))
            }
        }
    };

    

  const showToastSuccess = (message) => {


    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300
    }

    toastr.success(message)
  }
    const handleTableChange = (searchText) => {
        if (searchText !== "" && searchText !== undefined) {
            setCustomerList(
                props.customersList.filter(customer =>
                    Object.keys(customer).some(key =>
                        customer[key].toLowerCase().includes(searchText.toLowerCase())
                    )
                )
            )
        }
    }

    return (
        <div className="mx-auto my-auto">

            {loading ?
                <Row className="spinner-custom mx-auto my-auto pb-3">
                    <LoadingSpinnerThreeDots />
                </Row>
                :
                <IsEmpty
                    value={customerList}
                    yes={() => <>No Customers</>}
                    no={() => (
                        <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                        >
                            {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                    keyField="_id"
                                    data={customerList || []}
                                    columns={CustomerColumns()}
                                    bootstrap4
                                    search
                                >
                                    {toolkitProps => (
                                        <React.Fragment>
                                            <Row className="mb-2  mx-2 input-text-size">
                                                <Col className="item-center">
                                                    <div className="search-box mr-2 mb-2 d-inline-block">
                                                        <div className="position-relative input-text-size">
                                                            <SearchBar {...toolkitProps.searchProps} className="width-search" placeholder="Search Customer/Email/Mobile" />
                                                            <i className="bx bx-search-alt search-icon" />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {loading ?
                                                    <Row className="spinner-custom mx-auto my-auto pb-3">
                                                        <LoadingSpinnerThreeDots />
                                                    </Row>
                                                    :
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable

                                                                responsive
                                                                bordered={false}
                                                                hover={true}
                                                                striped={false}
                                                                classes={
                                                                    "table table-centered pointer table-nowrap table-hover input-text-size"
                                                                }
                                                                rowEvents={rowEvents}
                                                                keyField="_id"
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                onTableChange={handleTableChange(searchText)}
                                                                {...paginationTableProps}
                                                            />
                                                        </div>
                                                    </Col>}
                                            </Row>
                                            <Row className="align-items-md-center mt-30">
                                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                    <PaginationListStandalone
                                                        {...paginationProps}
                                                    />
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    )}
                                </ToolkitProvider>
                            )}
                        </PaginationProvider>
                    )} />}
            <Row>
                <Col className="item-center mb-3">
                    <Button
                        color="danger"
                        outline
                        className=" waves-effect waves-light mt-3 btn-lg mr-2"
                        onClick={handleCancelButton}
                    >
                        <i className="fas fa-backspace mr-1" />
                       Cancel
                   </Button></Col>
            </Row>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        customerCreated: state.Customer.customerCreated,
        customer: state.Customer.customer,
        loading: state.Customer.loading,
        customersList: state.Customer.customerList
    }
}


export default connect(mapStateToProps)(SearchCustomer)
