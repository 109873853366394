
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Button, CardBody, Card, Row, Col, Container } from "reactstrap"
// const fileStatic = "../../assets/help/PayAllLink_User_Guide_User_V2.pdf"
// const file = "http://localhost:3000/help/PayAllLink_User_Guide_User_V2.pdf"


const VideoTutorials = () => {


  return (

    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Card>
              <CardBody>
              
                <Row><Col>
                <div  style={{ "textAlign": "center" }} >
                    <h4 className="display-4">Videos will be posted soon!</h4>
                    <br></br>
                    <br></br>
                    <Link to="/help/userguide">
                     
                      <Button type="button"
                        color="primary"
                        className="btn-rounded waves-effect waves-light mb-2 mr-2"
                      > See User Guide</Button>
                    </Link>
                    </div>
                </Col></Row>

               
           

              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>


    </>


  )
}

export default VideoTutorials