import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap"
import { useForm } from "react-hook-form"
import AuthUser from "components/AuthUser/AuthUser"
import { useDispatch } from "react-redux"
import { updateUser } from "../../store/actions.js"

function UserUpdatePopUp(props) {
  const dispatch = useDispatch()
  let mPLus = props.userDetails.mobile.split("-")

  const [
    {
      title,
      userName,
      firstName,
      lastName,
      email,
      Mobile,
      role,
      MobilePlus,
      department,
      merchantId,
    },
    setPaymentRequest,
  ] = useState({
    title: props.userDetails.title,
    userName: props.userDetails.userName,
    firstName: props.userDetails.firstName,
    lastName: props.userDetails.lastName,
    email: props.userDetails.email,
    Mobile: mPLus[1],
    MobilePlus: mPLus[0],
    role: props.userDetails.role,
    department: props.userDetails.department,
    merchantId: props.userDetails.merchantId,
  })
  const [user, setUser] = useState(AuthUser())
  const { register, handleSubmit, errors } = useForm()
  const [merchantIds, setMerchantIds] = useState(props.merchantIds)

  const storeDataOnForm = event => {
    const re = /^[a-zA-Z\s]*$/
    const me = /^[0-9\s]*$/
    const { name, value } = event.target

    if (name === "firstName" && re.test(value)) {
      setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
    }
    if (name === "lastName" && re.test(value)) {
      setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
    }
    if (name === "Mobile" && me.test(value)) {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        [name]: value.replaceAll(" ", ""),
      }))
    }
    if (name !== "firstName" && name !== "lastName" && name !== "Mobile") {
      setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
    }
  }

  // useEffect(() => {
  //   console.log(Mobile)
  // }, [Mobile])

  const handleCreateUser = data => {
    if (user.role !== "SuperAdmin") {
      const userModel = {
        _id: props.userDetails._id,
        UserId: props.userDetails.userId,
        MerchantId: props.userDetails.merchantId,
        RegistrationDate: props.userDetails.registrationDate,
        Title: data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        Mobile: MobilePlus + "-" + Mobile.toString(),
        UserName: props.userDetails.userName,
        Password: props.userDetails._id,
        Email: data.email,
        Role: data.role,
        Department: data.department,
        IsActive: props.userDetails.isActive,
      }
      dispatch(updateUser(userModel))
    } else {
      const userModel = {
        _id: props.userDetails._id,
        UserId: props.userDetails.userId,
        MerchantId: data.merchantId,
        RegistrationDate: props.userDetails.registrationDate,
        Title: data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        Mobile: MobilePlus + "-" + Mobile.toString(),
        UserName: props.userDetails.userName,
        Password: props.userDetails._id,
        Email: data.email,
        Role: data.role,
        Department: data.department,
        IsActive: props.userDetails.isActive,
      }
      dispatch(updateUser(userModel))
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(handleCreateUser)}
      className=" mx-auto my-auto"
    >
      <Row className="form-group mx-3 my-3">
        <Col>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">TITLE</Label>
            </InputGroupAddon>
            <select
              className="form-control form-control-lg input-text-size"
              id="title"
              name="title"
              value={title}
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            >
              <option>MR</option>
              <option>MS</option>
            </select>
          </InputGroup>
          {errors.title && errors.title.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
        {user.role === "Admin" ? (
          <Col>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">ROLE</Label>
              </InputGroupAddon>
              <select
                className="form-control form-control-lg input-text-size"
                id="role"
                name="role"
                value={role}
                ref={register({ required: true })}
                onChange={event => storeDataOnForm(event)}
              >
                <option>Admin</option>
                <option>User</option>
              </select>
            </InputGroup>
            {errors.role && errors.role.type === "required" && (
              <p className="color-danger">This is required</p>
            )}
          </Col>
        ) : null}
        {user.role === "User" ? (
          <Col>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">ROLE</Label>
              </InputGroupAddon>
              <select
                className="form-control form-control-lg input-text-size"
                id="role"
                name="role"
                value={role}
                ref={register({ required: true })}
                onChange={event => storeDataOnForm(event)}
              >
                <option>User</option>
              </select>
            </InputGroup>
            {errors.role && errors.role.type === "required" && (
              <p className="color-danger">This is required</p>
            )}
          </Col>
        ) : null}
        {user.role === "SuperAdmin" ? (
          <Col>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">ROLE</Label>
              </InputGroupAddon>
              <select
                className="form-control form-control-lg input-text-size"
                id="role"
                name="role"
                value={role}
                ref={register({ required: true })}
                onChange={event => storeDataOnForm(event)}
              >
                <option>SuperAdmin</option>
                <option>Admin</option>
                <option>User</option>
              </select>
            </InputGroup>
            {errors.role && errors.role.type === "required" && (
              <p className="color-danger">This is required</p>
            )}
          </Col>
        ) : null}
      </Row>
      <Row className="form-group mx-3 my-3">
        {/* <Col>
                    <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                            <Label className="input-group-text input-text-size">
                                USERNAME
                              </Label>
                        </InputGroupAddon>
                        <input
                            id="userName"
                            name="userName"
                            value={userName}
                            type="text"
                            className="form-control  form-control-lg input-text-size"
                            ref={register({ required: true })}
                            onChange={(event) => storeDataOnForm(event)}
                        />
                    </InputGroup>
                    {errors.userName && errors.userName.type === "required" && <p className="color-danger">This is required</p>}
                </Col> */}
        <Col>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">EMAIL</Label>
            </InputGroupAddon>
            <input
              id="email"
              name="email"
              value={email}
              type="email"
              className="form-control  form-control-lg input-text-size"
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            />
          </InputGroup>
          {errors.email && errors.email.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
      </Row>
      <Row className="form-group mx-3 my-3">
        <Col>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                FIRSTNAME
              </Label>
            </InputGroupAddon>
            <input
              id="firstName"
              name="firstName"
              value={firstName}
              type="text"
              className="form-control  form-control-lg input-text-size"
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            />
          </InputGroup>
          {errors.firstName && errors.firstName.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
        <Col>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                LASTNAME
              </Label>
            </InputGroupAddon>
            <input
              id="lastName"
              name="lastName"
              value={lastName}
              type="text"
              className="form-control  form-control-lg input-text-size"
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            />
          </InputGroup>
          {errors.lastName && errors.lastName.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
      </Row>
      <Row className="form-group mx-3 my-3">
        <Col>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">MOBILE</Label>
            </InputGroupAddon>
            <select
              className="form-control  custom-select input-text-size"
              id="MobilePlus"
              name="MobilePlus"
              value={MobilePlus}
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            >
              <option value=" +974" hidden="hidden">
                +974
              </option>
              {props.countryCodes?.map((cc, i) => {
                return (
                  <option value={"+" + cc.countryCode}>
                    +{cc.countryCode}
                  </option>
                )
              })}
            </select>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <input
              id="Mobile"
              name="Mobile"
              value={Mobile}
              type="text"
              ref={register({ required: true })}
              className="form-control form-control-lg input-text-size"
              onChange={event => storeDataOnForm(event)}
            />
          </InputGroup>
          {errors.Name && errors.Name.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
      </Row>
      <Row className="form-group mx-3 my-3">
        <Col>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                DEPARTMENT
              </Label>
            </InputGroupAddon>
            <select
              className="form-control form-control-lg input-text-size"
              id="department"
              name="department"
              value={department}
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event)}
            >
              <option>Administration</option>
              <option>Accounting</option>
              <option>Operations</option>
              <option>Finance</option>
              <option>Sales and Marketing</option>
            </select>
          </InputGroup>
          {errors.department && errors.department.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
        {user.role === "SuperAdmin" ? (
          <Col>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <Label className="input-group-text input-text-size">
                  MERCHANT ID
                </Label>
              </InputGroupAddon>
              <select
                className="form-control  custom-select input-text-size"
                id="merchantId"
                name="merchantId"
                value={merchantId}
                ref={register({ required: true })}
                onChange={event => storeDataOnForm(event)}
              >
                <option></option>
                {merchantIds
                  ? merchantIds.map((ids, i) => {
                      return (
                        <option key={i} value={ids}>
                          {ids}
                        </option>
                      )
                    })
                  : "No Merchant Ids"}
              </select>
            </InputGroup>
            {errors.department && errors.department.type === "required" && (
              <p className="color-danger">This is required</p>
            )}
          </Col>
        ) : null}
      </Row>

      <Row className="">
        <Col className="item-center mb-3">
          <Button
            color="primary"
            type="submit"
            className=" waves-effect waves-light mt-3 btn-lg"
          >
            <i className="mdi mdi-account-multiple-plus mr-1" />
            Update User
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default UserUpdatePopUp
