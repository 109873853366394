import React, { useState, useContext, useEffect } from "react"
import PropTypes from 'prop-types'
import { ValuesContext } from "../index"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from "react-redux"
import { Col, Row, Card, CardBody, CardTitle, Table } from "reactstrap"
import QRCode from "qrcode.react";
import { toast } from 'react-toastify';
import FormFourPageItemList from "./FormFourPage-ItemList"
import FormFourPageAttachmentList from "./FormFourPage-AttachmentList"
import dateFormat from '../../../helpers/commonhelpers/dateFormat'

const FormFourPage = props => {
  //Constants--------------------------------------------------------------------------------------
  const values = useContext(ValuesContext);
  const [hide, setHidden] = useState(true)

  //UseEffects------------------------------------------------------------------------------------
  useEffect(() => {
    if (values.overViewDetails.itemId) {
      setHidden(false)
    }
  }, [values.overViewDetails])

  useEffect(() => {
  }, [values.overViewDetails.itemId])

  useEffect(()=>{},[props.itemId])

  //Functions--------------------------------------------------------------------------------------
  const CopyTinyURL = () => {
    toast.info("Link copied.")
    navigator.clipboard.writeText(values.overViewDetails.generatedLinkShort)
  }

  return (
    <form>
      <fieldset>
        <Row>
          <Col xl={7}>
            <span>Summary</span>
            <Card>
              <CardBody>
                <CardTitle className="text-align-center bg-color-secondary py-2">
                  <h1>{parseFloat((Math.round(values.overViewDetails.amount * 100) / 100).toFixed(2))} {values.overViewDetails.currency}</h1>
                  AMOUNT DUE FOR {values.overViewDetails.customerDetails && values.overViewDetails.customerDetails.name}
                </CardTitle>
                <Row className="my-3">
                  <Col className="mx-3">
                    <Table className="float-left">
                      <tbody>
                        <tr className="color-text">
                          <td > Transaction Reference:</td>
                          <td> {values.overViewDetails.transactionReference === null ? "N/A" : values.overViewDetails.transactionReference}</td>
                        </tr>
                        <tr className="color-text">
                          <td > Payment Request:</td>
                          <td>  {values.overViewDetails.paymentRequestId}</td>
                        </tr>
                        <tr className="color-text">
                          <td>Customer Name:</td>
                          <td> {values.overViewDetails.customerDetails && values.overViewDetails.customerDetails.name}</td>
                        </tr>
                        <tr className="color-text">
                          <td>Customer Mobile:</td>
                          <td>{values.overViewDetails.customerDetails && values.overViewDetails.customerDetails.mobile}</td>
                        </tr>
                        <tr className="color-text">
                          <td > Transaction Date:</td>
                          <td> {dateFormat(values.overViewDetails.paymentRequestDate )}</td>
                        </tr>
                        <tr className="color-text">
                          <td >Card Type: </td>
                          <td>  {values.overViewDetails.paymentRequestSettings && values.overViewDetails.paymentRequestSettings.paymentType}</td>
                        </tr>
                        <tr className="color-text">
                          <td>Language:</td>
                          <td> {values.overViewDetails.paymentRequestSettings && values.overViewDetails.paymentRequestSettings.language}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col>
                    <Table className="float-left">
                      <tbody>

                        <tr className="color-text">
                          <td>Email Subject Line: </td>
                          <td>{values.overViewDetails.emailSettings && values.overViewDetails.emailSettings.subjectLine}</td>
                        </tr>
                        <tr className="color-text">
                          <td>Attachments: </td>
                          <td>
                            <FormFourPageAttachmentList
                              Attachments={values.overViewDetails.emailSettings && values.overViewDetails.emailSettings.attachment}
                            />
                          </td>
                        </tr>
                        <tr className="color-text">
                          <td>Remarks:</td>
                          <td>{values.overViewDetails.remarks === "" ? "N/A" : values.overViewDetails.remarks}</td>
                        </tr>
                        <tr className="color-text">
                          <td>Items:</td>
                          <td>
                            <FormFourPageItemList
                              ItemId={props.itemId} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={5}>
          <span className="mb-3"><br/><br/><br/></span>
            <div className="mx-5 px-5">
           
                <Row className="my-auto item-center">
                  <h5> <label className="color-text ml-1"> GENERATED PAYMENT LINK</label></h5>
                </Row>
                <Row className="item-center">
                  <div className="app-search d-none d-lg-block pointer">
                    <div className="position-relative" onClick={CopyTinyURL}>
                      <input
                        type="text"
                        className="form-control pointer form-control-lg qrcode-searchbar"
                        value={values.overViewDetails.generatedLinkShort}
                        placeholder="HTTPS://PAYALLLINK.COM"
                        disabled
                      />
                      <span className="mdi mdi-content-copy color-primary"></span>
                    </div>
                  </div>
                </Row>
                <Row className="item-center">
                  <QRCode
                    value={values.overViewDetails.generatedLinkShort === undefined ? "HTTPS://PAYALLLINK.COM" : values.overViewDetails.generatedLinkShort}
                    className="qr-code " />

                </Row>
              
            </div>
          </Col>
        </Row>
      </fieldset>


    </form>
  )
}

FormFourPage.propTypes = {
  handleSubmit: PropTypes.func,
  previousPage: PropTypes.any
}

const mapStateToProps = state => {
  return {
    paymentRequestDeatils: state.paymentRequests.paymentRequestDetail,
    itemId: state.Items.ItemId
  }
}
export default connect(mapStateToProps)(FormFourPage)
