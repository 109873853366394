import React from 'react'
import {
    Col,
    FormGroup,
    Input,
    Row,
    Button,
    Form
} from "reactstrap"

const Services = (props) => {

    const {
        index,
        service,
        handleServiceChange,
        handleServiceDelete,
        handleSubmit,
        onSubmit,
        register,
        errors,
    } = props

    let inputService = "Service" + index

    function handleChange(changes) {
        handleServiceChange(service.id, { ...service, ...changes })
    }



    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} >
                <Row >
                    <Col lg="8">
                        <FormGroup>

                            <input
                                value={service.Service}
                                name={inputService}
                            onChange={(e) => handleChange({ Service: e.target.value })}
                                type="text"
                                className="form-control"
                                placeholder="Enter a product or service "
                                ref={register({
                                    required: {
                                        value: true,
                                        message: "This is required"
                                    }
                                })}
                            />

                            <small className="color-danger">{errors[inputService] && errors[inputService].message}</small>
                        </FormGroup>

                       
                    </Col>


                    <Col lg="2">
                        <FormGroup>


                            <Button
                                onClick={(e) => handleServiceDelete(service.id)}
                                color="danger"
                                className="btn-block inner"
                                style={{ width: "100%" }}
                            >
                                {" "}
                                          Delete{" "}
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </>
    )
}


export default Services
