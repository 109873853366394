import {
  GET_CUSTOMER_LIST_ARCHIVED,
  GET_CUSTOMER_LIST_ARCHIVED_SUCCESS,
  GET_CUSTOMER_LIST_ARCHIVED_FAIL,
  PATCH_CUSTOMER_ARCHIVE,
  PATCH_CUSTOMER_ARCHIVE_SUCCESS,
  PATCH_CUSTOMER_ARCHIVE_FAIL,
  RETURN_STATE,
} from "./actionTypes"

const INIT_STATE = {
  customerListArchived: [],
  customerArchive: {},
  customerArchiveResult: undefined,
  loading: false,
  error: {},
}

const customerArchive = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_LIST_ARCHIVED:
      return (state = {
        ...state,
        loading: true,
      })

    case GET_CUSTOMER_LIST_ARCHIVED_SUCCESS:
      return {
        ...state,
        customerListArchived: action.payload,
        loading: false,
      }

    case GET_CUSTOMER_LIST_ARCHIVED_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case PATCH_CUSTOMER_ARCHIVE:
      return (state = {
        ...state,
        customerArchive: action.payload,
        loading: true,
      })

    case PATCH_CUSTOMER_ARCHIVE_SUCCESS:
      return {
        ...state,
        customerArchive: action.payload,
        customerArchiveResult: true,
        loading: false,
      }

    case PATCH_CUSTOMER_ARCHIVE_FAIL:
      return {
        ...state,
        error: action.payload,
        customerArchiveResult: false,
        loading: false,
      }
    case RETURN_STATE:
      return {
        ...state,
        customerArchiveResult: undefined,
      }
    default:
      return state
  }
}

export default customerArchive
