import React from 'react'
import AgencyLogo from "../../assets/images/merchant/abctravels/logo.png"
import { Col, Row, Card, CardBody } from 'reactstrap'
function TravelAgencyLogoCard() {
    return (
        <Card className="overflow-hidden ">
        <CardBody  className="pt-0 item-center">
       <Row>
           <Col>
             <img src={AgencyLogo} alt="" className="img-fluid  " />
           </Col>
       </Row>
        </CardBody>
    </Card>
    )
}

export default TravelAgencyLogoCard
