import {
    GET_MERCHANT_PG_ACCOUNT_SETTING_OID,
    GET_MERCHANT_PG_ACCOUNT_SETTING_OID_SUCCESS,
    GET_MERCHANT_PG_ACCOUNT_SETTING_OID_FAIL,
    UPDATE_MERCHANT_PG_ACCOUNT_SETTING,
    UPDATE_MERCHANT_PG_ACCOUNT_SETTING_SUCCESS,
    UPDATE_MERCHANT_PG_ACCOUNT_SETTING_FAIL,
    RETURN_STATE,
  } from "./actionTypes"

const INIT_STATE = {
  merchantPGAccountSettings: {},
  error: {},
  loading: false,
  email: false,
}

const merchantPGSettings = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_MERCHANT_PG_ACCOUNT_SETTING_OID:
      return {
        ...state,
        merchantPGAccountSettings: action.payload,
        loading: true,
      }

    case GET_MERCHANT_PG_ACCOUNT_SETTING_OID_SUCCESS:
      return {
        ...state,
        merchantPGAccountSettings: action.payload,
        loading: false,
      }

    case GET_MERCHANT_PG_ACCOUNT_SETTING_OID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

  
    case UPDATE_MERCHANT_PG_ACCOUNT_SETTING:
      return {
        ...state,
        merchantPGAccountSettings: action.payload,
        loading: true,
        email: true,
      }
    case UPDATE_MERCHANT_PG_ACCOUNT_SETTING_SUCCESS:
      return {
        ...state,
        merchantPGAccountSettings: action.payload,
        isUpdated: true,
        loading: false,
        email: false,
      }

    case UPDATE_MERCHANT_PG_ACCOUNT_SETTING_FAIL:
      return {
        ...state,
        error: action.payload,
        isUpdated: false,
        loading: false,
        email: false,
      }

    case RETURN_STATE:
      return {
        ...state,
        merchantPGAccountSettings: {},
        error: {},
        registerMerchant: undefined,
        loading: undefined,
        email: undefined,
      }

    default:
      return state
  }
}

export default merchantPGSettings
