import React from 'react'
import Amadeusbanner from "../../assets/images/images/sample-banner.jpg"
import { Row, Col } from "reactstrap"
export default function Banner(props) {

  const {banner} = props
    return (
        <Row >
          <Col md={12}>
          <img src={banner}style={{"maxWidth":"100%","height":"auto"}}></img>
          </Col>
      
      </Row>
    )
}
