import {
    SET_ITEM,
    SET_ITEM_SUCCESS,
    SET_ITEM_FAIL,
    GET_ITEM_DETAIL,
    GET_ITEM_DETAIL_SUCCESS,
    GET_ITEM_DETAIL_FAIL,
    UPDATE_ITEM,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_FAIL,
    RESET_STATE
} from "./actionTypes"

export const setItem = items =>({
    type:SET_ITEM,
    payload:items
})

export const setItemSuccess = items=>({
    type:SET_ITEM_SUCCESS,
    payload:items
})

export const setItemFail = items =>({
    tyep:SET_ITEM_FAIL,
    payload:items
})

export const getItemDetail = items=>({
    type:GET_ITEM_DETAIL,
    payload:items
})

export const getItemDetailSuccess = items=>({
    type:GET_ITEM_DETAIL_SUCCESS,
    payload:items
})

export const getItemDetailFail = items=>({
    type:GET_ITEM_DETAIL_FAIL,
    payload:items
})

export const updateItem = items=>({
    type:UPDATE_ITEM,
    payload:items
})

export const updateItemSuccess = items=>({
    type:UPDATE_ITEM_SUCCESS,
    payload:items
})

export const updateItemFail = items=>({
    type:UPDATE_ITEM_FAIL,
    payload:items
})

export const resetState = ()=>({
    type:RESET_STATE
})