import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  Col,
  Row,
  Card,
  CardBody,
  Label,
  Modal,
  ModalBody,
  Button,
  ModalFooter,
} from "reactstrap"
import {
  getPaymentGatewayList,
  setPaymentGateway,
  updateStatusChange,
} from "store/actions"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import PayGateColumns from "./PayGateColumns"
import { useDispatch } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { IsEmpty } from "react-lodash"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"

import { dateFormatString } from "../../helpers/commonhelpers/dateFormat"
import PayGateAddPopUp from "./PayGateAddPopUp"
import { toast } from "react-toastify"
import ContactList from "./PayGateAddContactList"
import SweetAlert from "react-bootstrap-sweetalert"
import PayGateUpdate from "./PayDateUpdatePopUp"
import PayGateUpdateContactList from "./PayDateUpdatePopUpContactsList"
import { CSVLink } from "react-csv"

const headers = [
  { label: "Payment Gateway Id", key: "paymentGatewayId" },
  { label: "Payment Gateway", key: "paymentGateway" },
  { label: "Status", key: "status" },
  { label: "Registration Date", key: "registrationDate" },
  { label: "Contract Start Date", key: "contractStartDate" },
  { label: "Contract End Date", key: "contractEndDate" },
  { label: "IsActive", key: "isActive" },
  { label: "Api Default Link", key: "apiDefaultLink" },
  { label: "Payment Types", key: "paymentType" },
]

function PayGateTable(props) {
  let startDate = new Date()
  let endDate = new Date(startDate)
  endDate.setMonth(endDate.getMonth() + 1) //Default is contract good for 1 month. Ask Ms.Bless if 1 month or 1 year
  const dispatch = useDispatch()
  const [
    {
      paymentGateway,
      contractStartDate,
      contractEndDate,
      paymentType,
      apiDefultLink,
      position,
      name,
      email,
      mobilePlus,
      mobile,
      modal_large,
      step,
      paymentGatewayDetails,
    },
    setPaymentRequest,
  ] = useState({
    mobilePlus: "+974",
    name: "",
    email: "",
    mobile: "",
    paymentGateway: "",
    contractStartDate: startDate.toISOString(),
    contractEndDate: endDate.toISOString(),
    paymentType: [],
    apiDefultLink: "",
    position: "",
    modal_large: false,
    step: 0,
  })
  const [contactList, setContacts] = useState([
    {
      position: "",
      name: "",
      email: "",
      mobile: "",
    },
  ])
  const [logs, setLogs] = useState({})
  const pageOptionsEmailLogs = {
    sizePerPage: 15,
    totalSize: logs.length,
    custom: true,
    onPageChange: (page, sizePerPage) => {
      handleTableChangeLogs()
    },
  }
  let searchText = ""
  const [loading, setUserLoading] = useState(props.loading)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert_activate, setconfirm_alert_activate] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [paygateDownload, setPayGateDownload] = useState([])
  const [searchResult, setSearchResult] = useState([])
  let dataDownload = []
  let form = ""
  let modalHeader = ""
  let modalFooter = ""

  const { SearchBar } = Search
  const { ExportCSVButton } = CSVExport

  useEffect(() => {
    dispatch(getPaymentGatewayList())
  }, [])

  useEffect(() => {
    setUserLoading(true)
    if (Array.isArray(props.paymentGateways)) {
      setLogs(props.paymentGateways)
    }
  }, [props.paymentGateways])

  useEffect(() => {
    setUserLoading(props.loading)
  }, [props.loading])

  useEffect(() => {}, [loading])

  useEffect(() => {
    setUserLoading(false)
    if (
      Array.isArray(logs) &&
      logs?.length !== 0 &&
      searchResult?.length === 0
    ) {
      const logsToDownloadList = new Array()
      logs.map((log, i) => {
        const logsToDownload = {
          id: log.paymentGatewayId,
          PaymentGateway: log.paymentGateway,
          Status: log.status,
          RegistrationDate: dateFormatString(log.registrationDate),
          ContractStartDate: dateFormatString(log.contractStartDate),
          ContractEndDate: dateFormatString(log.contractEndDate),
        }
        logsToDownloadList.push(logsToDownload)
      })
      //console.log(logsToDownloadList)
      setPayGateDownload(logsToDownloadList)
    }
    if (
      Array.isArray(logs) &&
      logs?.length !== 0 &&
      searchResult?.length !== 0
    ) {
      const logsToDownloadList = new Array()
      searchResult.map((log, i) => {
        const logsToDownload = {
          id: log.paymentGatewayId,
          PaymentGateway: log.paymentGateway,
          Status: log.status,
          RegistrationDate: dateFormatString(log.registrationDate),
          ContractStartDate: dateFormatString(log.contractStartDate),
          ContractEndDate: dateFormatString(log.contractEndDate),
        }
        logsToDownloadList.push(logsToDownload)
      })
      //console.log(logsToDownloadList)
      setPayGateDownload(logsToDownloadList)
    }
  }, [logs])

  useEffect(() => {
    if (contractStartDate !== undefined && contractEndDate !== undefined) {
      if (contractStartDate === contractEndDate) {
        toast.warning(
          "Contract Start Date should not be equal to Contract End Date!"
        )
      }
      if (contractStartDate >= contractEndDate) {
        toast.warning(
          "Contract Start Date should not be after Contract End Date!"
        )
        setPaymentRequest(formRequest => ({
          ...formRequest,
          contractEndDate: endDate.toISOString(),
        }))
      }
    }
  }, [contractStartDate, contractEndDate])

  useEffect(() => {
    if (props.newPaymentGateway !== undefined) {
      if (props.newPaymentGateway === true) {
        setsuccess_dlg(true)
        setdynamic_title("Success")
        setdynamic_description("Payment Gateway has been created.")
        dispatch(getPaymentGatewayList())
      }
      if (props.newPaymentGateway === false) {
        seterror_dlg(true)
        setdynamic_title("Fail")
        setdynamic_description("Payment Gateway has not been created.")
      }
    }
  }, [props.newPaymentGateway])

  useEffect(() => {
    if (props.editPaygate !== undefined) {
      if (props.editPaygate === true) {
        setsuccess_dlg(true)
        setdynamic_title("Success")
        setdynamic_description("Payment Gateway has been updated.")
        dispatch(getPaymentGatewayList())
      }
      if (props.editPaygate === false) {
        seterror_dlg(true)
        setdynamic_title("Fail")
        setdynamic_description("Payment Gateway has not been updated.")
      }
    }
  }, [props.editPaygate])

  useEffect(() => {
    if (props.statusPaygate !== undefined) {
      if (props.statusPaygate === true) {
        setconfirm_alert(false)
        setconfirm_alert_activate(false)
        setsuccess_dlg(true)
        setdynamic_title("Success")
        setdynamic_description("Payment Gateway has been deactivated.")
        dispatch(getPaymentGatewayList())
      }
      if (props.statusPaygate === false) {
        seterror_dlg(true)
        setdynamic_title("Fail")
        setdynamic_description("Payment Gateway has not been deactivated.")
      }
    }
  }, [props.statusPaygate])

  useEffect(() => {
    if (step === 2) {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        modal_large: true,
      }))
    }
  }, [step])

  useEffect(() => {
    if (modal_large === false) {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        step: 0,
      }))
    }
  }, [modal_large])

  useEffect(() => {}, [paymentGatewayDetails])

  const handleActionTableUser = value => {
    if (value === "update") {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        step: 2,
      }))
    }
    if (value === "activate") {
      setconfirm_alert_activate(true)
    }
    if (value === "deactivate") {
      setconfirm_alert(true)
    }
  }

  const handleTableChangeLogs = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setLogs(
        logs.filter(logList =>
          Object.keys(logList).some(key =>
            logList[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }
  function tog_large() {
    setPaymentRequest(formRequest => ({
      ...formRequest,
      modal_large: !modal_large,
    }))
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const handleCreatePaymentGateway = () => {
    let pType = []
    if (paymentType !== undefined) {
      paymentType.select2.map((p, i) => {
        pType.push(p.value)
      })
    }
    const payGateData = {
      PaymentGatewayId: "",
      PaymentGateway: paymentGateway,
      Status: "Active",
      RegistrationDate: startDate.toISOString(),
      ContractStartDate: contractStartDate,
      ContractEndDate: contractEndDate,
      ApiDefaultLink: apiDefultLink,
      PaymentType: pType,
      Contacts: contactList,
    }
    dispatch(setPaymentGateway(payGateData))
    setPaymentRequest(formRequest => ({
      ...formRequest,
      step: 0,
      modal_large: false,
    }))
  }

  const handleDeactivatePaymentGateway = () => {
    let payGateData = {}
    if (paymentGatewayDetails !== undefined) {
      if (paymentGatewayDetails.status === "Active") {
        payGateData = {
          ...paymentGatewayDetails,
          Status: "InActive",
        }
      }
      if (paymentGatewayDetails.status === "InActive") {
        payGateData = {
          ...paymentGatewayDetails,
          Status: "Active",
        }
      }
    }
    dispatch(updateStatusChange(payGateData))
  }

  const handleUpdatePaymentGateway = () => {
    let pType = []
    if (paymentType.select2 !== undefined) {
      paymentType.select2.map((p, i) => {
        pType.push(p.value)
      })
    }
    const payGateData = {
      _id: paymentGatewayDetails._id,
      PaymentGatewayId: paymentGatewayDetails.paymentGatewayId,
      PaymentGateway: paymentGateway
        ? paymentGateway
        : paymentGatewayDetails.paymentGateway,
      Status: paymentGatewayDetails.status,
      RegistrationDate: paymentGatewayDetails.registrationDate,
      ContractStartDate: contractStartDate
        ? contractStartDate
        : paymentGatewayDetails.contractStartDate,
      ContractEndDate: contractEndDate
        ? contractEndDate
        : paymentGatewayDetails.contractEndDate,
      ApiDefaultLink: apiDefultLink
        ? apiDefultLink
        : paymentGatewayDetails.apiDefaultLink,
      PaymentType:
        pType.length !== 0 ? pType : paymentGatewayDetails.paymentType,
      Contacts:
        contactList[0].name !== ""
          ? contactList
          : paymentGatewayDetails.contacts,
    }
    dispatch(editPaymentGateway(payGateData))
    setPaymentRequest(formRequest => ({
      ...formRequest,
      step: 0,
      modal_large: false,
    }))
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        paymentGatewayDetails: row,
      }))
    },
  }

  switch (step) {
    case 0:
      form = <PayGateAddPopUp onChange={value => setPaymentRequest(value)} />
      modalHeader = "Create Payment Gateway"
      modalFooter = (
        <Row className="item-center">
          <Col>
            <Button
              color="primary"
              className=" waves-effect waves-light btn-lg"
              onClick={() => {
                setPaymentRequest(formRequest => ({
                  ...formRequest,
                  step: 1,
                }))
              }}
            >
              Next
            </Button>
          </Col>
        </Row>
      )
      break
    case 1:
      form = (
        <>
          <Label className=" input-text-size mt-2 item-center">CONTACTS</Label>
          <ContactList onChange={value => setContacts(value)} />{" "}
        </>
      )
      modalHeader = "Create Payment Gateway"
      modalFooter = (
        <Row className="">
          <Col className="item-center mb-3">
            <Button
              color="warning"
              outline
              className=" waves-effect waves-light mt-3 btn-lg mr-2"
              onClick={() => {
                setPaymentRequest(formRequest => ({
                  ...formRequest,
                  step: 0,
                }))
              }}
            >
              <i className="fas fa-backspace mr-1" />
              Back
            </Button>

            <Button
              color="success"
              type="submit"
              className=" waves-effect waves-light mt-3 btn-lg"
              onClick={() => handleCreatePaymentGateway()}
            >
              Create New Payment Gateway
            </Button>
          </Col>
        </Row>
      )
      break
    case 2:
      form = (
        <PayGateUpdate
          paymentGatewayDetails={paymentGatewayDetails}
          onChange={value => setPaymentRequest(value)}
        />
      )
      modalHeader = "Update Payment Gateway"
      modalFooter = (
        <Row className="item-center">
          <Col>
            <Button
              color="primary"
              className=" waves-effect waves-light btn-lg"
              onClick={() => {
                setPaymentRequest(formRequest => ({
                  ...formRequest,
                  step: 3,
                }))
              }}
            >
              Next
            </Button>
          </Col>
        </Row>
      )
      break
    case 3:
      form = (
        <>
          <Label className=" input-text-size mt-2 item-center">CONTACTS</Label>
          <PayGateUpdateContactList
            paymentGatewayDetails={paymentGatewayDetails}
            onChange={value => setContacts(value)}
          />{" "}
        </>
      )
      modalHeader = "Update Payment Gateway"
      modalFooter = (
        <Row className="">
          <Col className="item-center mb-3">
            <Button
              color="warning"
              outline
              className=" waves-effect waves-light mt-3 btn-lg mr-2"
              onClick={() => {
                setPaymentRequest(formRequest => ({
                  ...formRequest,
                  step: 2,
                }))
              }}
            >
              <i className="fas fa-backspace mr-1" />
              Back
            </Button>

            <Button
              color="primary"
              type="submit"
              className=" waves-effect waves-light mt-3 btn-lg"
              onClick={() => handleUpdatePaymentGateway()}
            >
              Update Payment Gateway
            </Button>
          </Col>
        </Row>
      )
      break
    default:
      form = <PayGateAddPopUp />
  }
  return (
    <Row>
      <Col>
        {confirm_alert ? (
          <SweetAlert
            title="Deactivate Payment Gateway"
            warning
            showCancel
            confirmButtonText="Confirm"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              handleDeactivatePaymentGateway()
            }}
            onCancel={() => setconfirm_alert(false)}
          >
            Deactivate the selected payment gateway.
          </SweetAlert>
        ) : null}

        {confirm_alert_activate ? (
          <SweetAlert
            title="Activate Payment Gateway"
            warning
            showCancel
            confirmButtonText="Confirm"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              handleDeactivatePaymentGateway()
            }}
            onCancel={() => setconfirm_alert_activate(false)}
          >
            Activate the selected payment gateway.
          </SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            timeout={1000}
            success
            showConfirm={false}
            title={dynamic_title}
            onConfirm={() => {
              setsuccess_dlg(false)
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}

        {error_dlg ? (
          <SweetAlert
            timeout={1000}
            error
            showConfirm={false}
            title={dynamic_title}
            onConfirm={() => {
              seterror_dlg(false)
            }}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}

        <Modal size="lg" isOpen={modal_large}>
          <div className="modal-header">
            {modalHeader}
            <h5 className="modal-title mt-0" id="myLargeModalLabel"></h5>
            <button
              onClick={() => {
                setPaymentRequest(formRequest => ({
                  ...formRequest,
                  modal_large: false,
                }))
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span caria-hidden="true" className="color-danger my-auto">
                {" "}
                x
              </span>
            </button>
          </div>
          <ModalBody>
            {loading ? (
              <Row className="spinner-custom mx-auto my-auto pb-3">
                <LoadingSpinnerThreeDots />
              </Row>
            ) : (
              form
            )}
          </ModalBody>
          <ModalFooter className="item-center">{modalFooter}</ModalFooter>
        </Modal>
        <Card>
          <CardBody>
            <Label> Payment Gateways</Label>
            {loading ? (
              <Row className="spinner-custom mx-auto my-auto pb-3">
                <LoadingSpinnerThreeDots />
              </Row>
            ) : (
              <IsEmpty
                value={logs}
                yes={() => (
                  <Row>
                    <Col>No list</Col>
                  </Row>
                )}
                no={() => (
                  <PaginationProvider
                    pagination={paginationFactory(pageOptionsEmailLogs)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={logs || []}
                        columns={PayGateColumns(handleActionTableUser)}
                        bootstrap4
                        search={{
                          afterSearch: newResult => {
                            setSearchResult(newResult)
                          },
                        }}
                        exportCSV={{
                          fileName: "PaymentGatewayList.csv",
                          separator: ",",
                          noAutoBOM: false,
                          exportAll: false,
                          onlyExportFiltered: true,
                        }}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2  mx-2">
                              <Col sm="4">
                                <div className="search-box mr-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="width-search"
                                      placeholder="Search "
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-right">
                                  <CSVLink
                                    color="warning"
                                    filename={"PaymentGatewayList.csv"}
                                    className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                                    data={paygateDownload}
                                    key="_id"
                                    target="_blank"
                                    separator={","}
                                  >
                                    <i className="bx bx-download label-icon mr-1" />
                                    Download Payment Gateways
                                  </CSVLink>
                                  {/* <ExportCSVButton
                                    color="warning"
                                    className="btn btn-warning btn-rounded waves-effect waves-light  mb-2 mr-2"
                                    {...toolkitProps.csvProps}
                                  >
                                    <i className="bx bx-download label-icon mr-1" />
                                    Download Payment Gateways
                                  </ExportCSVButton> */}
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                    onClick={() => {
                                      tog_large()
                                    }}
                                  >
                                    <i className="mdi mdi-account-multiple-plus mr-1" />
                                    New Payment Gateway
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    hover={true}
                                    striped={false}
                                    classes={
                                      "table table-centered pointer table-nowrap table-hover mr-5 mb-5"
                                    }
                                    rowEvents={rowEvents}
                                    keyField="_id"
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChangeLogs(
                                      searchText
                                    )}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                )}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
const mapStateToProps = state => {
  return {
    loading: state.PaymentGateway.loading,
    paymentGateways: state.PaymentGateway.paymentGateways,
    newPaymentGateway: state.PaymentGateway.newPaymentGateway,
    editPaygate: state.PaymentGateway.editPaygate,
    statusPaygate: state.PaymentGateway.statusPaygate,
  }
}
export default connect(mapStateToProps)(PayGateTable)
