import React, { useEffect, useState } from "react"
import { Col, Row, Card, CardBody, Label } from "reactstrap"
import TransactionUpdateLogsColumns from "./TransactionUpdateLogsColumns"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import UpdateTransactionModal from "./UpdateTransactionModal"

function LogsTransactionUpdateTable(props) {
  const {
    logsTransactionUpdateList,
    paymentHistory,
    errorPaymentHistory,
    isLoadedPaymentHistory,
    isLoadedEmail,
    isLoadedApprove,
    isErrorEmail,
    handleOpenOverviewPrId,
  } = props

  const [logsTrans, setLogsTrans] = useState()
  const [selectedRow, setSelectedRow] = useState({})
  const [modal, setModal] = useState(false)

  const pageOptionsTransLogs = {
    sizePerPage: 20,
    totalSize: logsTrans?.length,
    custom: true,
    onPageChange: (page, sizePerPage) => {
      handleTableChangeTransLogs()
    },
  }
  let searchText = ""

  const { SearchBar } = Search
  const { ExportCSVButton } = CSVExport

  useEffect(() => {
    setLogsTrans(logsTransactionUpdateList)
  }, [logsTransactionUpdateList])

  const handleTableChangeTransLogs = searchText => {
    if (searchText !== "" && searchText !== undefined) {
      setLogsTrans(
        logsTrans.filter(logs =>
          Object.keys(logs).some(key =>
            logs[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }
  }

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      setSelectedRow(row)
      toggleModal()
    },
    // onMouseEnter: (e, row, rowIndex) => {
    //   console.log(`enter on row with index: ${rowIndex}`);
    // }
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <UpdateTransactionModal
              isOpen={modal}
              toggle={toggleModal}
              selectedRow={selectedRow}
              paymentHistory={paymentHistory}
              errorPaymentHistory={errorPaymentHistory}
              isLoadedPaymentHistory={isLoadedPaymentHistory}
              isLoadedEmail={isLoadedEmail}
              isLoadedApprove={isLoadedApprove}
              isErrorEmail={isErrorEmail}
            />

            <PaginationProvider
              pagination={paginationFactory(pageOptionsTransLogs)}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="_id"
                  data={logsTrans || []}
                  columns={TransactionUpdateLogsColumns(handleOpenOverviewPrId)}
                  bootstrap4
                  search
                  exportCSV={{
                    fileName: "TransactionUpdateLogs.csv",
                    separator: ",",
                    noAutoBOM: false,
                    exportAll: false,
                    onlyExportFiltered: true,
                  }}
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2  mx-2">
                        <Col sm="4">
                          <div className="search-box mr-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar
                                {...toolkitProps.searchProps}
                                className="width-search"
                                placeholder="Search"
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField="_id"
                              rowEvents={tableRowEvents}
                              responsive
                              bordered={false}
                              hover={true}
                              striped={false}
                              classes={
                                "table table-centered pointer table-nowrap table-hover mr-5 mb-5"
                              }
                              //rowEvents={rowEvents}

                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              onTableChange={handleTableChangeTransLogs(
                                searchText
                              )}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default LogsTransactionUpdateTable
