import { call, put, takeEvery, takeLatest, take } from "redux-saga/effects"
import { setItem, getItemDetail, updateItem } from "helpers/fakebackend_helper"

import { SET_ITEM, GET_ITEM_DETAIL, UPDATE_ITEM } from "./actionTypes"
import {
  setItemSuccess,
  setItemFail,
  getItemDetailSuccess,
  getItemDetailFail,
  updateItemSuccess,
  updateItemFail,
  resetState,
} from "./actions"
function* _setItem({ payload: items }) {
  // try{
  //    const response= yield call(setItem,items)
  //   if(response){
  //     yield put(setItemSuccess(response))
  //   }
  //   else{
  //     yield put(setItemFail(response))
  //   }
  //   yield put(resetState())
  // }
  // catch(error){
  //   yield put(setItemFail(response))
  // }
  try {
    const response = yield call(setItem, items)

    if (response) {
      if (response.isSuccess) yield put(setItemSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              setItemFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(setItemFail(response))
        }
      }
    } else
      yield put(
        setItemFail({
          message: "Invalid Response.",
        })
      )
  } catch (error) {
    yield put(setItemFail(error?.message))
  }
}
function* _getItemDetail({ payload: items }) {
  // try {
  //   const response = yield call(getItemDetail, items)
  //   if (response) {
  //     yield put(getItemDetailSuccess(response))
  //   } else {
  //     yield put(getItemDetailFail(response))
  //   }
  // } catch (error) {
  //   yield put(getItemDetailFail(error))
  // }
  try {
    const response = yield call(getItemDetail, items)

    if (response) {
      if (response.isSuccess) yield put(getItemDetailSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getItemDetailFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(getItemDetailFail(response))
        }
      }
    } else
      yield put(
        getItemDetailFail({
          message: items + " Invalid Response.",
        })
      )
  } catch (error) {
    yield put(getItemDetailFail(error?.message))
  }
}

function* _updateItem({ payload: items }) {
  // try {
  //   const response = yield call(updateItem, items)
  //   if (response) {
  //     yield put(updateItemSuccess(response))
  //   } else {
  //     yield put(updateItemFail(response))
  //   }
  // } catch (error) {
  //   yield put(updateItemFail(response))
  // }
  try {
    const response = yield call(updateItem, items)

    if (response) {
      if (response.isSuccess) yield put(updateItemSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              updateItemFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
          else yield put(updateItemFail(response))
        }
      }
    } else
      yield put(
        updateItemFail({
          message: "Invalid Response.",
        })
      )
  } catch (error) {
    yield put(updateItemFail(error?.message))
  }
}

function* itemSaga() {
  yield takeEvery(SET_ITEM, _setItem)
  yield takeEvery(GET_ITEM_DETAIL, _getItemDetail)
  yield takeEvery(UPDATE_ITEM, _updateItem)
}

export default itemSaga
