import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import PGSettingsForm from "./PGSettingsForm"
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Progress,
  Row,
  TabContent,
  TabPane,
  Button,
  Form,
} from "reactstrap"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PayAllLinkLogo from "../../assets/images/images/payalllink-logo@2x.png"
import {
  getMerchantPGAccountSettingByOid,
  updateMerchantPGAccontSettings,
} from "store/actions"
import errorCatching from "../../helpers/commonhelpers/errorCatching"
import { isEmpty, set } from "lodash"
import SweetAlert from "react-bootstrap-sweetalert"
import LoadingModal from "./LoadingModal"


const initialState = {
  merchantId: null,
  merchantName: "",
  paymentGateway: "",
  date: new Date().toISOString(),
  apiKey: "",
  isActive: false,
}

export const MerchantPGAccountSettings = props => {
  const {
    onGetMerchantPGAccountSettingByOid,
    onUpdateMerchantPGAccountSetting,
    loading,
    match: { params },
    merchantPGAccountSettings,
    error,
    isUpdatedSuccess
  } = props

 
  const [merchantDetails, setMerchantDetails] = useState(initialState)
  const [errorResponse, setErrorResponse] = useState(undefined)
  const [loadingModal, setLoadingModal] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [error_dlg, seterror_dlg] = useState(false)
  const [dynamic_description, setdynamic_description] = useState("")
  const [dynamic_title, setdynamic_title] = useState("")


  useEffect(() => {
    if (params && params.id) {
      onGetMerchantPGAccountSettingByOid(params.id)
    }
  }, [params, onGetMerchantPGAccountSettingByOid])

  useEffect(() => {
    if (merchantPGAccountSettings) {
      setMerchantDetails(merchantPGAccountSettings)
    }
  }, [merchantPGAccountSettings])

  useEffect(() => {
    if (error !== undefined && !isEmpty(error) && error !== {}) {
      setErrorResponse(error)
    }
  }, [error])

  useEffect(() => {}, [errorResponse])
  useEffect(() => {setLoadingModal(loading)}, [loading])

  useEffect(() => {

    if(isUpdatedSuccess){
      setsuccess_dlg(true)
      setdynamic_title("Successful")
      setdynamic_description(
        "You have successfully submitted your Payment Gateway info. We will get in touch with you shortly."
      )

    }
    if(isUpdatedSuccess === false){
      seterror_dlg(true)
      setdynamic_title("Failed")
      setdynamic_description(
        "Something went wrong, please contact amadeus helpdesk."
      )
    }
  
  },[isUpdatedSuccess])

  function handleSubmit() {
    if (merchantDetails.apiKey !== "" && merchantDetails.isCompletedSteps) {
      onUpdateMerchantPGAccountSetting(merchantDetails)
    }
  }

  function handleMerchantPGAccountSettings(changes) {
    setMerchantDetails(changes)
  }

  const toggleModal = () => {
    setLoadingModal(!loadingModal)
  }

  return (
    <div>
      {errorCatching(errorResponse, setErrorResponse)}
      {loading &&
            <>
              <LoadingModal isOpen={loadingModal} toggle={toggleModal} />
            </>
      }
      {success_dlg ? (
            <SweetAlert
              success
              showConfirm={true}
              title={dynamic_title}
              confirmBtnBsStyle="success"
              confirmButtonText="Confirm"
              onConfirm={() => {
                window.location.reload()
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          {error_dlg ? (
            <SweetAlert
              // timeout={4000}
              error
              showConfirm={true}
              title={dynamic_title}
              confirmBtnBsStyle="danger"
              confirmButtonText="Confirm"
              onConfirm={() => {
                seterror_dlg(false)
                window.location.reload()
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

      <Col sm="12" md={{ size: 7, offset: 2 }}>
        <Container fluid={false} className="my-2 mx-2">
          <Link to="/">
            <img className="logo " height="60" src={PayAllLinkLogo} />
          </Link>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PGSettingsForm
                    merchantDetails={merchantDetails}
                    handleMerchantPGAccountSettings={
                      handleMerchantPGAccountSettings
                    }
                    handleSubmit={handleSubmit}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Col>
    </div>
  )
}

MerchantPGAccountSettings.propTypes = {
  // merchantPGAccountSettings: PropTypes.object,
}

const mapStateToProps = state => (
  {
    merchantPGAccountSettings:
    state.MerchantPGAccount.merchantPGAccountSettings,
    loading: state.MerchantPGAccount.loading,
    error: state.MerchantPGAccount.error,
    isUpdatedSuccess: state.MerchantPGAccount.isUpdated,
  }
)

const mapDispatchToProps = dispatch => ({
  onGetMerchantPGAccountSettingByOid: id =>
    dispatch(getMerchantPGAccountSettingByOid(id)),
  onUpdateMerchantPGAccountSetting: data =>
    dispatch(updateMerchantPGAccontSettings(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchantPGAccountSettings)
