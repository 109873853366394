import {
  SET_ITEM,
  SET_ITEM_SUCCESS,
  SET_ITEM_FAIL,
  GET_ITEM_DETAIL,
  GET_ITEM_DETAIL_SUCCESS,
  GET_ITEM_DETAIL_FAIL,
  UPDATE_ITEM,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAIL,
  RESET_STATE
} from "./actionTypes"

const initialState = {
  items: {}
}


const items = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEM:
      state = {
        ...state,
        items: action.payload,
        itemCreated:undefined
      }
      break
    case SET_ITEM_SUCCESS:
      state = {
        ...state,
        ItemId: action.payload.itemId,
        itemCreated: true
      }
      break
    case SET_ITEM_FAIL:
      state = {
        ...state,
        ItemId: action.payload.itemId,
        itemCreated:false
      }
      break
    case GET_ITEM_DETAIL:
      state = {
        ...state,
        items: action.payload
      }
      break
    case GET_ITEM_DETAIL_SUCCESS:
      state = {
        ...state,
        items: action.payload
      }
      break
    case GET_ITEM_DETAIL_FAIL:
      state = {
        ...state,
        items: action.payload
      }
      break
    case UPDATE_ITEM:
      state = {
        ...state,
        items: action.payload
      }
      break
    case UPDATE_ITEM_SUCCESS:
      state = {
        ...state,
        items: action.payload
      }
      break
    case UPDATE_ITEM_FAIL:
      state = {
        ...state,
        items: action.payload
      }
      break
    case RESET_STATE:
      state={
        ...state,
        itemCreated:undefined
      }
    default:
      state = state
      break
  }

  return state
}

export default items
