import {
    MAIN_SEARCH_TEXT,
    MAIN_SEARCH_TEXT_SUCCESS,
    MAIN_SEARCH_TEXT_FAIL,
    MAIN_SEARCH_FILTER_DATE_RANGE,
    MAIN_SEARCH_FILTER_DATE_RANGE_SUCCESS,
    MAIN_SEARCH_FILTER_DATE_RANGE_FAIL,
    MAIN_SEARCH_FILTER_DROPDOWN,
    MAIN_SEARCH_FILTER_DROPDOWN_SUCCESS,
    MAIN_SEARCH_FILTER_DROPDOWN_FAIL,
    RESET_FILTER_VALUES,
    RESET_FILTER_VALUES_SUCCESS,
    RESET_FILTER_VALUES_FAIL,
    MAIN_SEARCH_LIST_RESET,
    MAIN_SEARCH_LIST_RESET_SUCCESS,
    MAIN_SEARCH_LIST_RESET_FAIL,
    RESET_VALUES
  } from "./actionTypes"
  
  
  export const mainSearchText = search =>({
    type:MAIN_SEARCH_TEXT,
    payload:search
  })

  export const mainSearchTextSuccess = search =>({
    type:MAIN_SEARCH_TEXT_SUCCESS,
    payload:search
  })
  export const mainSearchTextFail = search =>({
    type:MAIN_SEARCH_TEXT_FAIL,
    payload:search
  })
  export const mainSearchDateRange = search =>({
    type:MAIN_SEARCH_FILTER_DATE_RANGE,
    payload:search
  })

  export const mainSearchDateRangeSuccess = search =>({
    type:MAIN_SEARCH_FILTER_DATE_RANGE_SUCCESS,
    payload:search
  })

  export const mainSearchDateRangeFail = search =>({
    type:MAIN_SEARCH_FILTER_DATE_RANGE_FAIL,
    payload:search
  })
  
  export const mainSearchDropdown = search =>({
    type:MAIN_SEARCH_FILTER_DROPDOWN,
    payload:search
  })

  export const mainSearchDropdownSuccess = search =>({
    type:MAIN_SEARCH_FILTER_DROPDOWN_SUCCESS,
    payload:search
  })
  export const mainSearchDropdownFail = search =>({
    type:MAIN_SEARCH_FILTER_DROPDOWN_FAIL,
    payload:search
  })


  export const resetFilterValues =()  =>({
    type:RESET_FILTER_VALUES,
  })
  export const resetFilterValuesSuccess = () =>({
    type:RESET_FILTER_VALUES_SUCCESS,
  })
  export const resetFilterValuesFail = () =>({
    type:RESET_FILTER_VALUES_FAIL,
  })

  export const mainSearchListReset = (search) =>({
    type:MAIN_SEARCH_LIST_RESET,
    payload: search
  })

  export const mainSearchListResetSuccess = (search) =>({
    type:MAIN_SEARCH_LIST_RESET_SUCCESS,
    payload: search
  })

  export const mainSearchListResetFail = (search) =>({
    type:MAIN_SEARCH_LIST_RESET_FAIL,
    payload: search
  })

  export const resetValue = () =>({
    type:RESET_VALUES
  })