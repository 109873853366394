import React from "react"
import { Link } from "react-router-dom"
import {  UncontrolledTooltip } from "reactstrap"
import dateFormat from '../../../helpers/commonhelpers/dateFormat'

const OfficeIdColumns = (user) =>{

  const isHidden = () => {
    if(user.role === "Admin") return true
    else return false
  }



  return(
    [
  
 
      {
        dataField: "officeId",
        text: "OfficeId",
        sort: true,
      },
      {
        dataField: "date",
        text: "Date Added",
        sort: true,
        formatter: (cellContent, row) => {
            return <span>
    
              {dateFormat(cellContent)}
    
            </span>
          }
      },
      
      // {
      //   dataField:"_id",
      //   hidden:isHidden(),
      //   text: "Action",
      //   formatter: () => (
      //     <>
      //       <Link to="#" className="mr-3 text-primary">
      //         <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip" />
      //         <UncontrolledTooltip placement="top" target="edittooltip">
      //           Edit
      //         </UncontrolledTooltip>
      //       </Link>
      //       <Link to="#" className="text-danger">
      //         <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip" />
      //         <UncontrolledTooltip placement="top" target="deletetooltip">
      //           Delete
      //         </UncontrolledTooltip>
      //       </Link>
      //     </>
      //   ),
      // },
    ]

  )
}

export default OfficeIdColumns
