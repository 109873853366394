import {
  GET_COUNTRY_CODE,
  GET_COUNTRY_CODE_SUCCESS,
  GET_COUNTRY_CODE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  loading: false,
  countryCodes: [],
  error: "",
}

const options = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNTRY_CODE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_COUNTRY_CODE_SUCCESS:
      state = {
        ...state,
        countryCodes: action.payload,
        loading: false,
      }
      break
    case GET_COUNTRY_CODE_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break

    default:
      return state
  }
  return state
}

export default options
