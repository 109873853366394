import React, { useContext, useState, useEffect } from "react"
import { Col, Row, Form, FormGroup, Label, Input, Button } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { PaymentMethodContext } from "../../index"

const ApplePayEmbedded = props => {
  const {mfApplePayEmbedded, paymentRequestDetails} = props
  const { onSetMyfatoorahInitiateSessionData } = useContext(
    PaymentMethodContext
  )

  useEffect(() => {
    const applePayScript = document.createElement("script")
    applePayScript.src = mfApplePayEmbedded?.paymentUrl
    applePayScript.async = true

    applePayScript.onload = function handleScriptLoaded() {

      var config = {
        sessionId: mfApplePayEmbedded?.sessionId, // Here you add the "SessionId" you receive from InitiateSession Endpoint.
        countryCode: mfApplePayEmbedded?.countryCode, // Here, add your Country Code. 
        currencyCode: paymentRequestDetails?.currency, // Here, add your Currency Code.
        amount: paymentRequestDetails?.amount, // Add the invoice amount.
        cardViewId: "card-element-applepay",
        callback: payment(),
        sessionStarted: myFatoorahAP.sessionStarted,
        sessionCanceled: myFatoorahAP.sessionCanceled   
    };
    myFatoorahAP.init(config);
    }
    applePayScript.onerror = function handleScriptError() {
      console.log("error loading script")
    }
    document.body.appendChild(applePayScript)
    return () => {
      document.body.removeChild(applePayScript)
    }
  }, [])




  const payment = () => {
    const paymentScript = document.createElement("script")
    paymentScript.src = mfApplePayEmbedded?.paymentUrl
    paymentScript.onload = function handleScriptLoaded() {
     
      window.addEventListener("message", event => {
        const { data } = event
        if (isJsonString(data) === true) {
          var jsonData = JSON.parse(data)
          var model = {
            SessionId: jsonData.data.Data.SessionId,
            TrackId: mfApplePayEmbedded?.trackId,
            PaymentMethod: "applepay"
          }
          onSetMyfatoorahInitiateSessionData(model)
        }
      });
    }
    document.body.appendChild(paymentScript)
  }


  function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
 
  return (
    <>
      <div className="">
      

        <div style={{height:"45px", backgroundColor: "black", borderRadius: "0.30rem"}} 
        className="btn-block inner mb-2 fw-medium"
        >
          <div id="card-element-applepay"></div>
        </div>

        
      </div>
    </>
  )
}

ApplePayEmbedded.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(ApplePayEmbedded))
