import React, { useState } from 'react'
import { Col, Row, Button, InputGroup, InputGroupAddon, Input, Label } from "reactstrap"

function PayGateUpdateContactList(props) {
    const [contactList, setContacts] = useState([{
        position: "",
        name: "",
        email: "",
        mobile: ""
    }]);
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...contactList];

        list[index][name] = value;

        setContacts(list);
        props.onChange(list)
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...contactList];
        list.splice(index, 1);
        setContacts(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setContacts([...contactList, {
            position: "",
            name: "",
            email: "",
            mobilePlus: "+974",
            mobile: ""
        }]);
    };
    return (
        <div>
            {contactList.map((x, i) => {
                return (
                    <Row className=" my-3 form-group" key={i}>
                        <Col >
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <Label className="input-group-text input-text-size">
                                                NAME
                                    </Label>
                                        </InputGroupAddon>
                                        <input
                                            type="text"
                                            value={x.name}
                                            name="name"
                                            className="form-control form-control-lg input-text-size"
                                            onChange={e => handleInputChange(e, i, props.onChange)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <Label className="input-group-text input-text-size">
                                                POSITION
                                    </Label>
                                        </InputGroupAddon>
                                        <input
                                            type="text"
                                            value={x.position}
                                            name="position"
                                            className="form-control form-control-lg input-text-size"
                                            onChange={e => handleInputChange(e, i, props.onChange)}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col>


                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <Label className="input-group-text input-text-size">

                                                EMAIL
                                    </Label>
                                        </InputGroupAddon>
                                        <input
                                            type="email"
                                            value={x.email}
                                            name="email"
                                            className="form-control form-control-lg input-text-size"
                                            onChange={e => handleInputChange(e, i, props.onChange)}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <Label className="input-group-text input-text-size">
                                                MOBILE
                            </Label>
                                        </InputGroupAddon>
                                        <select className="form-control  custom-select input-text-size"
                                            id="mobilePlus"
                                            name="mobilePlus"
                                            value={x.mobilePlus}
                                            onChange={e => handleInputChange(e, i, props.onChange)}>
                                            <option>+974</option>
                                            <option>+639</option>
                                        </select>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup className="mb-3" >
                                        <input
                                            id="mobile"
                                            name="mobile"
                                            value={x.mobile}
                                            type="number"
                                            className="form-control form-control-lg input-text-size"
                                            onChange={e => handleInputChange(e, i, props.onChange)}
                                        />
                                    </InputGroup>

                                </Col>



                            </Row>
                            <Row className="item-center">

                                {contactList.length !== 1 &&
                                    <Button
                                        onClick={() => handleRemoveClick(i)}
                                        className=" bnt btn-lg"
                                        color="danger" outline>
                                        <i className="fas fa-minus font-size-15 mr-1 color-danger" id="deletetooltip" />
                                 REMOVE CONTACT
                                </Button>}
                                {contactList.length - 1 === i &&
                                    <Button onClick={handleAddClick}
                                        className="mx-2 btn "
                                        color="primary" outline>
                                        <i className="fas fa-plus font-size-15 mr-1 color-primary" id="deletetooltip" />
                             ADD CONTACT
                             </Button>}

                            </Row>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default PayGateUpdateContactList
