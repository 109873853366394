import React from "react"
import dateFormat from '../../helpers/commonhelpers/dateFormat'

const ErrorLogsColumns = () =>[
    {
        dataField: "_id",
        text: "Id",
        searchable: true,
        sort: true,
    },
    {
        dataField: "userId",
        text: "User Id",
        searchable: true,
        sort: true,
    },
    {
        dataField: "date",
        text: "Date",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
            return (
              <span>
               {dateFormat(cellContent)}
              </span>
            )
          }
    },
    {
        dataField: "file",
        text: "File",
        searchable: true,
        sort: true,
    },
    {
        dataField: "message",
        text: "Message",
        searchable: true,
        sort: true,
    },
    
    {
        dataField: "stackTrace",
        text: "Stack Trace",
        searchable: true,
        sort: true,
    },
    
   
]
export default ErrorLogsColumns