import React, { useState } from "react"
import {
  Col,
  Row,
  Form,
  Container,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap"
import Rating from "react-rating"
import { isEmpty } from "lodash"
import RatingTooltip from "react-rating-tooltip"
import { useForm } from "react-hook-form"

export default function Questions(props) {
  const {
    surveyAnswers,
    handleChangeSurveyAnswers,
    handleSetSurveyAnswers,
    loadingSurvey,
  } = props
  const { register, handleSubmit, errors, reset, watch } = useForm()

  function handleChange(changes) {
    handleChangeSurveyAnswers({ ...surveyAnswers, ...changes })
  }
  const starStyle = {}
  const [customize, setcustomize] = useState("")

  const onSubmit = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      handleSetSurveyAnswers()
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-2" style={{ justifyContent: "left !important" }}>
          <Row>
            {/* <Col xl="3" md="4" sm="6"> */}
            <Col lg="12">
              <span className="font-16">
                {" "}
                Rate your experience with our product.
                {surveyAnswers.Rate > 0 && (
                  <span>
                    {" "}
                    <b>You rated: {surveyAnswers.Rate}</b>
                  </span>
                )}{" "}
              </span>

              <small className="color-danger">
                {errors.Rating && errors.Rating.message}
              </small>

              <RatingTooltip
                style={{ justifyContent: "left !important" }}
                max={5}
                value={surveyAnswers.Rate}
                onChange={rate => handleChange({ Rate: parseInt(rate || "") })}
                ActiveComponent={
                  <i
                    // key={"active_4"}
                    className="mdi mdi-heart text-danger"
                    style={starStyle}
                  />
                }
                InActiveComponent={
                  <i
                    // key={"active_04"}
                    className="mdi mdi-heart-outline text-danger"
                    style={starStyle}
                  />
                }
                // ref={register({
                //   required: {
                //     value: true,
                //     message: "This is required",
                //   },
                //   min: {
                //     value: 1,
                //     message: "Not a valid amount",
                //   },
                // })}
                name="Rating"
              />
              {errors.surveyAnswers?.Rate &&
                errors.surveyAnswers?.Rate?.message}
            </Col>
          </Row>
        </div>
        <div className="p-2" style={{ justifyContent: "left !importnat" }}>
          <Row>
            <Col lg="12">
              <span className="font-16">
                {" "}
                What feature could we improve or add to make your experience
                better?{" "}
              </span>
              <input
                value={surveyAnswers.FeatureSuggestion}
                onChange={e =>
                  handleChange({ FeatureSuggestion: e.target.value })
                }
                name="FeatureSuggestion"
                type="text"
                className="form-control"
                placeholder="Enter your suggestions"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />
              <small className="color-danger">
                {errors?.FeatureSuggestion &&
                  errors?.FeatureSuggestion?.message}
              </small>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="mt-4">
                {loadingSurvey === true && (
                  <Button color="primary" style={{ width: "100%" }} disabled>
                    {" "}
                    <i class="fas fa-circle-notch fa-spin"></i>
                  </Button>
                )}

                {loadingSurvey === false && (
                  <Button color="success" style={{ width: "100%" }} disabled>
                    {" "}
                    <i className="fas fa-check" /> Feedback Submitted
                    Successfully!{" "}
                  </Button>
                )}

                {loadingSurvey === undefined && (
                  <Button
                    color="primary"
                    type="submit"
                    style={{ width: "100%" }}
                  >
                    {" "}
                    Submit Feedback
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  )
}
