import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { GET_USERS_LIST_ARCHIVED, PATCH_USER_ARCHIVE } from "./actionTypes"
import {
  getUsersListArchivedSuccess,
  getUsersListArchivedFail,
  patchUserArchiveSuccess,
  patchUserArchiveFail,
  returnState,
} from "./actions"

//Include Both Helper File with needed methods

import {
  getUsersListArchived,
  patchUserArchive,
} from "helpers/fakebackend_helper"

function* _getUsersListArchived({ payload: data }) {
  // try {
  //   const response = yield call(getUsersListArchived, data)
  //   if (response) {
  //     yield put(getUsersListArchivedSuccess(response))
  //   } else {
  //     yield put(getUsersListArchivedFail(response))
  //   }
  // } catch (error) {
  //   yield put(getUsersListArchivedFail(error))
  // }
  try {
    const response = yield call(getUsersListArchived, data)

    if (response) {
      if (response?.isSuccess)
        yield put(getUsersListArchivedSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              getUsersListArchivedFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getUsersListArchivedFail(response.message))
      }
    } else yield put(getUsersListArchivedFail({ message: "Invalid Response." }))
    yield put(returnState())
  } catch (error) {
    yield put(getUsersListArchivedFail(error?.message))
  }
}

function* _patchUserArchive({ payload: data }) {
  try {
    const response = yield call(patchUserArchive, data)

    if (response) {
      if (response?.isSuccess) yield put(patchUserArchiveSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
              patchUserArchiveFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(patchUserArchiveFail(response.message))
      }
    } else yield put(patchUserArchiveFail({ message: "Invalid Response." }))
    yield put(returnState())
  } catch (error) {
    yield put(patchUserArchiveFail(error?.message))
  }
}

function* paymentrequestsArchiveSaga() {
  yield takeEvery(GET_USERS_LIST_ARCHIVED, _getUsersListArchived)
  yield takeEvery(PATCH_USER_ARCHIVE, _patchUserArchive)
}

export default paymentrequestsArchiveSaga
