import React from 'react'
import {
    Col,
    Row,
    CardTitle
} from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

const ServicesOffered = (props)=> {
  

    const { servicesOffered } = props
    return (
        <>
            

                    {servicesOffered?.map(
                        (s,index) =>
                       
                       <small key={index}>{ (index ? ', ' : '') + s.service }</small> 
                       
                        )}

       


        </>
    )
}

ServicesOffered.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
  }
  
  export default withRouter(withTranslation()(ServicesOffered))
