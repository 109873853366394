import {
  GET_USERS_LIST_ARCHIVED,
  GET_USERS_LIST_ARCHIVED_SUCCESS,
  GET_USERS_LIST_ARCHIVED_FAIL,
  PATCH_USER_ARCHIVE,
  PATCH_USER_ARCHIVE_SUCCESS,
  PATCH_USER_ARCHIVE_FAIL,
  RETURN_STATE,
} from "./actionTypes"

const INIT_STATE = {
  usersListArchived: [],
  userArchive: {},
  userArchiveResult: undefined,
  loading: false,
  error: {},
}

const userArchived = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_LIST_ARCHIVED:
      return (state = {
        ...state,
        loading: true,
      })

    case GET_USERS_LIST_ARCHIVED_SUCCESS:
      return {
        ...state,
        usersListArchived: action.payload,
        loading: false,
      }

    case GET_USERS_LIST_ARCHIVED_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case PATCH_USER_ARCHIVE:
      return (state = {
        ...state,
        userArchive: action.payload,
        loading: true,
      })

    case PATCH_USER_ARCHIVE_SUCCESS:
      return {
        ...state,
        userArchive: action.payload,
        userArchiveResult: true,
        loading: false,
      }

    case PATCH_USER_ARCHIVE_FAIL:
      return {
        ...state,
        error: action.payload,
        userArchiveResult: false,
        loading: false,
      }

    case RETURN_STATE:
      return {
        ...state,
        userArchiveResult: undefined,
      }
    default:
      return state
  }
}

export default userArchived
