import React from 'react'
import {
    Col,
    FormGroup,
    Input,
    Row,
    Button,
    Form
} from "reactstrap"

const NotificationEmails = (props) => {

    const {
        notificationEmail,
        handleNotificationChange,
        handleNotificationDelete,
        handleSubmit,
        onSubmit,
        register,
        errors,
        index
    } = props

    let inputEmail = "Email" + index

    function handleChange(changes) {
        handleNotificationChange(notificationEmail.id, { ...notificationEmail, ...changes })
    }



    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} >
                <Row >
                    <Col lg="8">
                        <FormGroup>

                            <input
                                value={notificationEmail.Email}
                                name={inputEmail}
                                onChange={(e) => handleChange({ Email: e.target.value })}
                                type="email"
                                className="form-control"
                                placeholder="Enter Email"
                                ref={register({
                                    required: {
                                        value: true,
                                        message: "This is required"
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid email address"
                                    }
                                })}
                            />

                            <small className="color-danger">{errors[inputEmail] && errors[inputEmail].message}</small>
                        </FormGroup>

                        <FormGroup>

                            <Input
                                hidden
                                defaultValue={notificationEmail.Date}
                                readOnly
                                name="Date"
                                type="text"
                                className="form-control"
                                placeholder=""
                            />
                        </FormGroup>
                    </Col>


                    <Col lg="2">
                        <FormGroup>


                            <Button
                                onClick={() => handleNotificationDelete(notificationEmail.id)}
                                color="danger"
                                className="btn-block inner"
                                style={{ width: "100%" }}
                            >
                                {" "}
                                          Delete{" "}
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </>
    )
}


export default NotificationEmails
