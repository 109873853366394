import React, { useState, useEffect } from "react"
import {
  Card, CardBody, Col, Container, NavItem,
  NavLink, Progress, Row, TabContent, TabPane, Button
} from "reactstrap"
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { setItem, setPaymentRequest, updatePaymentRequest, getItemDetail, updateItem, updatePaymentRequestBoolean, getMerchantDetail } from "../../store/actions"
import { sendPaymentRequestEmailNonExpress } from "store/actions"
import { uploadFileAttachment } from "helpers/fakebackend_helper"
import FormFirstPage from "./FormSteps/FormFirstPage"
import FormSecondPage from "./FormSteps/FormSecondPage"
import FormThirdPage from "./FormSteps/FormThirdPage"
import FormFourthPage from "./FormSteps/FormFourthPage"
import {  } from "helpers/fakebackend_helper"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
export const ValuesContext = React.createContext()
import AuthUser from "components/AuthUser/AuthUser"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"

//Initial values
const intialValues = {
  TransactionReference: "",
  PaymentRequestId: "",
  Amount: parseFloat(1),
  Currency: "QAR",
  PaymentRequestSettings: {
    PaymentType: "Debit and Credit",
    Language: "EN",
    LinkValidUpto: "2020-01-01T01:01:01",
    LinkValidity: parseFloat(0),
    TerminalId: ""
  },
  CustomerDetails: {
    Name: "",
    Mobile: "",
    Email: "",
    Type: "New"
  },
  EmailSettings: {
    SubjectLine: "",
    Attachment: [],
    SentTo: []
  },
  Remarks: "",
  GeneratedLinkLong: "",
  GeneratedLinkLongEncrypted: "",
  GeneratedLinkShort: "",
  GeneratedLinkStatus: "",
  MerchantId: "",
  ItemId: "",
  ItemSource: "",
  PaymentRequestDate: "2020-01-01T01:01:01",
  LastPayment: "",
  PaymentStatus: "Unpaid",
  PaymentGatewayId: "",
  Commission: {
    Type: "",
    Value: parseFloat(0),
    TotalCommission: parseFloat(0)
  }
}


const PayReqForm = props => {
  //Constants ---------------------------------------------------
  const dispatch = useDispatch();
  const [{ activeTab, activeTabProgress, progressValue }, setFormRequest] = useState({
    activeTab: 1,
    activeTabProgress: 1,
    progressValue: 25
  })
  const [user, setUser] = useState(AuthUser())
  const [{ TransactionReference, PaymentRequestId, Amount, Currency,
    PaymentType, Language, Name, Email, Mobile, MobilePlus, Type, SubjectLine, Attachment,
    Remarks, ListItems, ItemID, ItemCheck, customerSearchorCreated, FileName }, setPaymentRequestState] = useState(
      {
        ...intialValues,
        TransactionReference: "",
        Amount: parseFloat(1).toFixed(2),
        Currency: "QAR",
        PaymentType: "Debit and Credit",
        Language: "EN",
        MobilePlus: "+974",
        Name: "",
        Email: "",
        Mobile: 0,
        ItemID: "",
        SubjectLine: "",
        ItemCheck: false,
        ListItems: [],
        error: false,
        FileName: []

      })
  const [overViewDetails, setOverViewDetails] = useState({ ...intialValues })
  const [loading, setLoading] = useState(props.paymentRequestLoading)
  const [boolEmailAlertTrue, setBoolEmailAlertTrue] = useState(false);
  const [boolEmailAlertFalse, setBoolEmailAlertFalse] = useState(false);
  const [editPaymentRequestState, setEditPaymentRequest] = useState(props.editPayReqBool);
  const [itemSourceToCheck, setItemSourceToCheck] = useState()
  const [merchant, setMerchant] = useState(props.merchantDetail)
  const [proceedNextForm, setProceedNextForm] = useState(true)

  //UseEffects ---------------------------------------------------------------------
  useEffect(()=>{
    if(props.itemId !== undefined){
      setPaymentRequestState(formState =>({
        ...formState,
        ItemID:props.itemId
      }))
      toast.success("Items from portal created.")
    }
  },[props.itemId])
  //Use Effect for getting ItemID and FileName
  useEffect(() => {
  }, [ItemID, FileName])

  //Use Effect for setting overview details
  useEffect(() => {
    if (props.paymentRequestDeatils) {
      setOverViewDetails(props.paymentRequestDeatils)
    }
  }, [props.paymentRequestDeatils])

  //Use Effect for checking if customer is searched or created
  useEffect(() => {
  }, [customerSearchorCreated])

  //Use Effect for setting loading state
  useEffect(() => {
    setLoading(props.paymentRequestLoading)
  }, [overViewDetails])

  //Use Effect for sweetalert constants
  // useEffect(() => {
  //   if (boolEmailAlertTrue === false && props.emailStatus !== undefined) {
  //     setFormRequest(formData => ({
  //       ...formData,
  //       activeTab: 1,
  //       activeTabProgress: 1,
  //       progressValue: 25
  //     }))
  //     setPaymentRequestState(formRequest => ({
  //       ...formRequest,
  //       TransactionReference: "",
  //       Amount: parseFloat(1).toFixed(2),
  //       Currency: "QAR",
  //       PaymentType: "Debit and Credit",
  //       Language: "EN",
  //       MobilePlus: "+974",
  //       Name: "",
  //       Email: "",
  //       Mobile: 0,
  //       ItemID: "",
  //       SubjectLine: "",
  //       ItemCheck: false,
  //       ListItems: [],
  //       error: false,
  //       FileName: []
  //     }))
  //     setEditPaymentRequest(props.editPayReqBool)
  //     setMerchant(props.merchantDetail)
  //     setProceedNextForm(true)
  //   }
  // }, [boolEmailAlertTrue,props.emailStatus])

  //Use Effect for cheking Items on edit Pay Req
  useEffect(() => {
    if (props.items) {
      setItemSourceToCheck(props.items)
    }
  }, [props.items])

  //Use Effect for editing Pay Req
  useEffect(() => {
    if (editPaymentRequestState === true) {
      setFormRequest(formRequest => ({
        ...formRequest,
        activeTab: 1,
        activeTabProgress: 1,
        progressValue: 25
      }))
    }

  }, [editPaymentRequestState])

  useEffect(() => {
    if (props.editPayReqBool !== undefined) {
      setEditPaymentRequest(props.editPayReqBool)
    }
  }, [props.editPayReqBool])

 
  useEffect(() => {

    if (props.emailStatus !== undefined) {
      if (props.emailStatus === true) {
        setBoolEmailAlertTrue(true)
        setLoading(false)
        window.location.reload()
      }
      else {
        setBoolEmailAlertFalse(true)
        setLoading(false)
      }
    }

  }, [props.emailStatus])


  useEffect(() => {
    dispatch(getMerchantDetail(user.merchantId))
  }, [])

  useEffect(() => {
    if (props.merchantDetail !== undefined) {
      setMerchant(props.merchantDetail)
    }
  }, [props.merchantDetail])

  useEffect(() => {
  }, [merchant])

  useEffect(() => {
    if (ListItems.length !== 0) {
      ListItems.map((items, i) => {
        if (items.ItemName === "" || items.ItemName === undefined || items.Qty === "" || items.Qty === undefined) {
          setProceedNextForm(false)
        }
        else {
          setProceedNextForm(true)
        }

      })
    }
  }, [ListItems])

  useEffect(() => {
  }, [proceedNextForm])

  //Functions ------------------------------------------------------------------------
  //Function for tab progress update
  const toggleTabProgress = (tab) => {
    if (editPaymentRequestState === undefined || editPaymentRequestState === true) {
      if (activeTabProgress !== tab) {
        if (tab >= 1 && tab <= 4) {
          setFormRequest(formRequest => ({
            ...formRequest,
            activeTab: tab
          }))
          if (tab === 1) {
            setFormRequest(formRequest => ({
              ...formRequest,
              activeTabProgress: 1,
              progressValue: 25
            }))
          }
          if (tab === 2 && (Amount !== 0 && Amount !== "" && Amount !== "0") && proceedNextForm === true) {
            setFormRequest(formRequest => ({
              ...formRequest,
              activeTabProgress: 2,
              progressValue: 50
            }))
          }
          if (proceedNextForm === false) {
            toast.error("Item List has incorrect value.")
          }
          if (tab === 2 && (Amount === 0 || Amount === "" || Amount === "0")) {
            toast.error("Amonut required. Please enter valid amount.")
          }

          if (tab === 3 && Name !== "" && Email !== "" && Mobile !== "") {
           
            setFormRequest(formRequest => ({
              ...formRequest,
              activeTabProgress: 3,
              progressValue: 75
            }))
          }
          if (tab === 3 && Name === "" && Email === "" && Mobile === "") {
            toast.error("Customer not selected or added")
          }

        }

        if (activeTab === 3 && tab === 4 && Name !== "" && Email !== "" && Mobile !== "") {
          // if(ItemID !== "" ){
          //   toast.success("Items from portal created.")
          // }
          handleItemsCreation();
          dispatch(updatePaymentRequestBoolean(false))
          setLoading(true)
          setFormRequest(formRequest => ({
            ...formRequest,
            activeTabProgress: 4,
            progressValue: 100
          }))
        }
        if (tab === 4 && Name === "" && Email === "" && Mobile === "") {
          toast.error("Customer not selected or added")
        }
      }
    }

  }
  //Function for items creation from source portal
  const handleItemsCreation = async() => {
    const itemsSource = {
      ItemId: "",
      TrackId: "",
      Items: ListItems
    }
    if (ListItems.length != 0 && editPaymentRequestState === undefined) {
      // await setItem(itemsSource).then(result => {
      //   if (result !== undefined) {
      //     setPaymentRequestState(formRequest => ({
      //       ...formRequest,
      //       ItemID: result.itemId
      //     }))
      //     toast.success("Items from portal created.")
      //   }
      // })
      dispatch(setItem(itemsSource))
    }
    else if (ListItems.length != 0 && editPaymentRequestState === true && ItemID === "") {
      // await setItem(itemsSource).then(result => {
      //   if (result !== undefined) {
      //     setPaymentRequestState(formRequest => ({
      //       ...formRequest,
      //       ItemID: result.itemId
      //     }))
      //     toast.success("Items from portal created.")
      //   }
      // })
      dispatch(setItem(itemsSource))
    }
    else {
      await dispatch(getItemDetail(ItemID));
      if (itemSourceToCheck.items.itemId !== undefined) {
        const editItemsSource = {
          ItemId: itemSourceToCheck.items.itemId,
          TrackId: itemSourceToCheck.items.trackId,
          Items: itemsSource.Items
        }
        if (editItemsSource.Items.length !== itemSourceToCheck.items.items.length) {
          await   dispatch(updateItem(editItemsSource))
          toast.success("Items from portal updated.")
        }
      }
      else {
        if (ListItems.length !== 0 && editPaymentRequestState === true) 
        {
          dispatch(setItem(itemsSource))
        }
        // [
        // await  setItem(itemsSource).then(result => {
        //     if (result !== undefined) {
        //       setPaymentRequestState(formRequest => ({
        //         ...formRequest,
        //         ItemID: result.itemId
        //       }))
        //       toast.success("Items from portal created.")
        //     }
        //   })
        // ]
      }
    }
    handleUploadFileAttachment();
  }

  //Function for upload file attachment in email
  const handleUploadFileAttachment = () => {
    if (Attachment.length !== 0 && FileName.length === 0) {
      const formData = new FormData();
      for (var i = 0; i < Attachment.length; i++) {
        formData.append("file", Attachment[i]);
        formData.append("FileName", Attachment[i].name);
      }
      if (formData !== undefined) {
        uploadFileAttachment(formData).then(result => {
          if (result !== undefined) {
            setPaymentRequestState(formRequest => ({
              ...formRequest,
              FileName: result
            }))

            if (ItemID === "" && result === 0) {
              if (editPaymentRequestState === undefined) {
                handlePaymentRequest("", [])
              }
              else {
                handleEditPaymentRequestDetails("", [])
              }
            }
            else {
              if (editPaymentRequestState === undefined) {
                handlePaymentRequest(ItemID, result)
                toast.success("Files attached.")
              }
              else {
                handleEditPaymentRequestDetails(ItemID, result)
                toast.success("Files updated attached.")
              }
            }
          }
        })
      }
    }
    else {
      if (ItemID === "" && FileName.length !== 0) {
        if (editPaymentRequestState === undefined) {
          handlePaymentRequest("", [])
        }
        else {
          if (props.paymentRequestDeatils.emailSettings.attachment !== FileName) {
            const formData = new FormData();
            for (var i = 0; i < Attachment.length; i++) {
              formData.append("file", Attachment[i]);
              formData.append("FileName", Attachment[i].name);
            }
            if (formData) {
              uploadFileAttachment(formData).then(result => {
                if (result) {
                  handleEditPaymentRequestDetails("", result)
                }
              })
            }
          }

        }
      }
      else if (ItemID !== "" && FileName.length !== 0) {
        if (props.paymentRequestDeatils.emailSettings.attachment !== FileName) {
          const formData = new FormData();
          for (var i = 0; i < Attachment.length; i++) {
            formData.append("file", Attachment[i]);
            formData.append("FileName", Attachment[i].name);
          }
          if (formData) {
            uploadFileAttachment(formData).then(result => {
              if (result) {
                handleEditPaymentRequestDetails(ItemID, result)
              }
            })
          }
        }
      }
      else if (ItemID === "" && FileName.length === 0) {
        if (editPaymentRequestState === undefined) {
          handlePaymentRequest("", [])
        }
        else {
          handleEditPaymentRequestDetails("", [])
        }
      }
      else {
        if (editPaymentRequestState === undefined) {
          handlePaymentRequest(ItemID, [])
        }
        else {
          handleEditPaymentRequestDetails(ItemID, [])
        }
      }
    }

  }

  //Function for payment request creation
  const handlePaymentRequest = (itemId, fileName) => {
    let dateToday = new Date();
    dateToday.setHours(dateToday.getHours() + 3);
    const valuesToSend = {
      TransactionReference,
      PaymentRequestId,
      Amount: parseFloat((Math.round(Amount * 100) / 100).toFixed(2)),
      Currency,
      PaymentRequestSettings: {
        PaymentType,
        Language,
        LinkValidUpto: dateToday,
        LinkValidity: parseFloat(0),
        TerminalId: ""
      },
      CustomerDetails: {
        Name,
        Mobile: MobilePlus + "-" + Mobile.toString(),
        Email,
        Type
      },
      EmailSettings: {
        SubjectLine,
        Attachment: fileName,
        SentTo: []
      },
      Remarks,
      GeneratedLinkLong: "",
      GeneratedLinkLongEncrypted: "",
      GeneratedLinkShort: "",
      GeneratedLinkStatus: "",
      MerchantId: "",
      ItemId: "",
      ItemSource: "PORTAL",
      PaymentRequestDate: dateToday,
      LastPayment: "",
      IsExpress: false,
      PaymentStatus: "Unpaid",
      PaymentGatewayId: "",
      Commission: {
        Type: "",
        Value: parseFloat(0),
        TotalCommission: parseFloat(0)
      }
    }
    if (activeTab === 3 && Name !== "" && Email !== "" && Mobile !== "") {

      dispatch(setPaymentRequest(valuesToSend));

      toast.success("Payment Request created successfully.")
    }
  }

  const handleEditPaymentRequestDetails = (itemId, fileName) => {
    if (props.paymentRequestDeatils) {
      let transRef = "";
      if (TransactionReference) {
        transRef = TransactionReference
      }
      else {
        transRef = props.paymentRequestDeatils.transactionReference
      }
      const valuesToSend = {
        _id: props.paymentRequestDeatils._id,
        TransactionReference: transRef,
        PaymentRequestId: props.paymentRequestDeatils.paymentRequestId,
        Amount: parseFloat((Math.round(Amount * 100) / 100).toFixed(2)),
        Currency,
        PaymentRequestSettings: {
          PaymentType,
          Language,
          LinkValidUpto: props.paymentRequestDeatils.paymentRequestSettings.linkValidUpto,
          LinkValidity: props.paymentRequestDeatils.paymentRequestSettings.linkValidity,
          TerminalId: props.paymentRequestDeatils.paymentRequestSettings.terminalId
        },
        CustomerDetails: {
          Name,
          Mobile: MobilePlus + "-" + Mobile.toString(),
          Email,
          Type
        },
        EmailSettings: {
          SubjectLine,
          Attachment: fileName,
          SentTo: props.paymentRequestDeatils.emailSettings.sentTo
        },
        Remarks,
        GeneratedLinkLong: props.paymentRequestDeatils.generatedLinkLong,
        GeneratedLinkLongEncrypted: props.paymentRequestDeatils.generatedLinkLongEncrypted,
        GeneratedLinkShort: props.paymentRequestDeatils.generatedLinkShort,
        GeneratedLinkStatus: props.paymentRequestDeatils.generatedLinkStatus,
        MerchantId: props.paymentRequestDeatils.merchantId,
        ItemId: itemId,
        ItemSource: "PORTAL",
        PaymentRequestDate: props.paymentRequestDeatils.paymentRequestDate,
        LastPayment: "",
        IsExpress: false,
        PaymentStatus: "Unpaid",
        PaymentGatewayId: props.paymentRequestDeatils.paymentGatewayId,
        Commission: {
          Type: "",
          Value: parseFloat(0),
          TotalCommission: parseFloat(0)
        }
      }
      if (Name !== "" && Email !== "" && Mobile !== "") {
        dispatch(updatePaymentRequest(valuesToSend));
        setLoading(true)
        toast.success("Payment Request updated successfully.")
        // setEditPaymentRequest(false)
        dispatch(updatePaymentRequestBoolean(false))
      }
    }
  }

  //Function for email sending
  const handleEmailSending = (value) => {

    setLoading(true)
    if (value === "all") {
      const data = {
        id: props.paymentRequestDeatils._id,
        recipient: null,
        actionType: "Initial Send of Payment Request"
      }
      dispatch(sendPaymentRequestEmailNonExpress(data));
    }
    else if (value === "user") {
      const data = {
        id: props.paymentRequestDeatils._id,
        recipient: user.email,
        actionType: "Initial Send of Payment Request"
      }
      dispatch(sendPaymentRequestEmailNonExpress(data));
    }
    else {
      setBoolEmailAlertFalse(true);
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Send Payment Request" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {boolEmailAlertTrue ?
                    <>
                      <SweetAlert
                        title="Email sent successfully."
                        timeout={2000}
                        success
                        showConfirm={false}
                        onConfirm={() => {
                          setLoading(false)
                          setBoolEmailAlertTrue(false)
                        }} />
                    </>
                    : null}
                  {boolEmailAlertFalse ?
                    <>
                      <SweetAlert
                        title="Email not sent successfully."
                        timeout={2000}
                        danger
                        showConfirm={false}
                        onConfirm={() => {
                          setBoolEmailAlertFalse(false)
                        }} />
                    </>
                    : null}
                  <div id="progrss-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            toggleTabProgress(1)
                          }}
                        >
                          <span className="step-number-active mr-2 input-text-size">01</span>
                          {activeTabProgress === 1 ? <label className="color-primary bold input-text-size">PAYMENT DETAILS</label> : <label className="text-size">PAYMENT DETAILS</label>}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            toggleTabProgress(2)
                          }}
                        >
                          <span className="step-number-active mr-2 input-text-size">02</span>
                          {activeTabProgress === 2 ? <label className="color-primary bold input-text-size">CUSTOMER DETAILS</label> : <label className="text-size">CUSTOMER DETAILS</label>}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            toggleTabProgress(3)
                          }}
                        >
                          <span className="step-number-active mr-2 input-text-size">03</span>
                          {activeTabProgress === 3 ? <label className="color-primary bold input-text-size">EMAIL CONFIGURATION</label> : <label className="text-size">EMAIL CONFIGURATION</label>}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            toggleTabProgress(4)
                          }}
                        >
                          <span className="step-number-active mr-2 input-text-size">04</span>
                          {activeTabProgress === 4 ? <label className="color-primary bold input-text-size">SEND PAYMENT REQUEST</label> : <label className="text-size">SEND PAYMENT REQUEST</label>}

                        </NavLink>
                      </NavItem>
                    </ul>

                    <div id="bar" className="mt-4">
                      <Progress
                        color="success"
                        striped
                        animated
                        value={progressValue}
                      />
                      <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" />
                    </div>
                    <TabContent
                      activeTab={activeTabProgress}
                      className="twitter-bs-wizard-tab-content"
                    >
                      <ValuesContext.Provider value={{ overViewDetails }}>
                        <TabPane tabId={1}>
                          <FormFirstPage
                            Merchant={merchant}
                            Amount={Amount}
                            onChange={value => setPaymentRequestState(value)} />
                        </TabPane>
                        <TabPane tabId={2}>
                          <FormSecondPage
                            Name={Name}
                            onChange={value => setPaymentRequestState(value)}
                          />
                        </TabPane>
                        <TabPane tabId={3}>
                          <FormThirdPage
                            Name={Name}
                            customerSearchorCreated={customerSearchorCreated}
                            onChange={value => setPaymentRequestState(value)} />
                        </TabPane>
                        <TabPane tabId={4}>
                          {loading ?
                            <Row className="spinner-custom mx-auto my-auto pb-3">
                              <LoadingSpinnerThreeDots />
                            </Row>
                            :
                            <FormFourthPage
                              onChange={value => setPaymentRequestState(value)} />}
                        </TabPane>
                      </ValuesContext.Provider>
                    </TabContent>
                    {activeTab === 4 && editPaymentRequestState === false ?
                      <>
                        {loading ? null :
                          <Row className="">
                            <Col>
                              <Button
                                color="warning"
                                outline
                                className="waves-effect waves-light btn-lg"
                                onClick={() => {
                                  dispatch(updatePaymentRequestBoolean(true))
                                }}>
                                <i className=" fas fa-edit mr-2" />
                                EDIT PAYMENT REQUEST
                            </Button>
                            </Col>

                            <Col>
                              <Row className="float-right">
                                <Col>
                                  <Button
                                    color="primary"
                                    className=" waves-effect waves-light btn-lg button-email-width"
                                    onClick={() => handleEmailSending("all")}>
                                    SEND TO CUSTOMER 
                           </Button>
                                </Col>
                                <Col>
                                  <Button
                                    color="primary"
                                    outline
                                    className="waves-effect waves-light btn-lg button-email-width"
                                    onClick={() => handleEmailSending("user")}>
                                    SEND TO MY EMAIL
                              </Button>
                                </Col>

                              </Row>
                            </Col>
                          </Row>}
                      </>
                      :
                      <ul className="pager wizard twitter-bs-wizard-pager-link text-size">
                        <li
                          className={
                            activeTabProgress === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTabProgress(
                                activeTabProgress - 1
                              )
                            }}
                          >
                            Previous
                        </Link>
                        </li>
                        <li
                          className={
                            activeTabProgress === 4
                              ? "next disabled"
                              : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTabProgress(
                                activeTabProgress + 1
                              )
                            }}
                          >
                            Next
                        </Link>
                        </li>
                      </ul>}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    items: state.Items,
    itemId: state.Items.ItemId,
    file: state.File,
    paymentRequestDeatils: state.paymentRequests.paymentRequestDetail,
    paymentRequestLoading: state.paymentRequests.loading,
    emailStatus: state.paymentRequests.emailStatusNonExpress,
    editPayReqBool: state.paymentRequests.editPayReqBool,
    merchantDetail: state.Merchants.merchantDetail
  }
}
export default connect(mapStateToProps)(PayReqForm)
