const PayGateOverviewColumns = () =>[
   {
    dataField: "position",
    text: "Position",
    searchable: true,
    sort: true,
   },
   {
    dataField: "name",
    text: "Name",
    searchable: true,
    sort: true,
   },
   {
    dataField: "email",
    text: "Email",
    searchable: true,
    sort: true,
   },
   {
    dataField: "mobile",
    text: "Mobile",
    searchable: true,
    sort: true,
   },
]
export default PayGateOverviewColumns