import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  Collapse,
  Button,
} from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import CreditCustom from "./CreditCustom"
import DebitRedict from "./DebitRedict"
import ApplePayRedirect from "./ApplePayRedirect"
import ApplePayEmbedded from "./ApplePayEmbedded"


export const AmazonPaymentServices = (props) => {

  const {  
    apsCreditCustomTokenization,
    apsCreditCustom,
    apsDebitRedirect,
    apsApplePayRedirect,
    paymentRequestDetails } = props


  const [creditCard, setCreditCard] = useState(false)
  const [debitCard, setDebitCard] = useState(false)
  const [applePayCard, setApplePayCard] = useState(false)

  
  const creditCollapse = () => {
    setCreditCard(!creditCard)
    setDebitCard(false)
    setApplePayCard(false)
  }

  const debitCollapse = () => {
    setDebitCard(!debitCard)
    setCreditCard(false)
    setApplePayCard(false)
  }

  const applePayCollapse = () => {
    setApplePayCard(!applePayCard)
    setCreditCard(false)
    setDebitCard(false)
  }

  function isSafariBrowser() {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    return isSafari
  }

  return (
    <React.Fragment>
        <Col lg="12">
        
        {/* CREDIT CARD */}
        { apsCreditCustom &&
        <>
        {paymentRequestDetails?.paymentRequestSettings.paymentType.toLowerCase().includes("credit") && (
                <>
                
                  <Button
                    width="100%"
                    onClick={creditCollapse}
                    className={classnames(
                      "btn-block inner mb-2",
                      "fw-medium",
                      { collapsed: !creditCard }
                    )}
                    color="success"
                    outline
                    style={{   fontSize: "1rem"}}
                    >

                     <span style={{ border:"1px solid", borderRadius: "0.15rem", padding:"2px", fontSize:"7px", fontStyle:"italic", verticalAlign: "middle"}}><b>___</b></span>
                     <span  style={{   fontSize: "1rem"}}>{props.t(" CREDIT CARD")}</span>
                
                     {/* <i className="far fa-credit-card" /> <span>{props.t("CREDIT CARD")}</span> */}
                  </Button>

                  <Collapse isOpen={creditCard} className="p-3">
                   
                   <CreditCustom 
                    paymentRequestDetails={paymentRequestDetails}
                    apsCreditCustomTokenization={apsCreditCustomTokenization}
                    apsCreditCustom={apsCreditCustom}
                   
                   />
                     
                    
                    <hr></hr>
                  </Collapse>
                </>
              )}
        </>      
        }

    {/* DEBIT CARD */}
        {apsDebitRedirect &&
        <>
        {props.paymentRequestDetails?.paymentRequestSettings.paymentType
          .toLowerCase()
          .includes("debit") && (
          <>
            
              
               
                  <DebitRedict apsDebitRedirect={apsDebitRedirect}/>

          </>
        )}

        </>
        }


        
        {/* APPLE PAY CARD___________________________________________________________ */}
        {apsApplePayRedirect && (
          <>
            {props.paymentRequestDetails?.paymentRequestSettings.paymentType
              .toLowerCase()
              .includes("applepay") && (
              <>
                {/* <ApplePayEmbedded
                  apsApplePayRedirect={apsApplePayRedirect} paymentRequestDetails={paymentRequestDetails}
                /> */}
                <ApplePayRedirect apsApplePayRedirect={apsApplePayRedirect} paymentRequestDetails={paymentRequestDetails}/>
              </>
            )}
          </>
        )}
        
        </Col>
    </React.Fragment>
  )
}


AmazonPaymentServices.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(AmazonPaymentServices))