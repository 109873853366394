import React, { useState, useEffect } from "react"
import { Col, Row, Button, Spinner, Form } from "reactstrap"
import {
  getCustomerList,
  getCustomerDetail,
  updateCustomer,
  getCustomersByMerchantID,
} from "../../store/actions.js"
import { useDispatch } from "react-redux"
import { IsEmpty } from "react-lodash"
import { connect } from "react-redux"
import CustomerColumns from "./CustomerColumns"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { toast } from "react-toastify"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import AuthUser from "components/AuthUser/AuthUser"
import { useForm } from "react-hook-form"
import AddNewCustomer from "./AddNewCustomer"
import SearchCustomerForm from "./SearchCustomer"

function CustomerList(props) {
  // Constants ---------------------------------------------------------------------------------------------
  const _ = require("lodash")
  const dispatch = useDispatch()
  const [user, setUser] = useState(AuthUser())
  const [
    {
      id,
      Name,
      Email,
      Mobile,
      MobilePlus,
      Type,
      SearchCustomer,
      customerSearchorCreated,
      modal_xlarge,
      step,
    },
    setPaymentRequest,
  ] = useState({
    MobilePlus: "+974",
    Name: "",
    Email: "",
    Mobile: "",
    SearchCustomer: "",
    Type: "New",
    customerSearchorCreated: "",
    step: 0,
    modal_xlarge: false,
  })
  const [customerList, setCustomerList] = useState({})
  const [loading, setLoading] = useState(props.loading)
  const [addOrUpdateCustomer, setAddorUpdateCustomer] = useState(true)
  const { register, handleSubmit, errors, reset } = useForm()
  const [addOrSearchCustomer, setAddOrSearchCustomer] = useState("")
  let form = null
  //Use Effects--------------------------------------------------------------------------------------------
  //Use Effect for getting customer list with Merchant ID
  useEffect(() => {
    //TODO: User Role
    UserRole()
  }, [])

  useEffect(() => {}, [step])

  useEffect(() => {
    props.onChange(formRequest => ({
      ...formRequest,
      CustomerId: id,
      Name,
      Email,
      MobilePlus,
      Mobile,
      customerSearchorCreated,
    }))
  }, [Name, Email, MobilePlus, Mobile, customerSearchorCreated])
  useEffect(() => {
    //console.log(props.customerCreated,props.customer)
    if (props.customerCreated === true || props.customer !== undefined) {
      props.onChange(formRequest => ({
        ...formRequest,
        modal_xlarge: false,
      }))
    }
  }, [props.customerCreated, props.customer])

  //Use Effect for setting customerList with props
  useEffect(() => {
    setCustomerList(props.customersList)
    setLoading(props.loading)
  }, [props.customersList, props.loading])

  //Use Effect for getting and setting customerList from search
  useEffect(() => {
    handelSearchCustomer(SearchCustomer)
    if (props.customersList != undefined && SearchCustomer != "") {
      setCustomerList(props.customersList)
      setLoading(props.loading)
    } else if (SearchCustomer === "") {
      setCustomerList({})
      setLoading(props.loading)
    } else {
      setCustomerList({})
      setLoading(props.loading)
    }
  }, [SearchCustomer])

  //Functions--------------------------------------------------------------------------------------------------------
  //Function for setting data state changes for this component and props
  const storeDataOnForm = event => {
    const { name, value } = event.target
    setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
    props.onChange(formRequest => ({ ...formRequest, [name]: value }))
  }

  //Function for search customer
  const handelSearchCustomer = searchValue => {
    const search = {
      id: user.userId,
      searchTerm: searchValue,
    }
    if (searchValue) {
      dispatch(getCustomerDetail(search))
      setLoading(true)
    }
  }

  const UserRole = () => {
    if (user.role !== "SuperAdmin") {
      const data = {
        type: "all",
        merchantId: user.merchantId,
      }
      setLoading(props.loading)
      dispatch(getCustomersByMerchantID(data))
    }
    if (user.role === "SuperAdmin") {
      dispatch(getCustomerList())
    }
  }

  //Function for updating customer
  const handleUpdateCustomer = () => {
    const newCustomerDetails = {
      _id: id,
      Name,
      Email,
      Mobile: MobilePlus + "-" + Mobile.toString(),
      RegistrationDate: "2020-01-01T01:01:01",
      Type,
      MerchantId: user,
      IsActive: true,
    }
    if (
      id !== "" &&
      Name != "" &&
      Email != "" &&
      Mobile != "" &&
      Type === "Recurring"
    ) {
      dispatch(updateCustomer(newCustomerDetails))
      setPaymentRequest(formRequest => ({
        ...formRequest,
        CustomerId: id,
        Name,
        Email,
        MobilePlus,
        Mobile,
      }))
      props.onChange(formRequest => ({
        ...formRequest,
        CustomerId: id,
        Name,
        Email,
        MobilePlus,
        Mobile,
      }))
      toast.success("Customer: " + Name + " successfully updated.")
    }
  }

  //Function for clearing selected customer in form

  //Function for click customer in row event
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      toast.info("Added: " + row.name)
      setAddorUpdateCustomer(false)
      if (row.mobile != undefined) {
        let MobileSplit = row.mobile.split("-")
        setPaymentRequest(formRequest => ({
          ...formRequest,
          id: row._id,
          Name: row.name,
          Email: row.email,
          Mobile: MobileSplit[1],
          MobilePlus: MobileSplit[0],
          Type: row.type,
          customerSearchorCreated: "Searched",
        }))
        props.onChange(formRequest => ({
          ...formRequest,
          CustomerId: row._id,
          Name: row.name,
          Email: row.email,
          Mobile: MobileSplit[1],
          MobilePlus: MobileSplit[0],
          Type: row.type,
          customerSearchorCreated: "Searched",
        }))
      }
    },
  }

  switch (step) {
    case 0:
      form = (
        <Row className=" mx-4 my-4 item-center ">
          <Col className="item-center col-height-100">
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light btn-lg btn-block button-height-100 text-size-btn"
              onClick={() => {
                setPaymentRequest(formRequest => ({
                  ...formRequest,
                  step: 1,
                }))
              }}
            >
              New Customer
            </Button>
          </Col>
          <Col className="item-center  col-height-100">
            <Button
              color="info"
              className="btn btn-info waves-effect waves-light btn-lg btn-block button-height-100 text-size-btn"
              onClick={() => {
                setPaymentRequest(formRequest => ({
                  ...formRequest,
                  step: 2,
                }))
              }}
            >
              Existing Customer
            </Button>
          </Col>
        </Row>
      )
      break
    case 1:
      form = <AddNewCustomer onChange={value => setPaymentRequest(value)} />
      break
    case 2:
      form = <SearchCustomerForm onChange={value => setPaymentRequest(value)} />
      break
    default:
      form = (
        <Row className=" mx-3 my-3  item-center ">
          <Col className="item-center col-height-100">
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light btn-lg btn-block button-height-100"
            >
              New Customer
            </Button>
          </Col>
          <Col className="item-center  col-height-100">
            <Button
              color="info"
              className="btn btn-info waves-effect waves-light btn-lg btn-block button-height-100"
            >
              Existing Customer
            </Button>
          </Col>
        </Row>
      )
  }

  return <>{form}</>
}
const mapStateToProps = state => {
  return {
    customerCreated: state.Customer.customerCreated,
    customer: state.Customer.customer,
    loading: state.Customer.loading,
    customersList: state.Customer.customerList,
  }
}

export default connect(mapStateToProps)(CustomerList)
