import React from "react"
import { Col, Card, CardBody, Row, Table, CardTitle } from "reactstrap"
import { statusPillsBool } from "../../helpers/commonhelpers/statusPills"
import dateFormat from "../../helpers/commonhelpers/dateFormat"
import AuthUser from "components/AuthUser/AuthUser"
const path = "/merchant/"

export default function MerchantInfo(props) {
  const { merchantDetail } = props

  return (
    <>
      <Card>
        <CardTitle style={{ backgroundColor: "#CCDFF1", padding: "5px" }}>
          <i className="fas fa-address-book text-success mr-1" />
          Merchant Information
        </CardTitle>

        <CardBody>
          <Row>
            <Col lg="6">
              <div className="table-responsive">
                <div className="d-flex justify-content-center">
                  <img
                    // src={path + merchantDetail?.logo}
                    src={merchantDetail?.logo}
                    alt=""
                    className="img-fluid"
                    width="250px"
                  />
                </div>
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-address-card text-success mr-1" />
                        Merchant Name:{" "}
                      </th>
                      <td>
                        <b>{merchantDetail?.merchantName}</b>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-address-book text-success mr-1" />
                        Merchant Id:{" "}
                      </th>
                      <td>{merchantDetail?.merchantId}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-building text-success mr-1" />
                        Merchant Type:{" "}
                      </th>
                      <td>{merchantDetail?.type}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="far fa-calendar-alt text-success mr-1" />
                        Registration Date:{" "}
                      </th>
                      <td>{dateFormat(merchantDetail?.registrationDate)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col lg="6">
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-battery-full text-success mr-1" />
                        Is Active{" "}
                      </th>
                      <td>
                        {merchantDetail.isActive === null
                          ? statusPillsBool(
                              merchantDetail?.isActive,
                              "pending",
                              true
                            )
                          : statusPillsBool(
                              merchantDetail?.isActive,
                              merchantDetail?.isActive.toString(),
                              true
                            )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-globe text-success mr-1" />
                        Website:{" "}
                      </th>
                      <td>{merchantDetail?.website}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-link text-success mr-1" />T and C:{" "}
                      </th>
                      <td>{merchantDetail?.termsConditionUrl}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-phone text-success mr-1" />
                        Telephone:{" "}
                      </th>
                      <td>{merchantDetail?.phoneNumber}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-building text-success mr-1" />
                        Business Reg No:{" "}
                      </th>
                      <td>{merchantDetail?.businessRegNumber}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="far fa-building text-success mr-1" />
                        Tax Reg No:{" "}
                      </th>
                      <td>{merchantDetail?.taxRegNumber}</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <i className="fas fa-address-card text-success mr-1" />
                        Address{" "}
                      </th>
                      <td>
                        {merchantDetail?.address},{merchantDetail?.city}{" "}
                        {merchantDetail?.country} {merchantDetail?.poBox}{" "}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
