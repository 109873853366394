import React from "react"
import { Link } from "react-router-dom"
import {  UncontrolledTooltip } from "reactstrap"

const UsersColumns = (user) => {


  const isHidden = () => {
    if(user.role === "Admin") return true
    else return false
  }


  return (
    [
  
      {
        dataField: "role",
        text: "Role",
        sort: true,
      },
      {
        dataField: "firstName",
        text: "First Name",
        sort: true,
      },
      {
        dataField: "lastName",
        text: "Last Name",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "userName",
        text: "UserName",
        sort: true,
      },
      {
        dataField: "department",
        text: "Department",
        sort: true,
      },
      {
        dataField: "isActive",
        text: "IsActive",
        sort: true,
      },
      // {
      //   dataField: "_id",
      //   text: "Action",
      //   hidden:isHidden(),
      //   formatter: () => (
      //     <>
      //       <Link to="#" className="mr-3 text-primary">
      //         <i className="mdi mdi-pencil font-size-18 mr-3" id="edittooltip" />
      //         <UncontrolledTooltip placement="top" target="edittooltip">
      //           Edit
      //         </UncontrolledTooltip>
      //       </Link>
      //       <Link to="#" className="text-danger">
      //         <i className="mdi mdi-close font-size-18 mr-3" id="deletetooltip" />
      //         <UncontrolledTooltip placement="top" target="deletetooltip">
      //           View
      //         </UncontrolledTooltip>
      //       </Link>
      //     </>
      //   ),
      // },
    ]

  )
}

export default UsersColumns
