import {

  GET_PAYMENTRESPONSE_DETAIL,
  GET_PAYMENTRESPONSE_DETAIL_FAIL,
  GET_PAYMENTRESPONSE_DETAIL_SUCCESS,

} from "./actionTypes"

const INIT_STATE = {
  
  paymentResponseDetail: {},
  error: {},
  loading: false,

}



const paymentResponses = (state = INIT_STATE, action) => {
  switch (action.type) {
    
      case GET_PAYMENTRESPONSE_DETAIL:
    
      return {
        ...state,
        paymentResponseDetail: action.payload,
        loading:true
      }

    case GET_PAYMENTRESPONSE_DETAIL_SUCCESS:
    
      return {
        ...state,
        paymentResponseDetail: action.payload,
        loading:false
      }

    case GET_PAYMENTRESPONSE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading:false
      }
   

    default:
      return state
  }
}

export default paymentResponses
