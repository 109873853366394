import React from "react"
import dateFormat from '../../helpers/commonhelpers/dateFormat'

const ParserErrorLogsColumns = () =>[
   
    {
        dataField: "fileName",
        text: "File Name",
        searchable: true,
        sort: true,
    },
    {
        dataField: "date",
        text: "Date",
        searchable: true,
        sort: true,
        formatter: (cellContent, row) => {
            return (
              <span>
               {dateFormat(cellContent)}
              </span>
            )
          }
    },
    {
        dataField: "indexNumber",
        text: "Index Number",
        searchable: true,
        sort: true,
    },
    {
        dataField: "errorDescription",
        text: "Error Description",
        searchable: true,
        sort: true,
    },
    
    {
        dataField: "stackTrace",
        text: "Stack Trace",
        searchable: true,
        sort: true,
    },
   
    {
        dataField: "fileContent",
        text: "File Content",
        searchable: true,
        sort: true,
    },
    
   
   
]
export default ParserErrorLogsColumns