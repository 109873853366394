import React from "react"
import {
  Badge,
  Row,
  Col
} from "reactstrap"
import dateFormat from '../../helpers/commonhelpers/dateFormat'

const SyncCheckerExecutionLogsColumns = () => [
  {
    dataField: "date",
    text: "Date",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <span>
          {dateFormat(cellContent)}
        </span>
      )
    }
  },
  {
    dataField: "nFailed",
    text: "Failed",
    searchable: true,
    sort: true,
  },
  {
    dataField: "nSuccess",
    text: "Success",
    searchable: true,
    sort: true,
  },

  {
    dataField: "phChanged",
    text: "Payment Histories Recorded",
    searchable: true,
    sort: true,
    formatter: (cellContent, row) => {

      if (cellContent !== undefined || cellContent.Count() > 0) {
        return (

          cellContent.map((x, ind) =>
            <div index={ind}>
              <small> {x}</small> <br></br>
            </div>
          )

        )
      }
      else {
        return (<small>N/A</small>)
      }
    }

  },



]
export default SyncCheckerExecutionLogsColumns