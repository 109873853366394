import React, { useState, useEffect } from 'react'
import { Page, Document, pdfjs  } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

const file = "/help/NTA_PayAllLink_User_Guide_User_V2.pdf"
//const fileStatic = "../../assets/help/NTA_PayAllLink_User_Guide_User_V2"

const NonTA_User = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])


  return (
    <div >
      <Document
        file={file}
        //options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >

        
        {Array.from(new Array(numPages), (el, index) => (
        <Page scale={2.5} key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
        

      </Document>


    </div>
  );
}

export default NonTA_User