import React, { useEffect, useState, useContext } from 'react'
import { connect } from 'react-redux'
import {
    Col,
    Form,
    Row,
    Button
} from "reactstrap"
import { OfficeIds } from '../OfficeIds'
import { v4 as uuidv4 } from 'uuid'
import { PaymentGateways } from '../PaymentGateways'
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash'
import { MerchantCreateContext } from '../index'

export const ThirdPaymentGateway = (props) => {

    const {
        handleMerchantChange,
        merchantDetails,
        PaymentGatewayList,
        loadSubmitNowButton,
        toggleTabProgress,
        activeTabProgress
    } = props

    const { register, handleSubmit, errors, reset, watch } = useForm();
    const { settingThirdStepError } = useContext(MerchantCreateContext)

    // useEffect(() => {
    //     settingThirdStepError(errors)
    //    }, [errors])

    function handleChange(changes) {
        handleMerchantChange({ ...merchantDetails, ...changes })
    }

    function handleOfficeIdChange(id, officeId) {
        const newOfficeIds = [...merchantDetails.OfficeIds]
        const index = newOfficeIds.findIndex(i => i.id === id)
        newOfficeIds[index] = officeId
        handleChange({ OfficeIds: newOfficeIds })
    }

    function handleOfficeIdAdd() {
        const newOfficeId = {
            id: uuidv4(),
            OfficeId: '',
            Date: new Date().toISOString(),
        }
        handleChange({ OfficeIds: [...merchantDetails.OfficeIds, newOfficeId] })
    }

    function handleOfficeIdDelete(id) {
        handleChange({
            OfficeIds: merchantDetails.OfficeIds.filter(i => i.id !== id)
        })
    }

    function handlePaymentGatewayChange(id, paymentGateway) {
        const newPaymentGateways = [...merchantDetails.PaymentGatewayEnrollments]
        const index = newPaymentGateways.findIndex(i => i.id === id)
        newPaymentGateways[index] = paymentGateway
        handleChange({ PaymentGatewayEnrollments: newPaymentGateways })
    }

    function handlePaymentGatewayAdd() {
        const newPaymentGateway = {
            "id": uuidv4(),
            "PaymentGateway": "",
            // "TerminalId": "",
            // "TerminalPassword": "",
            // "MerchantId": "",
            "IsActive": false,
            "PaymentGatewayId": "",
            "CommissionSettings": [
                {
                    "id": uuidv4(),
                    "PaymentMethod": "",
                    "Value": 1,
                    "Type": ""
                },
            ],
            "CustomConfig": [
                {
                  "id": uuidv4(),
                  "Key": "",
                  "Value": "",
                  
                },
              ]
        }
        handleChange({ PaymentGatewayEnrollments: [...merchantDetails.PaymentGatewayEnrollments, newPaymentGateway] })
    }

    function handlePaymentGatewayDelete(id) {
        handleChange({
            PaymentGatewayEnrollments: merchantDetails.PaymentGatewayEnrollments.filter(i => i.id !== id)
        })
    }

    const onSubmit = data => handleCheckNoErrors();

    function handleCheckNoErrors() {

        if (isEmpty(errors)) {
            toggleTabProgress(activeTabProgress + 1)
        }

    }

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)} >
                <Row>
                    <Col lg="6">

                        {merchantDetails.Type === "Travel Agency" &&
                            <>
                                <b>Add OfficeIds</b>
                                <p></p>
                                {merchantDetails.OfficeIds.map((officeId, index) =>
                                    <OfficeIds
                                        index={index}
                                        key={officeId.id}
                                        officeId={officeId}
                                        handleOfficeIdChange={handleOfficeIdChange}
                                        handleOfficeIdDelete={handleOfficeIdDelete}
                                        handleSubmit={handleSubmit}
                                        onSubmit={onSubmit}
                                        register={register}
                                        errors={errors}
                                    />
                                )}

                                <Button
                                    onClick={() => handleOfficeIdAdd()}
                                    color="success"
                                    className="mt-1"
                                >
                                    Add New OfficeId
                       </Button>
                            </>

                        }

                    </Col>

                </Row>



                <hr></hr>
                <b>Add Default Payment Gateway</b>
                <p></p>

                {merchantDetails.PaymentGatewayEnrollments?.map((paymentGateway, index) =>

                    <PaymentGateways
                        index={index}
                        paymentGatewayList={PaymentGatewayList}
                        key={paymentGateway.id}
                        paymentGateway={paymentGateway}
                        handlePaymentGatewayChange={handlePaymentGatewayChange}
                        handlePaymentGatewayDelete={handlePaymentGatewayDelete}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        register={register}
                        errors={errors}
                    />
                )}

                <Button
                    onClick={() => handlePaymentGatewayAdd()}
                    color="success"
                    className="mt-1"
                >
                    Add Payment Gateway
                          </Button>


                <ul className="pager wizard twitter-bs-wizard-pager-link">
                    <li className=
                        {activeTabProgress === 1
                            ? "previous disabled"
                            : "previous"
                        }
                    >
                        <Button
                            color="primary"
                            onClick={() => { toggleTabProgress(activeTabProgress - 1) }}
                        > Previous </Button>
                    </li>
                    <li
                        className={
                            activeTabProgress === 5 ? "next" : "next"
                        }
                    >

                        {activeTabProgress === 5 ?
                            <>
                                {loadSubmitNowButton()}
                            </>
                            :
                            <Button
                                type="submit"
                                color="primary"
                                id='my-form_submit_3'
                            > Next </Button>
                        }

                    </li>
                </ul>
            </Form>
        </div>
    )
}

ThirdPaymentGateway.propTypes = {

}

const mapStateToProps = state => (

    {

    })

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPaymentGateway)
