import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import {
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap"
import AuthUser from "components/AuthUser/AuthUser"
import { connect } from "react-redux"
import { useDispatch } from "react-redux"
import { setCustomer, getCountryCode } from "../../store/actions.js"
import { toast } from "react-toastify"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import validator from "validator"

function AddNewCustomer(props) {
  const dispatch = useDispatch()
  const [
    {
      id,
      Name,
      Email,
      Mobile,
      MobilePlus,
      Type,
      SearchCustomer,
      customerSearchorCreated,
      step,
      createdCustomerBool,
    },
    setPaymentRequest,
  ] = useState({
    MobilePlus: "+974",
    Name: "",
    Email: "",
    Mobile: "",
    SearchCustomer: "",
    Type: "New",
    customerSearchorCreated: "",
  })
  const { register, handleSubmit, errors, reset } = useForm()
  const [user, setUser] = useState(AuthUser())
  const [countryCodes, setCountryCodes] = useState([])
  useEffect(() => {
    dispatch(getCountryCode())
  }, [])
  useEffect(() => {
    //console.log(props.customerCreated)
    if (props.customerCreated !== "" && props.customerCreated !== undefined) {
      if (props.customerCreated === true) {
        props.onChange(formRequest => ({
          ...formRequest,
          Name,
          Email,
          MobilePlus,
          Mobile,
          Type,
        }))
        //showToastSuccess("Customer: " + Name + " successfully created.")
      } else {
        // showToastError("Customer: " + Name + " already exists.")
      }
    }
  }, [props.customerCreated])
  useEffect(() => {
    //console.log(props.countryCodes)
    if (Array.isArray(props.countryCodes) && props.countryCodes?.length !== 0) {
      setCountryCodes(props.countryCodes)
    }
  }, [props.countryCodes])

  useEffect(() => {
    //console.log(countryCodes)
  }, [countryCodes])
  ///////////////////////////////////////////////////////////////////////////

  const storeDataOnForm = event => {
    const { name, value } = event.target
    const re = /^[a-zA-Z\s]*$/
    const me = /^[0-9\s]*$/

    if (name === "Name" && name !== "Mobile" && re.test(value)) {
      setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
      props.onChange(formRequest => ({ ...formRequest, [name]: value }))
    }
    if (name === "Mobile" && name !== "Name" && me.test(value)) {
      setPaymentRequest(formRequest => ({
        ...formRequest,
        [name]: value.replaceAll(" ", ""),
      }))
      props.onChange(formRequest => ({
        ...formRequest,
        [name]: value.replaceAll(" ", ""),
      }))
    }
    if (name !== "Name" && name !== "Mobile") {
      setPaymentRequest(formRequest => ({ ...formRequest, [name]: value }))
      props.onChange(formRequest => ({ ...formRequest, [name]: value }))
    }
  }

  const onSubmit = data => {}

  const handleCancelButton = () => {
    props.onChange(formRequest => ({
      ...formRequest,
      step: 0,
    }))
  }
  //Function for creating customer
  const handleCreateCustomer = () => {
    const customerDetails = {
      Name,
      Email,
      Mobile: MobilePlus + "-" + Mobile.toString(),
      RegistrationDate: "2020-01-01T01:01:01",
      Type,
      MerchantId: user.merchantId,
      IsActive: true,
    }
    if (
      Name != "" &&
      Email != "" &&
      validator.isEmail(Email) &&
      Mobile != "" &&
      Type === "New"
    ) {
      dispatch(setCustomer(customerDetails))
    }
  }

  const showToastSuccess = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.success(message)
  }

  const showToastError = message => {
    toastr.options = {
      positionClass: "toast-top-right",
      timeOut: 10000,
      extendedTimeOut: 1000,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.error(message)
  }

  return (
    <Form
      onSubmit={handleSubmit(handleCreateCustomer)}
      className=" mx-auto my-auto"
    >
      <Row className="form-group mx-1">
        <Col>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                CUSTOMER NAME
              </Label>
            </InputGroupAddon>
            <input
              id="Name"
              name="Name"
              value={Name}
              type="text"
              className="form-control form-control-lg input-text-size"
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event, props.setFormRequest)}
            />
          </InputGroup>
          {errors.Name && errors.Name.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
        <Col>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">
                CUSTOMER EMAIL
              </Label>
            </InputGroupAddon>
            <input
              id="Email"
              name="Email"
              value={Email}
              type="email"
              ref={register({ required: true })}
              className="form-control form-control-lg input-text-size"
              onChange={event => storeDataOnForm(event, props.setFormRequest)}
            />
          </InputGroup>
          {errors.Name && errors.Name.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
      </Row>
      <Row className="form-group mx-1">
        <Col>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <Label className="input-group-text input-text-size">MOBILE</Label>
            </InputGroupAddon>
            <select
              className="form-control  custom-select input-text-size"
              id="MobilePlus"
              name="MobilePlus"
              value={MobilePlus}
              ref={register({ required: true })}
              onChange={event => storeDataOnForm(event, props.setFormRequest)}
            >
              <option value=" +974" hidden="hidden">
                +974
              </option>
              {countryCodes?.map((cc, i) => {
                return (
                  <option value={"+" + cc.countryCode}>
                    +{cc.countryCode}
                  </option>
                )
              })}
            </select>
          </InputGroup>
          {errors.Name && errors.Name.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
        <Col>
          <InputGroup className="mb-3">
            <input
              id="Mobile"
              name="Mobile"
              value={Mobile}
              type="text"
              ref={register({ required: true })}
              className="form-control form-control-lg input-text-size"
              onChange={event => storeDataOnForm(event, props.setFormRequest)}
            />
          </InputGroup>
          {errors.Name && errors.Name.type === "required" && (
            <p className="color-danger">This is required</p>
          )}
        </Col>
      </Row>
      <Row className="">
        <Col className="item-center mb-3">
          <Button
            color="danger"
            outline
            className=" waves-effect waves-light mt-3 btn-lg mr-2"
            onClick={handleCancelButton}
          >
            <i className="fas fa-backspace mr-1" />
            Cancel
          </Button>

          <Button
            color="success"
            type="submit"
            className=" waves-effect waves-light mt-3 btn-lg"
          >
            <i className="mdi mdi-account-multiple-plus mr-1" />
            Enter New Customer
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
const mapStateToProps = state => {
  return {
    customer: state.Customer.customer,
    customerCreated: state.Customer.customerCreated,
    countryCodes: state.Options.countryCodes,
  }
}

export default connect(mapStateToProps)(AddNewCustomer)
