import React, { useState, useEffect } from "react"
import { Col, FormGroup, Input, Row, Button, Form } from "reactstrap"

const NotificationEmails = props => {
  const {
    notificationEmail,
    handleNotificationChange,
    handleNotificationDelete,
    validEmailList,
    setValidEmailList,
    index,
  } = props

  let inputEmail = "Email" + index

  useEffect(() => {
    // console.log(validEmailList)
  }, [validEmailList])

  function handleChange(changes) {
    handleNotificationChange(notificationEmail.id, {
      ...notificationEmail,
      ...changes,
    })
  }

  const [isEmailValid, setIsEmailValid] = useState()

  function handleEmail(value) {
    var pattern =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    if (pattern.test(value)) {
      setIsEmailValid(true)
      if (!validEmailList.includes(inputEmail)) {
        validEmailList.push(inputEmail)
      }
    } else {
      setIsEmailValid(false)
      if (validEmailList.includes(inputEmail)) {
        let tempArray = validEmailList.filter(
          item => !item.includes(inputEmail)
        )
        setValidEmailList(tempArray)
      }
    }

    handleChange({ Email: value })
  }

  return (
    <>
      <Row>
        <Col lg="8">
          <FormGroup>
            <input
              value={notificationEmail.Email}
              name={inputEmail}
              onChange={e => handleEmail(e.target.value)}
              onBlur={e => handleEmail(e.target.value)}
              type="email"
              className="form-control"
              placeholder="Enter Email"
            />

            {isEmailValid === false && (
              <small className="color-danger" style={{ float: "left" }}>
                Invalid Email.
              </small>
            )}
          </FormGroup>

          <FormGroup>
            <Input
              hidden
              defaultValue={notificationEmail.Date}
              readOnly
              name="Date"
              type="text"
              className="form-control"
              placeholder=""
            />
          </FormGroup>
        </Col>

        <Col lg="2">
          <FormGroup>
            {index > 0 && (
              <Button
                onClick={() =>
                  handleNotificationDelete(notificationEmail.id, index)
                }
                color="danger"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                {" "}
                <i className="mdi mdi-close-thick" />
              </Button>
            )}
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

export default NotificationEmails
