import { useState, useEffect } from "react"

const calculateRange = (data, rowsPerPage) => {
  // console.log(data)
  const range = []
  const num = Math.ceil(data.length / rowsPerPage)
  // console.log(num)
  let i = 1
  for (let i = 1; i <= num; i++) {
    range.push(i)
  }
  return range
}

const sliceData = (data, page, rowsPerPage) => {
  //console.log(data.slice((page - 1) * rowsPerPage, page * rowsPerPage))

  if (Array.isArray(data)) {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage)
  }
}

const useTable = (data, page, rowsPerPage) => {
  // const newData = data.filter(cus => cus.isActive === true)
  const [tableRange, setTableRange] = useState([])
  const [slice, setSlice] = useState([])

  useEffect(() => {
    if (Array.isArray(data)) {
      const range = calculateRange(data, rowsPerPage)
      setTableRange([...range])

      const slice = sliceData(data, page, rowsPerPage)
      setSlice([...slice])
    }
  }, [data, setTableRange, page, setSlice])

  return { slice, range: tableRange }
}

export default useTable
