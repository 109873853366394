export const SET_CUSTOMER = "SET_CUSTOMER"
export const SET_CUSTOMER_SUCCESS = "SET_CUSTOMER_SUCCESS"
export const SET_CUSTOMER_FAIL = "SET_CUSTOMER_FAIL"

export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST"
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS"
export const GET_CUSTOMER_LIST_FAIL = "GET_CUSTOMER_LIST_FAIL"

export const GET_CUSTOMERS_BY_MERCHANTID = "GET_CUSTOMERS_BY_MERCHANTID"
export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER"

export const GET_CUSTOMER_DETAIL = "GET_CUSTOMER_DETAIL"
export const GET_CUSTOMER_DETAIL_SUCCESS = "GET_CUSTOMER_DETAIL_SUCCESS"
export const GET_CUSTOMER_DETAIL_FAIL = "GET_CUSTOMER_DETAIL_FAIL"

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const UPDATE_CUSTOMER_RESULT_SUCCESS = "UPDATE_CUSTOMER_RESULT_SUCCESS"
export const UPDATE_CUSTOMER_RESULT_FAIL = "UPDATE_CUSTOMER_RESULT_FAIL"

export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL"

export const SELECT_CUSTOMER = "SELECT_CUSTOMER"
export const SELECT_CUSTOMER_SUCCESS = "SELECT_CUSTOMER_SUCCESS"
export const SELECT_CUSTOMER_FAIL = "SELECT_CUSTOMER_FAIL"

export const UPDATE_CUSTOMER_EXPRESS = "UPDATE_CUSTOMER_EXPRESS"
export const UPDATE_CUSTOMER_EXPRESS_SUCCESS = "UPDATE_CUSTOMER_EXPRESS_SUCCESS"
export const UPDATE_CUSTOMER_EXPRESS_FAIL =
  "UPDATE_CUSTOMER_RESULT_EXPRESS_FAIL"

export const SET_CUSTOMER_EXPRESS = "SET_CUSTOMER_EXPRESS"
export const SET_CUSTOMER_EXPRESS_SUCCESS = "SET_CUSTOMER_EXPRESS_SUCCESS"
export const SET_CUSTOMER_EXPRESS_FAIL = "SET_CUSTOMER_EXPRESS_FAIL"
export const RESTART_STATE = "RESTART_STATE"
