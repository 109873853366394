import React, { useEffect, useState } from 'react'
import { Col, Card, CardBody, Row, CardTitle, Button } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { isEmpty } from "lodash"
import OfficeIdsColumns from './DataFields/OfficeIdsColumns'
import AuthUser from "components/AuthUser/AuthUser"


export default function OfficeIds(props) {

  const { officeIds } = props
  const [officeIdList, setOfficeIdList] = useState([])
  const [user, setUser] = useState(AuthUser())
  const pageOptions = {
    sizePerPage: 5,
    totalSize: officeIds.length, 
    custom: true,
  }
  const { SearchBar } = Search



  // useEffect(() => {

  //   setOfficeIdList(officeIds)
  // }, [officeIds])

  useEffect(() => {
    if (!isEmpty(officeIds)) setOfficeIdList(officeIds)
  }, [officeIds])

  const handleTableChange = (type, { page, searchText }) => {
    setOfficeIdList(
      officeIdList?.filter(oid =>
        Object.keys(oid).some(
          key =>
            typeof oid[key] === "string" &&
            oid[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const isHidden = () => {

    if (user !== null && user !== undefined) {
      if (user.role === "SuperAdmin") {
        return <>
          <Button
            type="button"
            color="success"
            className="btn-rounded waves-effect waves-light mb-2 mr-2"
          >
            <i className="mdi mdi-plus mr-1" />
                Add Office Id
            </Button>

        </>
      }
      else {
        return <div></div>
      }
    }
  }

  return (
    <>
      <Card style={{ minHeight: "350px", maxHeight: "450px" }}>
        <CardTitle style={{ "backgroundColor": "#CCDFF1", "padding": "5px" }}><i className="fas fa-code-branch text-success mr-1" />Office Ids</CardTitle>
        <CardBody>
          <Row>
            <Col lg="12">

              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField={"_id"}
                    data={officeIdList}
                    columns={OfficeIdsColumns(user)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box mr-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar id="oidSearch"
                                  {...toolkitProps.searchProps}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-right">
                            {/* {isHidden()} */}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive

                                bordered={false}
                                striped={false}
                                classes={
                                  "table table-centered table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                onTableChange={handleTableChange}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>




            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
