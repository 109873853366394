import { call, put, takeEvery, takeLatest } from "redux-saga/effects"


import {
    GET_MERCHANT_PG_ACCOUNT_SETTING_OID,
    UPDATE_MERCHANT_PG_ACCOUNT_SETTING,
    RETURN_STATE,
  } from "./actionTypes"
  

import {
  getMerchantPGAccountSettingByOidSuccess,
  getMerchantPGAccountSettingByOidFail,
  updateMerchantPGAccontSettingsSuccess,
  updateMerchantPGAccontSettingsFail
} from "./actions"


import {
    getMerchantPGAccountSettingByOid,
    updateMerchantPGAccontSettings,
} from "helpers/fakebackend_helper"



function* _updateMerchantPgAccountSetting({ payload: data }) {
  try {
    const response = yield call(updateMerchantPGAccontSettings, data)

    if (response) {
      if (response?.isSuccess) yield put(updateMerchantPGAccontSettingsSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
                updateMerchantPGAccontSettingsFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(updateMerchantPGAccontSettingsFail(response.message))
      }
    } else yield put(updateMerchantPGAccontSettingsFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(updateMerchantPGAccontSettingsFail(error?.message))
  }
}



function* _getMerchantPGAccountSetting({ payload: oid }) {
  try {
    const response = yield call(getMerchantPGAccountSettingByOid, oid)

    if (response) {
      if (response?.isSuccess) yield put(getMerchantPGAccountSettingByOidSuccess(response.data))

      if (!response.isSuccess) {
        if (response.hasOwnProperty("_id")) {
          if (response._id)
            yield put(
                getMerchantPGAccountSettingByOidFail({
                message: "Error id: " + response._id + " " + response.message,
              })
            )
        }

        yield put(getMerchantPGAccountSettingByOidFail(response.message))
      }
    } else yield put(getMerchantPGAccountSettingByOidFail({ message: "Invalid Response." }))
  } catch (error) {
    yield put(getMerchantPGAccountSettingByOidFail(error?.message))
  }
}

function* merchantPGAccountSettings() {

  yield takeEvery(UPDATE_MERCHANT_PG_ACCOUNT_SETTING, _updateMerchantPgAccountSetting)
  yield takeEvery(GET_MERCHANT_PG_ACCOUNT_SETTING_OID, _getMerchantPGAccountSetting)
}

export default merchantPGAccountSettings
