import React, { useState, useEffect } from "react"
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Input
} from "reactstrap"



const ResendEmailModal = (props) => {
  const { isOpen, toggle, paymentrequestDetails, handleSendPaymentLinkEmail } = props

  const { register, handleSubmit, errors } = useForm();

  const [toggleModal, setToggleModal] = useState({toggle})

  const [drp_secondary, setDrp_secondary] = useState(false)

  const [showInput, setShowInput] = useState(false)

  const [dropDownValue, setDropDownValue] = useState("")

  const [selectedEmail, setSelectedEmail] = useState("")

  const [selectedPr, setSelectedPr] = useState(paymentrequestDetails)

  useEffect(() => {
    setSelectedPr(paymentrequestDetails)
    setSelectedEmail(paymentrequestDetails?.customerDetails?.email)
  }, [paymentrequestDetails])

  useEffect(() => {
    
  }, [selectedPr])


  useEffect(() => {

  }, [selectedEmail])

  useEffect(() => {

  }, [showInput, dropDownValue])

  function handleChangeEmail(e) {
    setSelectedEmail(e.target.value)

  }

  const onSubmit = (data) => {
    handleSendPaymentLinkEmail(selectedPr?._id,selectedEmail)
    setSelectedEmail("")
    toggle()
  }

  const onSubmitFailed = (data) => {
    console.warn(errors);
  }

  function handleNewEmail(value) {
  
    setSelectedEmail("")
    setDropDownValue(value)
    setShowInput(true)

  }

  function handleCurrentEmail(value) {
    // console.log(value)
    setDropDownValue(value)
    setSelectedEmail(selectedPr?.customerDetails?.email)
    setShowInput(false)
  }




  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">

        <form onSubmit={handleSubmit(onSubmit, onSubmitFailed)}>
          <ModalHeader toggle={toggle}>Resend Email Options</ModalHeader>
          <ModalBody style={{ minHeight: "170px"}}>
            <p>You are resending this Payment Request : <b>{selectedPr?.paymentRequestId}</b></p>
            <div className="btn-group mb-2">
              <div className="from-group ">

                <Row>
                  <Col lg={3}> <label >Send to : </label></Col>
                  <Col lg={9}>


                    <ButtonDropdown
                      isOpen={drp_secondary}
                      toggle={() => setDrp_secondary(!drp_secondary)}
                      name="emailBtn"
                      id="emailBtn"

                    >
                      <Button 
                      
                      style={{ minWidth: "300px", backgroundColor:"#bebebe", borderColor: "#bebebe", color:"black"}} 
                      disabled  
                      defaultValue={selectedEmail}
                      >
                        {selectedEmail}
                      </Button>

                      <DropdownToggle className="btn btn-info"    >
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>

                      <DropdownMenu style={{ minWidth: "300px", textAlign: "left" }} >

                        <DropdownItem value={selectedEmail} id="option1">
                          <div onClick={(e) => handleCurrentEmail(selectedEmail)} id="current">
                            <b>{selectedPr?.customerDetails?.email}</b>
                          </div>
                        </DropdownItem>
                        
                        <DropdownItem   id="option12">
                          <div onClick={(e) => handleNewEmail("")} id="newEmail">
                            Send to New Email ...
                              </div>
                        </DropdownItem>

                      </DropdownMenu>



                    </ButtonDropdown>

                    {showInput &&
                      <>
                        <Row>
                          <Col lg={8} style={{ "marginTop": "1rem" }}>
                            <input
                              name="email"
                              id="email"
                              type="text"
                              className="form-control"
                              style={{ minWidth: "300px", textAlign: "left" }}
                              ref={register({ required: true, pattern: /^\S+@\S+$/i})}
                              placeholder="Enter New Email..."
                              onChange={handleChangeEmail}
                            />

                            <span>{errors.email && errors.email.message}</span>

                          </Col>
                        </Row>

                      </>


                    }



                  </Col></Row>
              </div></div>{" "}

          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
          </Button>

            <Button type="submit" color="primary"

            >
              Send Email Now 
            </Button>
          </ModalFooter>
        </form>
      </div>
    </Modal>
  )
}

ResendEmailModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ResendEmailModal
