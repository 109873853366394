import React, { useState, useEffect } from "react"
import { getPaymentRequestList } from "store/actions"
import { Button, Card, CardBody, Col, Row, CardTitle } from "reactstrap"
import { connect, useDispatch } from "react-redux"
import AuthUser from "components/AuthUser/AuthUser"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import PaymentRequestColumns from "pages/PaymentRequestList/PaymentRequestColumns"
function LatestTransactions(props) {
  const [user, setUser] = useState(AuthUser())
  const dispatch = useDispatch()
  const [prList, setPrList] = useState([])
  const pageOptions = {
    sizePerPage: 10,
    totalSize: prList.length,
    custom: true,
    withFirstAndLast: true,
  }

  const { SearchBar } = Search

  useEffect(() => {
    if (user.role !== "SuperAdmin") {
      dispatch(getPaymentRequestList(user.merchantId))
    } else {
      dispatch(getPaymentRequestList("SuperAdmin"))
    }
  }, [])
  useEffect(() => {
    if (Array.isArray(props.paymentRequests)) {
      if (user.role === "User") {
        let topPR = props.paymentRequests.slice(0, 10)

        setPrList(topPR.filter(pr => pr.createdBy === user.userId))
      } else {
        let topPR = props.paymentRequests.slice(0, 10)
        setPrList(topPR)
      }
    }
  }, [props.paymentRequests, props.loading])

  useEffect(() => {
    // console.log(prList)
  }, [prList])

  const handleTableChange = (type, { page, searchText }) => {
    try {
      setPaymentRequestList(
        paymentrequests.paymentrequests.filter(pr =>
          Object.keys(pr).some(
            key =>
              typeof pr[key] === "string" &&
              pr[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    } catch {}
  }

  const handleOpenOverviewPrId = paymentRequestId => {
    const pr = prList.filter(x => x.paymentRequestId == paymentRequestId)
    const id = pr.map(function (p) {
      return p._id
    })
    const link = "/paymentrequest/overview/" + id
    window.open(link, "_blank")
  }

  const handleCopyLink = id => {
    // const pr = prList[index]
    // const shortLink = pr.generatedLinkShort
    const pr = prList.filter(x => x?._id == id)
    // console.log(pr)
    const shortLink = pr.map(function (p) {
      return p.generatedLinkShort
    })
    copy(shortLink)
    showToast(shortLink)
  }

  const handleResendEmail = id => {
    //setSelectedPrRow({})
    // const pr = prList[index]
    //console.log(pr)
    const pr = prList.filter(x => x?._id == id)
    setSelectedPrRow(pr[0])
    toggleModal()
  }

  const showToast = link => {
    toastr.options = {
      positionClass: "toast-top-right",
      // timeOut: timeOut,
      // extendedTimeOut: extendedTimeOut,
      closeButton: true,
      debug: false,
      progressBar: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 300,
    }

    toastr.success(link, "Link Copied!")
  }

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Latest 10 Transactions</CardTitle>
            {props.loading ? (
              <LoadingSpinnerThreeDots />
            ) : (
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="_id"
                    data={prList || []}
                    columns={PaymentRequestColumns(
                      handleOpenOverviewPrId,
                      handleCopyLink,
                      handleResendEmail,
                      user,
                      true
                    )}
                    loading={true}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                responsive
                                // remote={ {
                                //   filter: true, // not working
                                //   pagination: true, //not working
                                //   sort: true, // not working
                                //   cellEdit: false
                                // } }
                                loading={true}
                                bordered={false}
                                hover={true}
                                striped={false}
                                classes={"table table-centered table-nowrap"}
                                keyField="_id"
                                headerWrapperClasses={"thead-light"}
                                onTableChange={handleTableChange}
                                // rowEvents={ rowEvents }
                                // selectRow={selectRow}
                                filter={filterFactory()}
                                // defaultSorted={defaultSorted}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Link to="#" className="text-success">
                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />
                    Load more
                  </Link>
            </div>
          </Col>
        </Row> */}

                        <Row className="align-items-md-center mt-30">
                          <Col xs="6">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </Col>

                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return {
    paymentRequests: state.paymentRequests.paymentRequestList,
    loading: state.paymentRequests.loading,
  }
}
export default connect(mapStateToProps)(LatestTransactions)
